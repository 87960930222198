import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { XCircleIcon, CameraIcon } from '@heroicons/react/outline'
import star_fill from '../../img/star_fill.png'
import star_lineal from '../../img/star_lineal.png'
import Loader from '../UI/Loader'
import ReactBodymovin from 'react-bodymovin'
import animation from '../../img/check-circle.json'
import { ClockIcon, LocationMarkerIcon, ExclamationIcon, ChevronRightIcon, ArrowCircleLeftIcon, ArrowCircleRightIcon } from '@heroicons/react/solid'
import tools from '../../tools'
import connexion from '../../connexion'

class Inventory extends Component
{
	constructor(props)
	{
		super(props)
		this.state = {
			attempt_submit: false,
			accommodations: this.props.accommodations,
			accommodation: null,
			step: 0,
			screen: '',
			form1: {
				objects: [],
				reviews: []
			},
			form2: {
				pictures: [],
				prev: [],
				comment: ''
			},
			form3: {
				confirmation: false
			},
			answers: [],
			type: undefined,
			available_start: null,
			available_limit: null,
			inventory: null,
			error: this._initErrors()
		}
	}

	componentDidMount()
	{
		this.loadAccommodations()
		if (this.props.config_inventory !== null)
			this.loadInventory(this.props.config_inventory)
		else
		{
			this.props.fetchConfigInventory()
			.then(config_inventory => {
				this.loadInventory(config_inventory)
			})
			.catch(err => {
				console.log(err)
			})
		}
	}

	componentDidUpdate(prevProps, prevState)
	{
		if (prevState.type !== this.state.type)
		{
			const stay_number = (this.props.user.stay && this.props.user.stay.stay_number) ? this.props.user.stay.stay_number : null
			const location_category = (this.props.user.stay && this.props.user.stay.location_category) ? this.props.user.stay.location_category : null
			this.props.fetchInventory(stay_number, this.state.type, location_category)
			.then(inventory => {
				let available_start = 0
				let available_limit = 0
				const time = tools.getTimestamp()
				let screen = null
				if (this.state.type === "arrival")
				{
					available_start = this.props.user.stay.arrival_time
					available_limit = this.props.user.stay.arrival_time + (this.props.config_inventory.delay * 60 * 60)
					if (this.props.user.client.client_type === "resident")
						available_limit = this.props.user.stay.arrival_time + (24 * 60 * 60 * 60) // 2 mois
				}
				else if (this.state.type === "departure")
				{
					available_start = this.props.user.stay.departure_time - (this.props.config_inventory.delay * 60 * 60)
					available_limit = this.props.user.stay.departure_time
					if (this.props.user.client.client_type === "resident")
						available_start = this.props.user.stay.departure_time - (24 * 60 * 60 * 60) // 2 mois
				}
				if (time < available_start)
					screen = 'not-available'
				else if (inventory !== null)
					screen = 'recapitulatif'
				else if (time > available_limit && inventory !== null)
					screen = 'recapitulatif'
				else if (time > available_limit)
					screen = 'recapitulatif-empty'
				else
					screen = 'intro-delay'
				this.setState({ available_start: available_start, available_limit: available_limit, screen: screen, inventory: inventory })
			})
			.catch(err => {
				console.log(err)
			})
		}
	}

	_initErrors = () => {
		return {
			confirmation: null,
			complete: null
		}
	}

	setErrorMsg = (key, msg) => {
		const error = this._initErrors()
		error[key] = msg
		this.setState({ error: error })
	}

	loadAccommodations = (fromProps = false) => {
		if (fromProps === true)
			this.setState({ accommodations: this.props.accommodations })
		else
		{
			this.props.fetchAccommodations()
			.then(accommodations => {
				let accommodation = null
				for (let i = 0; i < accommodations.length; i++)
				{
					for (let j = 0; j < accommodations[i].location_category_list.length; j++)
					{
						if (accommodations[i].location_category_list[j] === this.props.user.stay.location_category)
						{
							accommodation = accommodations[i]
							break
						}
					}
				}
				const form1 = {
					objects: [],
					reviews: []
				}
				if (accommodation && accommodation.inventory)
				{
					const category = accommodation.inventory.categories[0]
					if (category.objects && category.objects.length > 0)
					{
						for (let j = 0; j < category.objects.length; j++)
						{
							form1.objects.push({
								translations: category.objects[j].translations,
								quantity: category.objects[j].quantity,
								count: category.objects[j].quantity,
								checked: true
							})
						}
					}
					if (category.reviews && category.reviews.length > 0)
					{
						for (let j = 0; j < category.reviews.length; j++)
						{
							form1.reviews.push({
								translations: category.reviews[j].translations,
								condition: 0,
								cleanliness: 0
							})
						}
					}
				}
				this.setState({ accommodation: accommodation, accommodations: accommodations, form1: form1 })
			})
			.catch(err => {
				console.log("could not fetch accommodations", err)
			})
		}
	}

	loadInventory = (config_inventory) => {
		if (this.state.type === undefined)
		{
			if (config_inventory.arrival === true && config_inventory.departure === false)
				this.setState({ type: 'arrival' })
			else if (config_inventory.arrival === false && config_inventory.departure === true)
				this.setState({ type: 'departure' })
			else if (config_inventory.arrival === false && config_inventory.departure === false)
				this.setState({ type: null })
		}
	}

	handleCount = (key, event) => {
		const objects = this.state.form1.objects
		objects[key].count = event.target.value
		if (this.state.form1.objects[key].count !== this.state.form1.objects[key].quantity)
			objects[key].checked = false
		else
			objects[key].checked = true
		const form1 = {
			...this.state.form1,
			objects: objects
		}
		this.setState({ form1: form1 })
	}

	handleCheckbox = (key) => {
		const objects = this.state.form1.objects
		objects[key].checked = this.state.form1.objects[key].checked
		objects[key].count = this.state.form1.objects[key].count
		if (objects[key].checked === true)
		{
			objects[key].count = ''
			objects[key].checked = false
			document.getElementById('number' + key).focus()
		}
		else
		{
			objects[key].count = this.state.form1.objects[key].quantity
			objects[key].checked = true
		}
		const form1 = {
			...this.state.form1,
			objects: objects
		}
		this.setState({ form1: form1 })
	}

	handleComment = (event) => {
		const form2 = {
			...this.state.form2,
			comment: event.target.value
		}
		this.setState({ form2: form2 })
	}

	addPicture = (event) => {
		const data = new FormData()
		data.append("file", event.target.files[0])
		data.append("path", "inventories")
		data.append("sizes[0]", 1920)
		data.append("sizes[1]", 1280)
		data.append("sizes[2]", 192)
		this.props.postInsertPicture(data)
		.then(ret => {
			const pictures = this.state.form2.pictures
			const prev = this.state.form2.prev
			pictures.push(ret._id)
			prev.push({
				img: connexion.connect.url + 'dashboard/files/get-image/' + String(ret._id) + '/150',
				name: ret.name,
				originalname: ret.originalname
			})
			const form2 = {
				...this.state.form2,
				pictures: pictures,
				prev: prev
			}
			this.setState({ form2: form2 })
		})
		.catch(err => console.log(err))
	}

	deletePic = (elem) => {
		const pictures = this.state.form2.pictures
		pictures.splice(elem, 1)
		const form2 = {
			...this.state.form2,
			pictures: pictures
		}
		this.setState({ form2: form2 })
	}

	handleConfirmation = () => {
		const form3 = {
			confirmation: (this.state.form3.confirmation === true) ? false : true
		}
		this.setState({ form3: form3, error: this._initErrors() })
	}

	onSubmit = (event) => {
		event.preventDefault()
		let step = this.state.step
		if (step + 1 < this.state.accommodation.inventory.categories.length)
		{
			let error = false
			const answers = this.state.answers
			if (this.state.form1.objects && this.state.form1.objects.length > 0)
			{
				for (let j = 0; j < this.state.form1.objects.length; j++)
				{
					if (this.state.form1.objects[j].count === "")
					{
						error = true
						break
					}
				}
			}
			if (this.state.form1.reviews && this.state.form1.reviews.length > 0)
			{
				for (let j = 0; j < this.state.form1.reviews.length; j++)
				{
					if (this.state.form1.reviews[j].condition === 0 || this.state.form1.reviews[j].cleanliness === 0)
					{
						error = true
						break
					}
				}
			}
			if (error === true)
				this.setErrorMsg('complete', this.props.lang_assets.inventory.confirmation.error_complete)
			else
			{
				answers.push({
					translations: this.state.accommodation.inventory.categories[step].translations,
					...this.state.form1
				})
				const form1 = {
					objects: [],
					reviews: []
				}
				step++
				const category = this.state.accommodation.inventory.categories[step]
				if (category.objects && category.objects.length > 0)
				{
					for (let j = 0; j < category.objects.length; j++)
					{
						form1.objects.push({
							translations: category.objects[j].translations,
							quantity: category.objects[j].quantity,
							count: category.objects[j].quantity,
							checked: true
						})
					}
				}
				if (category.reviews && category.reviews.length > 0)
				{
					for (let j = 0; j < category.reviews.length; j++)
					{
						form1.reviews.push({
							translations: category.reviews[j].translations,
							condition: 0,
							cleanliness: 0
						})
					}
				}
				this.setState({ answers: answers, step: step, form1: form1, error: this._initErrors()})
			}
		}
		else if (step + 1 === this.state.accommodation.inventory.categories.length)
		{
			let error = false
			const answers = this.state.answers
			if (this.state.form1.objects && this.state.form1.objects.length > 0)
			{
				for (let j = 0; j < this.state.form1.objects.length; j++)
				{
					if (this.state.form1.objects[j].count === "")
					{
						error = 1
						break
					}
				}
			}
			if (this.state.form1.reviews && this.state.form1.reviews.length > 0)
			{
				for (let j = 0; j < this.state.form1.reviews.length; j++)
				{
					if (this.state.form1.reviews[j].condition === 0 || this.state.form1.reviews[j].cleanliness === 0)
					{
						error = 2
						break
					}
				}
			}
			if (error !== false)
				this.setErrorMsg('complete', this.props.lang_assets.inventory.confirmation.error_complete)
			else
			{
				answers.push({
					translations: this.state.accommodation.inventory.categories[step].translations,
					...this.state.form1
				})
				step++
				this.setState({ answers: answers, step: step, error: this._initErrors() })
			}
		}
		else if (step === this.state.accommodation.inventory.categories.length) // commentaire et photo
		{
			step++
			this.setState({ step: step })
		}
		else
		{
			if (this.state.form3.confirmation === true)
			{
				this.setState({ attempt_submit: true })
				const inventory = {
					answers: this.state.answers,
					pictures: this.state.form2.pictures,
					comment: this.state.form2.comment,
					type: this.state.type,
					user: this.props.user._id,
					stay_number: this.props.user.stay.stay_number
				}
				this.props.postInventory(inventory)
				.then(ret => {
					if (ret)
						this.setState({ step: step + 1, attempt_submit: false })
					else
					{
						this.setState({ attempt_submit: false })
						console.log("can not submit inventory")
					}
				})
				.catch(err => console.log(err))
			}
			else
				this.setErrorMsg('confirmation', this.props.lang_assets.inventory.confirmation.error_confirmation)
		}
	}

	renderMonth = (param) => {
		const date = new Date(param * 1000)
		let month = date.getMonth() + 1
		if (month < 10)
			month = '0' + month
		return (month)
	}

	renderDay = (param) => {
		const date = new Date(param * 1000)
		return (date.getDate())
	}

	renderHours = (param) => {
		const date = new Date(param * 1000)
		let hours = date.getHours()
		if (hours < 10)
			hours = '0' + hours
		return (hours)
	}

	renderMin = (param) => {
		const date = new Date(param * 1000)
		let minutes = date.getMinutes()
		if (minutes < 10)
			minutes = '0' + minutes
		return (minutes)
	}

	selectInventoryType = (type) => {
		this.setState({ type: type, screen: null })
	}

	submitIntro = () => {
		let comment = null
		if (this.props.config_inventory.translations)
		{
			for (let i = 0; i < this.props.config_inventory.translations.length; i++)
			{
				if (this.props.config_inventory.translations[i].lang === this.props.user.lang)
				{
					comment = this.props.config_inventory.translations[i].comment
					break
				}
			}
		}
		if (this.state.screen === 'intro-delay' && comment && comment.length > 0)
			this.setState({ screen: 'intro-client' })
		else
			this.setState({ screen: 'inventaire' })
	}

	clickCleanlinessNotation = (note, itemIdx) => {
		const form1 = this.state.form1
		form1.reviews[itemIdx].cleanliness = note
		this.setState({ form1: form1 })
	}

	clickConditionNotation = (note, itemIdx) => {
		const form1 = this.state.form1
		form1.reviews[itemIdx].condition = note
		this.setState({ form1: form1 })
	}

	render() {
		const bodymovinOptions = {
			loop: false,
			autoplay: true,
			prerender: true,
			animationData: animation
		}
		const lang_assets = this.props.lang_assets.inventory
		const availableStartDay = this.renderDay(this.state.available_start)
		const availableStartMonth = this.renderMonth(this.state.available_start)
		const availableStartHours = this.renderHours(this.state.available_start)
		const availableStartMin = this.renderMin(this.state.available_start)
		const availableLimitDay = this.renderDay(this.state.available_limit)
		const availableLimitMonth = this.renderMonth(this.state.available_limit)
		const availableLimitHours = this.renderHours(this.state.available_limit)
		const availableLimitMin = this.renderMin(this.state.available_limit)
		if (this.props.config_inventory === null || this.state.screen === null || this.state.accommodation === null || !this.state.accommodation.inventory || !this.state.accommodation.inventory.categories)
		{
			return (
				<div className="loading">
					<Loader />
				</div>
			)
		}
		else if (this.state.type === undefined)
		{
			// CHOIX INVENTAIRE D'ENTRÉE / SORTIE

			return (
				<div className="min-h-4/5-screen">
					{/* <ButtonPrevious
						theme={this.props.theme}
						// handlePreviousBtn={this.props.handlePreviousBtn}
					/> */}
					<div className="flex justify-center items-center z-0 w-full fixed top-0 h-36" style={{ backgroundColor: '#' + this.props.theme.color1 }}>
						<div className="text-white text-3xl">
							<h1>{lang_assets.inventory.title}</h1>
						</div>
					</div>
					<div className="bg-white w-full pt-10 px-5 mt-32 min-h-9/10-screen absolute rounded-t-3xl pb-10">
						<div className="mx-4">
							<h2 className="text-2xl font-semibold text-gray-700 mt-8">{lang_assets.inventory.arrival_departure}</h2>
							<div className="flex flex-col">
								<button onClick={this.selectInventoryType.bind(this, 'arrival')} className="relative mt-10 bg-sky-100 rounded-lg px-6 py-5 flex items-center space-x-3 focus:bg-sky-200 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
									<span className="h-10 w-10 rounded-full overflow-hidden bg-teal-50">
										<ArrowCircleRightIcon className="text-sky-300"/>
									</span>
									<div className="flex-1 min-w-0">
										<div className="focus:outline-none text-left">
											<span className="absolute inset-0" aria-hidden="true" />
											<p className="text-sm font-medium text-sky-800">{lang_assets.inventory.checkin_inv}</p>
											<p className="text-sm text-sky-500">{lang_assets.inventory.arrival}</p>
										</div>
									</div>
									<div className="flex-none justify-end mr-2">
										<ChevronRightIcon className="h-8 opacity-50"/>
									</div>
								</button>
								<button onClick={this.selectInventoryType.bind(this, 'departure')} className="relative mt-6 bg-emerald-100 rounded-lg px-6 py-5 flex items-center space-x-3 hover:border-gray-400 focus:bg-emerald-200 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
									<span className="h-10 w-10 rounded-full overflow-hidden bg-teal-50">
										<ArrowCircleLeftIcon className="text-emerald-300"/>
									</span>
									<div className="flex-1 min-w-0">
										<div className="focus:outline-none text-left">
											<span className="absolute inset-0" aria-hidden="true" />
											<p className="text-sm font-medium text-emerald-800">{lang_assets.inventory.checkout_inv}</p>
											<p className="text-sm text-emerald-500">{lang_assets.inventory.departure}</p>
										</div>
									</div>
									<div className="flex-none justify-end mr-2">
										<ChevronRightIcon className="h-8 opacity-50"/>
									</div>
								</button>
							</div>
						</div>
					</div>
				</div>
			)
		}
		else if (this.state.type === null)
		{
			return (
				<Redirect to="/membres" />
			)
		}
		else if (this.state.screen === "not-available")
		{
			// PAS ENCORE ACCÈS À L'INVENTAIRE

			let subtitle = null
			if (this.state.type === "arrival")
				subtitle = lang_assets.inventory.nr_arrival
			else if (this.state.type === "departure")
				subtitle = lang_assets.inventory.nr_departure
			console.log("inventory", this.state.accommodation.inventory)
			const location_category = (this.props.user && this.props.user.stay && this.props.user.stay.location_category) ? this.props.user.stay.location_category : 'Hébergement inconnu'
			const location = (this.props.user && this.props.user.stay && this.props.user.stay.location) ? this.props.user.stay.location : 'non trouvé'
			return (
				<div className="min-h-4/5-screen">
					{/* <ButtonPrevious
						theme={this.props.theme}
						// handlePreviousBtn={this.props.handlePreviousBtn}
					/> */}
					<div className="flex justify-center items-center z-0 w-full fixed top-0 h-36" style={{ backgroundColor: '#' + this.props.theme.color1 }}>
						<div className="text-white text-3xl">
							<h1>{lang_assets.inventory.title}</h1>
						</div>
					</div>
					<div className="bg-white w-full pt-10 px-5 mt-32 min-h-9/10-screen absolute rounded-t-3xl pb-10">
						<div className="flex flex-col">
							<div className="w-full flex justify-center mt-10">
								<div className="bg-red-100 w-24 h-24 rounded-3xl flex justify-center items-center">
									<span className="text-6xl" role="img" aria-label="sleepy-face">😴</span>
								</div>
							</div>
							<div className="px-10 mt-8 text-center">
								<h2 className="font-semibold text-xl text-gray-700">{lang_assets.inventory.not_ready}</h2>
								<p className="text-base font-normal text-gray-400 mt-3">{subtitle}</p>
								<div className="flex flex-row mt-8">
								<ClockIcon className="w-5 h-5 text-gray-300 mr-1.5"/>
									<p className="text-base">{lang_assets.inventory.ready_at} <span style={{ color: '#' + this.props.theme.color3 }} className="font-extrabold">{availableStartDay}/{availableStartMonth}</span> {lang_assets.inventory.at} <span style={{ color: '#' + this.props.theme.color3 }} className="font-extrabold">{availableStartHours}h{availableStartMin}</span></p>
								</div>
							</div>
						</div>
						<h2 className="mx-8 mt-4 text-xl font-semibold text-gray-700">{location_category}</h2>
						<div className="flex flex-row  items-center mx-8 mt-3">
							<LocationMarkerIcon className="w-4 h-4 text-gray-300 mr-1.5"/>
							<p className="text-sm text-gray-500">{lang_assets.inventory.location} {location}</p>
						</div>
						<div className="px-8 pt-3">
							{this.state.accommodation.inventory.categories.map((category, catId) => {
								let cat_name = ""
								if (category.translations)
								{
									for (let i = 0; i < category.translations.length; i++)
									{
										if (category.translations[i].lang === this.props.user.lang)
										{
											cat_name = category.translations[i].name
											break
										}
									}
								}
								return ( 
									<div key={catId}>
										<h3 className="flex flex-row text-sm mt-4 text-gray-400 justify-between ml-3">
											{cat_name}
										</h3>
										<ul className="divide-y divide-gray-200 rounded-md bg-gray-50 py-4 px-6">
											{this.state.accommodation.inventory.categories[catId].objects.map((item, itemId) => {
												let name = ""
												if (item.translations)
												{
													for (let i = 0; i < item.translations.length; i++)
													{
														if (item.translations[i].lang === this.props.user.lang)
														{
															name = item.translations[i].name
															break
														}
													}
												}
												console.log("name", name)
												return (
													<li key={itemId} className="py-4 flex flex-row text-sm text-gray-400 justify-between">
														<p className="text-sm font-medium text-gray-600 opacity-90">{name}</p>
														<p className="text-sm font-medium text-gray-400 opacity-90">x{item.quantity}</p>
													</li>
												)
											})}
										</ul>
									</div>
								)
							})}
						</div>
						<p className="px-8 mt-3 text-sm text-gray-400 italic">{lang_assets.inventory.too_late}</p>
					</div>
				</div>
			)
		}
		else if (this.state.screen === "recapitulatif")
		{
			// TIMES UP, RECAP DE L'INVENTAIRE

			const location_category = (this.state.inventory && this.state.inventory.stay && this.state.inventory.stay.location_category) ? this.state.inventory.stay.location_category : 'Hébergement inconnu'
			const location = (this.state.inventory && this.state.inventory.stay && this.state.inventory.stay.location) ? this.state.inventory.stay.location : 'non trouvé'
			const inventory = (this.state.inventory && this.state.inventory.inventory) ? this.state.inventory.inventory : []
			const sent_time = (this.state.inventory && this.state.inventory.create_time) ? this.state.inventory.create_time : tools.getTimestamp()
			const sentDay = this.renderDay(sent_time)
			const sentMonth = this.renderMonth(sent_time)
			const sentHours = this.renderHours(sent_time)
			const sentMin = this.renderMin(sent_time)
			let available_limit = 0
			const time = tools.getTimestamp()
			if (this.state.type === "arrival")
				available_limit = this.props.user.stay.arrival_time + (this.props.config_inventory.delay * 60 * 60)
			else if (this.state.type === "departure")
				available_limit = this.props.user.stay.departure_time
			let btn = (<p className="px-8 mt-3 text-sm text-gray-400 italic">{lang_assets.inventory.too_late}</p>)
			if (time < available_limit)
			{
				btn = (
					<form onSubmit={this.submitIntro}>
						<button className="font-semibold py-5 w-full rounded-full text-white shadow-xl text-xl my-10 waves-effect waves-light" type="submit" style={{backgroundColor: "#" + this.props.theme.color3}}>{lang_assets.inventory.update}</button>
					</form>
				)
			}
			let nameStyle = "text-base font-medium text-green-600 flex-none w-32 pb-2 pt-2 opacity-90" // styles pour reviews (lifirst)
			let qtStyle = "text-base font-medium text-green-600 flex-auto w-16 opacity-90 text-right" // styles pour reviews (lifirst)
			return (
				<div className="min-h-4/5-screen">
					{/* <ButtonPrevious
						theme={this.props.theme}
						// handlePreviousBtn={this.props.handlePreviousBtn}
					/> */}
					<div className="flex justify-center items-center z-0 w-full fixed top-0 h-36" style={{ backgroundColor: '#' + this.props.theme.color1 }}>
						<div className="text-white text-3xl">
							<h1>{lang_assets.inventory.title}</h1>
						</div>
					</div>
					<div className="bg-white w-full pt-10 px-5 mt-32 min-h-9/10-screen absolute rounded-t-3xl pb-10">
						<h2 className="mx-8 mt-4 text-xl font-semibold text-gray-700">{location_category}</h2>
						<div className="flex flex-row  items-center mx-8 mt-3">
							<LocationMarkerIcon className="w-4 h-4 text-gray-300 mr-1.5"/>
							<p className="text-sm text-gray-500">{lang_assets.inventory.location} {location}</p>
						</div>
						<div className="flex flex-row  items-center mx-8 mt-1.5">
							<ClockIcon className="w-4 h-4 text-gray-300 mr-1.5"/>
							<p className="text-sm text-gray-500">{lang_assets.inventory.sent_at} {sentDay}/{sentMonth} {lang_assets.inventory.at} {sentHours}h{sentMin}</p>
						</div>
						<div className="px-8 pt-3">
							{inventory.map((category, catId) => {
								let liFirst = null
								if (inventory[catId].reviews.length > 0)
								{
									liFirst = (
										<div className="flex flex-row text-sm text-gray-400 justify-between">
											<div className={nameStyle}></div>
											<div className={qtStyle}>{lang_assets.inventory.condition_sm}</div>
											<div className={qtStyle}>{lang_assets.inventory.cleanliness_sm}</div>
										</div>
									)
								}
								return ( 
									<div key={catId}>
										<h3 className="flex flex-row text-sm mt-4 text-gray-400 justify-between ml-3">
											{category.name}
										</h3>
										<ul className="divide-y divide-gray-200 rounded-md bg-gray-50 py-4 px-6">
											{inventory[catId].objects.map((item, itemId) => {
												nameStyle = "text-sm font-medium text-green-600 opacity-90"
												qtStyle = "text-sm font-medium text-green-600 opacity-90"
												if (item.count !== item.quantity)
												{
													nameStyle = "text-sm font-medium text-red-600 opacity-50"
													qtStyle = "text-sm font-medium text-red-600 opacity-50"
												}
												let name = ""
												if (item.translations)
												{
													for (let i = 0; i < item.translations.length; i++)
													{
														if (item.translations[i].lang === this.props.user.lang)
														{
															name = item.translations[i].name
															break
														}
													}
												}
												else if (!item.translations && item.name) // quickfix translations
													name = item.name
												return (
													<li key={"objects" + itemId} className="py-4 flex flex-row text-sm text-gray-400 justify-between">
														<p className={nameStyle}>{name}</p>
														<p className={qtStyle}>{item.count}/{item.quantity}</p>
													</li>
												)
											})}
											{liFirst}
											{inventory[catId].reviews.map((item, itemId) => {
												nameStyle = "text-base font-medium text-green-600 flex-none w-32 pb-2 pt-2 opacity-90" // styles pour reviews
												qtStyle = "text-base font-medium text-green-600 flex-auto w-16 pb-2 pt-2 opacity-90 text-right" // styles pour reviews
												let name = ""
												if (item.translations)
												{
													for (let i = 0; i < item.translations.length; i++)
													{
														if (item.translations[i].lang === this.props.user.lang)
														{
															name = item.translations[i].name
															break
														}
													}
												}
												else if (!item.translations && item.name) // quickfix translations
													name = item.name
												return (
													<>
														<div key={"reviews" + itemId} className="flex flex-row text-base text-gray-400 justify-between">
															<p className={nameStyle}>{name}</p>
															<p className={qtStyle}>{item.condition}/5</p>
															<p className={qtStyle}>{item.cleanliness}/5</p>
														</div>
													</>
												)
											})}
										</ul>
									</div>
								)
							})}
						</div>
						{btn}
					</div>
				</div>
			)
		}
		else if (this.state.screen === "recapitulatif-empty")
		{
			// RECAPTIULATIF VIDE (TROP TARD)

			const location_category = (this.props.user && this.props.user.stay && this.props.user.stay.location_category) ? this.props.user.stay.location_category : 'Hébergement inconnu'
			const location = (this.props.user && this.props.user.stay && this.props.user.stay.location) ? this.props.user.stay.location : 'non trouvé'
			return (
				<div className="min-h-4/5-screen">
					{/* <ButtonPrevious
						theme={this.props.theme}
						// handlePreviousBtn={this.props.handlePreviousBtn}
					/> */}
					<div className="flex justify-center items-center z-0 w-full fixed top-0 h-36" style={{ backgroundColor: '#' + this.props.theme.color1 }}>
						<div className="text-white text-3xl">
							<h1>{lang_assets.inventory.title}</h1>
						</div>
					</div>
					<div className="bg-white w-full pt-10 px-5 mt-32 min-h-9/10-screen absolute rounded-t-3xl pb-10">
						<h2 className="mx-8 mt-4 text-xl font-semibold text-gray-700">{location_category}</h2>
						<div className="flex flex-row  items-center mx-8 mt-3">
							<LocationMarkerIcon className="w-4 h-4 text-gray-300 mr-1.5"/>
							<p className="text-sm text-gray-500">{lang_assets.inventory.location} {location}</p>
						</div>
						<div className="px-8 pt-3">
							{this.state.accommodation.inventory.categories.map((category, catId) => {
								return ( 
									<div key={catId}>
										<h3 className="flex flex-row text-sm mt-4 text-gray-400 justify-between ml-3">
											{category.name}
										</h3>
										<ul className="divide-y divide-gray-200 rounded-md bg-gray-50 py-4 px-6">
											{this.state.accommodation.inventory.categories[catId].objects.map((item, itemId) => {
												let name = ""
												if (item.translations)
												{
													for (let i = 0; i < item.translations.length; i++)
													{
														if (item.translations[i].lang === this.props.user.lang)
														{
															name = item.translations[i].name
															break
														}
													}
												}
												return (
													<li key={itemId} className="py-4 flex flex-row text-sm text-gray-400 justify-between">
														<p className="text-sm font-medium text-gray-600 opacity-90">{name}</p>
														<p className="text-sm font-medium text-gray-400 opacity-90">0/{item.quantity}</p>
													</li>
												)
											})}
										</ul>
									</div>
								)
							})}
						</div>
						<p className="px-8 mt-3 text-sm text-gray-400 italic">{lang_assets.inventory.too_late}</p>
					</div>
				</div>
			)
		}
		else if (this.state.screen === "intro-delay")
		{
			// INTRODUCTION : DATE LIMITE

			return (
				<div className="min-h-4/5-screen">
					{/* <ButtonPrevious
						theme={this.props.theme}
						// handlePreviousBtn={this.props.handlePreviousBtn}
					/> */}
					<div className="flex justify-center items-center z-0 w-full fixed top-0 h-36" style={{ backgroundColor: '#' + this.props.theme.color1 }}>
						<div className="text-white text-3xl">
							<h1>{lang_assets.inventory.title}</h1>
						</div>
					</div>
					<div className="bg-white w-full pt-10 px-5 mt-32 min-h-9/10-screen absolute rounded-t-3xl pb-10">
						<div className="mx-4">
							<div className="w-full flex justify-center">
								<div className="bg-gray-100 w-24 h-24 rounded-3xl flex justify-center items-center">
									<span className="text-6xl">🗓</span>
								</div>
							</div>
							<h2 className="text-2xl font-semibold text-gray-700 mt-8">{lang_assets.intro.how_it_works}</h2>
							<div className="flex flex-row mt-8">
								<ClockIcon className="w-5 h-5 text-gray-300 mr-1.5"/>
								<p className="text-base">{lang_assets.intro.due_date} : <span style={{ color: '#' + this.props.theme.color3 }} className="font-extrabold">{availableLimitDay}/{availableLimitMonth}</span> {lang_assets.inventory.at} <span style={{ color: '#' + this.props.theme.color3 }} className="font-extrabold">{availableLimitHours}h{availableLimitMin}</span></p>
							</div>
							<p className="text-base mt-4">{lang_assets.intro.signalement}</p>
							<div className="rounded-md bg-yellow-50 p-4 mt-8">
								<div className="flex">
									<div className="flex-shrink-0">
										<ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
									</div>
									<div className="ml-3">
										<h3 className="text-sm font-medium text-yellow-800">{lang_assets.intro.info}</h3>
									</div>
								</div>
							</div>
						</div>
						<button className="font-semibold py-5 w-full rounded-full text-white shadow-xl text-xl my-10 waves-effect waves-light" onClick={this.submitIntro} style={{backgroundColor: "#" + this.props.theme.color3}}>{lang_assets.attached_files.button}</button>
					</div>
				</div>
			)
		}
		else if (this.state.screen === "intro-client")
		{
			// INTRODUCTION

			let comment = ""
			for (let i = 0; i < this.props.config_inventory.translations.length; i++)
			{
				if (this.props.config_inventory.translations[i].lang === this.props.user.lang)
				{
					comment = this.props.config_inventory.translations[i].comment
					break
				}
			}
			return (
				<div className="min-h-4/5-screen">
					{/* <ButtonPrevious
						theme={this.props.theme}
						// handlePreviousBtn={this.props.handlePreviousBtn}
					/> */}
					<div className="flex justify-center items-center z-0 w-full fixed top-0 h-36" style={{ backgroundColor: '#' + this.props.theme.color1 }}>
						<div className="text-white text-3xl">
							<h1>{lang_assets.inventory.title}</h1>
						</div>
					</div>
					<div className="bg-white w-full pt-10 px-5 mt-32 min-h-9/10-screen absolute rounded-t-3xl pb-10">
						<div className="mx-4">
							<div className="w-full flex justify-center">
								<div className="bg-gray-100 w-24 h-24 rounded-3xl flex justify-center items-center">
									<span className="text-6xl" role="img" aria-label="nerd-face">🤓</span>
								</div>
							</div>
							<h2 className="text-2xl font-semibold text-gray-700 mt-8">{lang_assets.intro.good_to_know}</h2>
							<p className="text-base mt-5">{comment}</p>
						</div>
						<button className="font-semibold py-5 w-full rounded-full text-white shadow-xl text-xl my-10 waves-effect waves-light" onClick={this.submitIntro} style={{backgroundColor: "#" + this.props.theme.color3}}>{lang_assets.attached_files.button}</button>
					</div>
				</div>
			)
		}
		else if (this.state.step === this.state.accommodation.inventory.categories.length) // step = length : photos-commentaire
		{
			// PHOTOS + COMMENTAIRE

			return (
				<div>
					{/* <ButtonPrevious
						theme={this.props.theme}
						// handlePreviousBtn={this.props.handlePreviousBtn}
					/> */}
					<div style={{backgroundColor: "#" + this.props.theme.color3}} className="h-52 z-0 px-8 text-center">
						<p className="text-2xl text-white pt-14"></p>
						<h1 className="text-3xl text-white font-semibold m-0 pt-2">{lang_assets.attached_files.title}<span role="img" aria-label="thinking-face">🧐</span></h1>
					</div>
					<div className="rounded-t-3xl z-10" style={{backgroundColor: "#ffffff", marginTop: "-2rem"}}>
						<div className="px-10 pt-16 pb-10">
							<form onSubmit={this.onSubmit} encType="multipart/form-data">
								<div className="mt-5">
									<label className="text-lg font-medium text-gray-900">{lang_assets.attached_files.header}<span role="img" aria-label="Pen">✏️</span></label>
									<ul className="mt-1 grid grid-cols-3 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
										{this.state.form2.prev.map((elem, itemIdx) => {
											return (
												<li key={itemIdx} className="relative">
													<div className="group block w-full aspect-w-7 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
														<img src={elem.img} alt="" className="object-cover pointer-events-none group-hover:opacity-75"/>
														<button type="button" className="absolute inset-0 focus:outline-none">
															<span className="sr-only">View details for {elem.originalname}</span>
														</button>
													</div>
													<span className="absolute top-0 right-0 block h-6 w-6 rounded-full ring-2 ring-white bg-gray-700" onClick={this.deletePic.bind(this, itemIdx)}>
														<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white">
															<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
														</svg>
													</span>
													<p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">{elem.originalname}</p>
												</li>
											)
										})}
									</ul>
									<div>
										<label htmlFor="file" className="block relative mt-3 inline-flex items-center p-3 border border-transparent h-12 w-12 rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
											<CameraIcon className="h-6 w-6" aria-hidden="true" />
										</label>
										<input 
											className="opacity-0 w-0.5 h-0.5 absolute"
											type="file"
											onChange={this.addPicture}
											id="file"
										/>
									</div>
								</div>
								<div className="mt-5">
									<label htmlFor="comment" className="block text-sm font-medium text-gray-700">{lang_assets.attached_files.textarea_title}</label>
									<div className="mt-1">
										<textarea 
											rows="4" 
											name="comment" 
											id="comment" 
											className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" 
											value={this.state.form1.comment}
											maxLength='500'
											onChange={this.handleComment}
										>
										</textarea>
									</div>
								</div>
								<button className="font-semibold py-5 w-full rounded-full text-white shadow-xl text-xl my-10 waves-effect waves-light" type="submit" style={{backgroundColor: "#" + this.props.theme.color3}}>{lang_assets.attached_files.button}</button>
							</form>
						</div>
					</div>
				</div>
			)
		}
		else if (this.state.step === this.state.accommodation.inventory.categories.length + 1)
		{
			// RECAPITULATIF FINAL

			let error = null
			if (this.state.error.confirmation)
			{
				error = (
					<div className="flex">
						<div className="flex-shrink-0">
							<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
						</div>
						<div className="ml-3">
							<h3 className="text-sm font-medium text-red-800">{this.state.error.confirmation}</h3>
						</div>
					</div>
				)
			}
			let send_btn = (<button className="font-semibold py-5 w-full rounded-full text-white shadow-xl text-xl my-10 waves-effect waves-light" type="submit" style={{backgroundColor: "#" + this.props.theme.color3}}>{lang_assets.confirmation.button}</button>)
			if (this.state.attempt_submit === true)
			{
				send_btn = (
					<div className="text-center">
						<Loader />
					</div>
				)
			}
			let nameStyle = "text-base font-medium text-green-600 flex-none w-32 pb-2 pt-2 opacity-90" // styles pour reviews (lifirst)
			let qtStyle = "text-base font-medium text-green-600 flex-auto w-16 opacity-90 text-right" // styles pour reviews (lifirst)
			return (
				<div>
					{/* <ButtonPrevious
						theme={this.props.theme}
						// handlePreviousBtn={this.props.handlePreviousBtn}
					/> */}
					<div style={{backgroundColor: "#" + this.props.theme.color3}} className="h-52 z-0 px-8 text-center">
						<p className="text-2xl text-white pt-14"></p>
						<h1 className="text-3xl text-white font-semibold m-0 pt-2">{lang_assets.confirmation.title}</h1>
					</div>
					<div className="rounded-t-3xl z-10" style={{backgroundColor: "#ffffff", marginTop: "-2rem"}}>
						<form onSubmit={this.onSubmit}>
							<div className="px-10 pt-16 pb-10">
								{this.state.answers.map((category, catId) => {
									let liFirst = null
									if (this.state.answers[catId].reviews.length > 0)
									{
										liFirst = (
											<div className="flex flex-row text-sm text-gray-400 justify-between">
												<div className={nameStyle}></div>
												<div className={qtStyle}>{lang_assets.inventory.condition_sm}</div>
												<div className={qtStyle}>{lang_assets.inventory.cleanliness_sm}</div>
											</div>
										)
									}
									let category_name = ""
									for (let i = 0; i < category.translations.length; i++)
									{
										if (category.translations[i].lang === this.props.user.lang)
										{
											category_name = category.translations[i].name
											break
										}
									}
									return (
										<div key={catId}>
											<h3 className="flex flex-row text-sm mt-4 text-gray-400 justify-between ml-3">
												{category_name}
											</h3>
											<ul className="divide-y divide-gray-200 rounded-md bg-gray-50 py-4 px-6">
												{this.state.answers[catId].objects.map((item, itemId) => {
													let nameStyle = "text-sm font-medium text-green-600 opacity-90"
													let qtStyle = "text-sm font-medium text-green-600 opacity-90"
													if (item.count !== item.quantity)
													{
														nameStyle = "text-sm font-medium text-red-600 opacity-50"
														qtStyle = "text-sm font-medium text-red-600 opacity-50"
													}
													let name = ""
													if (item.translations)
													{
														for (let i = 0; i < item.translations.length; i++)
														{
															if (item.translations[i].lang === this.props.user.lang)
															{
																name = item.translations[i].name
																break
															}
														}
													}
													return (
														<li key={"objects" + itemId} className="py-4 flex flex-row text-sm text-gray-400 justify-between">
															<p className={nameStyle}>{name}</p>
															<p className={qtStyle}>{item.count}/{item.quantity}</p>
														</li>
													)
												})}
												{liFirst}
												{this.state.answers[catId].reviews.map((item, itemId) => {
													nameStyle = "text-base font-medium text-green-600 flex-none w-32 pb-2 pt-2 opacity-90" // styles pour reviews
													qtStyle = "text-base font-medium text-green-600 flex-auto w-16 pb-2 pt-2 opacity-90 text-right" // styles pour reviews
													let name = ""
													if (item.translations)
													{
														for (let i = 0; i < item.translations.length; i++)
														{
															if (item.translations[i].lang === this.props.user.lang)
															{
																name = item.translations[i].name
																break
															}
														}
													}
													else if (!item.translations && item.name) // quickfix translations
														name = item.name
													return (
														<>
															<div key={"reviews" + itemId} className="flex flex-row text-base text-gray-400 justify-between">
																<p className={nameStyle}>{name}</p>
																<p className={qtStyle}>{item.condition}/5</p>
																<p className={qtStyle}>{item.cleanliness}/5</p>
															</div>
														</>
													)
												})}
											</ul>
										</div>
									)
								})}
								<div className="rounded-md bg-green-50 p-4 mt-6">
									<div className="flex">
										<div className="flex items-center">
											<input
												value={this.state.form3.confirmation}
												onChange={this.handleConfirmation}
												type="checkbox"
												className="h-4 w-4 text-green-600 focus:ring-green-500 border-gray-300 rounded"
											/>
											<label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
												{lang_assets.confirmation.confirmation_message}  
											</label>
										</div>
									</div>
								</div>
								{error}
								{send_btn}
							</div>
						</form>
					</div>
				</div>
			)
		}
		else if (this.state.step === this.state.accommodation.inventory.categories.length + 2)
		{
			// PAGE DE REMERCIEMENTS

			return (
				<div>
					{/* <ButtonPrevious
						theme={this.props.theme}
						// handlePreviousBtn={this.props.handlePreviousBtn}
					/> */}
					<div style={{backgroundColor: "#" + this.props.theme.color3}} className="h-52 z-0 px-8 text-center">
						<p className="text-2xl text-white pt-14">{lang_assets.thanks.title}</p>
						<h1 className="text-3xl text-white font-semibold m-0 pt-2">{lang_assets.thanks.subtitle}</h1>
					</div>
					<div className="rounded-t-3xl z-10" style={{backgroundColor: "#ffffff", marginTop: "-2rem"}}>
						<div className="px-10 pt-16 pb-10">
							<div className="w-full flex justify-center">
								<div className="w-32">
									<ReactBodymovin options={bodymovinOptions} />
								</div>
							</div>
							<h2 className="text-center text-3xl font-bold tracking-tight sm:block sm:text-4xl mt-5" style={{ color: "#" + this.props.theme.color3 }}>
								{lang_assets.thanks.header}
							</h2>
							<p className="text-center text-2xl mt-4 font-semibold tracking-tight text-gray-600 sm:block sm:text-4xl">
								{lang_assets.thanks.content}
							</p>
						</div>
						<div id="animation"></div>
					</div>
				</div>
			)
		}
		else // 0 < step < (length - 1) : formulaire à cocher
		{
			// FORMULAIRE PRINCIPAL

			let name = ''
			const translations = this.state.accommodation.inventory.categories[this.state.step].translations
			if (translations && translations.length)
			{
				for (let i = 0; i < translations.length; i++)
				{
					if (translations[i].lang === this.props.user.lang)
					{
						name = translations[i].name
						break
					}
				}
			}
			let countObjectsJSX = null
			let conditionObjectsJSX = null
			if (this.state.form1.objects && this.state.form1.objects.length > 0)
			{
				countObjectsJSX = (
					<>
						<legend className="text-2xl font-bold text-gray-900">{lang_assets.inventory.header_objects}</legend>
						<div className="flex flex-row text-sm mt-4 text-gray-400 justify-between">
							<p>{lang_assets.inventory.tab_title_0}</p>
							<p>{lang_assets.inventory.tab_title_1}</p>
						</div>
						<div className="mt-1 border-t border-b border-gray-200 divide-y divide-gray-200">
							{this.state.form1.objects.map((item, itemIdx) => {
								let name = ""
								if (item.translations)
								{
									for (let i = 0; i < item.translations.length; i++)
									{
										if (item.translations[i].lang === this.props.user.lang)
										{
											name = item.translations[i].name
											break
										}
									}
								}
								return (
									<div key={itemIdx} className="flex flex-row justify-between items-center py-4"> 
										<div>
											<input
												id={itemIdx}
												type="checkbox"
												className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-grey-300 rounded"
												onChange={this.handleCheckbox.bind(this, itemIdx)}
												checked={item.checked}
											/>
										</div>
										<div className="w-24 text-sm">
											<label htmlFor={itemIdx} className="font-medium text-gray-700 select-none">
												{name}
											</label>
											<p className="text-gray-500">
												x {item.quantity}
											</p>
										</div>
										<div className="border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
											<input
												id={'number' + itemIdx}
												type="number"
												min="0"
												className="block w-24 border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 text-sm disabled:placeholder-pink-500 disabled:border-pink-200"
												value={item.count}
												onChange={this.handleCount.bind(this, itemIdx)}
											/>
										</div>
									</div>
							)})}
						</div>
					</>
				)
			}
			if (this.state.form1.reviews && this.state.form1.reviews.length > 0)
			{
				let class_title = "text-2xl font-bold text-gray-900 mb-4"
				if (countObjectsJSX !== null)
					class_title = "text-2xl font-bold text-gray-900 mt-12 mb-4"
				conditionObjectsJSX = (
					<>
						<legend className={class_title}>{lang_assets.inventory.header_reviews}</legend>
						<div>
							{this.state.form1.reviews.map((item, itemIdx) => {
								let name = ""
								if (item.translations)
								{
									for (let i = 0; i < item.translations.length; i++)
									{
										if (item.translations[i].lang === this.props.user.lang)
										{
											name = item.translations[i].name
											break
										}
									}
								}
								let star_condition = []
								for (let i = 0; i < 5; i++)
								{
									if (i < item.condition)
										star_condition.push(star_fill)
									else
										star_condition.push(star_lineal)
								}
								let star_cleanliness = []
								for (let i = 0; i < 5; i++)
								{
									if (i < item.cleanliness)
										star_cleanliness.push(star_fill)
									else
										star_cleanliness.push(star_lineal)
								}
								const class_subtitle = "text-sm text-gray-700"
								const class_stars = "flex flex-row justify-between items-center py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600"
								return (
									<>
										<div className="font-bold text-lg mt-6 mb-1 pb-2 border-b border-gray-200">
											<label htmlFor={itemIdx} className="select-none">
												{name}
											</label>
										</div>
										<div className={class_stars}> 
											<p className={class_subtitle}>{lang_assets.inventory.cleanliness}</p>
											<p className="flex justify-center space-x-3">
												<img className="w-8" src={star_cleanliness[0]} alt="note 1" onClick={this.clickCleanlinessNotation.bind(this, 1, itemIdx)} />
												<img className="w-8" src={star_cleanliness[1]} alt="note 2" onClick={this.clickCleanlinessNotation.bind(this, 2, itemIdx)} />
												<img className="w-8" src={star_cleanliness[2]} alt="note 3" onClick={this.clickCleanlinessNotation.bind(this, 3, itemIdx)} />
												<img className="w-8" src={star_cleanliness[3]} alt="note 4" onClick={this.clickCleanlinessNotation.bind(this, 4, itemIdx)} />
												<img className="w-8" src={star_cleanliness[4]} alt="note 5" onClick={this.clickCleanlinessNotation.bind(this, 5, itemIdx)} />
											</p>
										</div>
										<div className={class_stars}> 
											<p className={class_subtitle}>{lang_assets.inventory.condition}</p>
											<p className="flex justify-center space-x-3">
												<img className="w-8" src={star_condition[0]} alt="note 1" onClick={this.clickConditionNotation.bind(this, 1, itemIdx)} />
												<img className="w-8" src={star_condition[1]} alt="note 2" onClick={this.clickConditionNotation.bind(this, 2, itemIdx)} />
												<img className="w-8" src={star_condition[2]} alt="note 3" onClick={this.clickConditionNotation.bind(this, 3, itemIdx)} />
												<img className="w-8" src={star_condition[3]} alt="note 4" onClick={this.clickConditionNotation.bind(this, 4, itemIdx)} />
												<img className="w-8" src={star_condition[4]} alt="note 5" onClick={this.clickConditionNotation.bind(this, 5, itemIdx)} />
											</p>
										</div>
									</>
							)})}
						</div>
					</>
				)
			}
			let error = null
			if (this.state.error.complete)
			{
				error = (
					<div className="flex">
						<div className="flex-shrink-0">
							<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
						</div>
						<div className="ml-3">
							<h3 className="text-sm font-medium text-red-800">{this.state.error.complete}</h3>
						</div>
					</div>
				)
			}
			return (
				<div>
					{/* <ButtonPrevious
						theme={this.props.theme}
						// handlePreviousBtn={this.props.handlePreviousBtn}
					/> */}
					<div style={{backgroundColor: "#" + this.props.theme.color3}} className="h-52 z-0 px-8 text-center">
						<p className="text-2xl text-white pt-14">{lang_assets.inventory.title}</p>
						<h1 className="text-3xl text-white font-semibold m-0 pt-2">{name}</h1>
					</div>
					<div className="rounded-t-3xl z-10" style={{backgroundColor: "#ffffff", marginTop: "-2rem"}}>
						<div className="px-10 pt-16 pb-10">
							<form onSubmit={this.onSubmit}>
								<fieldset>
									{countObjectsJSX}
									{conditionObjectsJSX}
									{error}
									<button className="font-semibold py-5 w-full rounded-full text-white shadow-xl text-xl my-10 waves-effect waves-light" type="submit" style={{backgroundColor: "#" + this.props.theme.color3}}>{lang_assets.attached_files.button}</button>
								</fieldset>
							</form>
						</div>
					</div>
				</div>
			)
		}
	}
}

export default Inventory