module.exports = {
	parseDate: function(timestamp, complete = true) {
		if (timestamp === null)
			return null
		const date = new Date(timestamp * 1000)
		let day = date.getDate()
		let month = date.getMonth() + 1
		let year = date.getFullYear()
		let hours = date.getHours()
		let minutes = date.getMinutes()
		let seconds = date.getSeconds()
		if (day < 10)
			day = '0' + day
		if (month < 10)
			month = '0' + month
		if (hours < 10)
			hours = '0' + hours
		if (minutes < 10)
			minutes = '0' + minutes
		if (seconds < 10)
			seconds = '0' + seconds
		if (complete === true)
			return day + "/" + month + "/" + year + " " + hours + ":" + minutes + ":" + seconds
		else
			return day + "/" + month + "/" + year
	},

	queryString: function(baseurl, params) {
		const keys = Object.keys(params)
		let url = baseurl + '?'
		let a = false
		for (let i = 0; i < keys.length; i++)
		{
			const key = keys[i]
			if (Array.isArray(params[key]))
			{
				let value = ""
				let b = false
				for (let j = 0; j < params[key].length; j++)
				{
					if (!b)
						b = true
					else
						value += ","
					value += params[key][j]
				}
				if (!a)
					a = true
				else
					url += "&"
				url += key + "=" + value
			}
			// else if (params[key])
			else
			{
				if (!a)
					a = true
				else
					url += "&"
				url += key + '=' + params[key]
			}
		}
		if (url.length > 2000)
		{
			console.log("error: query string is longer than 2000 chars, can not send request to url", baseurl)
			return null
		}
		return url
	},

	getTimestamp: function(date = null) {
		if (!date)
			return Math.floor(new Date().getTime() / 1000)
		else
			return Math.floor(date.getTime() / 1000)
	},

	clone: function(obj) {
		return JSON.parse(JSON.stringify(obj))
	},

	colorText: function(color) {
		// parse hex values to int
		const r = parseInt("0x" + color.substr(0, 2))
		const g = parseInt("0x" + color.substr(2, 2))
		const b = parseInt("0x" + color.substr(4, 2))
		// calculate contrast of color (standard grayscale algorithmic formula)
		const contrast = (Math.round(r * 299) + Math.round(g * 587) + Math.round(b * 114)) / 1000
		return (contrast >= 128) ? 'black' : 'white'
	},

	concat: function(arr1, arr2) {
		let tab = arr2.slice()
		for (let i = 0; i < arr1.length; i++)
		{
			let found = false
			for (let j = 0; j < tab.length; j++)
			{
				if (tab[j]._id === arr1[i]._id)
				{
					found = true
					break
				}
			}
			if (found === false)
				tab.push(arr1[i])
		}
		return tab
	}
}
