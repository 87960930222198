import React, { Component } from 'react'
import Loader from '../UI/Loader'
import { LocationMarkerIcon, UserCircleIcon } from '@heroicons/react/solid'
import './Services.css'
import ReactBodymovin from 'react-bodymovin'
import animation from '../../img/check-circle.json'

class Buying extends Component {
	constructor(props) {
		super(props)
		this.state = {
			reservation: this.props.reservation,
			step: 0,
			curr: null,
			user: {
				client_number: null,
				identity: null,
				age: null,
				phone: null
			},
			attempt_submit: false,
			error: this._initErrors()
		}
		this.length_max = 40
		if (this.props.user.from === "dashboard")
		{
			this.state.user.client_number = 0
			this.state.user.identity = "Test Test"
			this.state.user.age = 42
			this.state.user.phone = "123456"
		}
		else
		{
			this.state.user.client_number = this.props.user.client_number
			let identity = ""
			if (this.props.user.civility && this.props.user.civility.length > 0)
			{
				if (this.props.user.civility === "femme")
					identity = "Mme. "
				else if (this.props.user.civility === "homme")
					identity = "Mr. "
			}
			identity += this.props.user.firstname + " " + this.props.user.lastname
			this.state.user.identity = identity
			let age = null
			const date = new Date()
			if (this.props.user.birthday && this.props.user.birthday.length > 0)
			{
				const day = Number(this.props.user.birthday.substr(0, 2))
				const month = Number(this.props.user.birthday.substr(3, 2)) - 1
				const year = Number(this.props.user.birthday.substr(6, 4))
				age = date.getFullYear() - year
				if (month > date.getMonth() || (month === date.getMonth() && day > date.getDate()))
					age--
			}
			this.state.user.age = age
			this.state.user.phone = this.props.user.phone
		}
	}

	componentDidMount() {
		const infos = {
			service: this.props.service
		}
		this.props.updateLogs(this.props.pathname, 'Services', infos)
	}

	setErrorMsg = (msg) => {
		this.setState({ error: msg })
	}

	clearFlash = () => {
		this.setState({ error: null })
	}

	_initErrors = () => {
		const errors = {
			global: null,
			firstname: [],
			lastname: []
		}
		if (this.state)
		{
			for (let i = 0; i < this.state.form1.participants.length; i++)
			{
				errors.firstname.push(null)
				errors.lastname.push(null)
			}
		}
		return errors
	}

	goToBookingMenu = () => {
		this.setState({ step: 0 })
	}

	confirmBuying = () => {
		const reservation = {
			...this.state.reservation,
			cart: this.props.cart,
			client_number: this.state.user.client_number,
			identity: this.state.user.identity,
			age: this.state.user.age,
			phone: this.state.user.phone
		}
		this.setState({ attempt_submit: true })
		this.props.postServiceReservationCart(reservation)
		.then(res => {
			this.setState({ step: 1, attempt_submit: false })
		})
		.catch(err => {
			this.setState({ attempt_submit: false })
			console.log(err)
		})
	}

	render() {
		const service = this.props.service
		const lang_assets = this.props.lang_assets.services
		if (service)
		{
			if (this.state.step === 0) //RECAP
			{
				// récapitulatif
				const reservation = this.state.reservation
				const start = new Date(reservation.start * 1000)
				let start_hour = start.getHours()
				if (start_hour < 10)
					start_hour = '0' + start_hour
				let start_minute = start.getMinutes()
				if (start_minute < 10)
					start_minute = '0' + start_minute
				const end = new Date(reservation.end * 1000)
				let end_hour = end.getHours()
				if (end_hour < 10)
					end_hour = '0' + end_hour
				let end_minute = end.getMinutes()
				if (end_minute < 10)
					end_minute = '0' + end_minute
				let weekday = this.props.lang_assets.time.weekdays[start.getDay()]
				let day = start.getDate()
				if (day < 10)
					day = '0' + day
				let month = start.getMonth() + 1
				if (month < 10)
					month = '0' + month
				const year = start.getFullYear()
				let location = ""
				for (let i = 0; i < service.translations.length; i++)
				{
					if (service.translations[i].lang === this.props.user.lang)
					{
						location = service.translations[i].location
						break
					}
				}
				let subtitle = "Vérifiez votre commande :"
				let price = 0
				const cart = this.props.cart
				for (let i = 0; i < cart.length; i++)
				{
					price += Number(cart[i].price) * Number(cart[i].quantity)
				}
				let price_txt = String(price)
				if (price > 0)
				{
					let price_tab = price_txt.split('.')
					if (price_tab.length > 1)
					{
						if (price_tab[1].length === 1)
							price_tab[1] += '0'
						else if (price_tab[1].length > 2)
							price_tab[1] = price_tab[1].substring(0, 2)
						price_txt = price_tab[0] + ',' + price_tab[1]
					}
				}
				const tarif = (price > 0) ? price_txt + '€' : lang_assets.free
				let buttonJSX = (<button type="button" onClick={this.confirmBuying} className="font-semibold py-5 w-full rounded-full text-white shadow-xl text-xl my-10 waves-effect waves-light" style={{ backgroundColor: '#' + this.props.theme.color1 }}>{lang_assets.button2}</button>)
				if (this.state.attempt_submit === true)
				{
					buttonJSX = (
						<div className="loading" style={{ marginTop: "20px" }}>
							<Loader />
						</div>
					)
				}
				return (
					<div className="min-h-4/5-screen">
						<div className="flex justify-center	items-center z-0 w-full fixed top-0 h-36" style={{ backgroundColor: '#' + this.props.theme.color1 }}>
							<div className="text-white text-center text-3xl">
								<h1>Votre panier</h1>
							</div>
						</div>
						<div className="bg-white w-full pt-10 px-7 mt-32 min-h-9/10-screen absolute rounded-t-3xl pb-10">
							<div className="text-base text-gray-400 mt-5">{weekday} {day}/{month}/{year}</div>
							<div className="rounded-md bg-gray-100 p-4 mt-1">
								<div className="ml-3">
									<h3 className="text-xl font-bold" style={{ color: '#' + this.props.theme.color1 }}>{subtitle}</h3>
									<div className="flex text-base mt-1">
										<LocationMarkerIcon className="text-gray-300 w-4 mr-1"/>
										<div className="text-sm text-gray-500">{location}</div>
									</div>
									<div className="w-8/12 mt-5">
										<div className="flex justify-between content-end ">
											<div className="text-base text-gray-500">{lang_assets.start_time}: </div> 
											<div className="text-base text-gray-500 font-semibold">{start_hour}:{start_minute}</div>
										</div>
										<div className="flex justify-between mt-1 content-end">
											<div className="text-base text-gray-500">{lang_assets.end_time}: </div> 
											<div className="text-base text-gray-500 font-semibold">{end_hour}:{end_minute}</div>
										</div>
									</div>
									{cart.map(({ _id, translations, price, quantity }) => {
										let name = ""
										for (let i = 0; i < translations.length; i++)
										{
											if (translations[i].lang === this.props.user.lang)
											{
												name = translations[i].subtitle
												break
											}
										}
										price = Number(price)
										quantity = Number(quantity)
										let price_txt = String(price)
										if (price > 0)
										{
											let price_tab = price_txt.split('.')
											if (price_tab.length > 1)
											{
												if (price_tab[1].length === 1)
													price_tab[1] += '0'
												else if (price_tab[1].length > 2)
													price_tab[1] = price_tab[1].substring(0, 2)
												price_txt = price_tab[0] + ',' + price_tab[1]
											}
										}
										return (
											<div key={_id} className="flex justify-between mt-5">
												<div className="text-base text-gray-500">{name}</div>
												<div className="text-base text-gray-500 font-semibold">{quantity}x {price_txt}€</div>
											</div>
										)
									})}
									<div className="flex text-sm justify-end mt-4 text-gray-500">
										<div>{lang_assets.price}: {tarif} </div>
									</div>
								</div>
							</div>
							<div className="rounded-md bg-gray-100 p-4 my-2">
								<div className="ml-3">
									<div className="flex">
										<UserCircleIcon className="w-7 text-purple-300 mr-2"/>
										<h3 className="text-lg font-semibold">{this.state.user.identity}</h3>
									</div>
								</div>
							</div>
					 		{buttonJSX}
						</div>
					</div>
				)
			}
			else if (this.state.step === 1) //CONFIRMATION FINALE
			{
				const bodymovinOptions = {
					loop: false,
					autoplay: true,
					prerender: true,
					animationData: animation
				}
				return (
					<div className="min-h-4/5-screen">
						<div className="flex justify-center	items-center z-0 w-full fixed top-0 h-36" style={{ backgroundColor: '#' + this.props.theme.color1 }}>
							<div className="text-white text-3xl">
								<h1>{lang_assets.confirm}</h1>
							</div>
						</div>
						<div className="bg-white w-full pt-10 px-5 mt-32 min-h-9/10-screen absolute rounded-t-3xl pb-10">
							<div className="px-10 pt-16 pb-10">
								<div className="w-full flex justify-center">
									<div className="w-32">
										<ReactBodymovin options={bodymovinOptions} />
									</div>
								</div>
								<h2 className="text-center text-3xl font-bold tracking-tight sm:block sm:text-4xl mt-5" style={{ color: "#" + this.props.theme.color3 }}>
									Merci ! 
								</h2>
								<p className="text-center text-2xl mt-4 font-semibold tracking-tight text-gray-600 sm:block sm:text-4xl">
									{lang_assets.confirm_msg}
								</p>
							</div>
						</div>
					</div>
				)
			}
			else
			{
				return (
					<div className="loading">
						<Loader />
					</div>
				)
			}
		}
		else
		{
			return (
				<div className="loading">
					<Loader />
				</div>
			)
		}
	}
}

export default Buying