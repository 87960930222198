import React, { Component } from 'react'
import Cookies from 'js-cookie'
import { Link, Redirect } from 'react-router-dom'
import Loader from '../UI/Loader'
import { ArrowNarrowRightIcon } from '@heroicons/react/solid'
import { XCircleIcon } from '@heroicons/react/solid'
import { CheckCircleIcon } from '@heroicons/react/solid'

class Membres extends Component
{
	constructor(props)
	{
		super(props)
		this.state = {
			deposit: null,
			config_pms: undefined,
			form1: {
				firstname: this.props.user.firstname,
				lastname: this.props.user.lastname,
				email: this.props.user.email, 
				civility: this.props.user.civility,
				lang: this.props.user.langage
			},
			redirect: false,
			inventory: false,
			user: this.props.user,
			error: null,
			success: null
		}
	}

	componentDidMount()
	{
		this.props.updateLogs(this.props.history.location.pathname, 'Membres')
		this.props.updateShell('profile', true, this.props.history.location.pathname)
		if (this.props.config_pms)
			this.setState({ config_pms: this.props.config_pms.values })
		else
		{
			this.props.fetchConfigPms()
			.then(config_pms => {
				this.setState({ config_pms: config_pms.values })
			})
			.catch(err => {
				this.setErrorMsg("Une erreur s'est produite")
				console.log(err)
			})
		}
		this.props.fetchNotifications()
		if (this.props.config_inventory)
			this.setInventory(this.props.config_inventory)
		else if (this.props.config_inventory === null)
		{
			this.props.fetchConfigInventory()
			.then(config_inventory => {
				this.setInventory(config_inventory)
			})
			.catch(err => {
				console.log(err)
			})
		}
		if (this.props.config_deposit)
			this.setDeposit(this.props.config_deposit)
		else if (this.props.config_deposit === null)
		{
			this.props.fetchConfigDeposit()
			.then(config_deposit => {
				this.setDeposit(config_deposit)
			})
			.catch(err => {
				console.log(err)
			})
		}
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps.user !== this.props.user)
		{
			const form1 = {
				firstname: this.props.user.firstname,
				lastname: this.props.user.lastname,
				email: this.props.user.email, 
				civility: this.props.user.civility,
				lang: this.props.user.langage
			}
			this.setState({ user: this.props.user, form1: form1 })
		}
	}

	setErrorMsg = (msg) => {
		this.setState({ error: msg, success: null })
	}

	setSuccessMsg = (msg) => {
		this.setState({ success: msg, error: null })
	}

	clearFlash = () => {
		this.setState({ error: null, success: null })
	}

	setInventory = (config_inventory) => {
		if (this.state.user.stay && this.state.user.stay.location_category && this && this.state.user.stay.location_category !== '?' &&
			(config_inventory.arrival === true || config_inventory.departure === true))
		{
			const stay_number = (this.state.user.stay && this.state.user.stay.stay_number) ? this.state.user.stay.stay_number : null
			const location_category = (this.state.user.stay && this.state.user.stay.location_category) ? this.state.user.stay.location_category : null
			this.props.fetchInventory(stay_number, "both", location_category)
			.then(inventory => {
				if (inventory !== false)
					this.setState({ inventory: true })
			})
			.catch(err => {
				console.log(err)
			})
		}
	}

	setDeposit = (config_deposit) => {
		if (config_deposit.activate === true && this.props.user && this.props.user.invite !== true)
		{
			this.props.fetchDepositLink()
			.then(deposit => {
				if (deposit !== false && typeof deposit === "object")
					this.setState({ deposit: true })
			})
			.catch(err => console.log("can not fetch deposit link", err))
		}
	}

	handleFirstname = (event) => {
		const form1 = {
			...this.state.form1,
			firstname: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleLastname = (event) => {
		const form1 = {
			...this.state.form1,
			lastname: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleCivility = (event) => {
		const form1 = {
			...this.state.form1,
			civility: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleLang = (event) => {
		const form1 = {
			...this.state.form1,
			lang: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleSubmit = (event) => {
		event.preventDefault()
		const user = this.state.user
		user.langage = this.state.form1.lang
		if (user.from === 'webapp')
		{
			const infos = {
				id: this.state.user._id,
				firstname: this.state.form1.firstname,
				lastname: this.state.form1.lastname,
				civility: this.state.form1.civility,
				langage: this.state.form1.lang
			}
			this.props.postUpdateUser(infos)
			.then(ret => {
				if (!ret)
					this.setErrorMsg("Une erreur s'est produite")
				else
				{
					this.setSuccessMsg("Les informations ont bien été mises à jours")
					console.log("update user", ret)
					if (!this.state.user._id)
						this.props.updateUser(ret.user, this.props.session)
					else
					{
						user.from = "webapp"
						this.props.updateUser(ret, this.props.session)
					}
				}
			})
			.catch(err => {
				this.setErrorMsg("Une erreur s'est produite")
				console.log(err)
			})
		}
		else if (user.from === 'dashboard')
		{
			this.props.updateUserDashboardLang(user)
			.then(ret => {
				if (!ret)
					this.setErrorMsg("Une erreur s'est produite")
				else
				{
					this.setSuccessMsg("Les informations ont bien été mises à jours")
					this.props.updateUser(user, this.props.session)
				}
			})
			.catch(err => {
				this.setErrorMsg("Une erreur s'est produite")
				console.log(err)
			})
		}
	}

	logout = () => {
		this.props.updateUser(null, null)
		Cookies.remove('session', { path: '' })
		localStorage.setItem("session", null)
		this.setState({ redirect: true })
	}

	render() {
		const lang_assets = this.props.lang_assets.account
		const version = localStorage.getItem("version")
		const color = { color: '#' + this.props.theme.color2 }
		if (this.state.redirect === true)
			return (<Redirect to="/" />)
		else if (this.state.user === null || this.state.config_pms === undefined)
		{
			return (
				<div className="min-h-4/5-screen">
					<div className="flex justify-center	items-center z-0 w-full fixed top-0 h-36" style={{ backgroundColor: '#' + this.props.theme.color1 }}>
						<div className="text-white text-3xl">
							<h1>{lang_assets.title}</h1>
						</div>
					</div>
					<div className="loading">
						<Loader />
					</div>
				</div>
			)
		}
		else
		{
			const user = this.state.user
			let stay = user.stay
			if (!stay)
			{
				stay = {
					location: '...',
					arrival: '...',
					departure: '...'
				}
			}
			const langs = this.props.langs
			let langJSX = null
			if (langs.length > 1)
			{
				let langs_available = []
				for (let i = 0; i < langs.length; i++)
				{
					if (langs[i] === "fr")
					{
						langs_available.push({
							value: "fr",
							text: "Français"
						})
					}
					else if (langs[i] === "en")
					{
						langs_available.push({
							value: "en",
							text: "English"
						})
					}
					else if (langs[i] === "de")
					{
						langs_available.push({
							value: "de",
							text: "Deutsch"
						})
					}
					else if (langs[i] === "nl")
					{
						langs_available.push({
							value: "nl",
							text: "Dutch"
						})
					}
					else if (langs[i] === "it")
					{
						langs_available.push({
							value: "it",
							text: "Italiano"
						})
					}
					else if (langs[i] === "es")
					{
						langs_available.push({
							value: "es",
							text: "Español"
						})
					}
					else if (langs[i] === "pt")
					{
						langs_available.push({
							value: "pt",
							text: "Português"
						})
					}
				}
				langJSX = (
					<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
						<label htmlFor="country" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
							Langue
						</label>
						<div className="mt-1 sm:mt-0 sm:col-span-2">
							<select className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md" value={this.state.form1.lang} onChange={this.handleLang}>
								<option value="" disabled>{lang_assets.langage.title}</option>
								{langs_available.map(({ text, value }) => {
									return (<option value={value} key={value}>{text}</option>)
								})}
							</select>
						</div>
					</div>
				)
			}
			let sejoursJSX = (
				<div className="relative mt-5 rounded-lg bg-gray-100 px-6 py-8 flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-purple-500">
					<span className="text-3xl" role="img" aria-label="calendar">
						🗓
					</span>
					<Link to="/membres/sejours" className="flex-1 min-w-0">
						<div className="focus:outline-none">
							<span className="absolute inset-0" aria-hidden="true" />
							<p className="text-lg font-medium text-gray-800">{lang_assets.stay.stay}</p>
							<p className="text-sm text-gray-500 truncate">{lang_assets.stay.stay_subtitle}</p>
						</div>
					</Link>
					<div className="h-5 w-5">
						<ArrowNarrowRightIcon />
					</div>
				</div>
			)
			let inventoryJSX = null
			if (this.state.inventory === true)
			{
				inventoryJSX = (
					<div className="relative mt-5 bg-gray-100 rounded-lg px-6 py-8 flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
						<span className="text-3xl" role="img" aria-label="checklist">
							📋
						</span>
						<Link to="/inventaire" className="flex-1 min-w-0">
							<div className="focus:outline-none">
								<span className="absolute inset-0" aria-hidden="true" />
								<p className="text-base font-medium text-gray-800">{lang_assets.stay.inventory}</p>
								<p className="text-base text-gray-500 truncate">{lang_assets.stay.inventory_subtitle}</p>
							</div>
						</Link>
						<div className="h-5 w-5">
							<ArrowNarrowRightIcon />
						</div>
					</div>
				)
			}
			let reservationsJSX = null
			if (this.props.features === "premium")
			{
				reservationsJSX = (
					<div className="relative mt-5 rounded-lg bg-gray-100 px-6 py-8 flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-purple-500">
						<span className="text-3xl" role="img" aria-label="calendar">
							🗓
						</span>
						<Link to="/membres/reservations" className="flex-1 min-w-0">
							<div className="focus:outline-none">
								<span className="absolute inset-0" aria-hidden="true" />
								<p className="text-lg font-medium text-gray-800">{lang_assets.stay.bookings}</p>
								<p className="text-sm text-gray-500 truncate">{lang_assets.stay.bookings_subtitle}</p>
							</div>
						</Link>
						<div className="h-5 w-5">
							<ArrowNarrowRightIcon />
						</div>
					</div>
				)
			}
			let depositJSX = null
			if (this.state.deposit === true)
			{
				depositJSX = (
					<div className="relative mt-5 rounded-lg bg-gray-100 px-6 py-8 flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-purple-500">
						<span className="text-3xl" role="img" aria-label="lock">
							🔐
						</span>
						<Link to="/membres/caution" className="flex-1 min-w-0">
							<div className="focus:outline-none">
								<span className="absolute inset-0" aria-hidden="true" />
								<p className="text-lg font-medium text-gray-800">Votre caution</p>
								<p className="text-sm text-gray-500 truncate">Retrouvez les infos de votre caution</p>
							</div>
						</Link>
						<div className="h-5 w-5">
							<ArrowNarrowRightIcon />
						</div>
					</div>
				)
			}
			let reviewsJSX = null 
			/*
			// TODO: Ajouter les reviews
			if (this.props.reviews)
			{
				reviewsJSX = (
					<div className="relative mt-5 rounded-lg bg-gray-100 px-6 py-8 flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-purple-500">
						<span className="text-3xl">
							⭐️
						</span>
						<Link to="" className="flex-1 min-w-0">
							<div className="focus:outline-none">
								<span className="absolute inset-0" aria-hidden="true" />
								<p className="text-lg font-medium text-gray-800">Votre avis</p>
								<p className="text-sm text-gray-500 truncate">Déposez un avis sur votre séjour</p>
							</div>
						</Link>
						<div className="h-5 w-5">
							<ArrowNarrowRightIcon />
						</div>
					</div>
				)
			}
			*/
			let staysJSX = (
				<div>
					<h2 className="text-base leading-6 font-medium my-4" style={color}>{lang_assets.stay.title}</h2>
					{sejoursJSX}
					{reservationsJSX}
					{inventoryJSX}
					{depositJSX}
					{reviewsJSX}
				</div>
			)
			let emplacement = null
			if (this.props.show_stay_location === true && user.stay && user.stay.location)
				emplacement = (<p className="text-base text-gray-500 mt-0.5">Parcelle n°{user.stay.location}</p>)
			let error = null
			if (this.state.error)
			{
				error = (
					<div className="rounded-md bg-red-50 p-4 mt-3">
						<div className="flex">
							<div className="flex-shrink-0">
								<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
							</div>
							<div className="ml-3">
								<h3 className="text-sm font-medium text-red-800">{this.state.error}</h3>
							</div>
						</div>
					</div>
				)
			}
			let success = null
			if (this.state.success)
			{
				success = (
					<div className="rounded-md bg-green-50 p-4 mt-3">
						<div className="flex">
							<div className="flex-shrink-0">
								<CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
							</div>
							<div className="ml-3">
								<h3 className="text-sm font-medium text-green-600">{this.state.success}</h3>
							</div>
						</div>
					</div>
				)
			}
			let stay_dates = null
			if (this.props.show_stay_dates === true && stay && stay.arrival_time && stay.departure_time)
			{
				const arrival = new Date(stay.arrival_time * 1000)
				let arrival_day = arrival.getDate()
				if (arrival_day < 10)
					arrival_day = '0' + arrival_day
				let arrival_month = arrival.getMonth() + 1
				if (arrival_month < 10)
					arrival_month = '0' + arrival_month
				const arrival_year = arrival.getFullYear()
				const departure = new Date(stay.departure_time * 1000)
				let departure_day = departure.getDate()
				if (departure_day < 10)
					departure_day = '0' + departure_day
				let departure_month = departure.getMonth() + 1
				if (departure_month < 10)
					departure_month = '0' + departure_month
				const departure_year = departure.getFullYear()
				stay_dates = 'Du ' + arrival_day + '/' + arrival_month + '/' + arrival_year + ' au ' + departure_day + '/' + departure_month + '/' + departure_year
			}
			let location_category = null
			if (stay && stay.location_category)
			{
				let words = stay.location_category.toLowerCase().split(" ")
				for (let i = 0; i < words.length; i++)
					if (words[i].length > 0)
						words[i] = words[i][0].toUpperCase() + words[i].substr(1)
				location_category = (<p className="text-base text-gray-500 mt-0.5">{words.join(" ")}</p>)
			}
			let no_stay = null
			if (!stay || !stay.stay_number || stay.stay_number === '?')
			{
				no_stay = (
					<Link to="/membres/retrouver-son-sejour">
						<div className="rounded-md bg-blue-50 p-4 mt-6 font-bold text-sm text-blue-800">
							<span role="img" aria-label="pointing-right">⚠️</span> Vous ne pouvez pas déposer de caution ou d'inventaires si vos dates de séjour sont introuvables.<br />
							Insérez votre code à 6 caractères en <span className="underline">cliquant ici !</span>
						</div>
					</Link>
				)
			}
			let disabled = false
			let logoutJSX = (
				<div>
					<button onClick={this.logout} className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
						{lang_assets.logout}
					</button>
				</div>
			)
			let infosJSX = (
				<div className="ml-5">
					<p className="text-xl font-medium text-gray-800">{user.firstname} {user.lastname}</p>
					{emplacement}
					<p className="text-base text-gray-500 mt-0.5">{stay_dates}</p>
					{location_category}
				</div>
			)
			let user_data_JSX = (
				<div className="">
					<h2 className="text-base leading-6 font-medium my-4" style={color}>{lang_assets.infos.title}</h2>
					{no_stay}
					<form onSubmit={this.handleSubmit}>
						<div className="space-y-6 sm:space-y-5 mt-5">
							<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
								<label htmlFor="first-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
									{lang_assets.infos.lastname}
								</label>
								<div className="mt-1 sm:mt-0 sm:col-span-2">
									<input
										type="text"
										value={this.state.form1.lastname}
										onChange={this.handleLastname}
										disabled={disabled}
										className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
									/>
								</div>
							</div>
							<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
								<label htmlFor="last-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
									{lang_assets.infos.firstname}
								</label>
								<div className="mt-1 sm:mt-0 sm:col-span-2">
									<input
										type="text"
										value={this.state.form1.firstname}
										onChange={this.handleFirstname}
										disabled={disabled}
										className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
									/>
								</div>
							</div>
							<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
								<label htmlFor="last-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
									{lang_assets.infos.email}
								</label>
								<div className="mt-1 sm:mt-0 sm:col-span-2">
									<input
										disabled
										type="text"
										value={this.state.form1.email}
										className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
									/>
								</div>
							</div>
							<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
								<label htmlFor="last-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
									{lang_assets.infos.birthday}
								</label>
								<div className="mt-1 sm:mt-0 sm:col-span-2">
									<input
										disabled
										type="text"
										value={this.state.form1.birthday}
										className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
									/>
								</div>
							</div>
							<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
								<label htmlFor="country" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
									{lang_assets.infos.civility}
								</label>
								<select
									value={this.state.form1.civility}
									onChange={this.handleCivility}
									disabled={disabled}
									className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
								>
									<option value=""></option>
									<option value="man">{lang_assets.infos.man}</option>
									<option value="woman">{lang_assets.infos.woman}</option>
									<option value="other">{lang_assets.infos.other}</option>
								</select>
							</div>
							{langJSX}
						</div>
						{success}
						{error}
						<div className="pt-5">
							<div className="flex justify-end">
								<button type="button" className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
									Annuler
								</button>
								<button type="submit" className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
									Enregistrer
								</button>
							</div>
						</div>
					</form>
				</div>
			)
			if (user.invite === true)
			{
				disabled = true
				logoutJSX = null
				staysJSX = null
				no_stay = null
				infosJSX = (
					<div className="ml-5">
						<p className="text-xl font-medium text-gray-800">Mode invité</p>
					</div>
				)
				let show_langJSX = null
				if (langJSX !== null)
				{
					show_langJSX = (
						<form onSubmit={this.handleSubmit}>
							<div className="space-y-6 sm:space-y-5 mt-5">
								{langJSX}
							</div>
							{success}
							{error}
							<div className="pt-5">
								<div className="flex justify-end">
									<button type="button" className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
										Annuler
									</button>
									<button type="submit" className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
										Enregistrer
									</button>
								</div>
							</div>
						</form>
					)
				}
				user_data_JSX = (
					<>
						<div className="flex flex-row items-center">
							<div className="ml-5 mt-6">
								<p className="text-xl font-medium text-gray-800">Déjà un compte ?</p>
							</div>
							<div className="mt-4">
								<Link to="/connexion" className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
									Cliquez ici pour vous connecter
								</Link>
							</div>
						</div>
						<div className="flex flex-row items-center">
							<div className="ml-5 mt-6">
								<p className="text-xl font-medium text-gray-800">Pas encore inscrit ?</p>
							</div>
							<div className="mt-4">
								<Link to="/inscription" className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
									Cliquez ici pour vous inscrire
								</Link>
							</div>
						</div>
						{show_langJSX}
					</>
				)
			}
			return (
				<div className="min-h-4/5-screen">
					<div className="flex justify-center	items-center z-0 w-full fixed top-0 h-36" style={{ backgroundColor: '#' + this.props.theme.color1 }}>
						<div className="text-white text-3xl">
							<h1>{lang_assets.title}</h1>
						</div>
					</div>
					<div className="bg-white w-full pt-10 px-5 mt-32 min-h-9/10-screen absolute rounded-t-3xl pb-10">
						<div className="space-y-6 sm:pt-10 sm:space-y-5 divide-y divide-gray-200">
							<div className="flex flex-row items-center">
								<div className="flex justify-center leading-none h-16 w-16 rounded-full overflow-hidden bg-gray-100">
									<span className="text-4xl pt-3" role="img" aria-label="fox">🦊</span>
								</div>
								{infosJSX}
							</div>
							{staysJSX}
							{/* RESERVATIONS / INVENTAIRE */}
							{user_data_JSX}
							<div>
								<div className="mt-5">
									<p className="text-sm text-gray-400 hover:text-gray-500">
									Pocketcamp {lang_assets.about.version.toLowerCase()}: {version}
									</p>
								</div>
								{logoutJSX}
							</div>

						</div>
					</div>
				</div>
			)
		}
	}
}

export default Membres