import React, { Component } from 'react'
import ButtonPrevious from '../UI/ButtonPrevious'
import { XCircleIcon, InformationCircleIcon, EyeIcon } from '@heroicons/react/solid'
import { Redirect } from 'react-router-dom'

class SubscribeStepEmail extends Component {
	constructor(props) {
		super(props)
		this.already_exists_msg = "Cette adresse e-mail existe déjà"
		this.button = (this.props.button) ? this.props.button : "Suivant"
		this.default_ph_color = "text-gray-400"
		this.updated_ph_color = "text-black"
		this.error_color = "#F87171"
		this.activated_btn_color = "#" + this.props.theme.color1
		this.deactivated_btn_color = "#A3A3A3"
		this.email_ph = ""
		this.password_ph = ""
		this.length_max = 100
		this.state = {
			form1: {
				email: (this.props.user.email.length > 0) ? this.props.user.email : this.email_ph,
				v_email: (this.props.user.email.length > 0) ? this.props.user.email : "",
				password: (this.props.user.password.length > 0) ? this.props.user.password : this.password_ph,
				v_password: (this.props.user.password.length > 0) ? this.props.user.password : "",
				t_password: (this.props.user.t_password.length > 0) ? this.props.user.t_password : "text",
				visible: false,
				confirmation: false
			},
			password_instruction_color : "#9CA3AF",
			current_btn_color : (this.props.user.email.length > 0 && this.props.user.password.length >= 8) ? this.activated_btn_color : this.deactivated_btn_color,
			config_pms: null,
			redirect: false,
			success: null,
			error: this._initErrors()
		}
	}

	componentDidMount()
	{
		if (this.props.config_pms !== null)
			this.setState({ config_pms: this.props.config_pms })
		else if (this.props.fetchConfigPms)
		{
			this.props.fetchConfigPms()
			.then(config_pms => {
				this.setState({ config_pms: config_pms })
			})
			.catch(err => {
				console.log(err)
			})
		}
	}

	setErrorMsg = (key, msg) => {
		const errors = this._initErrors()
		if (key === "email" && msg === this.already_exists_msg)
			errors.already_exists = true
		errors[key] = msg
		this.setState({ success: null, error: errors })
	}

	setSuccessMsg = (msg) => {
		this.setState({ success: msg, error: this._initErrors() })
	}

	clearFlash = () => {
		this.setState({ error: null, success: this._initErrors() })
	}

	_initErrors = () => {
		const errors = {
			global: null,
			email: null,
			already_exists: false,
			password: null,
			confirmation: null
		}
		return errors
	}

	focusEmail = () => {
		let error_obj = this.state.error
		error_obj.firstname = null
		this.setState({ error: error_obj })
		if (this.state.form1.v_email.length === 0)
		{
			let form1 = {
				...this.state.form1,
				email: '',
				v_email: this.state.form1.v_email
			}
			this.setState({ form1 : form1 })
		}
	}

	blurEmail = () => {
		if (this.state.form1.v_email.length === 0) {
			let form1 = {
				...this.state.form1,
				email: this.email_ph
			}
			this.setState({ form1 : form1 })
		}
	}

	handleEmail = (event) => {
		this.btn_color(event, this.state.form1.v_password)
		let form1 = {
			...this.state.form1,
			email : event.target.value.trim(),
			v_email : event.target.value.trim()
		}
		this.setState({ form1 : form1 })
	}

	ph_color = (v_input) => {
		let color = this.default_ph_color
		if (v_input !== "")
			color = this.updated_ph_color
		return (color)
	}

	focusPassword = () => {
		if (this.state.form1.v_password.length === 0) {
			const t_password = (this.state.form1.visible === false) ? "password" : "text"
			let form1 = {
				...this.state.form1,
				password: '',
				v_password: this.state.form1.v_password,
				t_password: t_password
			}
			this.setState({ form1 : form1 })
		}
	}

	blurPassword = () => {
		if (this.state.form1.v_password.length === 0) {
			let form1 = {
				...this.state.form1,
				password: this.password_ph,
				v_password: this.state.form1.v_password,
				t_password: "text"
			}
			this.setState({ form1 : form1 })
		}
	}

	handlePassword = (event) => {
		this.btn_color(event, this.state.form1.v_email)
		let form1 = {
			...this.state.form1,
			password : event.target.value,
			v_password : event.target.value,
			t_password: this.state.form1.t_password
		}
		this.setState({ form1 : form1 })
	}

	handleVisible = (event) => {
		let visible = (this.state.form1.visible === true) ? false : true
		let t_password = (visible === false && this.state.form1.v_password.length > 0) ? "password" : "text"
		let form1 = {
			...this.state.form1,
			t_password: t_password,
			visible: visible
		}
		this.setState({ form1: form1 })
	}

	handleConfirmation = (event) => {
		let confirmation = (this.state.form1.confirmation === true) ? false : true
		let form1 = {
			...this.state.form1,
			confirmation: confirmation
		}
		const error = this._initErrors()
		this.setState({ error: error, form1: form1 })
	}

	btn_color = (event, input) => {
		let style = this.state.current_btn_color
		if (event.target.value.length !== 0 && input.length !== 0)
			style = this.activated_btn_color
		else
			style = this.deactivated_btn_color
		this.setState({ current_btn_color : style })
	}

	_connectUser = (resolve) => {
		const user = {
			identifiant: this.state.form1.email,
			password: this.state.form1.password
		}
		this.props.postConnectUser(user)
		.then(user => {
			console.log("connect user", user)
			if (user)
			{
				this.props.connectUser(user)
				resolve(true)
			}
			else
			{
				resolve(false)
				this.setErrorMsg("email", this.already_exists_msg)
			}
		})
		.catch(err => console.log("can not connect user", err))
	}
	
	_validateForm1 = () => {
		return new Promise((resolve, reject) => {
			let error_obj = this._initErrors()
			let error = false
			let password_instruction = this.default_ph_color
			const email = this.state.form1.v_email
			const password = this.state.form1.v_password
			const confirmation = this.state.form1.confirmation
			if (!this.state.config_pms && this.props.fetchConfigPms)
			{
				error_obj.global = "Erreur de synchronisation pms"
				error = true
			}
			if (!email || !email.length)
			{
				error_obj.email = "Saisissez votre adresse e-mail. Vous en aurez besoin pour vous reconnecter."
				error = true
			}
			else if (email.length > this.length_max)
			{
				error_obj.email = 'Cette adresse e-mail est trop longue (' + this.length_max + ' caractères max.)'
				error = true
			}
			else if (/^[\w!#$%&'*+-/=?^_`{|}~]+(\.[\w!#$%&'*+-/=?^_`{|}~]+)*@((([-\w]+\.)+[a-zA-Z]{2,4})|(([0-9]{1,3}\.){3}[0-9]{1,3}))$/.test(email) === false)
			{
				error_obj.email = 'Cette adresse e-mail semble incorrecte.'
				error = true
			}
			if (!password || !password.length || password.length < 8)
			{
				error_obj.password = 'Utilisez 8 caractères ou plus pour votre mot de passe.'
				password_instruction = this.error_color
				error = true
			}
			else if (/[a-zA-Z-]/.test(password) === false)
			{
				error_obj.password = 'Votre mot de passe doit comprendre au moins une lettre'
				password_instruction = this.error_color
				error = true
			}
			else if (/[0-9]/.test(password) === false)
			{
				error_obj.password = 'Votre mot de passe doit comprendre au moins un chiffre'
				password_instruction = this.error_color
				error = true
			}
			if (!confirmation)
			{
				error_obj.confirmation = "Vous devez accepter la politique de confidentialité"
				error = true
			}
			if (error === true)
				this.setState({ success: null, error: error_obj, password_instruction_color: password_instruction })
			if (!error)
			{
				if (this.state.config_pms && this.state.config_pms.values.name === "koban")
				{
					this.props.postCheckUserKoban(email)
					.then(participant_koban => {
						if (participant_koban === 1)
						{
							resolve(false)
							this.setErrorMsg("email", "Cette adresse e-mail ne correspond à aucun étudiant")
						}
						else if (participant_koban === false)
							this._connectUser(resolve)
						else
							resolve(participant_koban)
					})
					.catch(err => reject(err))
				}
				else
				{
					this.props.postCheckUserEmail(email)
					.then(user => {
						if (user !== null)
							this._connectUser(resolve)
						else
							resolve(true)
					})
					.catch(err => reject(err))
				}
			}
			else
				resolve(false)
		})
	}

	show_password_instruction = () => {
		if (!this.state.error.password)
			return ("Utilisez au moins huit caractères avec des lettres et des chiffres")
		else
			return (this.state.error.password)
	}

	handleSubmit = (event) => {
		event.preventDefault()
		this._validateForm1()
		.then(ret => {
			if (ret !== false)
			{
				const form1 = this.state.form1
				const config_pms = (this.state.config_pms && this.state.config_pms.values) ? this.state.config_pms.values.name : null
				if (config_pms === "koban")
				{
					form1.v_lastname = ret.lastname
					form1.v_firstname = ret.firstname
					form1.v_birthday = ret.birthday
				}
				this.props.updateUser(form1)
				this.props.validateStepEmail(this.state.form1)
			}
		})
		.catch(err => {
			console.log(err)
			this.setErrorMsg("global", "Une erreur s'est produite")
		})
	}

	_printError = (msg, already_exists = false) => {
		if (msg)
		{
			let ret = (
				<div className="rounded-md bg-red-50 p-4 mt-3">
					<div className="flex">
						<div className="flex-shrink-0">
							<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
						</div>
						<div className="ml-3">
							<h3 className="text-sm font-medium text-red-800">{msg}</h3>
						</div>
					</div>
				</div>
			)
			let redirect_msg = null
			if (already_exists)
			{
				redirect_msg = (
					<p className="text-sm font-medium text-blue-800 font-bold text-center mt-4 mb-4" onClick={this.redirectLogin}><span role="img" aria-label="pointing-right">👉</span> Cliquez ici pour vous connecter <span role="img" aria-label="pointing-left">👈</span></p>
				)
				return (
					<>
						{ret}
						{redirect_msg}
					</>
				)
			}
			return ret
		}
	}

	redirectLogin = () => {
		this.setState({ redirect: true })
	}

	render ()
	{
		if (this.state.redirect === true)
		{
			const url = "/connexion/" + this.state.form1.v_email
			return (<Redirect to={url} />)
		}
		else
		{
			let infoPassword = (
				<div className="rounded-md bg-blue-50 p-4 mt-3">
					<div className="flex">
						<div className="flex-shrink-0">
							<InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
						</div>
						<div className="ml-3">
							<h3 className="text-sm font-medium text-blue-800">8 caractères minimum, dont au moins 1 chiffre et 1 lettre</h3>
						</div>
					</div>
				</div>
			)
			if (this.state.error.password || this.state.error.already_exists)
				infoPassword = null
			return (
				<div>
					<ButtonPrevious
						theme={this.props.theme}
						handlePreviousBtn={this.props.handlePreviousBtn}
					/>
					<div style={{backgroundColor: "#" + this.props.theme.color1}} className="h-52 z-0 px-8 text-center">
						<p className="text-2xl text-white pt-14">Inscription</p>
						<h1 className="text-3xl text-white font-semibold m-0 pt-2">Étape {this.props.stepNb}</h1>
					</div>
					<div className="rounded-t-3xl z-10" style={{backgroundColor: "#ffffff", marginTop: "-2rem"}}>
						<div className="px-10 pt-16 pb-10">
							<div>
								<h2 className="text-3xl font-semibold text-gray-700 mb-10">Créez votre compte <span role="img" aria-label="key">🔑</span></h2>
								<form className="mb-16" onSubmit={this.handleSubmit}>
									{this._printError(this.props.error_msg)}
									{this._printError(this.state.error.global)}
									<div className="mb-4">
										<h3 className="text-lg text-gray-500">Adresse e-mail</h3>
										<label>
											<div className="border-b border-gray-300 focus-within:border-indigo-600">
												<input
													className="block pl-0 w-full border-0 border-b border-transparent focus:border-indigo-600 focus:ring-0 text-xl"
													type="email" 
													value={this.state.form1.email} 
													onFocus={this.focusEmail} 
													onBlur={this.blurEmail} 
													onChange={this.handleEmail}
												/>
											</div>
										</label>
										{this._printError(this.state.error.email, this.state.error.already_exists)}
									</div>
									<h3 className="text-lg text-gray-500">Nouveau mot de passe</h3>
									<div className="mb-4">
										<div className="mt-1 flex justify-between border-b border-gray-300 focus-within:border-indigo-600">
											<div className="focus-within:border-indigo-600">
												<input
													type={this.state.form1.t_password}
													value={this.state.form1.password} 
													onFocus={this.focusPassword} 
													onBlur={this.blurPassword} 
													onChange={this.handlePassword}
													className="block pl-0 w-full border-0 border-b border-transparent focus:border-indigo-600 focus:ring-0 text-xl"
												/>
											</div>
											<button
												type="button"
												className="-ml-px relative inline-flex space-x-2 px-4 py-2 text-sm font-medium text-gray-700 focus-within:border-indigo-600"
												value={this.state.form1.visible}
												onClick={this.handleVisible}
											>
												<EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
											</button>
										</div>
									</div>
									{infoPassword}
									{this._printError(this.state.error.password)}
									{this._printError(this.state.error.confirmation)}
									<div className="rounded-md bg-green-50 p-4 mt-6">
										<div className="flex">
											<div className="flex items-center">
												<input
													value={this.state.form1.confirmation}
													onChange={this.handleConfirmation}
													type="checkbox"
													className="h-4 w-4 text-green-600 focus:ring-green-500 border-gray-300 rounded"
												/>
												<label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
													En poursuivant mon inscription, je déclare avoir lu et accepté la <a className="font-semibold underline" href="https://www.pocketcamp.fr/web/pdf/politique-confidentialite.pdf">politique de protection des données</a>.
												</label>
											</div>
										</div>
									</div>
									<button className="font-semibold py-5 w-full rounded-full text-white shadow-xl text-xl my-10 waves-effect waves-light" type="submit" style={{ backgroundColor: this.state.current_btn_color }}>{this.button}</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			)
		}
	}
}

export default SubscribeStepEmail