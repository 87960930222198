import React, { Component } from 'react'
import M from 'materialize-css'
import ButtonPrevious from '../UI/ButtonPrevious'
import DatePicker from '../UI/DatePicker'

class SubscribeStepCheckinCheckout extends Component {
	constructor(props) {
		super(props)
		this.activated_btn_color = "#" + this.props.theme.color1
		this.deactivated_btn_color = "#A3A3A3"
		this.state = {
			display: {
				checkin: false,
				checkout: false,
			},
			form1 : {
				d_checkin : (this.props.user.long_checkin.length > 0) ? this.props.user.long_checkin : "",
				v_checkin : (this.props.user.checkin && this.props.user.checkin.length > 0) ? this.props.user.checkin : "",
				d_checkout : (this.props.user.long_checkout.length > 0) ? this.props.user.long_checkout : "",
				v_checkout : (this.props.user.checkout && this.props.user.checkout.length > 0) ? this.props.user.checkout : ""
			},
			current_btn_color : (this.checkin === '' && this.checkout === '') ? this.activated_btn_color : this.deactivated_btn_color,
			error: this._initErrors()
		}
		this.click_date_picker = false
	}

	componentDidUpdate(prevProps, prevState) {
		const elems = document.querySelectorAll('select')
		M.FormSelect.init(elems)
	}

	setErrorMsg = (key, msg) => {
		const errors = this._initErrors()
		errors[key] = msg
		this.setState({ success: null, error: errors })
	}

	setSuccessMsg = (msg) => {
		this.setState({ success: msg, error: this._initErrors() })
	}

	clearFlash = () => {
		this.setState({ error: null, success: this._initErrors() })
	}

	_initErrors = () => {
		const errors = {
			checkout: null,
			global: null
		}
		return errors
	}

	showDatePicker = (name) => {
		// 1ere ligne : je verifie l'etat du datepicker, et je sauvegarde son prochaine etat dans une variable
		const bool = (this.state.display[name] === true) ? false : true

		// je réinitialise tous les datepicker par défaut à false (pour fermer le premier datepicker si je clique sur le second)
		const display = {
			checkout: false,
			checkin: false
		}

		// grace a cette technique, je peux set une valeur à une propriété d'un objet dynamiquement
		display[name] = bool // good to know
		this.setState({ display: display })
	}

	hideDatePicker = () => {
		if ((this.state.display.checkin === true || this.state.display.checkout === true) && !this.click_date_picker)
		{
			let display = {
				checkin: false,
				checkout: false
			}
			this.setState({ display : display })
		}
		this.click_date_picker = false
	}

	handleClickDatePicker = (name) => {
		this.click_date_picker = true
	}

	getDateValue = (long, numeric, name) => {
		const form1 = {
			...this.state.form1
		}
		const display = {
			checkout: false,
			checkin: false
		}
		form1["v_" + name] = numeric
		form1["d_" + name] = long
		this.setState({ form1: form1, display: display })
	}

	_validateForm = () => {
		let error_obj = this._initErrors()
		let error = false
		const checkin = this.state.form1.v_checkin
		let day = Number(checkin.substring(0, 2))
		let month = Number(checkin.substring(3, 5)) - 1
		let year = Number(checkin.substring(6))
		const checkin_time = new Date(year, month, day).getTime()
		const checkout = this.state.form1.v_checkout
		day = Number(checkout.substring(0, 2))
		month = Number(checkout.substring(3, 5)) - 1
		year = Number(checkout.substring(6))
		const checkout_time = new Date(year, month, day).getTime()
		if (!checkin || !checkin.length)
		{
			error_obj.checkin = "Veuillez renseigner votre date d'arrivée"
			error = true
		}
		else if (!checkout || !checkout.length)
		{
			error_obj.checkout = "Veuillez renseigner votre date de départ"
			error = true
		}
		else if (checkout_time <= checkin_time)
		{
			console.log("checkin", checkin)
			console.log("checkout", checkout)
			error_obj.global = "Ces dates semblent incorrectes."
			error = true
		}
		if (error === true)
		{
			this.setState({ success: null, error: error_obj })
		}
		return (!error) ? true : false
	}

	handleSubmit = (e) => {
		e.preventDefault()
		if (this._validateForm())
		{
			this.props.updateUser(this.state.form1)
			this.props.validateCheckin()
		}
	}

	error = () => {
		if (this.state.error.checkin)
			return (this.state.error.checkin)
		else if (this.state.error.checkout)
			return (this.state.error.checkin)
		else if (this.state.error.global)
			return (this.state.error.global)
	}
	
	render ()
	{
		let btn_style = {
			backgroundColor: this.deactivated_btn_color
		}
		if (this.state.form1.v_checkin !== "" && this.state.form1.v_checkout !== "")
			btn_style.backgroundColor = this.activated_btn_color
		return (
			<div onClick={this.hideDatePicker}>
				<ButtonPrevious
					theme={this.props.theme}
					handlePreviousBtn={this.props.handlePreviousBtn}
				/>
				<div style={{backgroundColor: "#" + this.props.theme.color1}} className="h-52 z-0 px-8 text-center">
					<p className="text-2xl text-white pt-14">Inscription</p>
					<h1 className="text-3xl text-white font-semibold m-0 pt-2">Étape {this.props.stepNb}</h1>
				</div>
				<div className="rounded-t-3xl z-10" style={{backgroundColor: "#ffffff", marginTop: "-2rem"}}>
					<div className="px-10 pt-16 pb-10 h-screen">
						<h2 className="text-3xl font-semibold text-gray-700 mb-10">Sélectionnez vos dates de séjour</h2>
						<form onSubmit={this.handleSubmit}>
							<DatePicker 
								updateShowPicker={this.updateShowPicker}
								name="checkin"
								checkinDate={this.state.form1}
								showDatePicker={this.showDatePicker}
								display={this.state.display.checkin}
								getDateValue={this.getDateValue}
								handleClick={this.handleClickDatePicker}
								title="Arrivée"
							/>
							<DatePicker 
								updateShowPicker={this.updateShowPicker}
								name="checkout"
								checkoutDate={this.state.form1}
								showDatePicker={this.showDatePicker}
								display={this.state.display.checkout}
								getDateValue={this.getDateValue}
								handleClick={this.handleClickDatePicker}
								title="Départ"
							/>
							<p className="text-sm text-red-400 mt-0 mb-3">{this.error()}</p>
							<div style={{ clear: "both" }}></div>
							<button className="font-semibold py-5 w-full rounded-full text-white shadow-xl text-xl my-10 waves-light" type="submit" style={ btn_style }>Suivant</button>
						</form>
					</div>
				</div>
			</div>
		)
	}
}

export default SubscribeStepCheckinCheckout