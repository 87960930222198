import React, { Component } from 'react'
import ReactBodymovin from 'react-bodymovin'
import animation from '../../img/check-circle.json'
import { XCircleIcon } from '@heroicons/react/solid'

class SubscribeStepEnd extends Component {
	constructor(props) {
		super(props)
		this.state = {
			email: this.props.user.email
		}
	}

	render () {
		const bodymovinOptions = {
			loop: false,
			autoplay: true,
			prerender: true,
			animationData: animation,
		}
		let error = null
		if (this.props.error_msg)
		{
			error = (
				<div className="rounded-md bg-red-50 p-4 mt-3">
					<div className="flex">
						<div className="flex-shrink-0">
							<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
						</div>
						<div className="ml-3">
							<h3 className="text-sm font-medium text-red-800">{this.props.error_msg}</h3>
						</div>
					</div>
				</div>
			)
		}
		return (
			<div>
				<div style={{backgroundColor: "#" + this.props.theme.color1}} className="h-52 z-0 px-8 text-center">
					<p className="text-2xl text-white pt-14">Inscription</p>
					<h1 className="text-3xl text-white font-semibold m-0 pt-2">Terminée !</h1>
				</div>
				<div className="rounded-t-3xl z-10" style={{backgroundColor: "#ffffff", marginTop: "-2rem"}}>
					<div className="px-10 pt-16 pb-10">
						{/* <div className="flex justify-center">
							<img src={fireworks} alt="validation icon" className="relative w-1/2"/>
						</div> */}
						<div className="w-full flex justify-center">
							<div className="w-32">
								<ReactBodymovin options={bodymovinOptions}/>
							</div>
						</div>
						<div>
							<h2 className="text-3xl font-semibold text-gray-700 text-center mb-10">Merci !</h2>
							<p className="text-lg text-gray-500 text-center mb-5">Vous pouvez maintenant consulter notre application pour vous accompagner tout au long de votre séjour !</p>
						</div>
						{/* <div>
							<h2 className="text-3xl font-semibold text-gray-700 text-center mb-10">Vous avez complété les étapes d'inscription ! Merci !</h2>
							<p className="text-lg text-gray-500 text-center mb-5">Un lien d'activation a été envoyé à cette adresse : 
								<span className="text-lg font-semibold text-gray-700"> {this.state.email}</span>
							</p>
							<p className="text-lg text-gray-500 text-center mb-10">
								Ce n'est pas la bonne addresse e-mail ? Il est encore temps de <span className="text-base text-lg font-semibold" style={{ color: '#' + this.props.theme.color1 }} onClick={this.props.gotoMail}>modifier cette adresse !</span>
							</p>
						</div> */}
						<div className="flex">
							<button onClick={this.props.validateStepEnd} className="text-center w-full font-semibold w-full py-5 px-4 rounded-full text-white shadow-xl text-xl waves-effect waves-light" type="submit" style={{backgroundColor: '#' + this.props.theme.color1}}>Suivant</button>
						</div>
						{error}
					</div>
				</div>	
			</div>
		)
	}
	
}

export default SubscribeStepEnd


