import React, { Component } from 'react'
import ModalMessage from '../UI/ModalMessage'
import ButtonPrevious from '../UI/ButtonPrevious'

class UpdateEmail extends Component {
	constructor(props) {
		super(props)
		this.default_ph_color = "text-gray-400"
		this.updated_ph_color = "text-black"
		this.activated_btn_color = "#" + this.props.theme.color1
		this.deactivated_btn_color = "#A3A3A3"
		this.email_ph = "ex : nathalie@gmail.com"
		this.state = {
			form1: {
				email: (this.props.user.email.length > 0) ? this.props.user.email : this.email_ph,
				v_email: (this.props.user.email.length > 0) ? this.props.user.email : ""
			},
			current_btn_color : (this.props.user.email.length > 0) ? this.activated_btn_color : this.deactivated_btn_color,
			modal_title: "Modification enregistrée !",
			modal_body: "Un lien d'activation vient de vous être envoyé à cette addresse.",
			modal_btn: "OK",
			error: null,
			success: null,
			show : false
		}
	}

	setErrorMsg = (msg) => {
		this.setState({ success: null, error: msg })
	}

	setSuccessMsg = (msg) => {
		this.setState({ success: msg, error: null })
	}

	clearFlash = () => {
		this.setState({ error: null, success: null })
	}

	_initErrors = () => {
		const errors = {
			global: null,
			email: null
		}
		return errors
	}

	focusEmail = (event) => {
		if (this.state.form1.v_email.length === 0) {
			let form1 = {
				...this.state.form1,
				email: '',
				v_email: this.state.form1.v_email
			}
			this.setState({ form1: form1 })
		}
	}

	blurEmail = (event) => {
		if (this.state.form1.v_email.length === 0) {
			let form1 = {
				...this.state.form1,
				email: this.email_ph,
				v_email: this.state.form1.v_email
			}
			this.setState({ form1: form1 })
		}
	}

	handleEmail = (event) => {
		this.btn_color(event)
		let form1 = {
			...this.state.form1,
			email: event.target.value.trim(),
			v_email: event.target.value.trim()
		}
		this.setState({ form1: form1 })
	}

	btn_color = (event) => {
		let style = this.state.current_btn_color
		if (event.target.value.length !== 0)
			style = this.activated_btn_color
		else
			style = this.deactivated_btn_color
		this.setState({ current_btn_color : style })
	}

	input_ph_color = () => {
		let color = this.default_ph_color
		if (this.state.form1.v_email !== "")
			color = this.updated_ph_color
		return (color)
	}

	_validateForm1 = () => {
		let error_obj = this._initErrors()
		let error = false
		const email = this.state.form1.v_email
		if (!email || !email.length)
		{
			error_obj.email = "Saisissez votre adresse e-mail. Vous en aurez besoin pour vous reconnecter."
			error = true
		}
		else if (email.length > this.length_max)
		{
			error_obj.email = 'Cette adresse e-mail est trop longue (' + this.length_max + ' caractères max.)'
			error = true
		}
		else if (/^[\w!#$%&'*+-/=?^_`{|}~]+(\.[\w!#$%&'*+-/=?^_`{|}~]+)*@((([-\w]+\.)+[a-zA-Z]{2,4})|(([0-9]{1,3}\.){3}[0-9]{1,3}))$/.test(email) === false)
		{
			error_obj.email = 'Cette adresse e-mail semble incorrecte.'
			error = true
		}
		if (error === true)
		{
			error_obj.global = "Il y a une ou plusieurs erreur(s) dans ce formulaire"
			this.setState({ success: null, error: error_obj })
		}
		return (!error) ? true : false
	}

	showModal = (e) => {
		e.preventDefault()
		this.setState({ show : true })
	}

	hideModal = (e) => {
		e.preventDefault()
		this.setState({ show : false })
	}

	handleSubmit = (event) => {
		event.preventDefault()
		if (this._validateForm1())
		{
			console.log("on vous a envoyé un mail")
			this.setSuccessMsg("Votre lien d'activation a été envoyé")
			this.props.updateUser(this.state.form1)
			this.showModal(event)
			/*const user = {
				email: this.state.form1.v_email,
			}
			this.props.postSubscribeUser(user)
			.then(user => {
				if (!user)
				{
					this.setErrorMsg('Cette adresse e-mail existe déjà')
					// vérifier si le mdp est ok, puis connecter...
				}
				else
				{
					this.props.postSendActivationEmail(user)
					.then(ret => {
						if (!ret)
							this.setErrorMsg('Impossible d\'envoyer le mail d\'activation')
						else
							this.props.validateStep2(user)
					})
					.catch(err => {
						this.setErrorMsg('Une erreur est survenue pendant l\'envoi du mail')
						console.log(err)
					})
				}
			})
			.catch(err => {
				this.setErrorMsg('Une erreur s\'est produite.')
				console.log(err)
			})*/
		}
	}

	render() {
		let modal = null 
		if (this.state.show === true)
		{
			modal = (<ModalMessage 
				show={this.state.show}
				hideModal={this.hideModal} 
				theme={this.props.theme}
				title={this.state.modal_title}
				body_text={this.state.modal_body}
				btn_text={this.state.modal_btn}
				validateHelp={this.props.modalRedirection}
			/>)
		}

		return (
			<div className="z-0">
				<ButtonPrevious
					theme={this.props.theme}
					handlePreviousBtn={this.props.handlePreviousBtn}
				/>
				<div style={{backgroundColor: "#" + this.props.theme.color1}} className="h-52 z-0 px-8 text-center">
				</div>
				<div className="rounded-t-3xl z-10 px-8 pt-16" style={{backgroundColor: "#ffffff", marginTop: "-3rem"}}>
					<h1 className="text-3xl text-gray-700 font-semibold mb-10">Modifier mon adresse e-mail <span role="img" aria-label="love-letter">💌</span></h1>
					<p className="mb-5 text-lg text-gray-500">Adresse e-mail</p>
					<form onSubmit={this.handleSubmit}>
						<p className="text-base text-red-400">{this.state.error}</p>
						<label>
							<input className={this.input_ph_color()} type="email" value={this.state.form1.email} onFocus={this.focusEmail} onBlur={this.blurEmail} onChange={this.handleEmail} />
						</label>
						<button 
							className=" z-0 font-semibold py-5 w-full rounded-full text-white shadow-xl text-xl mt-10 waves-light" 
							type="submit"
							style={{ backgroundColor: this.state.current_btn_color }}
						>
								Confirmer
						</button>
					</form>
					{modal}
				</div>
			</div>
		)
	}
}

export default UpdateEmail