import React, { Component } from 'react'

class DatePicker extends Component {
	constructor(props) {
		super(props)
		const today = new Date()
		this.default_ph_color = "#9CA3AF"
		this.updated_ph_color = "#111827"
		let options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }
		let updatedDate = today.toLocaleDateString('fr-FR', options)
		if (this.props.name === "checkin" && this.props.checkinDate.d_checkin !== "")
			updatedDate = this.props.checkinDate.d_checkin
		else if (this.props.name === "checkout" && this.props.checkoutDate.d_checkout !== "")
			updatedDate = this.props.checkoutDate.d_checkout
		this.state = {
			showDatepicker : false,
			datepickerValue: updatedDate,
			datePickerSelected : "",
			left: 0,
			month: today.getMonth(),
			year: today.getFullYear(),
			no_of_days: [],
			blankdays: []
		}
		let daysInMonth = new Date(this.state.year, this.state.month + 1, 0).getDate()
		let dayOfWeek = new Date(this.state.year, this.state.month).getDay()
		for (let i = 1; i <= dayOfWeek; i++)
			this.state.blankdays.push(i)
		for (let i = 1; i <= daysInMonth; i++)
			this.state.no_of_days.push(i)
		this.days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
		this.month_names = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
	}

	changeMonth = (btn) => {
		let currentMonth = this.state.month
		if (btn === "previousBtn")
			currentMonth -= 1
		if (btn === "nextBtn")
			currentMonth += 1
		if (currentMonth >= 0 && currentMonth <= 11)
		{
			let daysInMonth = new Date(this.state.year, currentMonth + 1, 0).getDate()
			let dayOfWeek = new Date(this.state.year, currentMonth).getDay()
			let blankdaysArray = []
			for (let i = 1; i <= dayOfWeek; i++)
				blankdaysArray.push(i)
			let daysArray = []
			for (let i = 1; i <= daysInMonth; i++)
				daysArray.push(i)
			this.setState({ blankdays: blankdaysArray, no_of_days: daysArray, month: currentMonth })
		}
	}	

	getDateValue = (date) => {
		let selectedDate = new Date(this.state.year, this.state.month, date)
		const options_long = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }
		const options_numeric = { year: 'numeric', month: 'numeric', day: 'numeric' }
		let datepickerValueLong = selectedDate.toLocaleDateString('fr-FR', options_long)
		let datepickerValueNumeric = selectedDate.toLocaleDateString('fr-FR', options_numeric)
		this.props.getDateValue(datepickerValueLong, datepickerValueNumeric, this.props.name)
		this.setState({ datepickerValue : datepickerValueLong, showDatepicker : false, datePickerSelected : datepickerValueLong })
	}

	inputColor = () => {
		let style = {
			color: this.default_ph_color
		}
		if (this.props.name === "checkin" && this.props.checkinDate.d_checkin !== "")
			style.color = this.updated_ph_color
		else if (this.props.name === "checkout" && this.props.checkoutDate.d_checkout !== "")
			style.color = this.updated_ph_color
		return (style)
	}

	isToday = (date) => {
		const today = new Date()
		const d = new Date(this.state.year, this.state.month, date)
		return (today.toDateString() === d.toDateString() ? true : false)
	}

	btnStyle = (btn) => {
		let style = null
		const styleNotAllowed = {
			cursor : "notAllowed",
			opacity : "0.25"
		}
		if (this.state.month <= 0 && btn === "previousBtn")
			style = styleNotAllowed	
		else if (this.state.month >= 11 && btn === "nextBtn")
			style = styleNotAllowed
		return (style)
	}

	todayStyle = (date) => {
		let date_style = {
			color: '',
			backgroundColor: '',
		}
		if (this.isToday(date) === true)
		{
			date_style.color = "white"
			date_style.backgroundColor = "#3B82F6"
		}
		else
			date_style.color = "#374151"
		return (date_style)
	}

	render () {
		const showDays = (
			this.days.map((day, index) =>
				<div key={index} style={{ width : "14.26%" }}>
					<div className="text-gray-800 font-medium text-center text-xs">{day}</div>
				</div>
			)
		)
		const handleBlankday = (
			this.state.blankdays.map((index) =>
				<div key={index} className="text-center border p-1 border-transparent text-sm" style={{ width : "14.28%" }}></div>
			)
		)
		const showDates = (
			this.state.no_of_days.map((date, index) => 
				<div key={index} style={{ width : "14.28%" }} className="px-1 mb-1">
					<div onClick={this.getDateValue.bind(this, date)} style={this.todayStyle(date)} className="cursor-pointer text-center text-sm leading-none rounded-full leading-loose transition ease-in-out duration-100">{date}</div>
				</div>
			)
		)
		let style = {
			width: "80vw",
			display: (this.props.display === true) ? "block" : "none",
			zIndex: 2000
		}
		const style_input = {
			float: "left",
			width: "49%"
		}
		if (this.props.name === "checkout")
			style_input.marginLeft = "2%"		
		return (
			<div className="mb-5 w-full mx-2" onClick={this.props.handleClick.bind(this, this.props.name)}>
				<div className="relative">
					<div style={style_input}>
						<label htmlFor="datepicker" className="font-bold mb-1 text-xl text-gray-400 block">{this.props.title}</label>
						<input 
							type="text"
							id="datepicker"
							readOnly
							className="w-full pl-4 pr-10 py-3 leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline text-gray-400 font-medium"
							style={ this.inputColor()}
							placeholder="Select date"
							value={this.state.datepickerValue}
							onClick={this.props.showDatePicker.bind(this, this.props.name)}
						/>
					</div>
					<div className="bg-white rounded-lg shadow p-4 absolute top-0 left-0" style={style} ref={this.datePickerRef}>
						<div className="flex justify-between items-center mb-2">
							<div>
								<span className="text-lg font-bold text-gray-800">{this.month_names[this.state.month]}</span>
								<span className="ml-1 text-lg text-gray-600 font-normal">{this.state.year}</span>
							</div>
							<div>
								<button 
									type="button"
									className="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full"
									style = {this.btnStyle("previousBtn")}
									onClick={this.changeMonth.bind(this, "previousBtn")}
								>
									<svg className="h-6 w-6 text-gray-500 inline-flex"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"/>
									</svg>  
								</button>
								<button 
									type="button"
									className="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full" 
									style = {this.btnStyle("nextBtn")}
									onClick={this.changeMonth.bind(this, "nextBtn")}
								>
									<svg className="h-6 w-6 text-gray-500 inline-flex"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"/>
									</svg>
								</button>
							</div>
						</div>
						<div className="flex flex-wrap mb-3 -mx-1">{showDays}</div>
						<div className="flex flex-wrap -mx-1">
							{handleBlankday}
							{showDates}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default DatePicker