import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class ModuleAnimation extends Component {
	constructor(props) {
		super(props)
		this.state = {
			date: null,
			animation: undefined
		}
	}

	componentDidMount() {
		this.loadAnimation()
	}

	loadAnimation = () => {
		this.props.fetchAnimationNext()
		.then(ret => {
			if (ret !== null)
				this.setState({ date: ret.date, animation: ret.animation })
			else
				this.setState({ date: null, animation: null })
		})
		.catch(err => console.log(err))
	}

	render() {
		const date = this.state.date
		const animation = this.state.animation
		if (date && animation)
		{
			const cover = "url(" + animation.cover + ")"
			const now = new Date()
			let dateObj = new Date(date.start * 1000)
			let month = dateObj.getMonth()
			let day = dateObj.getDate()
			const urlDate = new Date(dateObj.getFullYear(), month, day)
			const url = this.props.lang_assets.paths.activities + '/' + Math.floor(urlDate.getTime() / 1000)
			let dateTxt = null
			if (month === now.getMonth() && day === now.getDate())
			{
				let m = dateObj.getMinutes()
				if (m < 10)
					m = '0' + m
				dateTxt = dateObj.getHours() + "h" + m
			}
			else
			{
				month++
				if (month < 10)
					month = '0' + month
				if (day < 10)
					day = '0' + day
				dateObj = new Date(dateObj.getFullYear(), month - 1, day)
				dateTxt = day + "/" + month
			}
			let name = ""
			for (let i = 0; i < animation.translations.length; i++)
			{
				if (animation.translations[i].lang === this.props.user.lang)
				{
					name = animation.translations[i].name
					break
				}
			}
			return (
				<div>
					<h2 className="text-xl ml-5 mt-7">{this.props.title}</h2>
					<Link to={url}>
						<div className="w-11/12 mt-4 relative mx-auto mb-5 rounded-xl h-56 bg-cover shadow-lg" style={{ backgroundImage: cover }}>
							<div className="flex justify-end">
								<span className="items-end mt-3 mr-3 px-3 py-0.5 rounded-full text-base font-medium bg-gray-100 text-gray-800">{dateTxt}</span>
							</div>
							<div className="bg-gradient-to-t w-full from-gray-900 h-20 absolute bottom-0 rounded-xl text-white text-xl font-medium flex items-end p-2">
								<p className="mx-auto">{name}</p>
							</div>
						</div>
					</Link>
				</div>
			)
		}
		else if (animation === undefined)
		{
			return (
				<div className="animate-pulse w-11/12 mt-4 relative mx-auto mb-5 bg-gray-200 rounded-xl h-56 shadow-lg">
					<div className="flex justify-end">
				 		<span className="items-end mt-3 mr-3 px-3 py-0.5 rounded-full text-base font-medium bg-gray-100 text-gray-800">En attente</span>
				 	</div>
					<div className="w-full h-20 absolute bottom-0 rounded-xl text-white text-xl font-medium flex items-end p-2">
						<p className="mx-auto">{this.props.lang_assets.home.loading}</p>
					</div>
				</div>
			)
		}
		else
			return null
	}
}

export default ModuleAnimation