import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class ModuleList extends Component {
	constructor(props) {
		super(props)
		this.state = {
			list: null,
			category: null
		}
	}

	componentDidMount() {
		this.props.fetchListByID(this.props.list)
		.then(list => {
			this.props.fetchCategoryByID(list.category)
			.then(category => {
				this.setState({ category: category, list: list })
			})
			.catch(err => console.log(err))
		})
		.catch(err => console.log(err))
	}

	render() {
		const list = this.state.list
		const category = this.state.category
		const groups = this.props.groups
		if (list && category)
		{
			let group = null
			for (let i = 0; i < groups.length; i++)
			{
				if (groups[i]._id === category.group)
				{
					group = groups[i]
					break
				}
			}
			let url = this.props.lang_assets.paths.rubrics + '/'
			let prefix = ""
			if (group === null)
				console.log(list, category)
			if (group && group.translations)
			{
				for (let i = 0; i < group.translations.length; i++)
				{
					if (group.translations[i].lang === this.props.user.lang)
					{
						prefix = group.translations[i].prefix
						break
					}
				}
			}
			let suffix = ""
			for (let i = 0; i < category.translations.length; i++)
			{
				if (category.translations[i].lang === this.props.user.lang)
				{
					suffix = category.translations[i].suffix
					break
				}
			}
			if (group !== null)
				url = this.props.lang_assets.paths.rubrics + '/' + prefix + '/' + suffix
			if (list && list.cover !== null)
			{
				const cover = "url(" + list.cover + ")"
				return (
					<div>
						<h2 className="text-xl ml-5 mt-7">{this.props.title}</h2>
						<Link to={url}>
							<div className="w-11/12 mt-4 relative mx-auto mb-5 rounded-xl h-56 bg-cover shadow-lg" style={{ backgroundImage: cover }}>
								<div className="bg-gradient-to-t w-full from-gray-900 h-20 absolute flex items-end bottom-0 rounded-b-xl text-white p-2 justify-center">
									<p className="text-xl font-medium">{this.props.subtitle}</p>
								</div>
							</div>
						</Link>
					</div>
				)
			}
			else
			{
				return (
					<div>
						<h2 className="text-xl ml-5 mt-7">{this.props.title}</h2>
						<Link to={url}>
							<div className="w-11/12 mt-4 relative mx-auto mb-5 rounded-xl h-56 bg-cover shadow-lg">
								<div className="bg-gradient-to-t w-full from-gray-900 h-20 absolute flex items-end bottom-0 rounded-b-xl text-white p-2 justify-center">
									<p className="text-xl font-medium">{this.props.subtitle}</p>
								</div>
							</div>
						</Link>
					</div>
				)
			}
		}
		else
		{
			return (
				<div className="center">
					<div className="bg-gradient-to-t w-full from-gray-900 h-20 absolute flex items-end bottom-0 rounded-b-xl text-white p-2 justify-center">
						<p className="text-xl font-medium">{this.props.lang_assets.home.loading}</p>
					</div>
				</div>
			)
		}
	}
}

export default ModuleList