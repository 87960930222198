import React, { Component } from 'react'
import M from 'materialize-css'
import ButtonPrevious from '../UI/ButtonPrevious'

class SubscribeStepPrimaryEmails extends Component {
	constructor(props) {
		super(props)
		this.default_ph_color = "text-gray-400"
		this.updated_ph_color = "text-black"
		this.error_color = "#F87171"
		this.activated_btn_color = "#" + this.props.theme.color1
		this.deactivated_btn_color = "#A3A3A3"
		this.lastname_ph = ""
		this.firstname_ph = ""
		this.email_ph = ""
		this.name_length_max = 50
		this.length_max = 100
		this.state = {
			form1: {
				primary_emails: []
			},
			password_instruction_color : "#9CA3AF",
			current_btn_color : (this.props.user.email.length > 0 && this.props.user.password.length >= 8) ? this.activated_btn_color : this.deactivated_btn_color,
			success: null,
			error: this._initErrors()
		}
		const primary_emails = []
		for (let i = 0; i < this.props.user.nb_primary; i++)
		{
			if (this.props.user.primary_emails[i])
			{
				primary_emails.push({
					lastname: (this.props.user.primary_emails[i].lastname && this.props.user.primary_emails[i].lastname.length > 0) ? this.props.user.primary_emails[i].lastname : this.lastname_ph,
					v_lastname: (this.props.user.primary_emails[i].lastname && this.props.user.primary_emails[i].lastname.length > 0) ? this.props.user.primary_emails[i].lastname : "",
					firstname: (this.props.user.primary_emails[i].firstname && this.props.user.primary_emails[i].firstname.length > 0) ? this.props.user.primary_emails[i].firstname : this.firstname_ph,
					v_firstname: (this.props.user.primary_emails[i].firstname && this.props.user.primary_emails[i].firstname.length > 0) ? this.props.user.primary_emails[i].firstname : "",
					email: (this.props.user.primary_emails[i].email && this.props.user.primary_emails[i].email.length > 0) ? this.props.user.primary_emails[i].email : this.email_ph,
					v_email: (this.props.user.primary_emails[i].email && this.props.user.primary_emails[i].email.length > 0) ? this.props.user.primary_emails[i].email : ""
				})
			}
			else
			{
				primary_emails.push({
					lastname: this.lastname_ph,
					v_lastname: '',
					firstname: this.firstname_ph,
					v_firstname: '',
					email: this.email_ph,
					v_email: ''
				})
			}
		}
		this.state.form1.primary_emails = primary_emails
	}
	
	componentDidUpdate() {
		const elems = document.querySelectorAll('select')
		M.FormSelect.init(elems)
	}

	setErrorMsg = (key, msg) => {
		const errors = this._initErrors()
		errors[key] = msg
		this.setState({ success: null, error: errors })
	}

	setSuccessMsg = (msg) => {
		this.setState({ success: msg, error: this._initErrors() })
	}

	clearFlash = () => {
		this.setState({ error: null, success: this._initErrors() })
	}

	_initErrors = () => {
		const errors = []
		for (let i = 0; i < this.props.user.nb_primary; i++)
		{
			errors.push({
				lastname: null,
				firstname: null,
				email: null
			})
		}
		return errors
	}

	focusLastname = (primary_nb) => {
		if (this.state.form1.primary_emails[primary_nb].v_lastname.length === 0)
		{
			const primary_emails = this.state.form1.primary_emails
			primary_emails[primary_nb] = {
				...this.state.form1.primary_emails[primary_nb],
				lastname: ''
			}
			let form1 = {
				primary_emails: primary_emails
			}
			this.setState({ form1: form1 })
		}
	}

	blurLastname = (primary_nb) => {
		if (this.state.form1.primary_emails[primary_nb].v_lastname.length === 0)
		{
			const primary_emails = this.state.form1.primary_emails
			primary_emails[primary_nb] = {
				...this.state.form1.primary_emails[primary_nb],
				lastname: this.lastname_ph
			}
			let form1 = {
				primary_emails: primary_emails
			}
			this.setState({ form1: form1 })
		}
	}

	handleLastname = (primary_nb, event) => {
		const primary_emails = this.state.form1.primary_emails
		primary_emails[primary_nb] = {
			...this.state.form1.primary_emails[primary_nb],
			lastname: event.target.value.trim(),
			v_lastname: event.target.value.trim()
		}
		let form1 = {
			primary_emails: primary_emails
		}
		let nb = 0
		for (let i = 0; i < this.state.form1.primary_emails.length; i++)
			if (this.state.form1.primary_emails[i].v_firstname.length > 0 && this.state.form1.primary_emails[i].v_lastname.length > 0)
				nb++
		let current_btn_color = (nb === this.state.form1.primary_emails.length) ? this.activated_btn_color : this.deactivated_btn_color
		this.setState({ form1: form1, current_btn_color: current_btn_color })
	}

	focusFirstname = (primary_nb) => {
		if (this.state.form1.primary_emails[primary_nb].v_firstname.length === 0)
		{
			const primary_emails = this.state.form1.primary_emails
			primary_emails[primary_nb] = {
				...this.state.form1.primary_emails[primary_nb],
				firstname: ''
			}
			let form1 = {
				primary_emails: primary_emails
			}
			this.setState({ form1: form1 })
		}
	}

	blurFirstname = (primary_nb) => {
		if (this.state.form1.primary_emails[primary_nb].v_firstname.length === 0)
		{
			const primary_emails = this.state.form1.primary_emails
			primary_emails[primary_nb] = {
				...this.state.form1.primary_emails[primary_nb],
				firstname: this.firstname_ph
			}
			let form1 = {
				primary_emails: primary_emails
			}
			this.setState({ form1: form1 })
		}
	}

	handleFirstname = (primary_nb, event) => {
		const primary_emails = this.state.form1.primary_emails
		primary_emails[primary_nb] = {
			...this.state.form1.primary_emails[primary_nb],
			firstname: event.target.value.trim(),
			v_firstname: event.target.value.trim()
		}
		let form1 = {
			primary_emails: primary_emails
		}
		let nb = 0
		for (let i = 0; i < this.state.form1.primary_emails.length; i++)
			if (this.state.form1.primary_emails[i].v_firstname.length > 0 && this.state.form1.primary_emails[i].v_lastname.length > 0)
				nb++
		let current_btn_color = (nb === this.state.form1.primary_emails.length) ? this.activated_btn_color : this.deactivated_btn_color
		this.setState({ form1: form1, current_btn_color: current_btn_color })
	}

	focusEmail = (primary_nb) => {
		if (this.state.form1.primary_emails[primary_nb].v_email.length === 0)
		{
			const primary_emails = this.state.form1.primary_emails
			primary_emails[primary_nb] = {
				...this.state.form1.primary_emails[primary_nb],
				email: ''
			}
			let form1 = {
				primary_emails: primary_emails
			}
			this.setState({ form1: form1 })
		}
	}

	blurEmail = (primary_nb) => {
		if (this.state.form1.primary_emails[primary_nb].v_email.length === 0)
		{
			const primary_emails = this.state.form1.primary_emails
			primary_emails[primary_nb] = {
				...this.state.form1.primary_emails[primary_nb],
				email: this.email_ph
			}
			let form1 = {
				primary_emails: primary_emails
			}
			this.setState({ form1: form1 })
		}
	}

	handleEmail = (primary_nb, event) => {
		const primary_emails = this.state.form1.primary_emails
		primary_emails[primary_nb] = {
			...this.state.form1.primary_emails[primary_nb],
			email: event.target.value.trim(),
			v_email: event.target.value.trim()
		}
		let form1 = {
			primary_emails: primary_emails
		}
		let nb = 0
		for (let i = 0; i < this.state.form1.primary_emails.length; i++)
			if (this.state.form1.primary_emails[i].v_firstname.length > 0 && this.state.form1.primary_emails[i].v_lastname.length > 0)
				nb++
		let current_btn_color = (nb === this.state.form1.primary_emails.length) ? this.activated_btn_color : this.deactivated_btn_color
		this.setState({ form1: form1, current_btn_color: current_btn_color })
	}

	ph_color = (v_input) => {
		let color = this.default_ph_color
		if (v_input !== "")
			color = this.updated_ph_color
		return (color)
	}
	
	_validateForm1 = () => {
		let error_obj = this._initErrors()
		let error = false
		for (let i = 0; i < this.state.form1.primary_emails.length; i++)
		{
			const lastname = this.state.form1.primary_emails[i].v_lastname
			const firstname = this.state.form1.primary_emails[i].v_firstname
			const email = this.state.form1.primary_emails[i].v_email
			if (!lastname || !lastname.length)
			{
				error_obj[i].lastname = 'Veuillez renseigner le nom'
				error = true
			}
			else if (/[@./\\[\]#'"%*&!?;:{}0-9_]/.test(lastname))
			{
				error_obj[i].lastname = 'Ce nom n\'est pas valide, veuillez réessayer'
				error = true
			}
			else if (lastname.length > this.name_length_max)
			{
				error_obj[i].lastname = 'Le nom dépasse le nombre de charactère maximum (' + this.name_length_max + ' caractères max.)'
				error = true
			}
			if (!firstname || !firstname.length)
			{
				error_obj[i].firstname = 'Veuillez renseigner le prénom'
				error = true
			}
			else if (/[@./\\[\]#'"%*&!?;:{}0-9_]/.test(firstname))
			{
				error_obj[i].firstname = 'Ce prénom n\'est pas valide, veuillez réessayer'
				error = true
			}
			else if (!firstname || firstname.length < 2)
			{
				error_obj[i].firstname = 'Un peu court pour un prénom !'
				error = true
			}
			else if (firstname.length > this.name_length_max)
			{
				error_obj[i].firstname = 'Le prénom dépasse le nombre de charactère maximum (' + this.name_length_max + ' caractères max.)'
				error = true
			}
			if (email.length > this.length_max)
			{
				error_obj[i].email = 'Cette adresse e-mail est trop longue (' + this.length_max + ' caractères max.)'
				error = true
			}
			else if (email.length > 0 && /^[\w!#$%&'*+-/=?^_`{|}~]+(\.[\w!#$%&'*+-/=?^_`{|}~]+)*@((([-\w]+\.)+[a-zA-Z]{2,4})|(([0-9]{1,3}\.){3}[0-9]{1,3}))$/.test(email) === false)
			{
				error_obj[i].email = 'Cette adresse e-mail semble incorrecte.'
				error = true
			}
		}
		if (error === true)
		{
			error_obj.global = "Il y a une ou plusieurs erreur(s) dans le formulaire"
			this.setState({ success: null, error: error_obj })
		}
		return (!error) ? true : false
	}

	handleSubmit = (event) => {
		event.preventDefault()
		if (this._validateForm1())
		{
			this.props.updateUser(this.state.form1)
			this.props.validateStepPrimaryEmails()
		}
	}

	render ()
	{
		let title = null
		if (this.props.user.nb_primary === 1)
			title = "Infos du candidat :"
		else
			title = "Infos des candidats :"
		let primary_nb = -1
		return (
			<div>
				<ButtonPrevious
					theme={this.props.theme}
					handlePreviousBtn={this.props.handlePreviousBtn}
				/>
				<div style={{backgroundColor: "#" + this.props.theme.color1}} className="h-52 z-0 px-8 text-center">
					<p className="text-2xl text-white pt-14">Inscription</p>
					<h1 className="text-3xl text-white font-semibold m-0 pt-2">Étape {this.props.stepNb}</h1>
				</div>
				<div className="rounded-t-3xl z-10" style={{backgroundColor: "#ffffff", marginTop: "-2rem"}}>
					<div className="px-10 pt-16 pb-10">
						<div>
							<h2 className="text-3xl font-semibold text-gray-700 mb-10">{title}</h2>
							<form className="mb-16" onSubmit={this.handleSubmit}>
								{this.state.form1.primary_emails.map(({ firstname, v_firstname, lastname, v_lastname, email, v_email }) => {
									primary_nb++
									return (
										<div key={primary_nb}>
											<h3 className="mt-3 text-lg text-gray-500">Nom</h3>
											<label>
												{/* <input type="text" className={this.ph_color(v_lastname)} value={lastname} onFocus={this.focusLastname.bind(this, primary_nb)} onBlur={this.blurLastname.bind(this, primary_nb)} onChange={this.handleLastname.bind(this, primary_nb)} /> */}
												<div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
													<input
														className="block pl-0 w-full border-0 border-b border-transparent text-gray-500 focus:text-gray-700 focus:border-indigo-600 focus:ring-0 text-lg"
														type="text" 
														// className={this.ph_color(v_lastname)} 
														value={lastname} 
														onFocus={this.focusLastname.bind(this, primary_nb)} 
														onBlur={this.blurLastname.bind(this, primary_nb)} 
														onChange={this.handleLastname.bind(this, primary_nb)}
													/>
												</div>
											</label>
											<p className="text-sm text-red-400 mt-0 mb-3">{this.state.error[primary_nb].lastname}</p>
											<h3 className="mt-3 text-lg text-gray-500">Prénom</h3>
											<label>
												{/* <input type="text" className={this.ph_color(v_firstname)} value={firstname} onFocus={this.focusFirstname.bind(this, primary_nb)} onBlur={this.blurFirstname.bind(this, primary_nb)} onChange={this.handleFirstname.bind(this, primary_nb)} /> */}
												<div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
													<input
														className="block pl-0 w-full border-0 border-b border-transparent text-gray-500 focus:text-gray-700 focus:border-indigo-600 focus:ring-0 text-lg"
														type="text" 
														// className={this.ph_color(v_firstname)} 
														value={firstname} 
														onFocus={this.focusFirstname.bind(this, primary_nb)} 
														onBlur={this.blurFirstname.bind(this, primary_nb)} 
														onChange={this.handleFirstname.bind(this, primary_nb)}
													/>
												</div>
											</label>
											<p className="text-sm text-red-400 mt-0 mb-3">{this.state.error[primary_nb].firstname}</p>
											<h3 className="mt-3 text-lg text-gray-500">Adresse e-mail</h3>
											<label>
												{/* <input type="email" className={this.ph_color(v_email)} value={email} onFocus={this.focusEmail.bind(this, primary_nb)} onBlur={this.blurEmail.bind(this, primary_nb)} onChange={this.handleEmail.bind(this, primary_nb)} /> */}
												<div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
													<input
														className="block pl-0 w-full border-0 border-b border-transparent text-gray-500 focus:text-gray-700 focus:border-indigo-600 focus:ring-0 text-lg"
														type="email" 
														// className={this.ph_color(v_email)} 
														value={email} 
														onFocus={this.focusEmail.bind(this, primary_nb)} 
														onBlur={this.blurEmail.bind(this, primary_nb)} 
														onChange={this.handleEmail.bind(this, primary_nb)}
													/>
												</div>
											</label>
											<p className="text-sm text-red-400 mt-0 mb-3">{this.state.error[primary_nb].email}</p>
										</div>
									)
								})}
								<button className="font-semibold py-5 w-full rounded-full text-white shadow-xl text-xl my-10 waves-effect waves-light" type="submit" style={{ backgroundColor: this.state.current_btn_color }}>Suivant</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default SubscribeStepPrimaryEmails