import React, { Component } from 'react'
import Booking from './Booking'
import Buying from './Buying'
import picture from '../../img/default.jpg'
import Loader from '../UI/Loader'
import tools from '../../tools'
import './Services.css'

class Services extends Component {
	constructor(props) {
		super(props)
		const now = new Date()
		const timestamp = Math.round(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0).getTime() / 1000)
		this.state = {
			cart: null,
			service: null,
			service_benefits: null,
			service_benefit: null,
			service_products: null,
			service_reservations: null,
			service_workers: null,
			reservation: null,
			step: 0,
			error: null,
			timestamp: timestamp,
			mounted: false
		}
	}

	componentDidMount() {
		this.props.updateLogs(this.props.history.location.pathname, 'Services')
		this.loadService()
		this.props.updateShell(null, true, this.props.history.location.pathname)
		if (!this.props.icons)
			this.props.fetchIcons()
		this.props.fetchNotifications()
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.service !== this.state.service)
		{
			const service = this.state.service._id
			this.props.fetchServiceBenefitsByService(service)
			.then(service_benefits => {
				this.setState({ service_benefits: service_benefits })
			})
			this.props.fetchServiceReservationsByService(service)
			.then(service_reservations => {
				this.setState({ service_reservations: service_reservations })
			})
			this.props.fetchServiceWorkersByService(service)
			.then(service_workers => {
				this.setState({ service_workers: service_workers })
			})
			this.props.fetchServiceProductsByService(service)
			.then(service_products => {
				for (let i = 0; i < service_products.length; i++)
					service_products[i].quantity = 0
				this.setState({ service_products: service_products })
			})
		}
	}
	
	loadService = () => {
		const services = this.props.services
		const url = this.props.history.location.pathname.substr(10)
		let service = null
		if (services)
		{
			for (let i = 0; i < services.length; i++)
			{
				if (services[i].url === url)
				{
					service = services[i]
					break
				}
			}
		}
		if (service === null || !service.cover)
		{
			this.props.fetchServiceByURL(url, this.props.user.lang)
			.then(service => {
				this.setState({ service: service, mounted: true })
			})
			.catch(err => console.log(err))
		}
		else
			this.setState({ service: service, mounted: true })
	}

	selectSlot = (day, start, end, workers) => {
		start = start * 60 + day
		end = end * 60 + day
		workers = tools.clone(workers)
		const reservations = tools.clone(this.state.service_reservations)
		const count_workers = {}
		for (let i = 0; i < workers.length; i++)
			count_workers[workers[i]._id] = 0
		let i = 0
		while (i < reservations.length)
		{
			if (reservations[i].start !== start || reservations[i].end !== end)
			{
				reservations.splice(i, 1)
				continue
			}
			else
				count_workers[reservations[i].service_worker]++
			i++
		}
		let min = null
		for (const [key, value] of Object.entries(count_workers))
		{
			if (min === null || min > value)
				min = value
			if (!key) // obligatoire pour masquer le warning "unused variable `key`" (ça n'affichera rien car key a toujours une valeur)
				console.log(key)
		}
		for (const [key, value] of Object.entries(count_workers))
		{
			if (value !== min)
			{
				i = 0
				while (i < workers.length)
				{
					if (workers[i]._id === key)
					{
						workers.splice(i, 1)
						break
					}
					i++
				}
			}
		}
		const rand = Math.floor(Math.random() * workers.length)
		const worker = workers[rand]
		const service = {
			service: this.state.service_benefit.service,
			service_benefit: this.state.service_benefit._id,
			service_worker: worker._id,
			start: start,
			end: end,
			answers: null,
			user: this.props.user._id
		}
		this.setState({ step: 2, reservation: service })
	}

	selectSlotCart = (day, start, end) => {
		start = start * 60 + day
		end = end * 60 + day
		const reservations = tools.clone(this.state.service_reservations)
		let i = 0
		while (i < reservations.length)
		{
			if (reservations[i].start !== start || reservations[i].end !== end)
			{
				reservations.splice(i, 1)
				continue
			}
			i++
		}
		const service = {
			service: this.state.service,
			cart: this.state.cart,
			start: start,
			end: end,
			answers: null,
			user: this.props.user._id
		}
		this.setState({ step: 2, reservation: service })
	}

	updateTime = (time) => {
		this.setState({ timestamp: time })
	}

	_getFiveDays = () => {
		const time_assets = this.props.lang_assets.time
		const timestamp = this.state.timestamp
		const times = [
			timestamp - 172800,
			timestamp - 86400,
			timestamp,
			timestamp + 86400,
			timestamp + 172800
		]
		const dates = [
			new Date(times[0] * 1000),
			new Date(times[1] * 1000),
			new Date(times[2] * 1000),
			new Date(times[3] * 1000),
			new Date(times[4] * 1000)
		]
		let days = []
		for (let i = 0; i < 5; i++)
		{
			const month = dates[i].getMonth()
			days[i] = {
				dayNb: dates[i].getDate(),
				dayTxt: time_assets.weekdays[dates[i].getDay()],
				monthNb: month + 1,
				monthTxt: time_assets.months_short[month],
				time: times[i]
			}
			if (days[i].dayNb < 10)
				days[i].dayNb = '0' + days[i].dayNb
			if (days[i].monthNb < 10)
				days[i].monthNb = '0' + days[i].monthNb
		}
		return days
	}

	_checkSlotsAvailable = () => {
		const service_benefit = this.state.service_benefit
		const service_workers = this.state.service_workers
		const service_reservations = this.state.service_reservations
		const lang_assets = this.props.lang_assets.services
		const time_assets = this.props.lang_assets.time
		const days = this._getFiveDays()
		const date = new Date(days[2].time * 1000)
		const year = date.getFullYear()
		const weekday = date.getDay()
		const today = new Date(date.getFullYear(), date.getMonth(), date.getDate())
		let ret = {
			open: false,
			msg: null,
			days: days,
			timestamp: Math.round(today.getTime() / 1000),
			slots: []
		}
		let open = false
		for (let i = 0; i < service_benefit.open_dates.length; i++)
		{
			const open_date = service_benefit.open_dates[i]
			const start = Math.round(new Date(year, open_date.start_month - 1, open_date.start_day).getTime() / 1000)
			const end = Math.round(new Date(year, open_date.end_month - 1, open_date.end_day).getTime() / 1000)
			if (ret.timestamp >= start && ret.timestamp <= end)
			{
				open = true
				break
			}
		}
		if (open === false)
			ret.msg = lang_assets.not_available1
		else
		{
			let open_days = [false, false, false, false, false, false, false]
			for (let i = 0; i < service_benefit.open_hours.length; i++)
			{
				const open_hour = service_benefit.open_hours[i]
				if (open_hour.sunday === true)
					open_days[0] = true
				if (open_hour.monday === true)
					open_days[1] = true
				if (open_hour.tuesday === true)
					open_days[2] = true
				if (open_hour.wednesday === true)
					open_days[3] = true
				if (open_hour.thursday === true)
					open_days[4] = true
				if (open_hour.friday === true)
					open_days[5] = true
				if (open_hour.saturday === true)
					open_days[6] = true
			}
			if (open_days[weekday] === false)
			{
				let daystxt = []
				for (let i = 0; i < time_assets.weekdays.length; i++)
					daystxt.push(time_assets.weekdays[i].toLowerCase())
				const weekdaytxt = daystxt[weekday]
				ret.msg = lang_assets.not_available2 + weekdaytxt
			}
			else
			{
				let workers = []
				for (let i = 0; i < service_workers.length; i++)
				{
					let found = false
					for (let j = 0; j < service_workers[i].services.length; j++)
					{
						if (service_workers[i].services[j] === service_benefit._id)
						{
							found = true
							break
						}
					}
					if (!found)
						continue
					let available = false
					for (let j = 0; j < service_workers[i].working_dates.length; j++)
					{
						const working_date = service_workers[i].working_dates[j]
						const start = Math.round(new Date(year, working_date.start_month - 1, working_date.start_day).getTime() / 1000)
						const end = Math.round(new Date(year, working_date.end_month - 1, working_date.end_day).getTime() / 1000)
						if (ret.timestamp >= start && ret.timestamp <= end)
						{
							available = true
							break
						}
					}
					let working_days = [false, false, false, false, false, false, false]
					for (let j = 0; j < service_workers[i].working_hours.length; j++)
					{
						const open_hour = service_workers[i].working_hours[j]
						if (open_hour.sunday === true)
							working_days[0] = true
						if (open_hour.monday === true)
							working_days[1] = true
						if (open_hour.tuesday === true)
							working_days[2] = true
						if (open_hour.wednesday === true)
							working_days[3] = true
						if (open_hour.thursday === true)
							working_days[4] = true
						if (open_hour.friday === true)
							working_days[5] = true
						if (open_hour.saturday === true)
							working_days[6] = true
					}
					if (working_days[weekday] === false)
						available = false
					if (available === true)
						workers.push(service_workers[i])
				}
				if (workers.length === 0)
					ret.msg = lang_assets.not_available1
				else
				{
					ret.open = true
					let open_hours = []
					for (let i = 0; i < service_benefit.open_hours.length; i++)
					{
						const open_hour = service_benefit.open_hours[i]
						if ((weekday === 0 && open_hour.sunday === true) ||
							(weekday === 1 && open_hour.monday === true) ||
							(weekday === 2 && open_hour.tuesday === true) ||
							(weekday === 3 && open_hour.wednesday === true) ||
							(weekday === 4 && open_hour.thursday === true) ||
							(weekday === 5 && open_hour.friday === true) ||
							(weekday === 6 && open_hour.saturday === true))
						{
							open_hours.push({
								start_hour: open_hour.start_hour,
								start_minute: open_hour.start_minute,
								end_hour: open_hour.end_hour,
								end_minute: open_hour.end_minute
							})
						}
					}
					open_hours.sort((a, b) => {
						const start_time1 = a.start_hour * 60 + a.start_minute
						const start_time2 = b.start_hour * 60 + b.start_minute
						return start_time1 - start_time2
					})
					let i = 1
					while (i < open_hours.length)
					{
						const end_time = open_hours[i - 1].end_hour * 60 + open_hours[i - 1].end_minute
						const start_time = open_hours[i].start_hour * 60 + open_hours[i].start_minute
						if (end_time >= start_time)
						{
							const end_time2 = open_hours[i].end_hour * 60 + open_hours[i].end_minute
							if (end_time2 > end_time)
							{
								const new_slot = {
									start_hour: open_hours[i - 1].start_hour,
									start_minute: open_hours[i - 1].start_minute,
									end_hour: open_hours[i].end_hour,
									end_minute: open_hours[i].end_minute
								}
								open_hours.splice(i - 1, 2, new_slot)
								i = 1
								continue
							}
							else
							{
								const new_slot = {
									start_hour: open_hours[i - 1].start_hour,
									start_minute: open_hours[i - 1].start_minute,
									end_hour: open_hours[i - 1].end_hour,
									end_minute: open_hours[i - 1].end_minute
								}
								open_hours.splice(i - 1, 2, new_slot)
								i = 1
								continue
							}
						}
						i++
					}
					const duration = service_benefit.duration_hour * 60 + service_benefit.duration_minute
					const interval = service_benefit.interval_hour * 60 + service_benefit.interval_minute
					for (let i = 0; i < open_hours.length; i++)
					{
						let start_slot = open_hours[i].start_hour * 60 + open_hours[i].start_minute
						let end_slot = start_slot + duration
						const deadline = open_hours[i].end_hour * 60 + open_hours[i].end_minute
						while (end_slot + interval <= deadline)
						{
							ret.slots.push({
								start: start_slot,
								end: end_slot,
								workers: [],
								reservations: 0
							})
							start_slot = end_slot + interval
							end_slot = start_slot + duration
						}
					}
					for (let i = 0; i < ret.slots.length; i++)
					{
						for (let j = 0; j < workers.length; j++)
						{
							let available = false
							for (let k = 0; k < workers[j].working_hours.length; k++)
							{
								const working_hour = workers[j].working_hours[k]
								if ((weekday === 0 && working_hour.sunday === true) ||
									(weekday === 1 && working_hour.monday === true) ||
									(weekday === 2 && working_hour.tuesday === true) ||
									(weekday === 3 && working_hour.wednesday === true) ||
									(weekday === 4 && working_hour.thursday === true) ||
									(weekday === 5 && working_hour.friday === true) ||
									(weekday === 6 && working_hour.saturday === true))
								{
									const start_time = working_hour.start_hour * 60 + working_hour.start_minute
									const end_time = working_hour.end_hour * 60 + working_hour.end_minute
									if (start_time <= ret.slots[i].start && end_time >= ret.slots[i].end)
									{
										available = true
										break
									}
								}
							}
							if (available === true)
								ret.slots[i].workers.push(workers[j])
						}
					}
					i = 0
					while (i < ret.slots.length)
					{
						if (ret.slots[i].workers.length === 0)
						{
							ret.slots.splice(i, 1)
							continue
						}
						i++
					}
					for (let i = 0; i < ret.slots.length; i++)
					{
						const start_time = ret.slots[i].start * 60 + ret.timestamp
						const end_time = ret.slots[i].end * 60 + ret.timestamp
						for (let j = 0; j < ret.slots[i].workers.length; j++)
						{
							for (let k = 0; k < service_reservations.length; k++)
							{
								if (service_reservations[k].service_worker === ret.slots[i].workers[j]._id &&
									((service_reservations[k].start <= start_time && service_reservations[k].end > start_time) ||
									(service_reservations[k].start > start_time && service_reservations[k].start < end_time) ||
									(service_reservations[k].end > start_time && service_reservations[k].end < end_time)))
								{
									if (service_reservations[k].self === true)
										ret.slots[i].reservations++
									ret.slots[i].reservations += (service_reservations[k].participants) ? service_reservations[k].participants.length : 0
								}
							}
						}
					}
				}
			}
		}
		return ret
	}

	_checkSlotsAvailableCart = () => {
		const service = this.state.service
		const service_reservations = this.state.service_reservations
		const lang_assets = this.props.lang_assets.services
		const time_assets = this.props.lang_assets.time
		const days = this._getFiveDays()
		const date = new Date(days[2].time * 1000)
		const year = date.getFullYear()
		const weekday = date.getDay()
		// const today = new Date(date.getFullYear(), date.getMonth(), date.getDate())
		const now = tools.getTimestamp()
		let ret = {
			open: false,
			msg: null,
			days: days,
			timestamp: days[2].time,
			slots: []
		}
		let open = false
		for (let i = 0; i < service.open_dates.length; i++)
		{
			const open_date = service.open_dates[i]
			const start = Math.round(new Date(year, open_date.start_month - 1, open_date.start_day).getTime() / 1000)
			const end = Math.round(new Date(year, open_date.end_month - 1, open_date.end_day).getTime() / 1000)
			if (ret.timestamp >= start && ret.timestamp <= end)
			{
				open = true
				break
			}
		}
		if (open === false)
			ret.msg = lang_assets.not_available1
		else
		{
			let open_days = [false, false, false, false, false, false, false]
			for (let i = 0; i < service.open_hours.length; i++)
			{
				const open_hour = service.open_hours[i]
				if (open_hour.sunday === true)
					open_days[0] = true
				if (open_hour.monday === true)
					open_days[1] = true
				if (open_hour.tuesday === true)
					open_days[2] = true
				if (open_hour.wednesday === true)
					open_days[3] = true
				if (open_hour.thursday === true)
					open_days[4] = true
				if (open_hour.friday === true)
					open_days[5] = true
				if (open_hour.saturday === true)
					open_days[6] = true
			}
			if (open_days[weekday] === false)
			{
				let daystxt = []
				for (let i = 0; i < time_assets.weekdays.length; i++)
					daystxt.push(time_assets.weekdays[i].toLowerCase())
				const weekdaytxt = daystxt[weekday]
				ret.msg = lang_assets.not_available2 + weekdaytxt
			}
			else
			{
				ret.open = true
				let open_hours = []
				for (let i = 0; i < service.open_hours.length; i++)
				{
					const open_hour = service.open_hours[i]
					if ((weekday === 0 && open_hour.sunday === true) ||
						(weekday === 1 && open_hour.monday === true) ||
						(weekday === 2 && open_hour.tuesday === true) ||
						(weekday === 3 && open_hour.wednesday === true) ||
						(weekday === 4 && open_hour.thursday === true) ||
						(weekday === 5 && open_hour.friday === true) ||
						(weekday === 6 && open_hour.saturday === true))
					{
						open_hours.push({
							start_hour: open_hour.start_hour,
							start_minute: open_hour.start_minute,
							end_hour: open_hour.end_hour,
							end_minute: open_hour.end_minute
						})
					}
				}
				open_hours.sort((a, b) => {
					const start_time1 = a.start_hour * 60 + a.start_minute
					const start_time2 = b.start_hour * 60 + b.start_minute
					return start_time1 - start_time2
				})

				// on fusionne les créneaux horaires qui se chevauchent
				let i = 1
				while (i < open_hours.length)
				{
					const end_time = open_hours[i - 1].end_hour * 60 + open_hours[i - 1].end_minute
					const start_time = open_hours[i].start_hour * 60 + open_hours[i].start_minute
					if (end_time >= start_time)
					{
						const end_time2 = open_hours[i].end_hour * 60 + open_hours[i].end_minute
						if (end_time2 > end_time)
						{
							const new_slot = {
								start_hour: open_hours[i - 1].start_hour,
								start_minute: open_hours[i - 1].start_minute,
								end_hour: open_hours[i].end_hour,
								end_minute: open_hours[i].end_minute
							}
							open_hours.splice(i - 1, 2, new_slot)
							i = 1
							continue
						}
						else
						{
							const new_slot = {
								start_hour: open_hours[i - 1].start_hour,
								start_minute: open_hours[i - 1].start_minute,
								end_hour: open_hours[i - 1].end_hour,
								end_minute: open_hours[i - 1].end_minute
							}
							open_hours.splice(i - 1, 2, new_slot)
							i = 1
							continue
						}
					}
					i++
				}

				// création des slots
				const duration = service.duration_hour * 60 + service.duration_minute
				const interval = service.interval_hour * 60 + service.interval_minute
				for (let i = 0; i < open_hours.length; i++)
				{
					let start_slot = Number(open_hours[i].start_hour * 60) + Number(open_hours[i].start_minute)
					let end_slot = start_slot + duration
					const deadline = Number(open_hours[i].end_hour * 60) + Number(open_hours[i].end_minute)
					while (end_slot + interval <= deadline)
					{
						const start_time = this.state.timestamp + (start_slot * 60)
						let not_available = false
						if (now + (Number(service.limit_end) * 3600) > start_time)
							not_available = true
						ret.slots.push({
							start: start_slot,
							end: end_slot,
							reservations: 0,
							not_available: not_available
						})
						start_slot = Number(end_slot + interval)
						end_slot = Number(start_slot + duration)
					}
				}
				for (let i = 0; i < ret.slots.length; i++)
				{
					const start_time = ret.slots[i].start * 60 + ret.timestamp
					const end_time = ret.slots[i].end * 60 + ret.timestamp
					if (ret.slots[i])
					for (let k = 0; k < service_reservations.length; k++)
					{
						if ((service_reservations[k].start <= start_time && service_reservations[k].end > start_time) ||
							(service_reservations[k].start > start_time && service_reservations[k].start < end_time) ||
							(service_reservations[k].end > start_time && service_reservations[k].end < end_time))
						{
							ret.slots[i].reservations++
						}
					}
				}
			}
		}
		return ret
	}

	bookService = (id) => {
		let service_benefit = null
		const service_benefits = this.state.service_benefits
		for (let i = 0; i < service_benefits.length; i++)
		{
			if (service_benefits[i]._id === id)
			{
				service_benefit = service_benefits[i]
				break
			}
		}
		this.setState({ service_benefit: service_benefit, step: 1 })
	}

	printServiceBenefit = (_id, icon, duration_hour, duration_minute, show_price, price, translations, key) => {
		const lang_assets = this.props.lang_assets.services
		let jsx = null
		let prev = null
		const icons = this.props.icons
		const theme = this.props.theme
		for (let i = 0; i < icons.length; i++)
		{
			if (icons[i]._id === icon)
			{
				for (let j = 0; j < icons[i].fill.length; j++)
				{
					if (icons[i].fill[j].theme === theme._id)
					{
						prev = icons[i].fill[j].icon
						break
					}
				}
				break
			}
		}
		let duration = null
		if (duration_hour > 0)
			duration = duration_hour + 'h'
		if (duration_minute > 0)
		{
			if (duration === null)
				duration = duration_minute + 'm'
			else
				duration += duration_minute
		}
		let subtitle = ""
		let description = ""
		let location = ""
		for (let i = 0; i < translations.length; i++)
		{
			if (translations[i].lang === this.props.user.lang)
			{
				subtitle = translations[i].subtitle
				description = translations[i].description
				location = translations[i].location
				break
			}
		}
		const alt = "picto " + subtitle
		const pictoJSX = (prev !== null) ? <img src={prev} alt={alt} className="picto" /> : null
		const tarif = (price > 0) ? price + '€' : lang_assets.free
		const style = { color: '#' + this.props.theme.color1 }
		let priceJSX = null
		if (show_price === true)
			priceJSX = (<p className="price">{lang_assets.price}: <span className="text">{tarif}</span></p>)
		jsx = (
			<div className="service" key={key}>
				<p className="title">
					{pictoJSX}
					{subtitle}
				</p>
				<p className="duration">{lang_assets.duration}: {duration}</p>
				<p className="location">{lang_assets.location}: {location}</p>
				<div dangerouslySetInnerHTML={{ __html: description }}></div>
				{priceJSX}
				<p className="book" onClick={this.bookService.bind(this, _id)} style={style}>{lang_assets.button1}</p>
			</div>
		)
		return (jsx)
	}

	handleQuantity = (id, e) => {
		const service_products = this.state.service_products
		for (let i = 0; i < service_products.length; i++)
		{
			if (service_products[i]._id === id)
			{
				service_products[i].quantity = e.target.value
				break
			}
		}
		this.setState({ service_products: service_products })
	}

	_addProduct = (id) => {
		let service_product = null
		const service_products = this.state.service_products
		for (let i = 0; i < service_products.length; i++)
		{
			if (service_products[i]._id === id)
			{

				service_product = service_products[i]
				break
			}
		}
		this.setState({ service_benefit: service_product, step: 1 })
	
	}

	printServiceProduct = (_id, icon, stock, price, quantity, translations, service, key) => {
		const lang_assets = this.props.lang_assets.services
		let jsx = null
		let prev = null
		const icons = this.props.icons
		const theme = this.props.theme
		for (let i = 0; i < icons.length; i++)
		{
			if (icons[i]._id === icon)
			{
				for (let j = 0; j < icons[i].fill.length; j++)
				{
					if (icons[i].fill[j].theme === theme._id)
					{
						prev = icons[i].fill[j].icon
						break
					}
				}
				break
			}
		}
		let location = ""
		for (let i = 0; i < service.translations.length; i++)
		{
			if (service.translations[i].lang === this.props.user.lang)
			{
				location = service.translations[i].location
				break
			}
		}
		let subtitle = ""
		let description = ""
		for (let i = 0; i < translations.length; i++)
		{
			if (translations[i].lang === this.props.user.lang)
			{
				subtitle = translations[i].subtitle
				description = translations[i].description
				break
			}
		}
		const alt = "picto " + subtitle
		const pictoJSX = (prev !== null) ? <img src={prev} alt={alt} className="picto" /> : null
		let price_txt = String(price)
		if (price > 0)
		{
			let price_tab = String(price).split('.')
			if (price_tab.length > 1)
			{
				if (price_tab[1].length === 1)
					price_tab[1] += '0'
				else if (price_tab[1].length > 2)
					price_tab[1] = price_tab[1].substring(0, 2)
				price_txt = price_tab[0] + ',' + price_tab[1]
			}
		}
		const tarif = (price > 0) ? price_txt + '€' : lang_assets.free
		jsx = (
			<div className="service" key={key}>
				<p className="title">
					{pictoJSX}
					{subtitle}
				</p>
				<p className="location">{lang_assets.location}: {location}</p>
				<div dangerouslySetInnerHTML={{ __html: description }}></div>
				<p className="price">{lang_assets.price}: <span className="text">{tarif}</span></p>
				<label className="quantity">
					{lang_assets.quantity} : <input type="number" value={quantity} onChange={this.handleQuantity.bind(this, _id)} min="0" max={stock} />
				</label>
			</div>
		)
		return (jsx)
	}

	cartCheckout = (e) => {
		e.preventDefault()
		const service_products = this.state.service_products
		let cart = []
		for (let i = 0; i < service_products.length; i++)
		{
			if (service_products[i].quantity > 0)
				cart.push(service_products[i])
		}
		if (cart.length > 0)
			this.setState({ step: 1, cart: cart, error: null })
		else
			this.setState({ error: this.props.lang_assets.services.error_cart })
	}

	render() {
		const service = this.state.service
		const service_benefits = this.state.service_benefits
		const service_benefit = this.state.service_benefit
		const service_products = this.state.service_products
		const cart = this.state.cart
		const lang_assets = this.props.lang_assets.services
		if (service && service.type === "prestation" && service_benefits && this.props.icons)
		{
			if (this.state.step === 0)
			{
				// afficher les différentes prestations
				let image = this.state.service.cover
				if (image === null)
					image = picture
				let pictureJSX = null
				if (image)
				{
					pictureJSX = (
						<div className="photoWrap">
							<img src={image} alt="banner" />
						</div>
					)
				}
				let service_benefit_k = -1
				return (
					<div>
						{pictureJSX}
						<div className="contentWrapper">
							{
								service_benefits.map(({ _id, icon, duration_hour, duration_minute, show_price, price, translations }) => {
									service_benefit_k++
									let ret = this.printServiceBenefit(_id, icon, duration_hour, duration_minute, show_price, price, translations, service_benefit_k)
									return (ret)
								})
							}
						</div>
					</div>
				)
			}
			else if (this.state.step === 1 && service_benefit)
			{
				// afficher les slots disponibles
				let slotsJSX = null
				const checkSlotsAvailable = this._checkSlotsAvailable()
				if (checkSlotsAvailable.open === false)
				{
					slotsJSX = (
						<div className="slots">
							<p className="unavailable">{checkSlotsAvailable.msg}</p>
						</div>
					)
				}
				else
				{
					let slot_k = -1
					const simultaneous = service_benefit.simultaneous
					slotsJSX = (
						<div className="slots">
							{checkSlotsAvailable.slots.map(({ start, end, workers, reservations }) => {
								slot_k++
								let start_hour = Math.floor(start / 60)
								if (start_hour < 10)
									start_hour = '0' + start_hour
								let start_minute = start - (start_hour * 60)
								if (start_minute < 10)
									start_minute = '0' + start_minute
								let end_hour = Math.floor(end / 60)
								if (end_hour < 10)
									end_hour = '0' + end_hour
								let end_minute = end - (end_hour * 60)
								if (end_minute < 10)
									end_minute = '0' + end_minute
								let style = null
								let full = null
								let action = this.selectSlot.bind(this, checkSlotsAvailable.timestamp, start, end, workers)
								const max = workers.length * simultaneous
								if (simultaneous !== null && reservations >= max)
								{
									action = null
									style = { color: '#B9B9B9', cursor: 'default' }
									full = (<p className="full">{lang_assets.full}</p>)
								}
								let few = null
								const available = max - reservations
								if (available > 0 && ((max >= 6 && available < 3) || (max >= 4 && available < 2)))
								{
									if (available === 1)
										few = (<p className="few">1 {lang_assets.one_slot}</p>)
									else
										few = (<p className="few">{available} {lang_assets.two_slots}</p>)
								}
								return (
									<div className="elem" style={style} key={slot_k} onClick={action}>
										<p className="title">{start_hour}:{start_minute} - {end_hour}:{end_minute}</p>
										{full}
										{few}
										<div style={{ clear: 'both' }}></div>
									</div>
								)
							})}
						</div>
					)
				}
				let day_k = -1
				return (
					<div className="Dates">
						<div className="header" style={{ backgroundColor: '#' + this.props.theme.color1 }}>
							<h1>{lang_assets.title}</h1>
							<div className="days">
								{checkSlotsAvailable.days.map(({ dayNb, monthTxt, time }) => {
									day_k++
									let classname = "day"
									if (day_k === 0)
										classname += ' first'
									else if (day_k === 2)
										classname += ' select'
									if (day_k !== 2)
									{
										return (
											<div className={classname} key={day_k} onClick={this.updateTime.bind(this, time)}>
												<p>
													<strong>{dayNb}</strong><br />
													{monthTxt}
												</p>
											</div>
										)
									}
									else
									{
										return (
											<div className={classname} key={day_k}>
												<p>
													<strong>{dayNb}</strong><br />
													{monthTxt}
												</p>
											</div>
										)
									}
								})}
							</div>
							<div style={{ clear: 'both' }}></div>
						</div>
						{slotsJSX}
					</div>
				)
			}
			else if (this.state.step === 2)
			{
				return (
					<Booking
						pathname={this.props.history.location.pathname}
						user={this.props.user}
						updateShell={this.props.updateShell}
						updateLogs={this.props.updateLogs}
						reservation={this.state.reservation}
						service_benefit={this.state.service_benefit}
						theme={this.props.theme}
						lang_assets={this.props.lang_assets}
						postServiceReservation={this.props.postServiceReservation}
						postUpdateBirthday={this.props.postUpdateBirthday}
						postUpdatePhone={this.props.postUpdatePhone}
					/>
				)
			}
		}
		else if (service && service.type === "vente" && service_products && this.props.icons)
		{
			if (this.state.step === 0)
			{
				// afficher les produits disponibles
				let image = this.state.service.cover
				if (image === null)
					image = picture
				let pictureJSX = null
				if (image)
				{
					pictureJSX = (
						<div className="photoWrap">
							<img src={image} alt="banner" />
						</div>
					)
				}
				let service_product_k = -1
				const styles = { backgroundColor: '#' + this.props.theme.color1, borderColor: '#' + this.props.theme.color1, width: '90%' }
				return (
					<div>
						{pictureJSX}
						<div className="contentWrapper">
							<form className="cartForm" onSubmit={this.cartCheckout}>
								{
									service_products.map(({ _id, icon, stock, price, quantity, translations }) => {
										service_product_k++
										let ret = this.printServiceProduct(_id, icon, stock, price, quantity, translations, service, service_product_k)
										return (ret)
									})
								}
								<p className="error">{this.state.error}</p>
								<button type="submit" style={styles}>Continuer mes achats</button>
							</form>
						</div>
					</div>
				)
			}
			else if (this.state.step === 1 && cart)
			{
				// afficher les slots disponibles
				let slotsJSX = null
				const checkSlotsAvailable = this._checkSlotsAvailableCart()
				if (checkSlotsAvailable.open === false)
				{
					slotsJSX = (
						<div className="slots">
							<p className="unavailable">{checkSlotsAvailable.msg}</p>
						</div>
					)
				}
				else
				{
					let slot_k = -1
					slotsJSX = (
						<div className="slots">
							{checkSlotsAvailable.slots.map(({ start, end, not_available }) => {
								slot_k++
								let start_hour = Math.floor(start / 60)
								if (start_hour < 10)
									start_hour = '0' + start_hour
								let start_minute = start - (start_hour * 60)
								if (start_minute < 10)
									start_minute = '0' + start_minute
								let end_hour = Math.floor(end / 60)
								if (end_hour < 10)
									end_hour = '0' + end_hour
								let end_minute = end - (end_hour * 60)
								if (end_minute < 10)
									end_minute = '0' + end_minute
								let full = null
								let style = null
								let action = this.selectSlotCart.bind(this, checkSlotsAvailable.timestamp, start, end)
								if (not_available === true)
								{
									action = null
									style = { color: '#B9B9B9', cursor: 'default' }
									full = (<p className="full">{lang_assets.full}</p>)
								}
								return (
									<div className="elem" style={style} key={slot_k} onClick={action}>
										<p className="title">{start_hour}:{start_minute} - {end_hour}:{end_minute}</p>
										{full}
										<div style={{ clear: 'both' }}></div>
									</div>
								)
							})}
						</div>
					)
				}
				let day_k = -1
				return (
					<div className="Dates">
						<div className="header" style={{ backgroundColor: '#' + this.props.theme.color1 }}>
							<h1>{lang_assets.title}</h1>
							<div className="days">
								{checkSlotsAvailable.days.map(({ dayNb, monthTxt, time }) => {
									day_k++
									let classname = "day"
									if (day_k === 0)
										classname += ' first'
									else if (day_k === 2)
										classname += ' select'
									if (day_k !== 2)
									{
										return (
											<div className={classname} key={day_k} onClick={this.updateTime.bind(this, time)}>
												<p>
													<strong>{dayNb}</strong><br />
													{monthTxt}
												</p>
											</div>
										)
									}
									else
									{
										return (
											<div className={classname} key={day_k}>
												<p>
													<strong>{dayNb}</strong><br />
													{monthTxt}
												</p>
											</div>
										)
									}
								})}
							</div>
							<div style={{ clear: 'both' }}></div>
						</div>
						{slotsJSX}
					</div>
				)
			}
			else if (this.state.step === 2)
			{
				return (
					<Buying
						pathname={this.props.history.location.pathname}
						user={this.props.user}
						updateShell={this.props.updateShell}
						updateLogs={this.props.updateLogs}
						reservation={this.state.reservation}
						service={this.state.service}
						cart={this.state.cart}
						theme={this.props.theme}
						lang_assets={this.props.lang_assets}
						postServiceReservationCart={this.props.postServiceReservationCart}
						postUpdateBirthday={this.props.postUpdateBirthday}
						postUpdatePhone={this.props.postUpdatePhone}
					/>
				)
			}
		}
		else
		{
			return (
				<div className="loading">
					<Loader />
				</div>
			)
		}
	}
}

export default Services