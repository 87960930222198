import React, { Component } from 'react'
import Loader from '../UI/Loader'
import { XCircleIcon, LocationMarkerIcon, ExclamationIcon, UserCircleIcon, ChevronRightIcon, PlusSmIcon } from '@heroicons/react/solid'
import './Services.css'
import ReactBodymovin from 'react-bodymovin'
import animation from '../../img/check-circle.json'

class Booking extends Component {
	constructor(props) {
		super(props)
		this.state = {
			reservation: this.props.reservation,
			answers: [],
			step: 0,
			curr: null,
			question_nb: 0,
			user: {
				client_number: null,
				identity: null,
				age: null,
				phone: null
			},
			form1: {
				self: true,
				participants: []
			},
			form2: {
				day: '',
				month: '',
				year: '',
			},
			form3: {
				phone: ''
			},
			participants: [],
			attempt_submit: false,
			error: this._initErrors()
		}
		this.length_max = 40
		if (this.props.user.from === "dashboard")
		{
			this.state.user.client_number = 0
			this.state.user.identity = "Test Test"
			this.state.user.age = 42
			this.state.user.phone = "123456"
		}
		else
		{
			this.state.user.client_number = this.props.user.client_number
			let identity = ""
			if (this.props.user.civility && this.props.user.civility.length > 0)
			{
				if (this.props.user.civility === "femme")
					identity = "Mme. "
				else if (this.props.user.civility === "homme")
					identity = "Mr. "
			}
			identity += this.props.user.firstname + " " + this.props.user.lastname
			this.state.user.identity = identity
			let age = null
			const date = new Date()
			if (this.props.user.birthday && this.props.user.birthday.length > 0)
			{
				const day = Number(this.props.user.birthday.substr(0, 2))
				const month = Number(this.props.user.birthday.substr(3, 2)) - 1
				const year = Number(this.props.user.birthday.substr(6, 4))
				age = date.getFullYear() - year
				if (month > date.getMonth() || (month === date.getMonth() && day > date.getDate()))
					age--
			}
			this.state.user.age = age
			this.state.user.phone = this.props.user.phone
		}
	}

	componentDidMount() {
		const infos = {
			service: this.props.service_benefit.service,
			service_benefit: this.props.service_benefit._id
		}
		this.props.updateLogs(this.props.pathname, 'Services', infos)
		let answers = []
		const service_benefit = this.props.service_benefit
		for (let i = 0; i < service_benefit.questions.length; i++)
			answers.push('')
		this.setState({ answers: answers })
	}

	setErrorMsg = (msg) => {
		this.setState({ error: msg })
	}

	clearFlash = () => {
		this.setState({ error: null })
	}

	_initErrors = () => {
		const errors = {
			global: null,
			firstname: [],
			lastname: []
		}
		if (this.state)
		{
			for (let i = 0; i < this.state.form1.participants.length; i++)
			{
				errors.firstname.push(null)
				errors.lastname.push(null)
			}
		}
		return errors
	}

	handleSelf = () => {
		const form1 = {
			...this.state.form1,
			self: (this.state.form1.self === true) ? false : true
		}
		this.setState({ form1: form1 })
	}

	addParticipant = (event) => {
		// console.log("WHY DID WE CLICK ON ME")
		event.preventDefault()
		const participants = this.state.form1.participants
		participants.push({
			firstname: '',
			lastname: ''
		})
		const form1 = {
			...this.state.form1,
			participants: participants
		}
		const step = 5
		this.setState({ form1: form1, step: step })
	}

	showParticipant = (participant_k, event) => {
		this.setState({ step: 6, curr: participant_k })
	}

	deleteParticipant = (participant_k, event) => {
		event.preventDefault()
		let participants = this.state.form1.participants
		participants.splice(participant_k, 1)
		const form1 = {
			...this.state.form1,
			participants: participants
		}
		this.setState({ form1: form1, step: 0 })
	}

	handleLastname = (participant_k, event) => {
		event.preventDefault()
		const participants = this.state.form1.participants
		participants[participant_k].lastname = event.target.value
		const form1 = {
			...this.state.form1,
			participants: participants
		}
		this.setState({ form1: form1 })
	}

	handleFirstname = (participant_k, event) => {
		event.preventDefault()
		// console.log("Participant_k: ", participant_k)
		const participants = this.state.form1.participants
		participants[participant_k].firstname = event.target.value
		const form1 = {
			...this.state.form1,
			participants: participants
		}
		this.setState({ form1: form1 })
	}

	goToBookingMenu = () => {
		this.setState({ step: 0 })
	}

	_validateForm1 = () => {
		const self = this.state.form1.self
		const participants = []
		const error_obj = this._initErrors()
		let error = false
		for (let i = 0; i < this.state.form1.participants.length; i++)
		{
			const firstname = this.state.form1.participants[i].firstname
			const lastname = this.state.form1.participants[i].lastname
			if (firstname.length > this.length_max)
			{
				error = true
				error_obj.firstname[i] = "Le prénom ne doit pas dépasser " + this.length_max + " caractères"
			}
			if (firstname.length === 0 && lastname.length > 0)
			{
				error = true
				error_obj.firstname[i] = "Veuillez indiquer le prénom"
			}
			if (lastname.length > this.length_max)
			{
				error = true
				error_obj.lastname[i] = "Le nom ne doit pas dépasser " + this.length_max + " caractères"
			}
			if (lastname.length === 0 && firstname.length > 0)
			{
				error = true
				error_obj.lastname[i] = "Veuillez indiquer le nom"
			}
			if (firstname.length > 0 && firstname.length <= this.length_max &&
				lastname.length > 0 && lastname.length <= this.length_max)
			{
				participants.push({
					firstname: firstname,
					lastname: lastname
				})
			}
		}
		if (participants.length === 0 && self === false)
		{
			error = true
			error_obj.global = "Vous devez sélectionner au moins 1 participant"
		}
		if (error === true)
			this.setState({ error: error_obj })
		return (!error) ? participants : false
	}

	submitParticipants = (event) => {
		event.preventDefault()
		const participants = this._validateForm1()
		if (participants !== false)
		{
			let step = 0
			if (this.props.service_benefit.age === true && !this.state.user.age)
				step = 1
			else if (this.props.service_benefit.phone === true && !this.state.user.phone)
				step = 2
			else if (this.props.service_benefit.questions.length > 0)
				step = 3
			else
				step = 4
			this.setState({ step: step, error: this._initErrors(), participants: participants })
		}
	}

	handleDay = (event) => {
		let input = event.target.value
		const day = Number(event.target.value)
		if ((day >= 10 && day <= 31) || ((day >= 1 && day <= 3) && (input.length === 2)) || (day >= 4 && day < 10))
			document.getElementById('month').focus()
		if (day >= 4 && day < 10)
			input = '0' + input
		const form2 = {
			...this.state.form2,
			day: input
		}
		this.setState({ form2: form2 })
	}

	handleMonth = (event) => {
		let input = event.target.value
		const month = Number(event.target.value)
		if ((month >= 2 && month <= 12) || input === '01')
			document.getElementById('year').focus()
		if (month >= 2 && month <= 9)
			input = '0' + input
		const form2 = {
			...this.state.form2,
			month: input
		}
		this.setState({ form2: form2 })
	}

	handleYear = (event) => {
		const form2 = {
			...this.state.form2,
			year: event.target.value
		}
		this.setState({ form2: form2 })
	}

	_validateForm2 = () => {
		let error_obj = this._initErrors()
		let error = false
		let today = new Date()
		let currentyear = today.getFullYear()
		const day = this.state.form2.day
		const day_parsed = parseInt(day)
		const month = this.state.form2.month
		const month_parsed = parseInt(month)
		const year = this.state.form2.year
		const year_parsed = parseInt(year)
		if (!day && !month && !year)
		{
			error_obj.global = "Veuillez renseigner votre date de naissance !"
			error = true
		}
		else if (!day || (day_parsed <= 0 || day_parsed >= 32))
		{
			error_obj.global = "Oups, cette date n'est pas valide"
			error = true
		}
		else if (!month || (month_parsed <= 0 || month_parsed >= 13))
		{
			error_obj.global = "Oups, ce mois n'est pas valide"
			error = true
		}
		else if (!year || (year_parsed <= 1900 || year_parsed >= currentyear - 7))
		{
			error_obj.global = "Votre année de naissance est suspecte !"
			error = true
		}
		if (error === true)
			this.setState({ error: error_obj })
		return (!error) ? true : false
	}

	submitBirthdate = (event) => {
		event.preventDefault()
		if (this._validateForm2())
		{
			let day = Number(this.state.form2.day)
			if (day < 10)
				day = '0' + day
			let month = Number(this.state.form2.month)
			if (month < 10)
				month = '0' + month
			const birthday = day + "/" + month + "/" + this.state.form2.year
			const user = {
				...this.state.user,
				birthday: birthday
			}
			let step = 4
			if (!this.state.user.phone)
				step = 2
			else if (this.props.service_benefit.questions.length > 0)
				step = 3
			let update_birthday = {
				_id: this.props.user._id,
				birthday: birthday
			}
			this.props.postUpdateBirthday(update_birthday)
			.then(ret => {
				if (ret !== false)
					this.setState({ error: null, user: user, step: step })
				else
					this.setErrorMsg("Une erreur s'est produite")
			})
		}
	}

	handlePhone = (event) => {
		const form3 = {
			...this.state.form3,
			phone: event.target.value
		}
		this.setState({ form3: form3 })
	}

	submitPhone = (event) => {
		event.preventDefault()
		const phone = this.state.form3.phone
		if (phone.length === 0)
			this.setErrorMsg("Champ obligatoire")
		else
		{
			const user = {
				...this.state.user,
				phone: phone
			}
			let step = 4
			if (this.props.service_benefit.questions.length > 0)
				step = 3
			let update_phone = {
				_id: this.props.user._id,
				phone: phone
			}
			this.props.postUpdatePhone(update_phone)
			.then(ret => {
				if (ret !== false)
					this.setState({ error: null, user: user, step: step })
				else
					this.setErrorMsg("Une erreur s'est produite")
			})
		}
	}

	handleAnswer = (event) => {
		const question_nb = this.state.question_nb
		let answers = this.state.answers
		answers[question_nb] = event.target.value
		this.setState({ answers: answers })
	}

	submitAnswers = (event) => {
		event.preventDefault()
		const question_nb = this.state.question_nb
		const question = this.props.service_benefit.questions[question_nb]
		const answer = this.state.answers[question_nb]
		if (question.mandatory === true && answer.length === 0)
			this.setErrorMsg(this.props.lang_assets.services.error_question)
		else
		{
			if (question_nb + 1 === this.state.answers.length)
			{
				let reservation = this.state.reservation
				reservation.answers = this.state.answers
				this.setState({ step: 4, reservation: reservation, question_nb: 0 })
			}
			else
			{
				this.clearFlash()
				this.setState({ question_nb: question_nb + 1 })
			}
		}
	}

	confirmBooking = () => {
		const reservation = {
			...this.state.reservation,
			self: this.state.form1.self,
			participants: this.state.participants,
			client_number: this.state.user.client_number,
			identity: this.state.user.identity,
			age: this.state.user.age,
			phone: this.state.user.phone
		}
		this.setState({ attempt_submit: true })
		this.props.postServiceReservation(reservation)
		.then(res => {
			this.setState({ step: 7, attempt_submit: false })
		})
		.catch(err => {
			this.setState({ attempt_submit: false })
			console.log(err)
		})
	}

	// STEP 0 : Accueil réservation
	// STEP 1 : Question date de naissance
	// STEP 2 : Question tel
	// STEP 3 : Question texte / nb
	// STEP 4 : Recap
	// STEP 5 : Création du participant
	// STEP 6 : Édition du participant
	// STEP 7 : Confirmation

	render() {
		const service_benefit = this.props.service_benefit
		const lang_assets = this.props.lang_assets.services
		if (service_benefit)
		{
			const styles = { backgroundColor: '#' + this.props.theme.color1, borderColor: '#' + this.props.theme.color1 }
			let selfStyle = "relative mt-5 rounded-lg bg-purple-100 shadow-lg px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-purple-500"
			let selfIcon = "text-purple-300"
			if (this.state.form1.self === false)
			{
				selfStyle = "relative mt-5 rounded-lg bg-gray-100 px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-gray-500"
				selfIcon = "text-gray-300"
			}
			
			if (this.state.step === 0) //PAGE ACCUEIL RESERVATION
			{
				return (
					<div className="min-h-4/5-screen">
						<div className="flex justify-center	items-center z-0 w-full fixed top-0 h-36" style={{ backgroundColor: '#' + this.props.theme.color1 }}>
							<div className="text-white text-3xl">
								<h1>Ajoutez des participants<span role="img" aria-label="smiling face with hearts"> 🥰</span></h1>
							</div>
						</div>
						<div className="bg-white w-full pt-10 px-5 mt-32 min-h-9/10-screen absolute rounded-t-3xl pb-10">
							<div className="px-2.5 pt-5 pb-10">
								<h2 className="text-3xl font-semibold text-gray-700 mb-10">Participants</h2>
								<form onSubmit={this.submitParticipants}>
									<div
										className={selfStyle}
										onClick={this.handleSelf}
									>
										<span className="h-12 w-12 rounded-full overflow-hidden bg-gray-100">
											<UserCircleIcon className={selfIcon}/>
										</span>
										<div className="flex-1 min-w-0">
											<div className="focus:outline-none">
												<span className="absolute inset-0" aria-hidden="true" />
												<p className="text-base font-medium text-gray-900">Vous</p>
												<p className="text-sm text-gray-500 truncate">{this.state.user.identity}</p>
											</div>
										</div>
										<input
											type="checkbox"
											className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
											checked={this.state.form1.self}
										/>
									</div>
									{this.state.form1.participants.map(({ firstname, lastname }, id) => {
										return (
											<div
												key={id}
												className="relative mt-5 rounded-lg bg-purple-100 shadow-lg px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-purple-500"
												onClick={this.showParticipant.bind(this, id)}
											>
												<span className="h-12 w-12 rounded-full overflow-hidden bg-gray-100">
													<UserCircleIcon className="text-purple-300"/>
												</span>
												<div className="flex-1 min-w-0">
													<div className="focus:outline-none">
														<span className="absolute inset-0" aria-hidden="true" />
														<p className="text-base font-medium text-gray-900">{firstname} {lastname}</p>
													</div>
												</div>
												<ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
											</div>
										)
									})}
									<div
										className="relative mt-5 rounded-lg bg-gray-100 shadow-lg px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-gray-500"
									>
										<span className="h-12 w-12 rounded-full overflow-hidden bg-gray-100">
											<PlusSmIcon className="text-gray-300"/>
										</span>
										<button onClick={this.addParticipant} className="flex-1 min-w-0 text-left">
											<div className="focus:outline-none">
												<span className="absolute inset-0" aria-hidden="true" />
												<p className="text-base font-medium text-gray-900">Ajouter</p>
												<p className="text-sm text-gray-500 truncate">Famille, amis...</p>
											</div>
										</button>
										<ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
									</div>
									{/* <label>
										<input type="checkbox" checked={this.state.form1.self} onChange={this.handleSelf} /><span></span> <span className="text-lg text-gray-500">Pour moi-même</span>
									</label>
									<div>
										{this.state.form1.participants.map(({ firstname, lastname }) => {
											participant_k++
											return (
												<div key={participant_k}>
													<h3 className="text-lg text-gray-500">Nom ({participant_k + 1})</h3>
													<label>
														<input type="text" className="text-black" value={lastname} onChange={this.handleLastname.bind(this, participant_k)} />
													</label>
													<p className="text-sm text-red-400 mt-0 mb-3">{this.state.error.lastname[participant_k]}</p>
													<h3 className="text-lg text-gray-500">Prénom ({participant_k + 1})</h3>
													<label>
														<input type="text" className="text-black" value={firstname} onChange={this.handleFirstname.bind(this, participant_k)}/>
													</label>
													<p className="text-sm text-red-400 mt-0 mb-3">{this.state.error.firstname[participant_k]}</p>
												</div>
											)
										})}
										<div className="mt-4">
											<div className="w-full">
												<button
													style={{ backgroundColor: '#' + this.props.theme.color1 }}
													className="btn-floating btn-large waves-effect waves-light"
													onClick={this.addParticipant}
												>
													<i className="material-icons">add</i>
												</button>
											</div>
										</div>
									</div>
									<p className="text-sm text-red-400 mt-0 mb-3">{this.state.error.global}</p> */}
									<button type="submit" className="font-semibold py-5 w-full rounded-full text-white shadow-xl text-xl my-10 waves-effect waves-light" style={{ backgroundColor: '#' + this.props.theme.color1 }}>Continuer</button>
								</form>
							</div>
						</div>
					</div>
				)
			}
			else if (this.state.step === 5) //AJOUTER UN PARTICIPANT
			{
				let current = (!this.state.form1.participants.length ? this.state.form1.participants.length : this.state.form1.participants.length - 1)
				const participants = this.state.form1.participants
				return (
					<div className="min-h-4/5-screen">
						<div className="flex justify-center	items-center z-0 w-full fixed top-0 h-36" style={{ backgroundColor: '#' + this.props.theme.color1 }}>
							<div className="text-white text-3xl">
								<h1>Profil du participant</h1>
							</div>
						</div>
						<div className="bg-white w-full pt-10 px-5 mt-32 min-h-9/10-screen absolute rounded-t-3xl pb-10">
							<div className="px-2.5 pt-5 pb-10">
								<form onSubmit={this.goToBookingMenu} className="mt-9 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
										<div>
											<label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
												Prénom
											</label>
											<div className="mt-1">
												<input
													type="text"
													className="block w-full shadow-sm sm:text-sm py-3 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
													onChange={this.handleFirstname.bind(this, current)}
													value={participants[current].firstname}
												/>
											</div>
										</div>
										<div>
											<label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
												Nom
											</label>
											<div className="mt-1">
												<input
													type="text"
													className="block w-full shadow-sm sm:text-sm py-3 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
													onChange={this.handleLastname.bind(this, current)}
													value={participants[current].lastname}
												/>
											</div>
										</div>
										<div className="mt-5">
											<button type="submit" className="font-semibold py-5 w-full rounded-full text-white shadow-xl text-base waves-effect waves-light mt-5" style={{ backgroundColor: '#' + this.props.theme.color1 }}>Appliquer ce profil</button>
											<button onClick={this.deleteParticipant.bind(this, current)} className="font-semibold py-5 w-full rounded-full text-white shadow-xl text-base waves-effect waves-light border mt-5" style={{ borderColor: '#' + this.props.theme.color1, color:  '#' + this.props.theme.color1}}>Supprimer ce participant</button>
										</div>
								</form>
							</div>
						</div>
					</div>
				)
			}
			else if (this.state.step === 6) //show, edit and delete participant
			{
				const participants = this.state.form1.participants
				let current = null
				if (this.state.curr !== -1)
					current = this.state.curr
				return (
					<div>
						<div style={{backgroundColor: "#" + this.props.theme.color1}} className="h-52 z-0 px-8 text-center">
							<h1 className="text-3xl text-white font-semibold m-0 pt-16">Profil du participant</h1>
						</div>
						<div className="rounded-t-3xl z-10" style={{backgroundColor: "#ffffff", marginTop: "-2rem"}}>
							<div className="px-10 pt-16 pb-10">
								<form onSubmit={this.goToBookingMenu} className="mt-9 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
										<div>
											<label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
												Prénom
											</label>
											<div className="mt-1">
												<input
													type="text"
													className="block w-full shadow-sm sm:text-sm py-3 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
													onChange={this.handleFirstname.bind(this, current)}
													value={participants[current].firstname}
												/>
											</div>
										</div>
										<div>
											<label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
												Nom
											</label>
											<div className="mt-1">
												<input
													type="text"
													className="block w-full shadow-sm sm:text-sm py-3 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
													onChange={this.handleLastname.bind(this, current)}
													value={participants[current].lastname}
												/>
											</div>
										</div>
										<div className="mt-5">
											<button type="submit" className="font-semibold py-5 w-full rounded-full text-white shadow-xl text-base waves-effect waves-light mt-5" style={{ backgroundColor: '#' + this.props.theme.color1 }}>Appliquer ce profil</button>
											<button onClick={this.deleteParticipant.bind(this, current)} className="font-semibold py-5 w-full rounded-full text-white shadow-xl text-base waves-effect waves-light border mt-5" style={{ borderColor: '#' + this.props.theme.color1, color:  '#' + this.props.theme.color1}}>Supprimer ce participant</button>
										</div>
								</form>
							</div>
						</div>
					</div>
				)
			}
			else if (this.state.step === 1) // QUESTION DATE DE NAISSANCE
			{
				let error = (this.state.error) ? this.state.error.global : null
				let errorHtml = ""
				if (error)
				{
					errorHtml = (
						<div className="rounded-md bg-red-50 p-4 mt-5">
							<div className="flex">
								<div className="flex-shrink-0">
									<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
								</div>
								<div className="ml-3">
									<h3 className="text-sm font-medium text-red-800">{error}</h3>
								</div>
							</div>
						</div>
					)
				}
				let mendatoryOptHtml = ""
				if (lang_assets.mandatory)
				{
					mendatoryOptHtml = (
						<div>
							<div className="rounded-md bg-yellow-50 p-4 mt-5">
								<div className="flex">
									<div className="flex-shrink-0">
										<ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
									</div>
									<div className="ml-3">
										<h3 className="text-sm font-medium text-yellow-800">{lang_assets.mandatory}</h3>
									</div>
								</div>
							</div>
						</div>
					)
				}
				return (
					<div className="min-h-4/5-screen">
						<div className="flex justify-center	items-center z-0 w-full fixed top-0 h-36" style={{ backgroundColor: '#' + this.props.theme.color1 }}>
							<div className="text-white text-3xl">
								<h1>Date de naissance</h1>
							</div>
						</div>
						<div className="bg-white w-full pt-10 px-7 mt-32 min-h-9/10-screen absolute rounded-t-3xl pb-10">
							<div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5 divide-y divide-gray-200">
								<form onSubmit={this.submitBirthdate}>
									<div className="grid grid-cols-3 gap-4">
										<div className="">
											<label className="block text-sm font-medium text-gray-700">
												Jour
											</label>
											<div className="mt-1">
												<input
													type="number"
													value={this.state.form2.day}
													onChange={this.handleDay}
													className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
												/>
											</div>
										</div>

										<div className="">
											<label  className="block text-sm font-medium text-gray-700">
												Mois
											</label>
											<div className="mt-1">
												<input
													type="number"
													id="month"
													onChange={this.handleMonth}
													className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
												/>
											</div>
										</div>

										<div className="">
											<label className="block text-sm font-medium text-gray-700">
												Année
											</label>
											<div className="mt-1">
												<input
													type="number"
													id="year"
													onChange={this.handleYear}
													className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
												/>
											</div>
										</div>
									</div>
									{mendatoryOptHtml}
									{errorHtml}
									<button type="submit" className="font-semibold py-5 w-full rounded-full text-white shadow-xl text-base waves-effect waves-light mt-16" style={{ backgroundColor: '#' + this.props.theme.color1}}>{lang_assets.button3}</button>
								</form>
							</div>
						</div>
						{/* <label>
							<p className="error">{error}</p>
							<p className="question">* Date de naissance :</p>
							<input type="number" value={this.state.form2.day} className="birthday" onChange={this.handleDay} />
							<input type="number" id="month" value={this.state.form2.month} className="birthday" onChange={this.handleMonth} />
							<input type="number" id="year" value={this.state.form2.year} className="birthday year" onChange={this.handleYear} />
							<p className="mandatory">* {lang_assets.mandatory}</p>
						</label> */}
					</div>
				)
			}
			else if (this.state.step === 2) // QUESTION TELEPHONE

			{
				// console.log('Je vais tuer qq: ', this.state.error)
				let error = (this.state.error) ? this.state.error.global : null
				let errorHtml = ""
				if (error)
				{
					errorHtml = (
						<div className="rounded-md bg-red-50 p-4 mt-5">
							<div className="flex">
								<div className="flex-shrink-0">
									<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
								</div>
								<div className="ml-3">
									<h3 className="text-sm font-medium text-red-800">{error}</h3>
								</div>
							</div>
						</div>
					)
				}
				let mendatoryOptHtml = ""
				if (lang_assets.mandatory)
				{
					mendatoryOptHtml = (
						<div>
							<div className="rounded-md bg-yellow-50 p-4 mt-5">
								<div className="flex">
									<div className="flex-shrink-0">
										<ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
									</div>
									<div className="ml-3">
										<h3 className="text-sm font-medium text-yellow-800">{lang_assets.mandatory}</h3>
									</div>
								</div>
							</div>
						</div>
					)
				}
				return (
					<div className="min-h-4/5-screen">
						<div className="flex justify-center	items-center z-0 w-full fixed top-0 h-36" style={{ backgroundColor: '#' + this.props.theme.color1 }}>
							<div className="text-white text-3xl">
								<h1>Téléphone</h1>
							</div>
						</div>
						<div className="bg-white w-full pt-10 px-7 mt-32 min-h-9/10-screen absolute rounded-t-3xl pb-10">
							<div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5 divide-y divide-gray-200">
								<form onSubmit={this.submitPhone}>
									<div className="">
										<div className="">
											<label className="block text-sm font-medium text-gray-700">
												Téléphone
											</label>
											<div className="mt-1">
												<input
													type="number"
													value={this.state.form3.phone}
													onChange={this.handlePhone}
													className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
												/>
											</div>
										</div>
									</div>
									{mendatoryOptHtml}
									{errorHtml}
									<button type="submit" className="font-semibold py-5 w-full rounded-full text-white shadow-xl text-base waves-effect waves-light mt-16" style={{ backgroundColor: '#' + this.props.theme.color1}}>{lang_assets.button3}</button>
								</form>
							</div>
						</div>
					</div>
					// <div className="wrapper-content">
					// 	<div className="header" style={{ backgroundColor: '#' + this.props.theme.color1 }}>
					// 		<h1>Téléphone</h1>
					// 	</div>
					// 	<div className="contentWrapper serviceWrapper">
					// 		<form onSubmit={this.submitPhone}>
					// 			<label>
					// 				<p className="error">{error}</p>
					// 				<p className="question">* Téléphone :</p>
					// 				<input type="text" value={this.state.form3.phone} onChange={this.handlePhone} />
					// 				<p className="mandatory">* {lang_assets.mandatory}</p>
					// 			</label>
					// 			<button type="submit" style={styles}>{lang_assets.button3}</button>
					// 		</form>
					// 	</div>
					// </div>
				)
			}
			else if (this.state.step === 3 && this.state.answers.length > 0) // QUESTIONS TYPE TEXTE / NB
			{	
				let question_nb = this.state.question_nb
				const answer = this.state.answers[question_nb]
				const question = service_benefit.questions[question_nb]
				let title = ""
				for (let i = 0; i < question.translations.length; i++)
				{
					if (question.translations[i].lang === this.props.user.lang)
					{
						title = question.translations[i].title
						break
					}
				}
				let mandatory = null
				if (question.mandatory === true)
				{
					mandatory = (
						<div>
							<div className="rounded-md bg-yellow-50 p-4 mt-5">
								<div className="flex">
									<div className="flex-shrink-0">
										<ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
									</div>
									<div className="ml-3">
										<h3 className="text-sm font-medium text-yellow-800">{lang_assets.mandatory}</h3>
									</div>
								</div>
							</div>
						</div>
					)
				}
				let questionJSX = null
				const error = (this.state.error) ? this.state.error.global : null
				let errorHtml = ""
				if (error)
				{
					errorHtml = (	
						<div className="rounded-md bg-red-50 p-4 mt-5">
							<div className="flex">
								<div className="flex-shrink-0">
									<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
								</div>
								<div className="ml-3">
									<h3 className="text-sm font-medium text-red-800">{error}</h3>
								</div>
							</div>
						</div>
					)
				}
				if (question.type === "number")
				{
					questionJSX = (
						<label>
							<h2 className="text-3xl font-semibold text-gray-700 mb-10">{title}</h2>
							<div>
								<label htmlFor="comment" className="block text-sm font-medium text-gray-700">
									Votre réponse
								</label>
								<div className="mt-1">
									<input
										className="shadow-sm text-gray-700 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
										type="number"
										onChange={this.handleAnswer}
										value={answer}									
									/>
								</div>
						 		{mandatory}
								{errorHtml}
							</div>
						</label>
						// <label>
						// 	<p className="error">{error}</p>
						// 	<p className="question">{title}</p>
						// 	<input type="number" value={answer} onChange={this.handleAnswer} />
						// 	{mandatory}
						// </label>
					)
				}
				else
				{
					questionJSX = (
						<label>
							<h2 className="text-3xl font-semibold text-gray-700 mb-10">{title}</h2>
							<div>
								<label htmlFor="comment" className="block text-sm font-medium text-gray-700">
									Votre réponse
								</label>
								<div className="mt-1">
									<textarea
										rows={4}
										className="shadow-sm text-gray-700 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
										value={answer}
										onChange={this.handleAnswer}
									/>
								</div>
								{mandatory}
								{errorHtml}
							</div>
						</label>
						// <label>
						// 	<p className="error">{error}</p>
						// 	<p className="question">{title} :</p>
						// 	<input type="text" value={answer} onChange={this.handleAnswer} />
						// 	{mandatory}
						// </label>
					)
				}
				question_nb++
				return (
					<div className="wrapper-content">
						<div className="header" style={{ backgroundColor: '#' + this.props.theme.color1 }}>
							<h1>{lang_assets.question} {question_nb}/{this.state.answers.length}</h1>
						</div>
						<div className="contentWrapper serviceWrapper">
							<form onSubmit={this.submitAnswers}>
								{questionJSX}
								<button type="submit" style={styles}>{lang_assets.button3}</button>
							</form>
						</div>
					</div>
				)
			}
			else if (this.state.step === 4 && service_benefit) //RECAP
			{
				// récapitulatif
				const reservation = this.state.reservation
				const start = new Date(reservation.start * 1000)
				let start_hour = start.getHours()
				if (start_hour < 10)
					start_hour = '0' + start_hour
				let start_minute = start.getMinutes()
				if (start_minute < 10)
					start_minute = '0' + start_minute
				const end = new Date(reservation.end * 1000)
				let end_hour = end.getHours()
				if (end_hour < 10)
					end_hour = '0' + end_hour
				let end_minute = end.getMinutes()
				if (end_minute < 10)
					end_minute = '0' + end_minute
				let weekday = this.props.lang_assets.time.weekdays[start.getDay()]
				let day = start.getDate()
				if (day < 10)
					day = '0' + day
				let month = start.getMonth() + 1
				if (month < 10)
					month = '0' + month
				const year = start.getFullYear()
				let subtitle = ""
				let comment = ""
				let location = ""
				for (let i = 0; i < service_benefit.translations.length; i++)
				{
					if (service_benefit.translations[i].lang === this.props.user.lang)
					{
						subtitle = service_benefit.translations[i].subtitle
						comment = service_benefit.translations[i].comment
						location = service_benefit.translations[i].location
						break
					}
				}
				let price = (service_benefit.price === 0) ? lang_assets.free : service_benefit.price + "€"
				let commentJSX = null
				if (comment && comment.length > 0)
					commentJSX = (<div className="service" dangerouslySetInnerHTML={{ __html: comment }}></div>)
				let buttonJSX = (<button type="button" onClick={this.confirmBooking} className="font-semibold py-5 w-full rounded-full text-white shadow-xl text-xl my-10 waves-effect waves-light" style={{ backgroundColor: '#' + this.props.theme.color1 }}>{lang_assets.button2}</button>)

				if (this.state.attempt_submit === true)
				{
					buttonJSX = (
						<div className="loading" style={{ marginTop: "20px" }}>
							<Loader />
						</div>
					)
				}
				let participantRecapTitle = ""
				if (this.state.form1.participants.length)
					participantRecapTitle = (<div className="text-base text-gray-400 mt-6">Participants</div>)
				return (
					<div className="min-h-4/5-screen">
						<div className="flex justify-center	items-center z-0 w-full fixed top-0 h-36" style={{ backgroundColor: '#' + this.props.theme.color1 }}>
							<div className="text-white text-center text-3xl">
								<h1>Votre inscription</h1>
							</div>
						</div>
						<div className="bg-white w-full pt-10 px-7 mt-32 min-h-9/10-screen absolute rounded-t-3xl pb-10">
							<div className="text-base text-gray-400 mt-5">{weekday} {day}/{month}/{year}</div>
							<div className="rounded-md bg-gray-100 p-4 mt-1">
								<div className="ml-3">
									<h3 className="text-xl font-bold" style={{ color: '#' + this.props.theme.color1 }}>{subtitle}</h3>
									<div className="flex text-base mt-1">
										<LocationMarkerIcon className="text-gray-300 w-4 mr-1"/>
										<div className="text-sm text-gray-500">{location}</div>
									</div>
									<div className="w-8/12 mt-5">
										<div className="flex justify-between content-end ">
											<div className="text-base text-gray-500">{lang_assets.start_time}: </div> 
											<div className="text-base text-gray-500 font-semibold">{start_hour}:{start_minute}</div>
										</div>
										<div className="flex justify-between mt-1 content-end">
											<div className="text-base text-gray-500">{lang_assets.end_time}: </div> 
											<div className="text-base text-gray-500 font-semibold">{end_hour}:{end_minute}</div>
										</div>
									</div>
									<div className="flex text-sm justify-end mt-4 text-gray-500">
										<div>{lang_assets.price}: {price} </div>
									</div>
								</div>
							</div>

							{participantRecapTitle}
							<div className="rounded-md bg-gray-100 p-4 my-2">
								<div className="ml-3">
									<div className="flex">
										<UserCircleIcon className="w-7 text-purple-300 mr-2"/>
										<h3 className="text-lg font-semibold">{this.state.user.identity}</h3>
									</div>
								</div>
							</div>
							{this.state.form1.participants.map(({ firstname, lastname }, id) => {
								return (
									<div className="rounded-md bg-gray-100 p-4 my-2">
										<div className="ml-3">
											<div className="flex">
												<UserCircleIcon className="w-7 text-purple-300 mr-2"/>
												<h3 className="text-lg font-semibold">{firstname} {lastname}</h3>
											</div>
										</div>
									</div>
								)
							})}
							{commentJSX}
					 		{buttonJSX}
						</div>
					</div>
				)
			}
			else if (this.state.step === 7) //CONFIRMATION FINALE
			{
				const bodymovinOptions = {
					loop: false,
					autoplay: true,
					prerender: true,
					animationData: animation
				}
				return (
					<div className="min-h-4/5-screen">
						<div className="flex justify-center	items-center z-0 w-full fixed top-0 h-36" style={{ backgroundColor: '#' + this.props.theme.color1 }}>
							<div className="text-white text-3xl">
								<h1>{lang_assets.confirm}</h1>
							</div>
						</div>
						<div className="bg-white w-full pt-10 px-5 mt-32 min-h-9/10-screen absolute rounded-t-3xl pb-10">
							<div className="px-10 pt-16 pb-10">
								<div className="w-full flex justify-center">
									<div className="w-32">
										<ReactBodymovin options={bodymovinOptions} />
									</div>
								</div>
								<h2 className="text-center text-3xl font-bold tracking-tight sm:block sm:text-4xl mt-5" style={{ color: "#" + this.props.theme.color3 }}>
									Merci ! 
								</h2>
								<p className="text-center text-2xl mt-4 font-semibold tracking-tight text-gray-600 sm:block sm:text-4xl">
									{lang_assets.confirm_msg}
								</p>
							</div>
						</div>
					</div>
				)
			}
			else
			{
				return (
					<div className="loading">
						<Loader />
					</div>
				)
			}
		}
		else
		{
			return (
				<div className="loading">
					<Loader />
				</div>
			)
		}
	}
}

export default Booking