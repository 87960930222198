import React, { Component } from 'react'
import french_assets from '../../langs/main.fr.json'
import english_assets from '../../langs/main.en.json'
import german_assets from '../../langs/main.de.json'
import dutch_assets from '../../langs/main.nl.json'
import italian_assets from '../../langs/main.it.json'
import spanish_assets from '../../langs/main.es.json'
import portuguese_assets from '../../langs/main.pt.json'
import notification from '../../img/notification.png'
import M from 'materialize-css'

class SubscribeStepNotifications extends Component {
	constructor(props) {
		super(props)
		this.lang_assets = {
			fr: french_assets,
			en: english_assets,
			de: german_assets,
			nl: dutch_assets,
			it: italian_assets,
			es: spanish_assets,
			pt: portuguese_assets
		}
	}
		
	componentDidUpdate() {
		const elems = document.querySelectorAll('select')
		M.FormSelect.init(elems)
	}

	_askNotificationPermission = () => {
		return new Promise((resolve, reject) => {
			const permissionResult = Notification.requestPermission(result => {
				resolve(result)
			})
			if (permissionResult)
				permissionResult.then(resolve, reject)
		})
		.then(permissionResult => {
			this.props.validateStepNotifications()
		})
	}

	handleAccept = (accept) => {
		if (accept === true)
			this._askNotificationPermission()
		else
			this.props.validateStepNotifications()
	}

	render ()
	{
		return (
			<div>
				<div style={{backgroundColor: "#" + this.props.theme.color3}} className="h-52 z-0 px-8 text-center">
					<h1 className="text-3xl text-white font-semibold m-0 pt-16">Activer les notifications</h1>
				</div>
				<div className="rounded-t-3xl z-10" style={{backgroundColor: "#ffffff", marginTop: "-2rem"}}>
					<div className="px-10 pt-16 pb-10">
						<div className="flex justify-center">
							<img src={notification} alt="mobile with notification" className="relative w-1/2"/>
						</div>
						<h2 className="text-3xl font-semibold text-gray-700 mb-10">Dernière étape ! Il ne reste plus qu’à activer les notifications !<span role="img" aria-label="star-struck">🤩</span></h2>
						<h3 className="text-xl font-semibold" style={{ color: '#' + this.props.theme.color1 }}><span className="mr-3" role="img" aria-label="star-struck">🚀</span>Pour ne rien manquer de cette journée !</h3>
						<button type="button" onClick={this.handleAccept.bind(this, true)} className="font-semibold py-5 w-full rounded-full text-white shadow-xl text-xl my-10 waves-effect waves-light" style={{ backgroundColor: '#' + this.props.theme.color1 }}>Activer</button>
						<button type="button" onClick={this.handleAccept.bind(this, false)} className="font-semibold py-5 w-full text-xl mb-10 text-center" style={{ color: '#' + this.props.theme.color1 }}>Plus tard</button>
					</div>
				</div>
			</div>
		)
	}
}
		
export default SubscribeStepNotifications