import React, { Component } from 'react'
import SwipCat from './SwipCat'
import ModulePage from './ModulePage'
import ModuleList from './ModuleList'
import ModuleAnimation from './ModuleAnimation'
import PromoteDownload from '../UI/PromoteDownload' 
import PromoteInventory from '../UI/PromoteInventory'
// import PromoteReviews from '../UI/PromoteReviews'
// import PromoteDeposit from '../UI/PromoteDeposit'
import Loader from '../UI/Loader'
// import './Home.css'
import tools from '../../tools'

class Home extends Component {
	constructor(props) {
		super(props)
		this.state = {
			modules: null,
			showPromote: false
		}
	}

	componentDidMount() {
		const wrapper = document.getElementById("wrapper")
		if (this.props.scroll_home > 0)
		{
			setTimeout(() => {
				wrapper.scroll(0, this.props.scroll_home)
			}, window.innerHeight)
		}
		this.props.updateLogs(this.props.history.location.pathname, 'Home')
		this.props.updateShell('home', false, this.props.history.location.pathname)
		this.loadModules()
		this.loadIcons()
		this.props.fetchNotifications()
	}

	componentDidUpdate(prevProps) {
		if (prevProps.user !== this.props.user)
		{
			this.loadModules()
			// setTimeout(() => {
			// 	const modules = tools.clone(this.state.modules)
			// 	modules.splice(this.state.showPromote, 1)
			// 	this.setState({ modules: modules })
			// }, 150)
		}
	}

	componentWillUnmount() {
		const wrapper = document.getElementById("wrapper")
		this.props.updateScrollHome(wrapper.scrollTop)
	}

	loadIcons = () => {
		if (!this.props.icons)
			this.props.fetchIcons()
	}

	loadModules = () => {
		this.props.fetchModules()
		.then(modules => {
			for (let i = 0; i < modules.length; i++)
			{
				if (modules[i].type === "group")
				{
					for (let j = 0; j < this.props.groups.length; j++)
					{
						if (this.props.groups[j]._id === modules[i].values.group)
						{
							modules[i].values.group = this.props.groups[j]
							break
						}
					}
				}
			}
			let middle = false
			const time = tools.getTimestamp()
			const delay_dismiss = 60 * 60 * 24
			if (this.props.installEvent && (!this.props.user.promote_pwa || time - this.props.user.promote_pwa > delay_dismiss))
			{
				modules.push({ _id: 42, translations: null, type: "install" })
				middle = Math.floor(modules.length / 2)
				const tmp = tools.clone(modules[middle])
				modules[middle] = modules[modules.length - 1]
				modules[modules.length - 1] = tmp
			}
			const stay = (this.props.user && this.props.user.stay) ? this.props.user.stay : null
			if (stay && time > stay.arrival_time && (!this.props.user.dismiss_inventory || time - this.props.dismiss_inventory > delay_dismiss))
			{
				this.props.fetchInventory(stay.stay_number, "both", stay.location_category)
				.then(inventories => {
					if (inventories !== false)
					{
						this.props.fetchConfigInventory()
						.then(config_inventory => {
							let inventory_arrival = null
							let inventory_departure = null
							for (let i = 0; i < inventories.length; i++)
							{
								if (inventories[i].type === "arrival")
									inventory_arrival = inventories[i]
								else if (inventories[i].type === "departure")
									inventory_departure = inventories[i]
							}
							const arrival_limit = stay.arrival_time + (config_inventory.delay * 60 * 60)
							const departure_limit = stay.departure_time - (config_inventory.delay * 60 * 60)
							if ((config_inventory.arrival === true && time <= arrival_limit && inventory_arrival === null) ||
								(config_inventory.departure === true && time >= departure_limit && time < stay.departure_time && inventory_departure === null))
								modules.unshift({ _id: 21, type: "inventory" })
						})
						.catch(err => {
							console.log(err)
						})
					}
				})
				.catch(err => {
					console.log(err)
				})
			}
			this.setState({ modules: modules, showPromote: middle })
		})
		.catch(err => console.log(err))
	}

	render() {
		if (!this.state.modules || !this.props.icons)
		{
			return (
				<div className="loading">
					<Loader />
				</div>
			)
		}
		else
		{
			return (
				<div>
					<div className="flex justify-center	items-center z-0 w-full fixed top-0 h-36" style={{ backgroundColor: '#' + this.props.theme.color1 }}>
						<h1 className="text-white text-3xl mx-12 text-center">{this.props.appName}</h1>
					</div>
					<div className="z-10 w-full absolute mt-32 pt-12 rounded-3xl bg-white">
						<div className="body" onScroll={this.onScroll}>
							{this.state.modules.map(({ _id, translations, type, values }) => {
								let title = ""
								let subtitle = ""
								if (translations)
								{
									for (let i = 0; i < translations.length; i++)
									{
										if (translations[i].lang === this.props.user.lang)
										{
											if ((type === "page" || type === "list") && translations[i].values)
												subtitle = translations[i].values.subtitle
											title = translations[i].title
											break
										}
									}
								}
								if (type === "install") // FRANCKY LES MODULES PROMOTES ICI
								{
									return (
										<PromoteDownload
											lang_assets={this.props.lang_assets}
											btnLater= {this.props.handleInstallApp.bind(this, false)}
											btnInstall={this.props.handleInstallApp.bind(this, true)}
											id={_id}
										/>
									)
								}
								if (type === "inventory")
								{
									// return (<PromoteDeposit />)
									return (
										<PromoteInventory
											lang_assets={this.props.lang_assets}
											dismissInventory={this.props.dismissInventory}
											key={_id}
										/>
									)
								}
								else if (type === "animation")
								{
									return (
										<ModuleAnimation
											key={_id}
											lang_assets={this.props.lang_assets}
											title={title}
											user={this.props.user}
											fetchAnimationNext={this.props.fetchAnimationNext}
										/>
									)
								}
								else if (type === "group")
								{
									return (
										<SwipCat
											key={_id}
											lang_assets={this.props.lang_assets}
											title={title}
											icons={this.props.icons}
											categories={this.props.categories}
											fetchCategoriesByGroup={this.props.fetchCategoriesByGroup}
											group={values.group}
											theme={this.props.theme}
											user={this.props.user}
										/>
									)
								}
								else if (type === "page")
								{
									return (
										<ModulePage
											key={_id}
											lang_assets={this.props.lang_assets}
											title={title}
											page={values.page}
											category={values.category}
											groups={this.props.groups}
											subtitle={subtitle}
											user={this.props.user}
											fetchPageByID={this.props.fetchPageByID}
											fetchPageByCategory={this.props.fetchPageByCategory}
											fetchCategoryByID={this.props.fetchCategoryByID}
										/>
									)
								}
								else if (type === "list")
								{
									return (
										<ModuleList
											key={_id}
											lang_assets={this.props.lang_assets}
											title={title}
											list={values.list}
											category={values.category}
											groups={this.props.groups}
											subtitle={subtitle}
											user={this.props.user}
											fetchCategoryByID={this.props.fetchCategoryByID}
											fetchListByID={this.props.fetchListByID}
										/>
									)
								}
								else
									return null
							})}
						</div>
					</div>
				</div>
			)
		}
	}
}

export default Home