import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Loader from '../UI/Loader'
import AskNotifications from '../UI/AskNotifications'
import './Notifications.css'

class Notifications extends Component {
	constructor(props) {
		super(props)
		this.state = {
			notifications: this.props.notifications,
			redirect: false,
			premium: false
			// premium: true
		}
	}

	componentDidMount() {
		this.loadNotifications()
		this.props.updateLogs(this.props.history.location.pathname, 'Notifications')
		this.props.updateShell('notification', true, this.props.history.location.pathname)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.notifications !== this.props.notifications)
			this.loadNotifications(true)
	}

	loadNotifications = (fromProps = false) => {
		if (fromProps === true)
			this.setState({ notifications: this.props.notifications })
		else
		{
			this.props.fetchNotifications()
			.then(notifications => {
				this.setState({ notifications: notifications })
			})
			.catch(err => {
				console.log("could not fetch notifications", err)
			})
		}
	}

	// hashCode() creates a hash of the notification's identifiers to match with its tag.
	// It must be the same hash function than the one in the serviceworker (custom-service-worker.js)
	hashCode = (s) => {
		let h = 0
		let l = s.length
		let i = 0
		if (l > 0)
		{
			while (i < l)
				h = (h << 5) - h + s.charCodeAt(i++) | 0
		}
		return h
	}

	clickNotification = (read, _id, url) => {
		if (read === false)
		{
			const notification = {
				_id: _id,
				from: 'app'
			}
			this.props.postClickNotification(notification)
			.then(ret => {
				this.setState({ redirect: url })
			})
			.catch(err => console.log("could not set notification read", err))
		}
		else
			this.setState({ redirect: url })
	}

	parseDate = (timestamp) => {
		const time = new Date(timestamp * 1000)
		const timeday = Math.round(new Date(time.getFullYear(), time.getMonth(), time.getDate(), 0, 0, 0).getTime() / 1000)
		const now = new Date()
		const today = Math.round(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0).getTime() / 1000)
		const weektime = 60 * 60 * 24 * 7
		const daytime = 60 * 60 * 24
		let day = null
		if (timeday + daytime > today) // Current day
			day = this.props.lang_assets.time.today
		else if (timeday + daytime * 2 > today) // Yesterday
			day = this.props.lang_assets.time.yesterday
		else if (timeday + weektime > today) // Current week
			day = this.props.lang_assets.time.weekdays[time.getDay()]
		else
			day = time.getDate() + ' ' + this.props.lang_assets.time.months_short[time.getMonth()]
		let hours = time.getHours()
		if (hours < 10)
			hours = '0' + hours
		let minutes = time.getMinutes()
		if (minutes < 10)
			minutes = '0' + minutes
		return (day + this.props.lang_assets.time.at + hours + ':' + minutes)
	}

	render() {
		const notifications = this.state.notifications
		const redirect = this.state.redirect
		let notif = null
		const lang_assets = this.props.lang_assets
		let wrapper = "bg-white w-full pt-36 min-h-9/10-screen"
		let headerMessage = null
		if (notifications === undefined)
		{
			return (
				<div className="loading">
					<Loader />
				</div>
			)
		}
		else if (redirect !== false)
		{
			return (
				<Redirect to={redirect} />
			)
		}
		else
		{
			// if (this.props.features === 'premium')
			if (this.state.premium === true)
			{
				wrapper = "bg-white w-full pt-52 min-h-9/10-screen"
				headerMessage = (
					<div className="w-full h-16 flex" style={{ filter: 'drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))' }}>
						<div className="w-1/2 h-full bg-white hover:bg-blue-50 pl-2.5 text-center border-r border-gray-300 flex items-center justify-center">
							<div className="">{lang_assets.notifications.title}</div>
						</div>
						<Link to="/messages" className="w-1/2 h-full bg-white hover:bg-blue-50 pr-2.5 text-center flex items-center justify-center">
							<div>{lang_assets.messages.title}</div>
						</Link>
					</div>
				)
			}
			if (notifications && notifications.length === 0)
			{
				notif = (
					<div className={wrapper}>
						<div className="flex flex-row justify-center mt-5">
							<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
								<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
							</svg>
							<h3 className="text-lg font-medium text-gray-500 ml-2">{lang_assets.notifications.empty}</h3>
						</div>
					</div>
				)
			}
			else if (notifications && notifications.length > 0)
			{
				notif = (
					<div className={wrapper}>
						{notifications && notifications.map(({ _id, cron, title, content, created_time, read, user, url }) => {
							const time = this.parseDate(created_time)
							let classname = "notification"
							if (read === false)
								classname += " unread"
							return (
								<div className={classname} key={_id} onClick={this.clickNotification.bind(this, read, _id, url)}>
									<p className="title">{title}</p>
									<p className="text">{content}</p>
									<p className="time">{time}</p>
								</div>
							)
						})}
							<AskNotifications
								lang_assets={this.props.lang_assets}
								theme={this.props.theme}
								text={lang_assets.activate}
								user={this.props.user}
								handleAcceptNotifications={this.props.handleAcceptNotifications}
							/>
					</div>
				)
			}
			return (
				<div>
					<div className="flex flex-col w-full fixed">
						<div className="flex justify-center items-center z-0 w-full top-0 h-36" style={{ backgroundColor: '#' + this.props.theme.color1 }}>
							<div className="text-white text-3xl">
								<h1>{lang_assets.notifications.title}</h1>
							</div>
						</div>
						{headerMessage}
					</div>
					{notif}
				</div>
			)
		}
	}
}

export default Notifications