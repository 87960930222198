import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { XCircleIcon } from '@heroicons/react/solid'

class FindStay extends Component {
	constructor(props) {
		super(props)
		this.default_ph_color = "text-gray-400"
		this.updated_ph_color = "text-black"
		this.activated_btn_color = "#" + this.props.theme.color1
		this.deactivated_btn_color = "#A3A3A3"
		this.code_ph = "ex: CFUTRQ"
		this.state = {
			form1: {
				code: this.code_ph,
				v_code: ''
			},
			redirect: false,
			current_btn_color: this.deactivated_btn_color,
			error: null,
			success: null
		}
	}

	componentDidMount() {
		this.props.updateLogs(this.props.history.location.pathname, 'Membres')
		this.props.updateShell('profile', true, this.props.history.location.pathname)
	}

	setErrorMsg = (msg) => {
		this.setState({ success: null, error: msg })
	}

	setSuccessMsg = (msg) => {
		this.setState({ success: msg, error: null })
	}

	clearFlash = () => {
		this.setState({ error: null, success: null })
	}

	focusCode = (event) => {
		this.setState({ error : null })
		if (this.state.form1.v_code.length === 0) {
			let form1 = {
				code: '',
				v_code: this.state.form1.v_code
			}
			this.setState({ form1: form1 })
		}
	}

	blurCode = (event) => {
		if (this.state.form1.v_code.length === 0) {
			let form1 = {
				code: this.code_ph,
				v_code: this.state.form1.v_code
			}
			this.setState({ form1: form1 })
		}
	}

	btn_color = (event) => {
		let style = this.state.current_btn_color
		if (event.target.value.trim().length === 6)
			style = this.activated_btn_color
		else
			style = this.deactivated_btn_color
		this.setState({ current_btn_color : style })
	}

	handleCode = (event) => {
		this.btn_color(event)	
		let form1 = {
			code: event.target.value.trim(),
			v_code: event.target.value.trim()
		}
		this.setState({ form1: form1 })
	}

	code_ph_color = () => {
		let color = this.default_ph_color
		if (this.state.form1.v_code !== "")
			color = this.updated_ph_color
		return (color)
	}

	_validateForm1 = () => {
		let error = false
		let error_msg = null
		if (this.state.form1.v_code.length !== 6)
		{
			if (this.state.form1.v_code.length === 0)
				error_msg = "Saisissez votre code appli"
			else
				error_msg = "Ce code doit comprendre 6 caractères"
			error = true
			this.setState({ error: error_msg })
		}
		return (!error) ? true : false
	}

	handleSubmit = (event) => {
		event.preventDefault()
		if (this._validateForm1())
		{
			const code = this.state.form1.v_code.toUpperCase()
			this.props.postResetClient(code, this.props.user._id)
			.then(ret => {
				if (ret._id)
				{
					this.props.updateUser(ret, ret.session)
					this.setState({ redirect: true })
				}
				else if (ret === 1)
					this.setErrorMsg("Le code d'activation n'est pas valide")
			})
			.catch(err => {
				console.log("can not reset client", err)
			})
		}
	}

	render() {
		if (this.state.redirect === true)
		{
			return (
				<Redirect to="/membres" />
			)
		}
		else
		{
			let error = null
			if (this.state.error)
			{
				error = (
					<div className="rounded-md bg-red-50 p-4 mt-3">
						<div className="flex">
							<div className="flex-shrink-0">
								<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
							</div>
							<div className="ml-3">
								<h3 className="text-sm font-medium text-red-800">{this.state.error}</h3>
							</div>
						</div>
					</div>
				)
			}
			return (
				<div className="min-h-4/5-screen">
					<div className="flex justify-center	items-center z-0 w-full fixed top-0 h-36" style={{ backgroundColor: '#' + this.props.theme.color1 }}>
						<div className="text-white text-3xl">
							<h1>Retrouver son séjour</h1>
						</div>
					</div>
					<div className="bg-white w-full pt-10 px-5 mt-32 min-h-9/10-screen absolute rounded-t-3xl pb-10">
						<div className="px-10 pt-16 pb-10">
							<div>
								<h2 className="text-2xl text-gray-700 font-semibold mb-10">Entrez votre code à 6 caractères</h2>
								<div className="w-full flex justify-center">
							</div>
								<form className="mb-16" onSubmit={this.handleSubmit}>
									<label>
										<div>
											<div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
												<input
													className="block pl-0 w-full border-0 border-b border-transparent text-gray-500 focus:text-gray-700 focus:border-indigo-600 focus:ring-0 text-xl"
													type="text"
													value={this.state.form1.code}
													onChange={this.handleCode}
													onBlur={this.blurCode}
													onFocus={this.focusCode}
												/>
											</div>
										</div>
									</label>
									{error}
									<button 
										className="items-center px-6 py-5 border border-transparent text-xl font-medium rounded-full w-full shadow-sm text-white my-10" 
										type="submit" 
										style={{ backgroundColor: this.state.current_btn_color }}
									>
										Suivant
									</button>
									{/* <p className="text-gray-400 text-base">Vous ne retrouvez pas votre code d’activation ?</p>
									<button className="text-base font-semibold" style={{ color: '#' + this.props.theme.color1 }} type="button" onClick={this.props.gotoHelp}>Obtenez de l'aide.</button> */}
								</form>
							</div>
						</div>
					</div>
				</div>
			)
		}
	}
}

export default FindStay
