import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class PromoteInventory extends Component {
	constructor(props) {
		super()
		this.state = {}
	}
	
	render() {
		return (
			<div className="w-11/12 p-5 text-base mx-auto rounded-xl shadow" style={{ backgroundColor: '#EDE9FD' }}>
				<p className="font-semibold text-gray-900">{this.props.lang_assets.home.title_inventory} <span role="img" aria-label="mobile phone with arrow">✅</span></p>
				<p className="dl-content text-gray-800 mt-2">{this.props.lang_assets.home.promote_inventory}</p>
				<div className="btn-container mt-2">
					<div className="mt-2 flex space-x-7">
						<button
							type="button"
							onClick={this.props.dismissInventory}
							className="rounded-md text-base font-normal text-gray-600 text-opacity-50 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
						>
							{this.props.lang_assets.home.later}
						</button>
						<Link
							to="/inventaire"
							type="button"
							style={{ color:'#5248B5' }}
							className="rounded-md text-base font-medium hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
						>
							{this.props.lang_assets.home.btn_inventory}
						</Link>
					</div>
				</div>
			</div>
		)
	}
}

export default PromoteInventory