import React, { Component } from 'react'

class PromoteDownload extends Component {
	constructor(props) {
		super()
		this.state = {}
	}
	
	render() {
		return (
			<div key={this.props.id} className="w-11/12 p-5 text-base mx-auto rounded-xl bg-blue-100 shadow">
				<p className="font-semibold text-gray-700">{this.props.lang_assets.home.full_experience} <span role="img" aria-label="mobile phone with arrow">📲</span></p>
				<p className="dl-content text-gray-700 mt-2">{this.props.lang_assets.home.promote_install}</p>
				<div className="btn-container mt-2">
					<div className="mt-3 flex space-x-7">
						<button
							type="button"
							onClick={this.props.btnLater}
							className="rounded-md text-base font-normal text-gray-600 text-opacity-50 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
						>
							{this.props.lang_assets.home.later}
						</button>
						<button
							type="button"
							onClick={this.props.btnInstall}
							className="rounded-md text-base font-medium text-blue-600 hover:text-sky-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
						>
							{this.props.lang_assets.home.install}
						</button>
					</div>
				</div>
			</div>
		)
	}
}

export default PromoteDownload