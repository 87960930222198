import React, { Component } from 'react'
import ButtonPrevious from '../UI/ButtonPrevious'
import { XCircleIcon } from '@heroicons/react/solid'

class SubscribeStepBirth extends Component {
	constructor(props) {
		super(props)
		this.default_ph_color = "text-gray-400"
		this.updated_ph_color = "text-black"
		this.day_ph = ""
		this.month_ph = ""
		this.year_ph = ""
		this.activated_btn_color = "#" + this.props.theme.color1
		this.deactivated_btn_color = "#A3A3A3"
		this.state = {
			form1: {
				day: (this.props.user.day.length > 0) ? this.props.user.day : this.day_ph,
				v_day: (this.props.user.day.length > 0) ? this.props.user.day : "",
				month: (this.props.user.month.length > 0) ? this.props.user.month : this.month_ph,
				v_month : (this.props.user.month.length > 0) ? this.props.user.month : "",
				year: (this.props.user.year.length > 0) ? this.props.user.year : this.year_ph,
				v_year: (this.props.user.year.length > 0) ? this.props.user.year : ""
			},
			current_btn_color : (this.is_btn_on() === false && this.props.mandatory === true) ? this.deactivated_btn_color : this.activated_btn_color,
			success: null,
			error: this._initErrors()
		}
	}

	setErrorMsg = (key, msg) => {
		const errors = this._initErrors()
		errors[key] = msg
		this.setState({ success: null, error: errors })
	}

	setSuccessMsg = (msg) => {
		this.setState({ success: msg, error: this._initErrors() })
	}

	clearFlash = () => {
		this.setState({ error: null, success: this._initErrors() })
	}

	_initErrors = () => {
		const errors = {
			global: null,
			day: null,
			month: null,
			year: null
		}
		return errors
	}

	/* DAYS */

	focusDay = (event) => {
		if (this.state.form1.v_day === "")
		{
			let form_obj = this.state.form1
			form_obj.day = ""
			this.setState({ form1 : form_obj })
		}
	}
	
	blurDay = () => {
		if (this.state.form1.day === "" && this.state.form1.v_day === "")
		{
			let form_obj = this.state.form1
			form_obj.day = this.day_ph
			this.setState({ form1 : form_obj })
		}
	}

	handleDay = (event) => {
		this.btn_color(event, 'day')
		let event_parsed = parseInt(event.target.value, 10)
		let input = event.target.value
		if ((event_parsed >= 10 && event_parsed <= 31) 
			|| ((event_parsed >= 1 && event_parsed <= 3) && (event.target.value.length === 2))
			|| (event_parsed >= 4 && event_parsed <= 10))
		{
			document.getElementById('fieldMonth').focus()
		}
		if (event_parsed >= 4 && event_parsed < 10)
			input = '0' + event_parsed.toString()
		let form1_obj = {
			...this.state.form1,
			day: input,
			v_day: input
		}
		this.setState({ form1: form1_obj })
	}

	/* MONTHS */

	focusMonth = () => {
		if (this.state.form1.v_month === "")
		{
			let form_obj = this.state.form1
			form_obj.month = ""
			this.setState({ form1 : form_obj })
		}
	}

	blurMonth = () => {
		if (this.state.form1.month === "" && this.state.form1.v_month === "")
		{
			let form_obj = {
				...this.state.form1,
				month : this.month_ph
			}
			this.setState({ form1 : form_obj })
		}
	}

	handleMonth = (event) => {
		this.btn_color(event, 'month')
		let input = event.target.value
		let event_parsed = parseInt(event.target.value, 10)
		if ((event_parsed >= 10 && event_parsed <= 12) || input === '01')
			document.getElementById('fieldYear').focus()
		else if (event_parsed >= 2 && event_parsed <= 9)
		{
			input = '0' + event_parsed.toString()
			document.getElementById('fieldYear').focus()
		}
		let form1_obj = {
			...this.state.form1,
			month: input,
			v_month: input
		}
		this.setState({ form1: form1_obj })
	}

	/* YEAR */

	focusYear = () => {
		if (this.state.form1.v_year === "")
		{
			let form_obj = this.state.form1
			form_obj.year = ""
			this.setState({ form1 : form_obj })
		}
	}

	blurYear = () => {
		if (this.state.form1.year === "" && this.state.form1.v_year === "")
		{
			let form_obj = {
				...this.state.form1,
				year : this.year_ph
			}
			this.setState({ form1 : form_obj })
		}
	}

	handleYear = (event) => {
		this.btn_color(event, 'year')
		let form_obj = {
			...this.state.form1,
			year: event.target.value,
			v_year: event.target.value
		}
		this.setState({ form1 : form_obj })
	}

	_validateForm1 = (e) => {
		let error_obj = this._initErrors()
		let error = false
		let today = new Date()
		let currentyear = today.getFullYear()
		const day = this.state.form1.v_day
		const day_parsed = parseInt(day)
		const month = this.state.form1.v_month
		const month_parsed = parseInt(month)
		const year = this.state.form1.v_year
		const year_parsed = parseInt(year)
		if (!day && !month && !year && this.props.mandatory === true)
		{
			error_obj.global = "Veuillez renseigner votre date de naissance !"
			error = true
		}
		if (day || month || year)
		{
			if (!day || (day_parsed <= 0 || day_parsed >= 32))
			{
				error_obj.day = "Oups, cette date n'est pas valide"
				error = true
			}
			else if (!month || (month_parsed <= 0 || month_parsed >= 13))
			{
				error_obj.month = "Oups, ce mois n'est pas valide"
				error = true
			}
			else if (!year || (year_parsed <= 1900 || year_parsed >= currentyear - 7))
			{
				error_obj.month = "Votre année de naissance est suspecte !"
				error = true
			}
		}
		if (error === true)
		{
			this.setState({ success: null, error: error_obj })
		}
		return (!error) ? true : false
	}

	check_empty_input = (event, current_step) => {
		let i = 0
		let count = 0
		let res = 1
		const arr = [
			{
				step : 'day',
				value : this.state.form1.v_day
			} ,
			{
				step : 'month', 
				value : this.state.form1.v_month
			},
			{
				step : 'year',
				value : this.state.form1.v_year
			}
		]
		if (event.target.value.length !== 0)
			count++
		else
			return (res)
		while (i < 3)
		{
			if (arr[i].step === current_step && current_step !== 'year' )
				i++
			if (arr[i].value !== "" && arr[i].step !== current_step)
				count++
			i++
		}
		if (count === 3)
			res = 0
		return (res)
	}

	btn_color = (event, current_step) => {
		let style = this.current_btn_color
		if (this.check_empty_input(event, current_step) === 0)
			style = this.activated_btn_color
		else
			style = this.deactivated_btn_color
		this.setState({ current_btn_color : style })
	}

	is_btn_on = () => {
		if (this.props.user.day.length > 0 && this.props.user.month.length > 0 && this.props.user.year.length > 0)
			return true
		return false
	}

	day_ph_color = () => {
		let color = this.default_ph_color
		if (this.state.form1.v_day !== "")
			color = this.updated_ph_color
		return (color)
	}

	month_ph_color = () => {
		let color = this.default_ph_color
		if (this.state.form1.v_month !== "")
			color = this.updated_ph_color
		return (color)
	}

	year_ph_color = () => {
		let color = this.default_ph_color
		if (this.state.form1.v_year !== "")
			color = this.updated_ph_color
		return (color)
	}

	handleSubmit = (e) => {
		e.preventDefault()
		if (this._validateForm1())
		{
			let day = Number(this.state.form1.v_day)
			let month = Number(this.state.form1.v_month)
			let year = Number(this.state.form1.v_year)
			if (day < 10)
				day = '0' + day
			if (month < 10)
				month = '0' + month
			const birthday = {
				v_day: day,
				v_month: month,
				v_year: year
			}
			this.props.updateUser(birthday)
			this.props.validateStepBirth()
		}
	}

	_printError = (msg) => {
		if (msg)
		{
			return (
				<div className="rounded-md bg-red-50 p-4 mt-3">
					<div className="flex">
						<div className="flex-shrink-0">
							<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
						</div>
						<div className="ml-3">
							<h3 className="text-sm font-medium text-red-800">{msg}</h3>
						</div>
					</div>
				</div>
			)
		}
	}

	render ()
	{
		return (
			<div>
				<ButtonPrevious
					theme={this.props.theme}
					handlePreviousBtn={this.props.handlePreviousBtn}
				/>
				<div style={{backgroundColor: "#" + this.props.theme.color1}} className="h-52 z-0 px-8 text-center">
					<p className="text-2xl text-white pt-14">Inscription</p>
					<h1 className="text-3xl text-white font-semibold m-0 pt-2">Étape {this.props.stepNb}</h1>
				</div>
				<div className="rounded-t-3xl z-10" style={{backgroundColor: "#ffffff", marginTop: "-2rem"}}>
					<div className="px-10 pt-16 pb-10">
						<h2 className="text-3xl font-semibold text-gray-700 mb-10">Quelle est votre date de naissance ? <span role="img" aria-label="birthday-cake">🎂</span></h2>
						<h3 className="text-lg text-gray-500 mb-10">Jour / Mois / Année</h3>
						<form className="mb-16" onSubmit={this.handleSubmit}>
							{this._printError(this.state.error.global)}
							{this._printError(this.state.error.day)}
							{this._printError(this.state.error.month)}
							{this._printError(this.state.error.year)}
							<div className="flex row space-x-4">
								<div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
									<input
										type="number"
										value={this.state.form1.day}
										className="block w-full border-0 border-b border-transparent focus:border-indigo-600 focus:ring-0 text-xl"
										onFocus={this.focusDay} 
										onBlur={this.blurDay} 
										onChange={this.handleDay} 
										id="fieldDay"
									/>
								</div>
								<span className="text-xl text-gray-700">/</span>
								<div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
									<input
										type="number"
										value={this.state.form1.month}
										className="block w-full border-0 border-b border-transparent focus:border-indigo-600 focus:ring-0 text-xl"
										onFocus={this.focusMonth} 
										onBlur={this.blurMonth} 
										onChange={this.handleMonth} 
										id="fieldMonth"
									/>
								</div>
								<span className="text-xl text-gray-700">/</span>
								<div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
									<input
										type="number"
										value={this.state.form1.year}
										className="block w-full border-0 border-b border-transparent focus:border-indigo-600 focus:ring-0 text-xl"
										onFocus={this.focusYear} 
										onBlur={this.blurYear} 
										onChange={this.handleYear} 
										id="fieldYear"
									/>
								</div>
							</div>
							<button className="font-semibold py-5 w-full rounded-full text-white shadow-xl text-xl my-10 waves-effect waves-light" type="submit" style={{ backgroundColor: this.state.current_btn_color }}>Suivant</button>
						</form>
					</div>
				</div>
			</div>
		)
	}
}


export default SubscribeStepBirth