import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import iNoBounce from '../../inobounce'
import Cookies from 'js-cookie'
import Tracker from '../Core/Tracker'
import Forgot from '../Unlogged/Forgot'
import Signin from '../Unlogged/Signin'
import SubscribeCamping from '../Unlogged/SubscribeCamping'
import SubscribeCampus from '../Unlogged/SubscribeCampus'
import SubscribeDemo from '../Unlogged/SubscribeDemo'
import SubscribeSyndic from '../Unlogged/SubscribeSyndic'
import Lobby from '../Unlogged/Lobby'
import ReviewsClient from '../Reviews/ReviewsClient'
import Header from '../UI/Header'
import Main from './Main'
import BottomBar from '../UI/BottomBar'
import Loader from '../UI/Loader'
import connexion from '../../connexion'
import tools from '../../tools'
import french_assets from '../../langs/main.fr.json'
import english_assets from '../../langs/main.en.json'
import german_assets from '../../langs/main.de.json'
import dutch_assets from '../../langs/main.nl.json'
import italian_assets from '../../langs/main.it.json'
import spanish_assets from '../../langs/main.es.json'
import portuguese_assets from '../../langs/main.pt.json'
import 'tailwindcss/tailwind.css'
import '../../css/app.css'
import mockup from '../../img/mockup.png'
// import qrcode from '../../img/qrcode.png'
// import './App.css'

class App extends Component {
	constructor(props) {
		super(props)
		this.lang_assets = {
			fr: french_assets,
			en: english_assets,
			de: german_assets,
			nl: dutch_assets,
			it: italian_assets,
			es: spanish_assets,
			pt: portuguese_assets
		}
		this.state = {
			// fetch state
			accommodations: null,
			activation_code_specials: null,
			animations: null,
			animation_dates: null,
			animation_categories: null,
			app_name: null,
			app_type: null,
			app_url: null,
			background_img: null,
			categories: null,
			client: null,
			config_deposit: null,
			config_inventory: null,
			config_pms: null,
			conversations: null,
			deposit: null,
			device: null,
			features: null,
			groups: null,
			icons: null,
			langs: null,
			lang_assets: null,
			lang_default: null,
			lists: null,
			logo: null,
			messages: null,
			modules: null,
			notifications: null,
			pages: null,
			reviews: null,
			services: null,
			service_benefits: null,
			service_products: null,
			service_reservations: null,
			service_workers: null,
			session: undefined,
			show_stay_dates: null,
			show_stay_location: null,
			theme: null,
			user: null,
			welcome_txt: [],
			
			// others
			activation_code: null,
			scroll_home: 0,
			history: {
				previous: [],
				current: null,
				show: false,
				click: false
			},
			bottomHighlight: null
		}
		this.installEvent = undefined
		window.addEventListener('beforeinstallprompt', e => {
			e.preventDefault()
			this.installEvent = e
		})
		this.nobounce = iNoBounce.isEnabled()
	}

	componentDidMount() {
		// M.AutoInit()
		this.fetchVersion()
		this.fetchConfig()
		this.fetchColors()
		this.fetchLogo()
		this.fetchCookie()
		this.fetchGroups()
		window.addEventListener('resize', this.appHeight)
		this.appHeight()
	}

	componentDidUpdate(prevProps, prevState)
	{
		if (this.state.lang_default !== prevState.lang_default)
		{
			console.log("update lang", this.state.lang_default, prevState.lang_default)
			this.fetchCookie()
		}
		if ((prevProps.session !== null && this.state.session === null && this.state.application_ouverte === true) ||
			(prevState.application_ouverte === undefined && this.state.application_ouverte === true && this.state.session === null))
		{
			// Le settimeout, couplé au set de la variable "promiseDetectDevice" à 42 (voir plus bas) permet d'éviter un doublon en db
			setTimeout(() => {
				this.detectDevice()
			}, Math.random() * 1000)
		}
	}

	appHeight = () => {
		// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
		const vh = window.innerHeight * 0.01
		// Then we set the value in the --vh custom property to the root of the document
		document.documentElement.style.setProperty('--vh', vh + 'px')
	}

	/*
	**  FETCH METHODS
	*/

	fetchAccommodations = () => {
		console.log('fetch accommodations')
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/accommodations/list')
			.then(res => res.json())
			.then(accommodations => {
				this.setState({ accommodations: accommodations })
				resolve(accommodations)
			})
			.catch(err => {
				console.log(err)
				reject(err)
			})
		})
	}

	promiseMessagesByConversation = {}

	fetchMessagesByConversation = (conversation) => {
		if (Object.keys(this.promiseMessagesByConversation).indexOf(conversation) !== -1)
			return this.promiseMessagesByConversation[conversation]
		else
		{
			console.log("fetch messages by conversation", conversation)
			this.promiseMessagesByConversation[conversation] = new Promise((resolve, reject) => {
				fetch(connexion.connect.url + 'webapp/messages/find-by-conversation', {
					method: 'POST',
					body: JSON.stringify({
						conversation: conversation
					})
				})
				.then(res => res.json())
				.then(ret => {
					if (ret)
					{
						let messages = (this.state.messages) ? this.state.messages : []
						for (let i = 0; i < ret.length; i++)
						{
							let found = false
							for (let j = 0; j < messages.length; j++)
							{
								if (ret[i]._id === messages[j]._id)
								{
									found = true
									break
								}
							}
							if (!found)
								messages.push(ret[i])
						}
						this.setState({ messages: messages })
					}
					resolve(ret)
				})
				.catch(err => {
					reject(err)
					console.log(err)
				})
			})
			const ret = this.promiseMessagesByConversation[conversation]
			delete this.promiseMessagesByConversation[conversation]
			return ret
		}
	}

	fetchAnimationCategories = () => {
		console.log('fetch animation categories')
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/animation-categories/list')
			.then(res => res.json())
			.then(categories => {
				resolve(categories)
				this.setState({ animation_categories: categories })
			})
			.catch(err => {
				reject(err)
				console.log(err)
			})
		})
	}

	fetchAnimationNext = () => {
		return new Promise((resolve, reject) => {
			console.log("fetch animation next starred")
			let found = false
			let animation = null
			let animation_time = null
			let date = null
			let date_time = null
			const animations = (this.state.animations) ? this.state.animations : []
			const dates = (this.state.animation_dates) ? this.state.animation_dates : []
			dates.sort((a, b) => {
				return b.start - a.start
			})
			if (animations.length > 0 && dates.length > 0)
			{
				for (let i = 0; i < dates.length; i++)
				{
					for (let j = 0; j < animations.length; j++)
					{
						if (dates[i].animation === String(animations[j]._id))
						{
							if (animations[j].front === true)
							{
								date = dates[i]
								animation_time = (dates[i].edited_time) ? dates[i].edited_time : dates[i].created_time
								animation = animations[j]
								animation_time = (animations[j].edited_time) ? animations[j].edited_time : animations[j].created_time
								found = true
							}
							break
						}
					}
					if (found === true)
						break
				}
			}
			fetch(connexion.connect.url + 'webapp/animations/next-starred', {
				method: 'POST',
				body: JSON.stringify({
					date: (date) ? date._id : '',
					date_time: (date_time) ? date_time : '',
					animation: (animation) ? animation._id : '',
					animation_time: (animation_time) ? animation_time : ''
				})
			})
			.then(res => res.json())
			.then(fetch_animation => {
				if (fetch_animation === 1)
				{
					resolve({
						animation: animation,
						date: date
					})
				}
				else if (fetch_animation === null)
					resolve(fetch_animation)
				else if (fetch_animation !== false)
				{
					let dates = (this.state.animation_dates) ? this.state.animation_dates : []
					let found = false
					for (let i = 0; i < dates.length; i++)
					{
						if (dates[i]._id === fetch_animation.date._id)
						{
							found = true
							break
						}
					}
					if (!found)
						dates.push(fetch_animation.date)
					let animations = (this.state.animations) ? this.state.animations : []
					found = false
					for (let i = 0; i < animations.length; i++)
					{
						if (animations[i]._id === fetch_animation.animation._id)
						{
							found = true
							break
						}
					}
					if (!found)
						animations.push(fetch_animation.animation)
					this.setState({ animations: animations, animation_dates: dates })
					resolve(fetch_animation)
				}
				else
				{
					console.log("false", fetch_animation)
					resolve(fetch_animation)
				}
			})
			.catch(err => {
				reject(err)
				console.log(err)
			})
		})
	}

	promiseCategoriesByGroup = {}

	fetchCategoriesByGroup = (group) => {
		if (Object.keys(this.promiseCategoriesByGroup).indexOf(group) !== -1)
			return this.promiseCategoriesByGroup[group]
		else
		{
			const requrl = tools.queryString(connexion.connect.url + 'webapp/categories/find-by-group', {
				group: group
			})
			console.log("fetch categories by group", group)
			this.promiseCategoriesByGroup[group] = new Promise((resolve, reject) => {
				fetch(requrl)
				.then(res => res.json())
				.then(cats => {
					let categories = (this.state.categories) ? this.state.categories : []
					for (let i = 0; i < cats.length; i++)
					{
						let j = 0
						let found = false
						while (j < categories.length)
						{
							if (cats[i]._id === categories[j]._id)
							{
								found = true
								break
							}
							j++
						}
						if (!found)
							categories.push(cats[i])
						else
							categories[j] = cats[i]
					}
					categories.sort((a, b) => {
						return a.order - b.order
					})
					this.setState({ categories: categories })
					resolve(categories)
				})
				.catch(err => {
					reject(err)
					console.log(err)
				})
			})
			const ret = this.promiseCategoriesByGroup[group]
			delete this.promiseCategoriesByGroup[group]
			return ret
		}
	}

	promiseCategoryByID = {}

	fetchCategoryByID = (id) => {
		if (Object.keys(this.promiseCategoryByID).indexOf(id) !== -1)
			return this.promiseCategoryByID[id]
		else
		{
			console.log("fetch category by id", id)
			const requrl = tools.queryString(connexion.connect.url + 'webapp/categories/find', {
				id: id
			})
			this.promiseCategoryByID[id] = new Promise((resolve, reject) => {
				fetch(requrl)
				.then(res => res.json())
				.then(category => {
					let found = false
					let categories = (this.state.categories) ? this.state.categories : []
					for (let i = 0; i < categories.length; i++)
					{
						if (categories[i]._id === category._id)
						{
							found = true
							break
						}
					}
					if (!found)
					{
						categories.push(category)
						categories.sort((a, b) => {
							return a.order - b.order
						})
						this.setState({ categories: categories })
					}
					resolve(category)
				})
				.catch(err => {
					reject(err)
					console.log(err)
				})
			})
			const ret = this.promiseCategoryByID[id]
			delete this.promiseCategoryByID[id]
			return ret
		}
	}

	promiseConversationByUser = {}

	fetchConversationsByUser = (user) => {
		if (Object.keys(this.promiseConversationByUser).indexOf(user) !== -1)
			return this.promiseConversationByUser[user]
		else
		{
			// const requrl = tools.queryString(connexion.connect.url + 'webapp/conversations/find-by-user', {
			// 	user: user
			// })
			console.log("fetch conversation by user", user)
			this.promiseConversationByUser[user] = new Promise((resolve, reject) => {
				fetch(connexion.connect.url + 'webapp/conversations/find-by-user', {
					method: 'POST',
					body: JSON.stringify({
						user: user
					})
				})
				.then(res => res.json())
				.then(conversation => {
					if (conversation)
					{
						let found = false
						let conversations = (this.state.conversations) ? this.state.conversations : []
						let i = 0
						while (i < conversations.length)
						{
							if (conversations[i]._id === conversation._id)
							{
								found = true
								break
							}
							i++
						}
						if (!found)
							conversations.push(conversation)
						else
							conversations[i] = conversation
						this.setState({ conversations: conversations })
					}
					resolve(conversation)
				})
				.catch(err => {
					reject(err)
					console.log(err)
				})
			})
			const ret = this.promiseConversationByUser[user]
			delete this.promiseConversationByUser[user]
			return ret
		}
	}

	fetchCookie = () => {
		let token = Cookies.get('session') // return undefined or session id
		if (!token)
			token = localStorage.getItem("session") // return "null" or session id
		if (token !== "null" && token !== undefined)
		{
			console.log("fetch cookie")
			const requrl = tools.queryString(connexion.connect.url + 'webapp/users/session', {
				token: token
			})
			fetch(requrl)
			.then(res => res.json())
			.then(session => {
				if (session !== false)
				{
					let user = (session.user) ? session.user : session.device.user
					let device = (session.device) ? session.device : null
					if (this.state.langs) // Si state.langs n'est pas chargé, la config n'est pas chargée
					{
						let found = false
						let found_en = false
						for (let i = 0; i < this.state.langs.length; i++)
						{
							if (this.state.langs[i] === user.langage)
								found = true
							else if (this.state.langs[i] === "en")
								found_en = true
						}
						if (!found)
						{
							user.langage = (found_en) ? "en" : this.state.lang_default
							this.updateUserLang(user)
							.then(ret => {
								user.lang = user.langage
								user.from = 'webapp'
								this.setState({ user: user, session: session, lang_assets: this.lang_assets[user.lang], device: device })
							})
							.catch(err => {
								// this.setErrorMsg("Oups, une erreur s'est produite.")
								console.log(err)
							})
						}
						else
						{
							user.lang = user.langage
							user.from = 'webapp'
							this.setState({ user: user, session: session, lang_assets: this.lang_assets[user.lang], device: device })
						}
					}
					else
					{
						user.lang = user.langage
						user.from = 'webapp'
						this.setState({ user: user, session: session, lang_assets: this.lang_assets[user.lang] })
					}
				}
				else
				{
					const requrl = tools.queryString(connexion.connect.url + 'dashboard/users/session', {
						token: token
					})
					fetch(requrl)
					.then(res => res.json())
					.then(session => {
						if (session !== false)
						{
							let user = session.user
							let found = false
							for (let i = 0; i < this.state.langs.length; i++)
							{
								if (this.state.langs[i] === user.langage)
								{
									found = true
									break
								}
							}
							if (!found)
							{
								user.langage = this.state.lang_default
								this.updateUserDashboardLang(user)
								.then(ret => {
									user.lang = user.langage
									user.from = 'dashboard'
									this.setState({ user: user, session: session, lang_assets: this.lang_assets[user.lang] })
								})
								.catch(err => {
									this.setErrorMsg("Oups, une erreur s'est produite.")
									console.log(err)
								})
							}
							else
							{
								user.lang = user.langage
								user.from = 'dashboard'
								this.setState({ user: user, session: session, lang_assets: this.lang_assets[user.lang] })
							}
						}
						else
						{
							Cookies.remove('session', { path: '' })
							localStorage.setItem("session", null)
							this.setState({ user: null, session: null })
						}
					})
					.catch(err => {
						this.setState({ session: null })
						console.log(err)
					})
				}
			})
			.catch(err => {
				this.setState({ user: { lastname: '?', firstname: '?', sexe: undefined }, session: '?' })
				console.log(err)
			})
		}
		else
			this.setState({ session: null })
	}

	fetchColors = () => {
		console.log('fetch theme')
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/config/theme')
			.then(res => res.json())
			.then(theme => {
				this.setState({ theme: theme })
				resolve(theme)
			})
			.catch(err => {
				console.log('Can not retrieve theme config', err)
				reject(err)
			})
		})
	}

	fetchConfig = () => {
		console.log('fetch config')
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/config/global/list')
			.then(res => res.json())
			.then(config => {
				let activation_code_specials = null
				let app_name = null
				let app_type = null
				let app_url = null
				let application_ouverte = null
				let features = null
				let langs = null
				let lang_default = null
				let background = null
				let welcome = []
				let show_stay_dates = null
				let show_stay_location = null
				for (let i = 0; i < config.length; i++)
				{
					if (config[i].key === "establishment")
						app_name = config[i].value
					else if (config[i].key === "establishment-type")
						app_type = config[i].value
					else if (config[i].key === "features")
						features = config[i].value
					else if (config[i].key === "langs")
						langs = config[i].value
					else if (config[i].key === "lang-default")
						lang_default = config[i].value
					else if (config[i].key === "activation-code-specials")
						activation_code_specials = config[i].value
					else if (config[i].key === "application-ouverte")
						application_ouverte = config[i].value
					else if (config[i].key === "background-img")
						background = config[i].value
					else if (config[i].key === "welcome-txt")
						welcome = config[i].value
					else if (config[i].key === "app-url")
						app_url = config[i].value
					else if (config[i].key === "show-stay-dates")
						show_stay_dates = config[i].value
					else if (config[i].key === "show-stay-location")
						show_stay_location = config[i].value
				}
				this.setState({
					app_name: app_name,
					app_type: app_type,
					app_url: app_url,
					application_ouverte: application_ouverte,
					features: features,
					langs: langs,
					lang_default: lang_default,
					activation_code_specials: activation_code_specials,
					background_img: background,
					welcome_txt: welcome,
					show_stay_dates: show_stay_dates,
					show_stay_location: show_stay_location
				})
				resolve(app_name)
			})
			.catch(err => {
				console.log(err)
				reject(err)
			})
		})
	}

	fetchConfigDeposit = () => {
		console.log('fetch config deposit')
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/config/deposit')
			.then(res => res.json())
			.then(config => {
				resolve(config)
				this.setState({ config_deposit: config })
			})
			.catch(err => {
				reject(err)
				console.log(err)
			})
		})
	}

	fetchConfigInventory = () => {
		console.log('fetch config inventory')
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/config/inventory/list')
			.then(res => res.json())
			.then(config => {
				resolve(config)
				this.setState({ config_inventory: config })
			})
			.catch(err => {
				reject(err)
				console.log(err)
			})
		})
	}

	fetchConfigPms = () => {
		console.log("fetch config pms")
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/config/pms')
			.then(res => res.json())
			.then(config => {
				this.setState({ config_pms: config })
				resolve(config)
			})
			.catch(err => {
				reject(err)
				console.log(err)
			})
		})
	}

	promiseConversationByID = {}

	fetchConversationByID = (id) => {
		if (Object.keys(this.promiseConversationByID).indexOf(id) !== -1)
			return this.promiseConversationByID[id]
		else
		{
			console.log("fetch conversation by id", id)
			this.promiseConversationByID[id] = new Promise((resolve, reject) => {
				fetch(connexion.connect.url + 'webapp/conversations/find', {
					method: 'POST',
					body: JSON.stringify({
						id: id
					})
				})
				.then(res => res.json())
				.then(conversation => {
					if (conversation)
					{
						let found = false
						let conversations = (this.state.conversations) ? this.state.conversations : []
						for (let i = 0; i < conversations.length; i++)
						{
							if (conversations[i]._id === conversation._id)
							{
								found = i
								break
							}
						}
						if (found === false)
						{
							conversations.push(conversation)
							this.setState({ conversations: conversations })
						}
					}
					resolve(conversation)
				})
				.catch(err => {
					reject(err)
					console.log(err)
				})
			})
			const ret = this.promiseConversationByID[id]
			delete this.promiseConversationByID[id]
			return ret
		}
	}

	fetchDepositLink = () => {
		console.log('fetch deposit link')
		return new Promise((resolve, reject) => {
			const requrl = tools.queryString(connexion.connect.url + 'webapp/deposits/getlink', {
				user: this.state.user._id,
				stay_number: this.state.user.stay.stay_number
			})
			fetch(requrl)
			.then(res => res.json())
			.then(deposit => {
				resolve(deposit)
				this.setState({ deposit: deposit })
			})
			.catch(err => {
				reject(err)
				console.log(err)
			})
		})
	}

	fetchGroups = () => {
		console.log('fetch groups')
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/groups/list')
			.then(res => res.json())
			.then(groups => {
				this.setState({ groups: groups })
				resolve(groups)
			})
			.catch(err => {
				console.log("could not retrieve groups", err)
				reject(err)
			})
		})
	}

	fetchIcons = () => {
		const theme = this.state.theme
		if (theme)
		{
			return new Promise((resolve, reject) => {
				console.log('fetch icons by theme', theme._id)
				const requrl = tools.queryString(connexion.connect.url + 'dashboard/icons/find-by-theme', {
					theme: theme._id
				})
				fetch(requrl)
				.then(res => res.json())
				.then(icons => {
					this.setState({ icons: icons })
					resolve(icons)
				})
				.catch(err => {
					console.log(err)
					reject(err)
				})
			})
		}
		else
			console.log("could not fetch icons, no theme is set")
	}

	fetchInventory = (stay_number, type, location_category) => {
		console.log('fetch inventory', type, stay_number, location_category)
		return new Promise((resolve, reject) => {
			const requrl = tools.queryString(connexion.connect.url + 'dashboard/inventories/find-by-location-category', {
				stay_number: stay_number,
				type: type,
				location_category: location_category
			})
			fetch(requrl)
			.then(res => res.json())
			.then(inventory => {
				resolve(inventory)
			})
			.catch(err => {
				reject(err)
				console.log(err)
			})
		})
	}

	promiseListByCategory = {}

	fetchListByCategory = (category) => {
		if (Object.keys(this.promiseListByCategory).indexOf(category) !== -1)
			return this.promiseListByCategory[category]
		else
		{
			const requrl = tools.queryString(connexion.connect.url + 'webapp/lists/find-by-category', {
				category: category
			})
			console.log("fetch list by category", category)
			this.promiseListByCategory[category] = new Promise((resolve, reject) => {
				fetch(requrl)
				.then(res => res.json())
				.then(list => {
					if (list)
					{
						let found = false
						let lists = (this.state.lists) ? this.state.lists : []
						let i = 0
						while (i < lists.length)
						{
							if (lists[i]._id === list._id)
							{
								found = true
								break
							}
							i++
						}
						if (!found)
							lists.push(list)
						else
							lists[i] = list
						this.setState({ lists: lists })
					}
					resolve(list)
				})
				.catch(err => {
					reject(err)
					console.log(err)
				})
			})
			const ret = this.promiseListByCategory[category]
			delete this.promiseListByCategory[category]
			return ret
		}
	}

	promiseListByID = {}

	fetchListByID = (id) => {
		if (Object.keys(this.promiseListByID).indexOf(id) !== -1)
			return this.promiseListByID[id]
		else
		{
			const requrl = tools.queryString(connexion.connect.url + 'webapp/lists/find', {
				id: id
			})
			console.log("fetch list by id", id)
			this.promiseListByID[id] = new Promise((resolve, reject) => {
				fetch(requrl)
				.then(res => res.json())
				.then(list => {
					if (list)
					{
						let found = false
						let lists = (this.state.lists) ? this.state.lists : []
						for (let i = 0; i < lists.length; i++)
						{
							if (lists[i]._id === list._id)
							{
								found = i
								break
							}
						}
						if (found === false)
						{
							lists.push(list)
							this.setState({ lists: lists })
						}
						else if (found !== false && !lists[found].cover)
						{
							lists[found].cover = list.cover
							this.setState({ lists: lists })
						}
					}
					resolve(list)
				})
				.catch(err => {
					reject(err)
					console.log(err)
				})
			})
			const ret = this.promiseListByID[id]
			delete this.promiseListByID[id]
			return ret
		}
	}

	promiseCheckUpdate = {}

	fetchListCheckUpdate = (list) => {
		if (!list._id || !list.timestamp)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in fetch list check update", list)
				reject(false)
			})
		}
		else
		{
			console.log("fetch list check update", list._id)
			this.promiseCheckUpdate[list._id] = new Promise((resolve, reject) => {
				fetch(connexion.connect.url + 'webapp/lists/check-update', {
					method: 'POST',
					body: JSON.stringify({
						id: list._id,
						timestamp: list.timestamp
					})
				})
				.then(res => res.json())
				.then(fetch_list => {
					if (fetch_list === 1)
						resolve(1)
					else if (fetch_list !== false)
					{
						let lists = (this.state.lists) ? this.state.lists : []
						for (let i = 0; i < lists.length; i++)
						{
							if (String(lists[i]._id) === String(fetch_list._id))
							{
								lists[i] = fetch_list
								break
							}
						}
						this.setState({ lists: lists })
						resolve(fetch_list)
					}
					else
						resolve(list)
				})
				.catch(err => {
					reject(err)
					console.log(err)
				})
			})
			const ret = this.promiseCheckUpdate[list._id]
			delete this.promiseCheckUpdate[list._id]
			return ret
		}
	}

	fetchLogo = () => {
		console.log('fetch logo')
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/config/logo')
			.then(res => res.json())
			.then(logo => {
				this.setState({ logo: logo })
				resolve(logo)
			})
			.catch(err => {
				console.log('Can not retrieve logo config', err)
				reject(err)
			})
		})
	}

	promiseAnimationsByDate = {}

	fetchAnimationsByDate = (date, date_end = '') => {
		if (Object.keys(this.promiseAnimationsByDate).indexOf(date) !== -1)
			return this.promiseAnimationsByDate[date]
		else
		{
			const requrl = tools.queryString(connexion.connect.url + 'webapp/animations/find-by-date', {
				date: date,
				date_end: date_end
			})
			console.log("fetch animations by date", date)
			this.promiseAnimationsByDate[date] = new Promise((resolve, reject) => {
				fetch(requrl)
				.then(res => res.json())
				.then(ret => {
					if (ret)
					{
						let dates = (this.state.dates) ? this.state.dates : []
						for (let i = 0; i < ret.dates.length; i++)
						{
							let found = false
							for (let j = 0; j < dates.length; j++)
							{
								if (ret.dates[i]._id === dates[j]._id)
								{
									found = true
									break
								}
							}
							if (!found)
								dates.push(ret.dates[i])
						}
						let animations = (this.state.animations) ? this.state.animations : []
						for (let i = 0; i < ret.animations.length; i++)
						{
							let found = false
							for (let j = 0; j < animations.length; j++)
							{
								if (ret.animations[i]._id === animations[j]._id)
								{
									found = true
									break
								}
							}
							if (!found)
								animations.push(ret.animations[i])
						}
						this.setState({ animations: animations, animation_dates: dates })
					}
					resolve(ret)
				})
				.catch(err => {
					reject(err)
					console.log(err)
				})
			})
			const ret = this.promiseAnimationsByDate[date]
			delete this.promiseAnimationsByDate[date]
			return ret
		}
	}

	fetchModules = () => {
		console.log('fetch modules')
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/modules/list')
			.then(res => res.json())
			.then(modules => {
				modules.sort((a, b) => {
					return a.order - b.order
				})
				resolve(modules)
				this.setState({ modules: modules })
			})
			.catch(err => {
				reject(err)
				console.log(err)
			})
		})
	}

	fetchNotifications = () => {
		return new Promise((resolve, reject) => {
			let obj_req = {}
			if (this.state.user.from === 'webapp')
				obj_req = { user: this.state.user._id }
			console.log("fetch notifications by user", obj_req)
			const requrl = tools.queryString(connexion.connect.url + 'webapp/notification_msg/find-by-user', obj_req)
			fetch(requrl)
			.then(res => res.json())
			.then(notifications => {
				resolve(notifications)
				this.setState({ notifications: notifications })
			})
			.catch(err => {
				console.log(err)
				reject(err)
			})
		})
	}

	promisePageByCategory = {}

	fetchPageByCategory = (category) => {
		if (Object.keys(this.promisePageByCategory).indexOf(category) !== -1)
			return this.promisePageByCategory[category]
		else
		{
			const requrl = tools.queryString(connexion.connect.url + 'webapp/pages/find-by-category', {
				category: category
			})
			console.log("fetch page by category", category)
			this.promisePageByCategory[category] = new Promise((resolve, reject) => {
				fetch(requrl)
				.then(res => res.json())
				.then(page => {
					if (page)
					{
						let found = false
						let pages = (this.state.pages) ? this.state.pages : []
						for (let i = 0; i < pages.length; i++)
						{
							if (pages[i]._id === page._id)
							{
								found = i
								break
							}
						}
						if (found === false)
						{
							pages.push(page)
							this.setState({ pages: pages })
						}
						else if (found !== false && !pages[found].cover)
						{
							pages[found].cover = page.cover
							this.setState({ pages: pages })
						}
					}
					resolve(page)
				})
				.catch(err => {
					reject(err)
					console.log(err)
				})
			})
			const ret = this.promisePageByCategory[category]
			delete this.promisePageByCategory[category]
			return ret
		}
	}

	promisePageByID = {}

	fetchPageByID = (id) => {
		if (Object.keys(this.promisePageByID).indexOf(id) !== -1)
			return this.promisePageByID[id]
		else
		{
			const requrl = tools.queryString(connexion.connect.url + 'webapp/pages/find', {
				id: id
			})
			console.log("fetch page by id", id)
			this.promisePageByID[id] = new Promise((resolve, reject) => {
				fetch(requrl)
				.then(res => res.json())
				.then(page => {
					if (page)
					{
						let found = false
						let pages = (this.state.pages) ? this.state.pages : []
						for (let i = 0; i < pages.length; i++)
						{
							if (pages[i]._id === page._id)
							{
								found = i
								break
							}
						}
						if (found === false)
						{
							pages.push(page)
							this.setState({ pages: pages })
						}
						else if (found !== false && !pages[found].cover)
						{
							pages[found].cover = page.cover
							this.setState({ pages: pages })
						}
					}
					resolve(page)
				})
				.catch(err => {
					reject(err)
					console.log(err)
				})
			})
			const ret = this.promisePageByID[id]
			delete this.promisePageByID[id]
			return ret
		}
	}

	promisePagesByIDList = {}

	fetchPagesByIDList = (id_list) => {
		const key = JSON.stringify(id_list)
		if (Object.keys(this.promisePagesByIDList).indexOf(key) !== -1)
			return this.promisePagesByIDList[key]
		else
		{
			const requrl = tools.queryString(connexion.connect.url + 'webapp/pages/find-many', {
				pages: id_list,
				nocover: 1
			})
			console.log("fetch pages many", id_list)
			this.promisePagesByIDList[key] = new Promise((resolve, reject) => {
				fetch(requrl)
				.then(res => res.json())
				.then(ret => {
					let pages = (this.state.pages) ? this.state.pages : []
					if (ret)
					{
						for (let i = 0; i < ret.length; i++)
						{
							let found = false
							for (let j = 0; j < pages.length; j++)
							{
								if (ret[i]._id === pages[j]._id)
								{
									found = true
									break
								}
							}
							if (!found)
								pages.push(ret[i])
						}
						this.setState({ pages: pages })
					}
					resolve(pages)
				})
				.catch(err => {
					reject(err)
					console.log(err)
				})
			})
			const ret = this.promisePagesByIDList[key]
			delete this.promisePagesByIDList[key]
			return ret
		}
	}

	promisePageByURL = {}

	fetchPageByURL = (url) => {
		if (Object.keys(this.promisePageByURL).indexOf(url) !== -1)
			return this.promisePageByURL[url]
		else
		{
			const requrl = tools.queryString(connexion.connect.url + 'webapp/pages/find-by-url', {
				url: url,
				lang: this.state.user.lang
			})
			console.log("fetch page by url", url)
			this.promisePageByURL[url] = new Promise((resolve, reject) => {
				fetch(requrl)
				.then(res => res.json())
				.then(page => {
					if (page)
					{
						let found = false
						let pages = (this.state.pages) ? this.state.pages : []
						for (let i = 0; i < pages.length; i++)
						{
							if (pages[i]._id === page._id)
							{
								found = i
								break
							}
						}
						if (found === false)
						{
							pages.push(page)
							this.setState({ pages: pages })
						}
						else if (found !== false && !pages[found].cover)
						{
							pages[found].cover = page.cover
							this.setState({ pages: pages })
						}
					}
					resolve(page)
				})
				.catch(err => {
					reject(err)
					console.log(err)
				})
			})
			const ret = this.promisePageByURL[url]
			delete this.promisePageByURL[url]
			return ret
		}
	}

	promisePageCheckUpdate = {}

	fetchPageCheckUpdate = (page) => {
		if (!page._id || !page.timestamp)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in fetch page check update", page)
				reject(false)
			})
		}
		else
		{
			console.log("fetch page check update", page._id)
			this.promisePageCheckUpdate[page._id] = new Promise((resolve, reject) => {
				fetch(connexion.connect.url + 'webapp/pages/check-update', {
					method: 'POST',
					body: JSON.stringify({
						id: page._id,
						timestamp: page.timestamp
					})
				})
				.then(res => res.json())
				.then(fetch_page => {
					if (fetch_page === 1)
						resolve(1)
					else if (fetch_page !== false)
					{
						let pages = (this.state.pages) ? this.state.pages : []
						for (let i = 0; i < pages.length; i++)
						{
							if (String(pages[i]._id) === String(fetch_page._id))
							{
								pages[i] = fetch_page
								break
							}
						}
						this.setState({ pages: pages })
						resolve(fetch_page)
					}
					else
						resolve(page)
				})
				.catch(err => {
					reject(err)
					console.log(err)
				})
			})
			const ret = this.promisePageCheckUpdate[page._id]
			delete this.promisePageCheckUpdate[page._id]
			return ret
		}
	}

	promiseReviewByURL = {}

	fetchReviewByURL = (url, hash, client = false) => {
		const id = url + ':' + hash
		if (Object.keys(this.promiseReviewByURL).indexOf(id) !== -1)
			return this.promiseReviewByURL[id]
		else
		{
			let requrl = null
			if (client === true)
			{
				requrl = tools.queryString(connexion.connect.url + 'webapp/reviews/find-by-url', {
					hash: hash
				})
			}
			else
			{
				requrl = tools.queryString(connexion.connect.url + 'webapp/reviews/find-by-url', {
					url: url,
					hash: hash,
					user: this.state.user._id
				})
			}
			console.log("fetch review by url", url, hash, client)
			this.promiseReviewByURL[id] = new Promise((resolve, reject) => {
				fetch(requrl)
				.then(res => res.json())
				.then(review => {
					if (review)
					{
						let found = false
						let reviews = (this.state.reviews) ? this.state.reviews : []
						for (let i = 0; i < reviews.length; i++)
						{
							if (reviews[i]._id === review._id)
							{
								found = i
								break
							}
						}
						if (found === false)
						{
							reviews.push(review)
							this.setState({ reviews: reviews })
						}
					}
					resolve(review)
				})
				.catch(err => {
					reject(err)
					console.log(err)
				})
			})
			const ret = this.promiseReviewByURL[id]
			delete this.promiseReviewByURL[id]
			return ret
		}
	}

	fetchServiceBenefits = () => {
		console.log('fetch service_benefits')
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/service_benefits/list')
			.then(res => res.json())
			.then(service_benefits => {
				resolve(service_benefits)
				this.setState({ service_benefits: service_benefits })
			})
			.catch(err => {
				reject(err)
				console.log(err)
			})
		})
	}

	promiseServiceBenefitsByService = {}

	fetchServiceBenefitsByService = (service) => {
		if (Object.keys(this.promiseServiceBenefitsByService).indexOf(service) !== -1)
			return this.promiseServiceBenefitsByService[service]
		else
		{
			const requrl = tools.queryString(connexion.connect.url + 'dashboard/service_benefits/find-by-service', {
				service: service
			})
			console.log("fetch service_benefit by service", service)
			this.promiseServiceBenefitsByService[service] = new Promise((resolve, reject) => {
				fetch(requrl)
				.then(res => res.json())
				.then(ret => {
					let service_benefits = (this.state.service_benefits) ? this.state.service_benefits : []
					if (ret)
					{
						for (let i = 0; i < ret.length; i++)
						{
							let found = false
							for (let j = 0; j < service_benefits.length; j++)
							{
								if (ret[i]._id === service_benefits[j]._id)
								{
									found = true
									break
								}
							}
							if (!found)
								service_benefits.push(ret[i])
						}
						this.setState({ service_benefits: service_benefits })
					}
					resolve(ret)
				})
				.catch(err => {
					reject(err)
					console.log(err)
				})
			})
			const ret = this.promiseServiceBenefitsByService[service]
			delete this.promiseServiceBenefitsByService[service]
			return ret
		}
	}

	promiseServiceByID = {}

	fetchServiceByID = (id) => {
		if (Object.keys(this.promiseServiceByID).indexOf(id) !== -1)
			return this.promiseServiceByID[id]
		else
		{
			const requrl = tools.queryString(connexion.connect.url + 'dashboard/services/find', {
				id: id
			})
			console.log("fetch service by id", id)
			this.promiseServiceByID[id] = new Promise((resolve, reject) => {
				fetch(requrl)
				.then(res => res.json())
				.then(service => {
					if (service)
					{
						let found = false
						let services = (this.state.services) ? this.state.services : []
						for (let i = 0; i < services.length; i++)
						{
							if (services[i]._id === service._id)
							{
								found = i
								break
							}
						}
						if (found === false)
						{
							services.push(service)
							this.setState({ services: services })
						}
						else if (found !== false && !services[found].cover)
						{
							services[found].cover = service.cover
							this.setState({ services: services })
						}
					}
					resolve(service)
				})
				.catch(err => {
					reject(err)
					console.log(err)
				})
			})
			const ret = this.promiseServiceByID[id]
			delete this.promiseServiceByID[id]
			return ret
		}
	}

	promiseServiceByURL = {}

	fetchServiceByURL = (url) => {
		if (Object.keys(this.promiseServiceByURL).indexOf(url) !== -1)
			return this.promiseServiceByURL[url]
		else
		{
			const requrl = tools.queryString(connexion.connect.url + 'dashboard/services/find-by-url', {
				url: url,
				lang: this.state.user.lang
			})
			console.log("fetch service by url", url)
			this.promiseServiceByURL[url] = new Promise((resolve, reject) => {
				fetch(requrl)
				.then(res => res.json())
				.then(service => {
					if (service)
					{
						let found = false
						let services = (this.state.services) ? this.state.services : []
						for (let i = 0; i < services.length; i++)
						{
							if (services[i]._id === service._id)
							{
								found = i
								break
							}
						}
						if (found === false)
						{
							services.push(service)
							this.setState({ services: services })
						}
						else if (found !== false && !services[found].cover)
						{
							services[found].cover = service.cover
							this.setState({ services: services })
						}
					}
					resolve(service)
				})
				.catch(err => {
					reject(err)
					console.log(err)
				})
			})
			const ret = this.promiseServiceByURL[url]
			delete this.promiseServiceByURL[url]
			return ret
		}
	}

	promiseServiceProductsByService = {}

	fetchServiceProductsByService = (service) => {
		if (Object.keys(this.promiseServiceProductsByService).indexOf(service) !== -1)
			return this.promiseServiceProductsByService[service]
		else
		{
			const requrl = tools.queryString(connexion.connect.url + 'dashboard/service_products/find-by-service', {
				service: service
			})
			console.log("fetch service_products by service", service)
			this.promiseServiceProductsByService[service] = new Promise((resolve, reject) => {
				fetch(requrl)
				.then(res => res.json())
				.then(ret => {
					let service_products = (this.state.service_products) ? this.state.service_products : []
					if (ret)
					{
						for (let i = 0; i < ret.length; i++)
						{
							let found = false
							for (let j = 0; j < service_products.length; j++)
							{
								if (ret[i]._id === service_products[j]._id)
								{
									found = true
									break
								}
							}
							if (!found)
								service_products.push(ret[i])
						}
						this.setState({ service_products: service_products })
					}
					resolve(ret)
				})
				.catch(err => {
					reject(err)
					console.log(err)
				})
			})
			const ret = this.promiseServiceProductsByService[service]
			delete this.promiseServiceProductsByService[service]
			return ret
		}
	}

	promiseServiceReservationsByService = {}

	fetchServiceReservationsByService = (service) => {
		if (Object.keys(this.promiseServiceReservationsByService).indexOf(service) !== -1)
			return this.promiseServiceReservationsByService[service]
		else
		{
			const requrl = tools.queryString(connexion.connect.url + 'webapp/service_reservations/find-by-service', {
				service: service
			})
			console.log("fetch service_reservations by service", service)
			this.promiseServiceReservationsByService[service] = new Promise((resolve, reject) => {
				fetch(requrl)
				.then(res => res.json())
				.then(ret => {
					let service_reservations = (this.state.service_reservations) ? this.state.service_reservations : []
					if (ret)
					{
						for (let i = 0; i < ret.length; i++)
						{
							let found = false
							for (let j = 0; j < service_reservations.length; j++)
							{
								if (ret[i]._id === service_reservations[j]._id)
								{
									found = true
									break
								}
							}
							if (!found)
								service_reservations.push(ret[i])
						}
						this.setState({ service_reservations: service_reservations })
					}
					resolve(ret)
				})
				.catch(err => {
					reject(err)
					console.log(err)
				})
			})
			const ret = this.promiseServiceReservationsByService[service]
			delete this.promiseServiceReservationsByService[service]
			return ret
		}
	}

	promiseServiceReservationsByServiceList = {}

	fetchServiceReservationsByServiceList = (service_list) => {
		if (Object.keys(this.promiseServiceReservationsByServiceList).indexOf(service_list) !== -1)
			return this.promiseServiceReservationsByServiceList[service_list]
		else
		{
			const requrl = tools.queryString(connexion.connect.url + 'webapp/service_reservations/find-by-service-list', {
				service_list: service_list
			})
			console.log("fetch service_reservations by service_list", service_list)
			this.promiseServiceReservationsByServiceList[service_list] = new Promise((resolve, reject) => {
				fetch(requrl)
				.then(res => res.json())
				.then(ret => {
					let service_reservations = (this.state.service_reservations) ? this.state.service_reservations : []
					if (ret)
					{
						for (let i = 0; i < ret.length; i++)
						{
							let found = false
							for (let j = 0; j < service_reservations.length; j++)
							{
								if (ret[i]._id === service_reservations[j]._id)
								{
									found = true
									break
								}
							}
							if (!found)
								service_reservations.push(ret[i])
						}
						this.setState({ service_reservations: service_reservations })
					}
					resolve(ret)
				})
				.catch(err => {
					reject(err)
					console.log(err)
				})
			})
			const ret = this.promiseServiceReservationsByServiceList[service_list]
			delete this.promiseServiceReservationsByServiceList[service_list]
			return ret
		}
	}

	promiseServiceReservationsByUser = {}

	fetchServiceReservationsByUser = (user) => {
		if (Object.keys(this.promiseServiceReservationsByUser).indexOf(user) !== -1)
			return this.promiseServiceReservationsByUser[user]
		else
		{
			const requrl = tools.queryString(connexion.connect.url + 'webapp/service_reservations/find-by-user', {
				user: user
			})
			console.log("fetch service_reservations by user", user)
			this.promiseServiceReservationsByUser[user] = new Promise((resolve, reject) => {
				fetch(requrl)
				.then(res => res.json())
				.then(ret => {
					let service_reservations = (this.state.service_reservations) ? this.state.service_reservations : []
					if (ret)
					{
						for (let i = 0; i < ret.length; i++)
						{
							let found = false
							for (let j = 0; j < service_reservations.length; j++)
							{
								if (ret[i]._id === service_reservations[j]._id)
								{
									found = true
									break
								}
							}
							if (!found)
								service_reservations.push(ret[i])
						}
						this.setState({ service_reservations: service_reservations })
					}
					resolve(ret)
				})
				.catch(err => {
					reject(err)
					console.log(err)
				})
			})
			const ret = this.promiseServiceReservationsByUser[user]
			delete this.promiseServiceReservationsByUser[user]
			return ret
		}
	}

	promiseServiceWorkersByService = {}

	fetchServiceWorkersByService = (service) => {
		if (Object.keys(this.promiseServiceWorkersByService).indexOf(service) !== -1)
			return this.promiseServiceWorkersByService[service]
		else
		{
			const requrl = tools.queryString(connexion.connect.url + 'dashboard/service_workers/find-by-service', {
				service: service
			})
			console.log("fetch service_workers by service", service)
			this.promiseServiceWorkersByService[service] = new Promise((resolve, reject) => {
				fetch(requrl)
				.then(res => res.json())
				.then(ret => {
					let service_workers = (this.state.service_workers) ? this.state.service_workers : []
					if (ret)
					{
						for (let i = 0; i < ret.length; i++)
						{
							let found = false
							for (let j = 0; j < service_workers.length; j++)
							{
								if (ret[i]._id === service_workers[j]._id)
								{
									found = true
									break
								}
							}
							if (!found)
								service_workers.push(ret[i])
						}
						this.setState({ service_workers: service_workers })
					}
					resolve(ret)
				})
				.catch(err => {
					reject(err)
					console.log(err)
				})
			})
			const ret = this.promiseServiceWorkersByService[service]
			delete this.promiseServiceWorkersByService[service]
			return ret
		}
	}

	promiseServiceWorkersByServiceList = {}

	fetchServiceWorkersByServiceList = (service_list) => {
		if (Object.keys(this.promiseServiceWorkersByServiceList).indexOf(service_list) !== -1)
			return this.promiseServiceWorkersByServiceList[service_list]
		else
		{
			const requrl = tools.queryString(connexion.connect.url + 'dashboard/service_workers/find-by-service-list', {
				service_list: service_list
			})
			console.log("fetch service_workers by service_list", service_list)
			this.promiseServiceWorkersByServiceList[service_list] = new Promise((resolve, reject) => {
				fetch(requrl)
				.then(res => res.json())
				.then(ret => {
					let service_workers = (this.state.service_workers) ? this.state.service_workers : []
					if (ret)
					{
						for (let i = 0; i < ret.length; i++)
						{
							let found = false
							for (let j = 0; j < service_workers.length; j++)
							{
								if (ret[i]._id === service_workers[j]._id)
								{
									found = true
									break
								}
							}
							if (!found)
								service_workers.push(ret[i])
						}
						this.setState({ service_workers: service_workers })
					}
					resolve(ret)
				})
				.catch(err => {
					reject(err)
					console.log(err)
				})
			})
			const ret = this.promiseServiceWorkersByServiceList[service_list]
			delete this.promiseServiceWorkersByServiceList[service_list]
			return ret
		}
	}

	fetchServices = () => {
		console.log("fetch services")
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/services/list')
			.then(res => res.json())
			.then(services => {
				this.setState({ services: services })
				resolve(services)
			})
			.catch(err => {
				console.log(err)
				reject(err)
			})
		})
	}

	fetchVersion = () => {
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/config/version')
			.then(res => res.json())
			.then(version => {
				console.log('fetch version', version)
				let localVersion = localStorage.getItem("version")
				if (localVersion === null || localVersion !== version)
				{
					window.location.reload()
					localStorage.setItem("version", version)
				}
				resolve(version)
			})
			.catch(err => {
				console.log(err)
				reject(err)
			})
		})
	}
	
	/*
	**  END FETCH METHODS
	*/

	/*
	** POST METHODS
	*/

	handleInstallApp = (answer) => {
		if (answer === true)
		{
			this.installEvent.prompt()
			this.installEvent.userChoice
			.then(choice => {
				if (choice.outcome === "accepted")
				{
					fetch(connexion.connect.url + 'webapp/config/downloads/increase')
					.then(res => res.json())
					.then(ret => {
						if (ret !== true)
							console.log("could not increase downloads nb")
					})
					.catch(err => {
						console.log("could not make request for increase downloads", err)
					})
				}
				const user = {
					...this.state.user,
					promote_pwa: tools.getTimestamp()
				}
				this.setState({ user: user })
			})
			.catch(err => {
				console.log("can not catch user choice", err)
			})
			this.installEvent = undefined
		}
		else
		{
			const user = {
				...this.state.user,
				promote_pwa: tools.getTimestamp()
			}
			this.setState({ user: user })
		}
	}

	handleAcceptNotifications = (accept) => {
		const user = {
			...this.state.user,
			promote_notification: tools.getTimestamp()
		}
		if (accept === true)
		{
			return new Promise((resolve, reject) => {
				const permissionResult = Notification.requestPermission(result => {
					console.log("result accept notification", result)
					resolve(result)
				})
				if (permissionResult)
					permissionResult.then(resolve, reject)
			})
			.then(permissionResult => {
				if (permissionResult !== 'granted')
					throw new Error('We weren\'t granted permission.')
				this.setState({ user: user })
			})
		}
		this.setState({ user: user })
	}

	postCheckActivationCode = (code) => {
		if (!code || !code.length)
		{
			return new Promise((resolve, reject) => {
				console.log("miss code in check activation code", code)
				reject(false)
			})
		}
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/clients/activation-code', {
				method: 'POST',
				body: JSON.stringify({
					code: code
				})
			})
			.then(res => res.json())
			.then(client_number => {
				resolve(client_number)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	postCheckUserEmail = (email) => {
		if (!email || !email.length)
		{
			return new Promise((resolve, reject) => {
				console.log("miss email in check user email", email)
				reject(false)
			})
		}
		console.log("check user email", email)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/users/find-by-email', {
				method: 'POST',
				body: JSON.stringify({
					email: email
				})
			})
			.then(res => res.json())
			.then(user => {
				resolve(user)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	postCheckUserKoban = (email) => {
		if (!email || !email.length)
		{
			return new Promise((resolve, reject) => {
				console.log("miss email in check user koban", email)
				reject(false)
			})
		}
		console.log("check user email", email)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/users/find-koban', {
				method: 'POST',
				body: JSON.stringify({
					email: email
				})
			})
			.then(res => res.json())
			.then(user => {
				resolve(user)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	postClickNotification = (notification) => {
		if (!notification._id || !notification.from)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in click notification", notification)
				reject(false)
			})
		}
		console.log("click notification", notification)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/notification_msg/read', {
				method: 'POST',
				body: JSON.stringify({
					id: notification._id,
					from: notification.from
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let notifications = (this.state.notifications) ? tools.clone(this.state.notifications) : []
					for (let i = 0; i < notifications.length; i++)
					{
						if (notifications[i]._id === ret._id)
						{
							notifications[i] = ret
							break
						}
					}
					if (navigator && navigator.serviceWorker && navigator.serviceWorker.controller)
					{
						navigator.serviceWorker.controller.postMessage({
							type: 'notification_click',
							notification_id: notification._id
						})
					}
					this.setState({ notifications: notifications })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	postConnectUser = (user) => {
		if (!user.identifiant || !user.password)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in connect user", user)
				reject(false)
			})
		}
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/users/login', {
				method: 'POST',
				body: JSON.stringify({
					identifiant: user.identifiant,
					password: user.password
				})
			})
			.then(res => res.json())
			.then(user => {
				resolve(user)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	postDeleteServiceReservation = (service_reservation) => {
		if (!service_reservation)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in delete service reservation", service_reservation)
				reject(false)
			})
		}
		console.log("post delete service reservation", service_reservation)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/service_reservations/delete', {
				method: 'POST',
				body: JSON.stringify({
					id: service_reservation
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret === true)
				{
					let found = null
					const service_reservations = this.state.service_reservations
					for (let i = 0; i < service_reservations.length; i++)
					{
						if (service_reservations[i]._id === service_reservation)
						{
							found = i
							break
						}
					}
					service_reservations.splice(found, 1)
					resolve(true)
				}
				else
					reject(false)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	postInsertPicture = (picture) => {
		console.log("insert picture", picture)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/files/add-image', {
				method: 'POST',
				body: picture
			})
			.then(res => res.json())
			.then(ret => {
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	postInventory = (inventory) => {
		console.log("send inventory", inventory)
		return new Promise((resolve, reject) => {
			const comment = [{
				comment: inventory.comment
			}]
			fetch(connexion.connect.url + 'dashboard/inventories/insert', {
				method: 'POST',
				body: JSON.stringify({
					answers: inventory.answers,
					pictures: inventory.pictures,
					comment: comment,
					type: inventory.type,
					user: inventory.user,
					stay_number: inventory.stay_number
				})
			})
			.then(res => res.json())
			.then(ret => {
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	postRequestActivationCode = (email) => {
		console.log("request activation code", email)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/users/send-activation-code', {
				method: 'POST',
				body: JSON.stringify({
					email: email
				})
			})
			.then(res => res.json())
			.then(ret => {
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	postResetClient = (activation_code, user) => {
		console.log("reset client", user)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/users/reset-client', {
				method: 'POST',
				body: JSON.stringify({
					activation_code: activation_code,
					user: user
				})
			})
			.then(res => res.json())
			.then(ret => {
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	postRetrieveUserByHash = (hash) => {
		if (!hash)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in retrieve user by hash", hash)
				reject(false)
			})
		}
		console.log("post retrieve user by hash", hash)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/users/retrieve-password', {
				method: 'POST',
				body: JSON.stringify({
					code: hash
				})
			})
			.then(res => res.json())
			.then(ret => {
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	postServiceReservation = (service_reservation) => {
		if (!service_reservation.service || !service_reservation.service_benefit || !service_reservation.start || !service_reservation.end ||
			!service_reservation.service_worker || !service_reservation.user || (!service_reservation.self && service_reservation.self !== false) ||
			!service_reservation.participants)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in post service_reservation", service_reservation)
				reject(false)
			})
		}
		console.log("submit service_reservation", service_reservation)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/service_reservations/submit', {
				method: 'POST',
				body: JSON.stringify({
					from: "webapp",
					id: service_reservation._id,
					service: service_reservation.service,
					service_benefit: service_reservation.service_benefit,
					service_worker: service_reservation.service_worker,
					start: service_reservation.start,
					end: service_reservation.end,
					client_number: service_reservation.client_number,
					identity: service_reservation.identity,
					age: service_reservation.age,
					phone: service_reservation.phone,
					answers: service_reservation.answers,
					user: service_reservation.user,
					self: service_reservation.self,
					participants: service_reservation.participants
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let service_reservations = (this.state.service_reservations) ? tools.clone(this.state.service_reservations) : []
					for (let i = 0; i < service_reservations.length; i++)
					{
						if (service_reservations[i]._id === ret._id)
						{
							service_reservations[i] = ret
							break
						}
					}
					this.setState({ service_reservations: service_reservations })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	postServiceReservationCart = (service_reservation) => {
		if (!service_reservation.service || !service_reservation.cart || !service_reservation.start || !service_reservation.end || !service_reservation.user)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in post service_reservation cart", service_reservation)
				reject(false)
			})
		}
		console.log("submit service_reservation", service_reservation)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/service_reservations/submit-cart', {
				method: 'POST',
				body: JSON.stringify({
					from: "webapp",
					id: service_reservation._id,
					service: service_reservation.service._id,
					cart: service_reservation.cart,
					start: service_reservation.start,
					end: service_reservation.end,
					client_number: service_reservation.client_number,
					identity: service_reservation.identity,
					age: service_reservation.age,
					phone: service_reservation.phone,
					user: service_reservation.user
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let service_reservations = (this.state.service_reservations) ? tools.clone(this.state.service_reservations) : []
					for (let i = 0; i < service_reservations.length; i++)
					{
						if (service_reservations[i]._id === ret._id)
						{
							service_reservations[i] = ret
							break
						}
					}
					this.setState({ service_reservations: service_reservations })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	postSubmitConversation = (conversation) => {
		if (!conversation.user || !conversation.title)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in submit conversation", conversation)
				reject(false)
			})
		}
		console.log("submit conversation", conversation)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/conversations/insert', {
				method: 'POST',
				body: JSON.stringify({
					title: conversation.title,
					type: conversation.type,
					user: conversation.user
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let conversations = (this.state.conversations) ? tools.clone(this.state.conversations) : []
					for (let i = 0; i < conversations.length; i++)
					{
						if (conversations[i]._id === ret._id)
						{
							conversations[i] = ret
							break
						}
					}
					this.setState({ conversations: conversations })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	postSubmitMessage = (message) => {
		if (!message.conversation || !message.message)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in submit message", message)
				reject(false)
			})
		}
		console.log("submit message", message)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/messages/insert', {
				method: 'POST',
				body: JSON.stringify({
					message: message.message,
					conversation: message.conversation,
					user: message.user
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let messages = (this.state.messages) ? tools.clone(this.state.messages) : []
					messages.push(ret)
					// for (let i = 0; i < messages.length; i++)
					// {
					// 	if (messages[i]._id === ret._id)
					// 	{
					// 		messages[i] = ret
					// 		break
					// 	}
					// }
					this.setState({ messages: messages })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	postSubmitReview = (review) => {
		if (!review._id || !review.answers)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in click review", review)
				reject(false)
			})
		}
		console.log("submit review", review)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/reviews/submit', {
				method: 'POST',
				body: JSON.stringify({
					id: review._id,
					answers: review.answers
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let reviews = (this.state.reviews) ? tools.clone(this.state.reviews) : []
					for (let i = 0; i < reviews.length; i++)
					{
						if (reviews[i]._id === ret._id)
						{
							reviews[i] = ret
							break
						}
					}
					this.setState({ reviews: reviews })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	postSubscribeUser = (user) => {
		if (!user.lastname || !user.firstname || !user.email ||
			(!user.birthday && user.birthday !== null) || (!user.civility && user.civility !== null) || !user.password)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in subscribe user", user)
				reject(false)
			})
		}
		console.log("post subscribe user", user)
		const user_obj = {
			birthday: user.birthday,
			civility: user.civility,
			client_number: user.client_number,
			email: user.email,
			password: user.password,
			lastname: user.lastname,
			firstname: user.firstname,
			company: user.company,
			phone: user.phone,
			arrival: user.arrival,
			departure: user.departure,
			user_type: user.client_type,
			street: user.street
		}
		if (user.user_type && user.user_type === "primary")
		{
			user_obj.user_type = user.user_type
			user_obj.nb_participants = user.nb_participants
			user_obj.participant_emails = user.participant_emails
		}
		else if (user.user_type && user.user_type === "participant")
		{
			user_obj.user_type = user.user_type
			user_obj.participant_type = user.participant_type
			user_obj.nb_primary = user.nb_primary
			user_obj.primary_emails = user.primary_emails
		}
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/users/insert', {
				method: 'POST',
				body: JSON.stringify(user_obj)
			})
			.then(res => res.json())
			.then(ret => {
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	postUpdateBirthday = (user) => {
		if (!user._id || !user.birthday)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update birthday", user)
				reject(false)
			})
		}
		console.log("post update birthday", user)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/users/update-birthday', {
				method: 'POST',
				body: JSON.stringify({
					id: user._id,
					birthday: user.birthday
				})
			})
			.then(res => res.json())
			.then(ret => {
				const user = {
					...this.state.user,
					birthday: ret.birthday
				}
				this.setState({ user: user })
				resolve(user)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	postUpdateEmail = (user) => {
		if (!user._id || !user.email || !user.firstname || !user.lastname)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update email", user)
				reject(false)
			})
		}
		console.log("post update email", user)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/users/update-email', {
				method: 'POST',
				body: JSON.stringify({
					id: user._id,
					email: user.email,
					firstname: user.firstname,
					lastname: user.lastname
				})
			})
			.then(res => res.json())
			.then(ret => {
				resolve(user)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	postUpdatePassword = (user) => {
		if (!user._id || !user.password)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update password", user)
				reject(false)
			})
		}
		console.log("post update password", user)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/users/update-password', {
				method: 'POST',
				body: JSON.stringify({
					id: user._id,
					password: user.password
				})
			})
			.then(res => res.json())
			.then(ret => {
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	postUpdatePhone = (user) => {
		if (!user._id || !user.phone)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update phone", user)
				reject(false)
			})
		}
		console.log("post update phone", user)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/users/update-phone', {
				method: 'POST',
				body: JSON.stringify({
					id: user._id,
					phone: user.phone
				})
			})
			.then(res => res.json())
			.then(ret => {
				const user = {
					...this.state.user,
					phone: ret.phone
				}
				this.setState({ user: user })
				resolve(user)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	postUpdateUser = (user) => {
		if (!user.firstname || !user.lastname || !user.langage)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update user webapp", user)
				reject(false)
			})
		}
		console.log("update user", this.state.device)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/users/update', {
				method: 'POST',
				body: JSON.stringify({
					id: user.id,
					device: (this.state.device) ? this.state.device._id : null,
					firstname: user.firstname,
					lastname: user.lastname,
					civility: user.civility,
					birthday: user.birthday,
					langage: user.langage
				})
			})
			.then(res => res.json())
			.then(ret => {
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	postUpdateStay = (user) => {
		if (!user || !user.stay)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update stay", user)
				reject(false)
			})
		}
		console.log("update stay", user)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/users/update-stay', {
				method: 'POST',
				body: JSON.stringify({
					id: user.id,
					stay: user.stay
				})
			})
			.then(res => res.json())
			.then(user => {
				resolve(user)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	/*
	** END POST METHODS
	*/

	deleteInstallEvent = () => {
		this.installEvent = undefined
	}

	dismissInventory = () => {
		const user = {
			...this.state.user,
			dismiss_inventory: tools.getTimestamp()
		}
		this.setState({ user: user })
	}

	updateActivationCode = (code) => {
		this.setState({ activation_code: code })
	}

	updateUser = (user, session) => {
		let lang_assets = null
		if (user)
		{
			let lang = null
			if (this.state.langs.length === 1)
				lang = this.state.langs[0]
			else
			{
				for (let k = 0; k < this.state.langs.length; k++)
				{
					if (user.langage === this.state.langs[k])
					{
						lang = this.state.langs[k]
						break
					}
				}
				if (!lang && user.langage !== this.state.lang_default)
				{
					for (let k = 0; k < this.state.langs.length; k++)
					{
						if (this.state.langs[k] === 'en')
						{
							lang = 'en'
							break
						}
					}
				}
				if (!lang)
					lang = this.state.lang_default
			}
			lang_assets = this.lang_assets[lang]
			user.lang = lang
		}
		this.setState({ user: user, session: session, lang_assets: lang_assets })
	}

	updateUserLang = (user) => {
		if (!user._id || !user.langage)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update user lang", user)
				reject(false)
			})
		}
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/users/update-lang', {
				method: 'POST',
				body: JSON.stringify({
					id: user._id,
					langage: user.langage
				})
			})
			.then(res => res.json())
			.then(ret => {
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	updateUserDashboardLang = (user) => {
		if (!user._id || !user.langage)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update user dashboard lang", user)
				reject(false)
			})
		}
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/users/update-lang', {
				method: 'POST',
				body: JSON.stringify({
					id: user._id,
					langage: user.langage
				})
			})
			.then(res => res.json())
			.then(ret => {
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	updateScrollHome = (scroll) => {
		this.setState({ scroll_home: scroll })
	}

	shiftPrevious = () => {
		let previousPage = this.state.history.previous
		if (previousPage.length > 0)
			previousPage.shift()
		this.setState({ history: { ...this.state.history, previous: previousPage, click: true }})
	}

	updateShell = (bottomHighlight, showPrevious, currentPage) => {
		let previousPage = this.state.history.previous
		if (showPrevious === true && this.state.history.current !== null)
			previousPage.unshift(this.state.history.current)
		else
			previousPage = []
		if (this.state.history.click === true && showPrevious === true && this.state.history.current !== null)
			previousPage.shift()
		this.setState({
			bottomHighlight: bottomHighlight,
			history: {
				show: showPrevious,
				current: currentPage,
				previous: previousPage,
				click: false
			}
		})
	}

	_requestIP = () => {
		return new Promise((resolve, reject) => {
			const xhttp = new XMLHttpRequest()
			xhttp.onreadystatechange = function() {
				if (this.readyState === 4 && this.status === 200)
					resolve(this.responseText)
				else if (this.readyState === 4)
					reject(this.status)
			}
			xhttp.open("GET", "//api.ipify.org?format=json", true)
			xhttp.send()
		})
	}

	updateLogs = (pathname, component = null, infos = null, nouser = false) => {
		this.fetchVersion()
		if (nouser === true || this.state.user.from === 'webapp')
		{
			this._requestIP()
			.then(json => JSON.parse(json))
			.then(ip => {
				const width = window.innerWidth
				const height = window.innerHeight
				const orientation = (height > width) ? 'portrait' : 'landscape'
				const app = (window.navigator.standalone === true || window.matchMedia('(display-mode: standalone)').matches) ? true : false
				const user = (nouser) ? null : this.state.user._id
				const client_number = (nouser) ? null : this.state.user.client_number
				const stay_number = (nouser) ? null : this.state.user.stay_number
				const session = (nouser) ? null : this.state.session.token
				const log = {
					time: tools.getTimestamp(),
					path: pathname,
					component: component,
					infos: infos,
					user: user,
					session: session,
					client_number: client_number,
					stay_number: stay_number,
					agent: navigator.userAgent,
					ip: ip.ip,
					screen: { width: width, height: height, orientation: orientation },
					app: app
				}
				fetch(connexion.connect.url + 'webapp/logs/insert', {
					method: 'POST',
					body: JSON.stringify({
						time: log.time,
						path: log.path,
						component: log.component,
						infos: log.infos,
						user: log.user,
						device: (this.state.device) ? this.state.device.shadow_user : null,
						session: log.session,
						client_number: log.client_number,
						stay_number: log.stay_number,
						agent: log.agent,
						ip: log.ip,
						screen: log.screen,
						app: log.app
					})
				})
				.then(res => res.json())
				.then(ret => {
					if (ret === false)
						console.log("Log failed.")
				})
				.catch(err => console.log(err))
			})
			.catch(err => console.log(err))
		}
		else
			console.log("log not enabled for dashboard")
	}

	promiseDetectDevice = {}

	detectDevice = () => {
		let token = Cookies.get('device') // return undefined or session id
		if (!token)
			token = localStorage.getItem("device") // return "null" or session id
		console.log("connect device", token, this.promiseDetectDevice)
		if (Object.keys(this.promiseDetectDevice).indexOf(token) !== -1)
			return this.promiseDetectDevice[token]
		else
		{
			this.promiseDetectDevice[token] = 42 // Il faut set la variable rapidement (plus rapidement que la promesse en-dessous) pour éviter un doublon en db
			// console.log("connect device 42", token, this.promiseDetectDevice)
			const requrl = tools.queryString(connexion.connect.url + 'webapp/devices/connect', {
				device: token
			})
			this.promiseDetectDevice[token] = new Promise((resolve, reject) => {
				fetch(requrl)
				.then(res => res.json())
				.then(device => {
					let user = {
						civility: '',
						email: 'invite@pocketamp.fr',
						firstname: 'Mode',
						lastname: 'Invité',
						stay: device.stay,
						langage: device.langage,
						from: 'webapp',
						session: device.session,
						invite: true
					}
					Cookies.set('session', device.session, { sameSite: 'Strict' })
					localStorage.setItem("session", device.session)
					if (device.new === true)
					{
						Cookies.set('device', device.shadow_user, { sameSite: 'Strict', expires: 365 * 20 })
						localStorage.setItem("device", device.shadow_user)
					}
					this.updateUser(user, device.session)
					this.setState({ device: device })
					resolve(device)
				})
				.catch(err => {
					// this.setState({ user: { lastname: '?', firstname: '?', sexe: undefined }, session: '?' })
					console.log(err)
				})
			})
			const ret = this.promiseDetectDevice[token]
			delete this.promiseDetectDevice[token]
			return ret
		}
	}

	render() {
		if (window.innerWidth >= 640)
		{
			let app_name = this.state.app_name
			let url_name = (this.state.app_url) ? this.state.app_url.replace(/^https?:\/\//, '') : ""
			url_name = url_name.replace(/\/$/, '')
			return (
				<div className="h-screen w-screen flex flex-row">
					<div className="h-screen w-full flex-col justify-start px-32 py-40">
						<h1 className="text-6xl font-bold">Téléchargez l'app {app_name} !</h1>
						<h3 className="pt-12 pb-12 text-2xl leading-8 text-gray-600 font-bold">Pour télécharger notre app, rien ne plus simple.</h3>
						<p className="p-0 text-xl text-left	font-bold text-gray-600"><span role="img" aria-label="step-1">1️⃣</span> Depuis votre mobile, ouvrez votre navigateur</p>
						<p className="p-0 text-xl text-left	font-medium italic text-gray-400">Sur <span role="img" aria-label="apple">🍏</span> Iphone ouvrez Safari ou sur <span role="img" aria-label="android">🤖</span> Android ouvrez Chrome</p>
						<p className="pt-4 text-xl text-left font-bold text-gray-600"><span role="img" aria-label="step-2">2️⃣</span> Tapez l'URL <span role="img" aria-label="right-finger">👉</span> <span className="text-blue-600">{url_name}</span> <span role="img" aria-label="left-finger">👈</span></p>
						{/* <p className="pt-6 pb-2 text-xl text-left font-bold text-gray-600">... Ou flashez ce QR code !</p>
						<img className="w-56" src={qrcode} alt="validation icon"/> */}
					</div>
					<div className="bg-blue-50 h-screen w-full flex justify-end">
						<img className="w-5/6" src={mockup} alt="validation icon"/>
					</div>
				</div>
			)
		}
		else if ((this.state.session === undefined || (this.state.session === null && this.state.application_ouverte === true)) ||
			(this.state.application_ouverte === true && this.state.lang_assets === null) || this.state.groups === null || this.state.config === null ||
			this.state.theme === null || (typeof this.state.session === "string" && this.state.user === undefined) ||
			this.state.logo === null || this.state.notifications === undefined || this.state.features === undefined ||
			this.state.langs === null || this.state.lang_default === null | this.state.background_img === null ||
			this.state.welcome_txt.length === 0)
		{
			return (
				<div className="App">
					<div className="loading">
						<Loader />
					</div>
				</div>
			)
		}
		else if (this.state.application_ouverte === false && (this.state.session === null || (this.state.user && this.state.user.invite === true)))
		{
			const assets = Object.values(this.lang_assets)
			let review_paths = []
			for (let i = 0; i < assets.length; i++)
				review_paths.push(assets[i].paths.client_reviews + '/:client_number/:hash')
			let subscribeRouteJSX = null
			if (this.state.app_type === "camping")
			{
				subscribeRouteJSX = (
					<Route
						exact
						// path="/inscription/:code?"
						path="/inscription"
						render={(props) =>
							<SubscribeCamping
								{...props}
								activation_code={this.state.activation_code}
								activation_code_specials={this.state.activation_code_specials}
								app_name={this.state.app_name}
								app_type={this.state.app_type}
								config_pms={this.state.config_pms}
								langs={this.state.langs}
								lang_default={this.state.lang_default}
								updateUser={this.updateUser}
								updateUserLang={this.updateUserLang}
								logo={this.state.logo}
								theme={this.state.theme}
								deleteInstallEvent={this.deleteInstallEvent}
								installEvent={this.props.installEvent}
								handleInstallApp={this.props.handleInstallApp}
								fetchConfigPms={this.fetchConfigPms}
								postCheckUserEmail={this.postCheckUserEmail}
								postCheckUserKoban={this.postCheckUserKoban}
								postConnectUser={this.postConnectUser}
								postCheckActivationCode={this.postCheckActivationCode}
								postRequestActivationCode={this.postRequestActivationCode}
								postSubscribeUser={this.postSubscribeUser}
								postUpdateEmail={this.postUpdateEmail}
							/>
						}
					/>
				)
			}
			else if (this.state.app_type === "campus")
			{
				subscribeRouteJSX = (
					<Route
						exact
						// path="/inscription/:code?"
						path="/inscription"
						render={(props) =>
							<SubscribeCampus
								{...props}
								activation_code={this.state.activation_code}
								activation_code_specials={this.state.activation_code_specials}
								app_name={this.state.app_name}
								app_type={this.state.app_type}
								langs={this.state.langs}
								lang_default={this.state.lang_default}
								updateUser={this.updateUser}
								updateUserLang={this.updateUserLang}
								logo={this.state.logo}
								theme={this.state.theme}
								deleteInstallEvent={this.deleteInstallEvent}
								installEvent={this.props.installEvent}
								handleInstallApp={this.props.handleInstallApp}
								postConnectUser={this.postConnectUser}
								postCheckActivationCode={this.postCheckActivationCode}
								postCheckUserEmail={this.postCheckUserEmail}
								postRequestActivationCode={this.postRequestActivationCode}
								postSubscribeUser={this.postSubscribeUser}
								postUpdateEmail={this.postUpdateEmail}
							/>
						}
					/>
				)
			}
			else if (this.state.app_type === "demo")
			{
				subscribeRouteJSX = (
					<Route
						exact
						// path="/inscription/:code?"
						path="/inscription"
						render={(props) =>
							<SubscribeDemo
								{...props}
								activation_code={this.state.activation_code}
								activation_code_specials={this.state.activation_code_specials}
								app_name={this.state.app_name}
								app_type={this.state.app_type}
								logo={this.state.logo}
								theme={this.state.theme}
								langs={this.state.langs}
								lang_default={this.state.lang_default}
								updateUser={this.updateUser}
								updateUserLang={this.updateUserLang}
								deleteInstallEvent={this.deleteInstallEvent}
								installEvent={this.props.installEvent}
								handleInstallApp={this.props.handleInstallApp}
								postConnectUser={this.postConnectUser}
								postCheckUserEmail={this.postCheckUserEmail}
								postRequestActivationCode={this.postRequestActivationCode}
								postSubscribeUser={this.postSubscribeUser}
								postUpdateEmail={this.postUpdateEmail}
							/>
						}
					/>
				)
			}
			else if (this.state.app_type === "syndic")
			{
				subscribeRouteJSX = (
					<Route
						exact
						// path="/inscription/:code?"
						path="/inscription"
						render={(props) =>
							<SubscribeSyndic
								{...props}
								activation_code={this.state.activation_code}
								activation_code_specials={this.state.activation_code_specials}
								app_name={this.state.app_name}
								app_type={this.state.app_type}
								config_pms={this.state.config_pms}
								langs={this.state.langs}
								lang_default={this.state.lang_default}
								updateUser={this.updateUser}
								updateUserLang={this.updateUserLang}
								logo={this.state.logo}
								theme={this.state.theme}
								deleteInstallEvent={this.deleteInstallEvent}
								installEvent={this.props.installEvent}
								handleInstallApp={this.props.handleInstallApp}
								fetchConfigPms={this.fetchConfigPms}
								postCheckUserEmail={this.postCheckUserEmail}
								postCheckUserKoban={this.postCheckUserKoban}
								postConnectUser={this.postConnectUser}
								postCheckActivationCode={this.postCheckActivationCode}
								postRequestActivationCode={this.postRequestActivationCode}
								postSubscribeUser={this.postSubscribeUser}
								postUpdateEmail={this.postUpdateEmail}
							/>
						}
					/>
				)
			}
			return (
				<div>
					<Switch>
						<Route
							exact
							path={review_paths}
							render={(props) =>
								<ReviewsClient
									{...props}
									features={this.state.features}
									reviews={this.state.reviews}
									lang_assets={this.lang_assets}
									theme={this.state.theme}
									fetchReviewByURL={this.fetchReviewByURL}
									postSubmitReview={this.postSubmitReview}
									updateLogs={this.updateLogs}
								/>
							}
						/>
						<Route
							exact
							path="/forgot-password/:code"
							render={(props) =>
								<Forgot
									{...props}
									theme={this.state.theme}
									langs={this.state.langs}
									lang_default={this.state.lang_default}
									postRetrieveUserByHash={this.postRetrieveUserByHash}
									postUpdatePassword={this.postUpdatePassword}
									postConnectUser={this.postConnectUser}
									updateUser={this.updateUser}
									updateUserLang={this.updateUserLang}
								/>
							}
						/>
						<Route
							exact
							path="/connexion/:identifiant?"
							render={(props) =>
								<Signin
									{...props}
									langs={this.state.langs}
									lang_default={this.state.lang_default}
									updateUser={this.updateUser}
									logo={this.state.logo}
									theme={this.state.theme}
									postConnectUser={this.postConnectUser}
									postUpdateStay={this.postUpdateStay}
									updateUserLang={this.updateUserLang}
									updateUserDashboardLang={this.updateUserDashboardLang}
									installEvent={this.installEvent}
									deleteInstallEvent={this.deleteInstallEvent}
								/>
							}
						/>
						<Route
							exact
							path="/redirect/:token"
							render={(props) =>
								<Tracker
									{...props}
									updateActivationCode={this.updateActivationCode}
								/>
							}
						/>
						<Route
							exact
							path="/qrcode/:code"
							render={(props) =>
								<Tracker
									{...props}
									updateActivationCode={this.updateActivationCode}
								/>
							}
						/>
						{subscribeRouteJSX}
						<Route
							path="/"
							render={(props) =>
								<Lobby
									{...props}
									background_img={this.state.background_img}
									lang_default={this.state.lang_default}
									logo={this.state.logo}
									theme={this.state.theme}
									welcome_txt={this.state.welcome_txt}
								/>
							}
						/>
					</Switch>
				</div>
			)
		}
		else
		{
			return (
				<div className="App">
					<Header
						theme={this.state.theme}
						swipeLeft={this.swipeLeft}
						user={this.state.user}
						logo={this.state.logo}
						icons={this.state.icons}
						groups={this.state.groups}
						previousPage={this.state.history.previous}
						showPrevious={this.state.history.show}
						shiftPrevious={this.shiftPrevious}
					/>
					<div id="wrapper" className="Wrapper">
						<Main
							// attributes
							accommodations={this.state.accommodations}
							animations={this.state.animations}
							animationDates={this.state.animation_dates}
							animationCategories={this.state.animation_categories}
							app_name={this.state.app_name}
							app_type={this.state.app_type}
							application_ouverte={this.state.application_ouverte}
							categories={this.state.categories}
							config_deposit={this.state.config_deposit}
							config_inventory={this.state.config_inventory}
							config_pms={this.state.config_pms}
							conversations={this.state.conversations}
							deposit={this.state.deposit}
							features={this.state.features}
							groups={this.state.groups}
							icons={this.state.icons}
							langs={this.state.langs}
							lang_default={this.state.lang_default}
							lang_assets={this.state.lang_assets}
							lang_assets_global={this.lang_assets}
							lists={this.state.lists}
							messages={this.state.messages}
							notifications={this.state.notifications}
							pages={this.state.pages}
							reviews={this.state.reviews}
							service_benefits={this.state.service_benefits}
							service_products={this.state.service_products}
							service_reservations={this.state.service_reservations}
							service_workers={this.state.service_workers}
							services={this.state.services}
							session={this.state.session}
							show_stay_dates={this.state.show_stay_dates}
							show_stay_location={this.state.show_stay_location}
							theme={this.state.theme}
							user={this.state.user}
							
							// fetch methods
							fetchAccommodations={this.fetchAccommodations}
							fetchAnimationsByDate={this.fetchAnimationsByDate}
							fetchAnimationNext={this.fetchAnimationNext}
							fetchAnimationCategories={this.fetchAnimationCategories}
							fetchCategoriesByGroup={this.fetchCategoriesByGroup}
							fetchCategoryByID={this.fetchCategoryByID}
							fetchConfigDeposit={this.fetchConfigDeposit}
							fetchConfigInventory={this.fetchConfigInventory}
							fetchConfigPms={this.fetchConfigPms}
							fetchConversationByID={this.fetchConversationByID}
							fetchConversationsByUser={this.fetchConversationsByUser}
							fetchDepositLink={this.fetchDepositLink}
							fetchIcons={this.fetchIcons}
							fetchInventory={this.fetchInventory}
							fetchListByCategory={this.fetchListByCategory}
							fetchListByID={this.fetchListByID}
							fetchListCheckUpdate={this.fetchListCheckUpdate}
							fetchMessagesByConversation={this.fetchMessagesByConversation}
							fetchModules={this.fetchModules}
							fetchNotifications={this.fetchNotifications}
							fetchPageByCategory={this.fetchPageByCategory}
							fetchPageByID={this.fetchPageByID}
							fetchPagesByIDList={this.fetchPagesByIDList}
							fetchPageByURL={this.fetchPageByURL}
							fetchPageCheckUpdate={this.fetchPageCheckUpdate}
							fetchReviewByURL={this.fetchReviewByURL}
							fetchServiceBenefits={this.fetchServiceBenefits}
							fetchServiceBenefitsByService={this.fetchServiceBenefitsByService}
							fetchServiceByID={this.fetchServiceByID}
							fetchServiceByURL={this.fetchServiceByURL}
							fetchServiceProductsByService={this.fetchServiceProductsByService}
							fetchServiceReservationsByService={this.fetchServiceReservationsByService}
							fetchServiceReservationsByServiceList={this.fetchServiceReservationsByServiceList}
							fetchServiceReservationsByUser={this.fetchServiceReservationsByUser}
							fetchServiceWorkersByService={this.fetchServiceWorkersByService}
							fetchServiceWorkersByServiceList={this.fetchServiceWorkersByServiceList}
							fetchServices={this.fetchServices}
							
							// post methods
							postClickNotification={this.postClickNotification}
							postDeleteServiceReservation={this.postDeleteServiceReservation}
							postInsertPicture={this.postInsertPicture}
							postInventory={this.postInventory}
							postResetClient={this.postResetClient}
							postServiceReservation={this.postServiceReservation}
							postServiceReservationCart={this.postServiceReservationCart}
							postSubmitConversation={this.postSubmitConversation}
							postSubmitMessage={this.postSubmitMessage}
							postSubmitReview={this.postSubmitReview}
							postUpdateBirthday={this.postUpdateBirthday}
							postUpdatePhone={this.postUpdatePhone}
							postUpdateUser={this.postUpdateUser}
							
							// others
							dismissInventory={this.dismissInventory}
							handleInstallApp={this.handleInstallApp}
							handleAcceptNotifications={this.handleAcceptNotifications}
							installEvent={this.installEvent}
							scroll_home={this.state.scroll_home}
							updateUser={this.updateUser}
							updateUserLang={this.updateUserLang}
							updateUserDashboardLang={this.updateUserDashboardLang}
							updateLogs={this.updateLogs}
							updateScrollHome={this.updateScrollHome}
							updateShell={this.updateShell}

							// subscribe camping + signin + forgot
							activation_code={this.state.activation_code}
							activation_code_specials={this.state.activation_code_specials}
							deleteInstallEvent={this.deleteInstallEvent}
							postCheckUserEmail={this.postCheckUserEmail}
							postCheckUserKoban={this.postCheckUserKoban}
							postConnectUser={this.postConnectUser}
							postCheckActivationCode={this.postCheckActivationCode}
							postRequestActivationCode={this.postRequestActivationCode}
							postSubscribeUser={this.postSubscribeUser}
							postUpdateEmail={this.postUpdateEmail}
							postRetrieveUserByHash={this.postRetrieveUserByHash}
							postUpdatePassword={this.postUpdatePassword}
							postUpdateStay={this.postUpdateStay}
						/>
					</div>
					<BottomBar
						lang_assets={this.state.lang_assets}
						bottomHighlight={this.state.bottomHighlight}
						notifications={this.state.notifications}
						fetchNotifications={this.fetchNotifications}
					/>
				</div>
			)
		}
	}
}

export default App
