import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import AskNotifications from '../UI/AskNotifications'
import Loader from '../UI/Loader'
import tools from '../../tools'
import './Animations.css'
import {
	ChevronLeftIcon,
	ChevronRightIcon
} from '@heroicons/react/solid'

class Animations extends Component {
	constructor(props) {
		super(props)
		const date = new Date();
		const today = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
		this.state = {
			animations: null,
			categories: null,
			month: null,
			year: null,
			days: null,
			today: Math.floor(today.getTime() / 1000)
		}
	}

	componentDidMount() {
		const date = new Date()
		this.setState({ month: date.getMonth(), year: date.getFullYear() })
		this.props.updateLogs(this.props.history.location.pathname, 'Animations')
		this.loadAnimationCategories()
		this.props.updateShell('calendar', true, this.props.history.location.pathname)
		this.props.fetchNotifications()
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.month !== this.state.month || prevState.year !== this.state.year)
			this.constructDates()
		if (prevProps.animationCategories !== this.props.animationCategories)
			this.loadAnimationCategories()
	}

	constructDates() {
		const infos = {
			month: this.state.month,
			year: this.state.year
		}
		this.props.updateLogs(this.props.history.location.pathname, 'Animations', infos)
		const feb = (this.state.year % 4 === 0) ? 29 : 28
		const nb_days = [31, feb, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
		const start_month = tools.getTimestamp(new Date(this.state.year, this.state.month, 1, 0, 0, 0))
		const end_month = tools.getTimestamp(new Date(this.state.year, this.state.month + 1, 1, 0, 0, 0))
		let firstDay = new Date(this.state.year, this.state.month, 1).getDay()
		let year = this.state.year
		let month = this.state.month
		if (firstDay === 0)
			firstDay = 6
		else
			firstDay--
		let i = 0
		if (this.state.month === 0)
		{
			i = nb_days[11]
			month = 11
			year--
		}
		else
		{
			i = nb_days[this.state.month - 1]
			month--
		}
		i = i - firstDay + 1
		let c = 0
		let days = [[]]
		while (c < firstDay)
		{
			days[0][c] = {
				day: c,
				nb: i,
				white: true,
				date: new Date(year, month, i),
				pastille: false
			}
			i++
			c++
		}
		if (month === 11)
		{
			month = 0
			year++
		}
		else
			month++
		let k = 0
		i = 0
		while (i < nb_days[this.state.month])
		{
			if (c % 7 === 0)
			{
				k++
				days[k] = []
				c = 0
			}
			days[k][c] = {
				day: c,
				nb: i + 1,
				white: false,
				date: new Date(year, month, i + 1),
				pastille: false
			}
			c++
			i++
		}
		if (month === 11)
		{
			month = 0
			year++
		}
		else
			month++
		i = 1
		while (c < 7)
		{
			days[k][c] = {
				day: c,
				nb: i,
				white: true,
				date: new Date(year, month, i),
				pastille: false
			}
			i++
			c++
		}
		this.props.fetchAnimationsByDate(start_month, end_month)
		.then(animation_dates => {
			for (let i = 0; i < days.length; i++)
			{
				for (let j = 0; j < days[i].length; j++)
				{
					const start = tools.getTimestamp(days[i][j].date)
					const end = start + 86400
					for (let k = 0; k < animation_dates.dates.length; k++)
					{
						if (animation_dates.dates[k].start > start && animation_dates.dates[k].start < end)
						{
							days[i][j].pastille = true
							break
						}
					}
				}
			}
			this.setState({ days: days })
		})
		.catch(err =>  {
			console.log("can not load animation dates", err)
		})
	}

	loadAnimationCategories = () => {
		if (this.props.animationCategories)
			this.setState({ categories: this.props.animationCategories })
		else
			this.props.fetchAnimationCategories()
	}

	previousMonth = () => {
		let month = this.state.month
		let year = this.state.year
		if (month === 0)
		{
			month = 11
			year--
		}
		else
			month--
		this.setState({ month: month, year: year })
	}

	nextMonth = () => {
		let month = this.state.month
		let year = this.state.year
		if (month === 11)
		{
			month = 0
			year++
		}
		else
			month++
		this.setState({ month: month, year: year })
	}

	render() {
		if (this.state.categories === null || this.state.month === null || this.state.year === null || this.state.days === null)
		{
			return (
				<div className="loading">
					<Loader />
				</div>
			)
		}
		else
		{
			const lang_assets = this.props.lang_assets.time
			let cols = -1
			let rows_k = -1
			let cols_k = -1
			return (
				<div className="AnimationMonth">
					<div className="header flex justify-center items-center z-0 w-full fixed top-0 h-36" style={{ backgroundColor: '#' + this.props.theme.color1 }}>
						<h1 className="text-white text-3xl">{this.props.lang_assets.activities.title}</h1>
					</div>
					<div className="body">
						{/* <div className="month">
							<p className="arrow-left" onClick={this.previousMonth}><img src={left} alt="previous" /></p>
							<p className="text">{lang_assets.months[this.state.month]} {this.state.year}</p>
							<p className="arrow-right" onClick={this.nextMonth}><img src={right} alt="next" /></p>
						</div> */}
						<div className="flex justify-center items-center h-20 min-h-full text-gray-900">
							<div 
								className="flex items-center h-full w-32 pl-24 pr-5" 
								onClick={this.previousMonth}
							>
								<button className="-m-1.5 flex flex-none items-center justify-center text-gray-400 hover:text-gray-500">
									<ChevronLeftIcon className="h-5 w-5" alt="previous" />
								</button>
							</div>
							<div>
								<p className="flex-auto text-center w-40 font-semibold">{lang_assets.months[this.state.month]} {this.state.year}</p>
							</div>
							<div 
								className="flex items-center h-full w-32 pl-5 pr-24" 
								onClick={this.nextMonth}
							>
								<button>
									<ChevronRightIcon className="-m-1.5 flex flex-none items-center justify-center text-gray-400 hover:text-gray-500 h-5 w-5" alt="next" />
								</button>
							</div>
						</div>
						{/* <div className="calendar"> */}
						<div className="mx-auto w-11/12 bg-white p-3 shadow rounded-xl mb-5">
							<div className="weekDays">
								<p className="name">{lang_assets.weekdays_short[1]}</p>
								<p className="name">{lang_assets.weekdays_short[2]}</p>
								<p className="name">{lang_assets.weekdays_short[3]}</p>
								<p className="name">{lang_assets.weekdays_short[4]}</p>
								<p className="name">{lang_assets.weekdays_short[5]}</p>
								<p className="name">{lang_assets.weekdays_short[6]}</p>
								<p className="name">{lang_assets.weekdays_short[0]}</p>
							</div>
							<div className="monthTable">
								{this.state.days.map((rows) => {
									rows_k++
									return (
										<div key={rows_k} className="dayRow">
											{rows.map(({ day, nb, white, date, pastille }) => {
												cols++
												if (cols === 7)
													cols = 0
												let styleDiv = null
												let styleText = null
												let classname = (cols === 0) ? "relative day first" : "relative day"
												const numclass = (white) ? "num gray" : "num font-medium"
												cols_k++
												const time = Math.floor(date.getTime() / 1000)
												if (time === this.state.today)
												{
													const color = 'white'
													styleDiv = { backgroundColor: '#' + this.props.theme.color1 }
													styleText = { color: color }
													classname = classname + " highlight"
												}
												const url = this.props.lang_assets.paths.activities + "/" + time
												let pastilleJSX = null
												if (pastille === true)
												{
													pastilleJSX = (
														<div className="absolute w-full">
															<p style={{ backgroundColor: '#' + this.props.theme.color3}} className="block w-1.5 h-1.5 rounded-full bottom-0 mx-auto"></p>
														</div>
													)
												}
												return (
													<Link key={cols_k} to={url}>
														<div className={classname} style={styleDiv}>
															<p className={numclass} style={styleText}>{nb}</p>
															{pastilleJSX}
														</div>
													</Link>
												)
											})}
										</div>
									)
								})}
							</div>
						</div>
						<AskNotifications
							lang_assets={this.props.lang_assets}
							theme={this.props.theme}
							text={this.props.lang_assets.activities.activate}
							user={this.props.user}
							handleAcceptNotifications={this.props.handleAcceptNotifications}
						/>
					</div>
				</div>
			)
		}
	}
}

export default Animations