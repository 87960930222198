import React, { Component } from 'react'
import connexion from '../../connexion'
import french_assets from '../../langs/main.fr.json'
import english_assets from '../../langs/main.en.json'
import german_assets from '../../langs/main.de.json'
import dutch_assets from '../../langs/main.nl.json'
import italian_assets from '../../langs/main.it.json'
import spanish_assets from '../../langs/main.es.json'
import portuguese_assets from '../../langs/main.pt.json'
import M from 'materialize-css'

class SubscribeStepPWA extends Component {
	constructor(props) {
		super(props)
		this.lang_assets = {
			fr: french_assets,
			en: english_assets,
			de: german_assets,
			nl: dutch_assets,
			it: italian_assets,
			es: spanish_assets,
			pt: portuguese_assets
		}
	}
		
	componentDidUpdate() {
		const elems = document.querySelectorAll('select')
		M.FormSelect.init(elems)
	}

	handleInstallApp = (answer) => {
		if (answer === true)
		{
			this.props.installEvent.prompt()
			this.props.installEvent.userChoice
			.then(choice => {
				fetch(connexion.connect.url + 'webapp/config/downloads/increase')
				.then(res => res.json())
				.then(ret => {
					if (ret !== true)
						console.log("could not increase downloads nb")
					setTimeout(() => {
						this.props.validateStepPWA()
						this.props.deleteInstallEvent()
					}, 1000)
				})
				.catch(err => {
					console.log("could not make request for increase downloads", err)
					setTimeout(() => {
						this.props.validateStepPWA()
						this.props.deleteInstallEvent()
					}, 1000)
				})
			})
		}
		else
			this.props.validateStepPWA()
	}

	render ()
	{
		const lang_assets = this.lang_assets['fr'].signin.step5   
		const btnStyle = "font-semibold py-5 w-full rounded-full text-white shadow-xl text-xl my-10 waves-effect waves-light"
		return (
			<div>
				<div style={{backgroundColor: "#" + this.props.theme.color1}} className="h-52 z-0 px-8 text-center">
					<h1 className="text-3xl text-white font-semibold m-0 pt-16">{lang_assets.title}</h1>
				</div>
				<div className="rounded-t-3xl z-10" style={{backgroundColor: "#ffffff", marginTop: "-2rem"}}>
					<div className="px-10 pt-16 pb-10">
						<h2 className="text-3xl font-semibold text-gray-700 mb-10">Installez l'icône sur l'écran d'accueil pour y accéder facilement <span role="img" aria-label="star-struck">🤩</span></h2>
						<h3 className="text-xl font-semibold" style={{ color: '#' + this.props.theme.color1 }}><span className="mr-3" role="img" aria-label="star-struck">🚀</span>{lang_assets.info}</h3>
						<button type="button" className={btnStyle} onClick={this.handleInstallApp.bind(this, true)} style={{ backgroundColor: '#' + this.props.theme.color1 }}>{lang_assets.button}</button>
						<button className="font-semibold py-5 w-full text-xl mb-10 text-center" style={{ color: '#' + this.props.theme.color1 }} onClick={this.handleInstallApp.bind(this, false)}>{lang_assets.later}</button>
					</div>
				</div>
			</div>
		)
	}
}
		
export default SubscribeStepPWA