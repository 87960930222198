import React, { Component } from 'react'
import check_icon from '../../img/check-green.png'
import { AnimatePresence, motion } from "framer-motion"

class ModalMessage extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isVisible : true
		}
	}

	render() {
		const container_style = {
			width: "90%",
			height: "350px",
			left: "5%",
			top: "calc(50% - 150px)"
		}

		const icon_style = {
			width: "80px",
			left: "calc(50% - 40px)"
		}
		const btn_style = {
			backgroundColor: '#' + this.props.theme.color1,
			margin: 'auto',
			marginTop: '2rem'
		}

		return (
				<motion.div>
					<AnimatePresence>
						<motion.div className="fixed inset-0 h-screen w-full bg-black bg-opacity-60">
							<motion.div 
								className="absolute bg-white rounded-lg z-10" 
								style={container_style}
								initial= {{ opacity: 0, scale: 0.75}}
								animate= {{ opacity: 1, scale: 1, transition: { delay : 0.2 } }}
								exit={{ opacity: 0, scale: 0 }}
							>
								<div className="p-7">
									<img src={check_icon} alt="validation icon" className="relative" style={icon_style}/>
									<h1 className="text-xl font-medium text-center">{this.props.title}</h1>
									<p className="text-gray-500 text-lg text-center">{this.props.body_text}</p>
									<button 
										className="rounded-full min-w-1/3 py-4 px-6 my-5 text-white font-medium flex justify-center"
										style={btn_style}
										onClick={this.props.validateHelp}
									>
										{this.props.btn_text}
									</button>
								</div>
							</motion.div>
						</motion.div>
					</AnimatePresence>
				</motion.div>
		)
	}
}

export default ModalMessage