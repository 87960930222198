import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './BottomBar.css'
import home_f from '../../img/bottombar/home.png'
import calendar_f from '../../img/bottombar/calendar.png'
import notification_f from '../../img/bottombar/notification.png'
import user_f from '../../img/bottombar/user.png'
import home_l from '../../img/bottombar/home_l.png'
import calendar_l from '../../img/bottombar/calendar_l.png'
import notification_l from '../../img/bottombar/notification_l.png'
import user_l from '../../img/bottombar/user_l.png'

class BottomBar extends Component {
	constructor(props) {
		super(props)
		this.state = {
			bottomHighlight: this.props.bottomHighlight,
			notifications: this.props.notifications
		}
	}

	componentDidMount() {
		this.loadNotifications()
	}
	
	componentDidUpdate(prevProps) {
		if (prevProps.bottomHighlight !== this.props.bottomHighlight)
			this.setState({ bottomHighlight: this.props.bottomHighlight })
		if (prevProps.notifications !== this.props.notifications)
			this.setState({ notifications: this.props.notifications })
	}

	loadNotifications = () => {
		if (this.props.notifications)
			this.setState({ notifications: this.props.notifications })
		else
			this.props.fetchNotifications()
	}

	render() {
		const lang_assets = this.props.lang_assets.bottomBar
		const highlight = this.state.bottomHighlight
		const notifications = this.state.notifications
		let home = home_f
		let calendar = calendar_f
		let notification = notification_f
		let user = user_f
		if (highlight === 'home')
			home = home_l
		else if (highlight === 'calendar')
			calendar = calendar_l
		else if (highlight === 'notification')
			notification = notification_l
		else if (highlight === 'profile')
			user = user_l
		let dot = null
		if (notifications !== null)
		{
			let unread = false
			for (let i = 0; i < notifications.length; i++)
			{
				if (notifications[i].read === false)
				{
					unread = true
					break
				}
			}
			if (unread === true)
				dot = (<div className="dot"></div>)
		}
		const path_home = '/'
		const path_activities = this.props.lang_assets.paths.activities
		const path_notifications = this.props.lang_assets.paths.notifications
		const path_account = this.props.lang_assets.paths.account
		return (
			<div className="BottomBar">
				<div className="Button">
					<Link to={path_home}>
						<img src={home} alt={lang_assets.home} />
						<p>{lang_assets.home}</p>
					</Link>
				</div>
				<div className="Button">
					<Link to={path_activities}>
						<img src={calendar} alt={lang_assets.activities} />
						<p>{lang_assets.activities}</p>
					</Link>
				</div>
				<div className="Button">
					<Link to={path_notifications}>
						{dot}
						<img src={notification} alt={lang_assets.notifications} />
						<p>{lang_assets.notifications}</p>
					</Link>
				</div>
				<div className="Button">
					<Link to={path_account}>
						<img src={user} alt={lang_assets.user} />
						<p>{lang_assets.user}</p>
					</Link>
				</div>
			</div>
		)
	}
}

export default BottomBar