import React, { Component } from 'react'
import Swipeable from 'react-swipeable'
import { Link } from 'react-router-dom'
import './SwipCat.css'

class SwipCat extends Component {
	constructor(props) {
		super(props)
		this.theme = [props.theme.color1, props.theme.color2, props.theme.color3, props.theme.color4]
		this.state = {
			translateX: 0,
			categories: null
		}
	}

	componentDidMount() {
		this.loadCategories()
	}

	/*componentDidUpdate(prevProps) {
		if (prevProps.categories !== this.props.categories)
			this.loadCategories()
	}*/

	loadCategories = () => {
		const group = this.props.group._id
		this.props.fetchCategoriesByGroup(group)
		.then(ret => {
			let categories = []
			for (let i = 0; i < ret.length; i++)
			{
				if (ret[i].group === group)
				{
					ret[i].color = { backgroundColor: '#' + this.props.theme.color1 }
					categories.push(ret[i])
				}
			}
			this.setState({ categories: categories })
		})
		.catch(err => console.log(err))
	}

	swipeLeft = (event, absX) => {
		if (this.state.categories)
		{
			const screenWidth = window.innerWidth * 0.9
			// const elemWidth = (screenWidth / 10) * 3
			// const marginWidth = ((screenWidth / 10) * 3) / 15
			const elemWidth = 120
			const marginWidth = 10
			const totalWidth = (this.state.categories.length * elemWidth) + ((this.state.categories.length - 1) * marginWidth) - screenWidth
			let list = window.document.getElementById(this.props.group._id)
			let translateX = this.state.translateX

			translateX -= absX
			if (translateX * -1 > totalWidth)
				translateX = totalWidth * -1
			list.style.transform = "translateX(" + translateX + "px)"
		}
	}

	swipeRight = (event, absX) => {
		if (this.state.categories)
		{
			let list = window.document.getElementById(this.props.group._id)
			let translateX = this.state.translateX
			translateX += absX
			if (translateX > 0)
				translateX = 0
			list.style.transform = "translateX(" + translateX + "px)"
		}
	}

	swiped = (event, deltaX, deltaY, isFlick, velocity) => {
		if (this.state.categories)
		{
			const screenWidth = window.innerWidth * 0.9
			// const elemWidth = (screenWidth / 10) * 3
			// const marginWidth = ((screenWidth / 10) * 3) / 15
			const elemWidth = 120
			const marginWidth = 10
			const totalWidth = (this.state.categories.length * elemWidth) + ((this.state.categories.length - 1) * marginWidth) - screenWidth
			let list = window.document.getElementById(this.props.group._id)
			let translateX = this.state.translateX
			translateX += deltaX * -1
			if (deltaX > 0 && translateX * -1 > totalWidth)
				translateX = totalWidth * -1
			else if (deltaX < 0 && translateX > 0)
				translateX = 0
			list.style.transform = "translateX(" + translateX + "px)"
			this.setState({ translateX: translateX })
		}
	}

	render() {
		const icons = this.props.icons
		const swipeid = this.props.group._id
		const categories = this.state.categories
		if (categories)
		{
			return (
				<div>
					<h2 className="text-xl ml-5 mt-7">{this.props.title}</h2>
					<Swipeable className="mt-4" onSwiped={this.swiped} onSwipingLeft={this.swipeLeft} onSwipingRight={this.swipeRight}>
						{/* <div className="listElems"> */}
						<div className="w-11/12 m-auto">
							<div className="Slider" id={swipeid}>
							{/* <div className="w-full mb-8 whitespace-nowrap" id={swipeid}> */}
							{categories && categories.map(({ _id, translations, icon, color, page, list }) => {
								let prev = null
								for (let i = 0; i < icons.length; i++)
								{
									if (icons[i]._id === icon)
									{
										for (let j = 0; j < icons[i].lineal.length; j++)
										{
											if (icons[i].lineal[j].theme === "blank")
											{
												prev = icons[i].lineal[j].icon
												break
											}
										}
										break
									}
								}
								let name = ""
								let suffix = ""
								for (let i = 0; i < translations.length; i++)
								{
									if (translations[i].lang === this.props.user.lang)
									{
										name = translations[i].name
										suffix = translations[i].suffix
										break
									}
								}
								let prefix = ""
								for (let i = 0; i < this.props.group.translations.length; i++)
								{
									if (this.props.group.translations[i].lang === this.props.user.lang)
									{
										prefix = this.props.group.translations[i].prefix
										break
									}
								}
								const path = this.props.lang_assets.paths.rubrics + '/'  + prefix + '/' + suffix
								if (prev === null)
								{
									return (
										<Link 
											className="element" 
											to={path} 
											key={_id}
										>
											<div key={_id} style={color}>
												<p className="text-base" >{name}</p>
											</div>
										</Link>
									)
								}
								else
								{
									return (
										<Link 
											className="element" 
											to={path} 
											key={_id}
										>
											<div key={_id} style={color}>
												<img className="w-10 inline" src={prev} alt="Icône" />
												<p className="text-base text-white mt-3 text-center font-medium break-word">{name}</p>
											</div>
										</Link>
									)
								}
							})}
							</div>
						</div>
					</Swipeable>
				</div>
			)
		}
		else
		{
			return (
				<div>
					<Swipeable onSwiped={this.swiped} onSwipingLeft={this.swipeLeft} onSwipingRight={this.swipeRight}>
						<div className="listElems">
							<div className="Slider" id={swipeid}>
								<div className="element"><div></div></div>
								<div className="element"><div></div></div>
								<div className="element"><div></div></div>
								<div className="element"><div></div></div>
							</div>
						</div>
					</Swipeable>
				</div>
			)
		}
	}
}

export default SwipCat;