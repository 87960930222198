import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Loader from '../UI/Loader'
import Cookies from 'js-cookie'
import tools from '../../tools'
import french_assets from '../../langs/main.fr.json'
import english_assets from '../../langs/main.en.json'
import german_assets from '../../langs/main.de.json'
import dutch_assets from '../../langs/main.nl.json'
import italian_assets from '../../langs/main.it.json'
import spanish_assets from '../../langs/main.es.json'
import portuguese_assets from '../../langs/main.pt.json'
import connexion from '../../connexion'
import ButtonPrevious from '../UI/ButtonPrevious'
import { EyeIcon, XCircleIcon, CheckCircleIcon, ArrowLeftIcon } from '@heroicons/react/solid'

class Signin extends Component {
	constructor(props) {
		super(props)
		this.lang_assets = {
			fr: french_assets,
			en: english_assets,
			de: german_assets,
			nl: dutch_assets,
			it: italian_assets,
			es: spanish_assets,
			pt: portuguese_assets
		}
		this.identifiant_ph = ""
		this.password_ph = ""
		this.email_ph = "E-mail"
		this.activated_btn_color = "#" + this.props.theme.color1
		this.deactivated_btn_color = "#A3A3A3"
		this.default_ph_color = "text-gray-400"
		this.updated_ph_color = "text-black"
		this.state = {
			form1: {
				identifiant: (this.props.match.params.identifiant) ? this.props.match.params.identifiant : this.identifiant_ph,
				v_identifiant: (this.props.match.params.identifiant) ? this.props.match.params.identifiant : "",
				password: this.password_ph,
				v_password: "",
				t_password: "text",
				visible: false
			},
			form2: {
				stay: ""
			},
			form3: {
				nb_emails: 3,
				emails: [this.email_ph + ' 1', this.email_ph + ' 2', this.email_ph + ' 3'],
				v_emails: ["", "", ""]
			},
			form4: {
				email: this.identifiant_ph,
				v_email: ''
			},
			attempt_connexion: false,
			attempt_submit: false,
			user: null,
			success: null,
			error: null,
			step: 1,
			current_btn_color : this.deactivated_btn_color
		}
		this.showPrompt = (this.props.installEvent) ? true : false
		this.theme = props.theme
		this.updateUser = props.updateUser
	}

	componentDidMount()
	{
		if (this.props.user && this.props.user.invite !== true)
			this.setState({ step: 6 })
	}

	setSuccessMsg = (msg) => {
		this.setState({ error: null, success: msg })
	}

	setErrorMsg = (msg) => {
		this.setState({ error: msg, success: null })
	}

	clearFlash = () => {
		this.setState({ error: null, success: null })
	}

	focusID = (event) => {
		if (this.state.form1.v_identifiant.length === 0) {
			let form1 = {
				identifiant: '',
				v_identifiant: this.state.form1.v_identifiant,
				password: this.state.form1.password,
				v_password: this.state.form1.v_password,
				t_password: this.state.form1.t_password,
				visible: this.state.form1.visible
			}
			this.setState({ form1: form1 })
		}
	}

	blurID = (event) => {
		if (this.state.form1.v_identifiant.length === 0) {
			let form1 = {
				identifiant: this.identifiant_ph,
				v_identifiant: this.state.form1.v_identifiant,
				password: this.state.form1.password,
				v_password: this.state.form1.v_password,
				t_password: this.state.form1.t_password,
				visible: this.state.form1.visible
			}
			this.setState({ form1: form1 })
		}
	}

	handleID = (event) => {
		let form1 = {
			identifiant: event.target.value.trim().toLowerCase(),
			v_identifiant: event.target.value.trim().toLowerCase(),
			password: this.state.form1.password,
			v_password: this.state.form1.v_password,
			t_password: this.state.form1.t_password,
			visible: this.state.form1.visible
		}
		this.setState({ form1: form1 })
	}

	focusPassword = (event) => {
		if (this.state.form1.v_password.length === 0) {
			const t_password = (this.state.form1.visible === false) ? "password" : "text"
			let form1 = {
				identifiant: this.state.form1.identifiant,
				v_identifiant: this.state.form1.v_identifiant,
				password: '',
				v_password: this.state.form1.v_password,
				t_password: t_password,
				visible: this.state.form1.visible
			}
			this.setState({ form1: form1 })
		}
	}

	blurPassword = (event) => {
		if (this.state.form1.v_password.length === 0) {
			let form1 = {
				identifiant: this.state.form1.identifiant,
				v_identifiant: this.state.form1.v_identifiant,
				password: this.password_ph,
				v_password: this.state.form1.v_password,
				t_password: "text",
				visible: this.state.form1.visible
			}
			this.setState({ form1: form1 })
		}
	}

	handlePassword = (event) => {
		this.btn_color(event, this.state.form1.v_password)
		let form1 = {
			identifiant: this.state.form1.identifiant,
			v_identifiant: this.state.form1.v_identifiant,
			password: event.target.value,
			v_password: event.target.value,
			t_password: this.state.form1.t_password,
			visible: this.state.form1.visible
		}
		this.setState({ form1: form1 })
	}

	handleVisible = (event) => {
		let visible = (this.state.form1.visible === true) ? false : true
		let t_password = (visible === false && this.state.form1.v_password.length > 0) ? "password" : "text"
		let form1 = {
			identifiant: this.state.form1.identifiant,
			v_identifiant: this.state.form1.v_identifiant,
			password: this.state.form1.password,
			v_password: this.state.form1.v_password,
			t_password: t_password,
			visible: visible
		}
		this.setState({ form1: form1 })
	}

	handleStay = (stay_id) => {
		let form2 = {
			stay: stay_id
		}
		this.setState({ form2: form2 })
	}

	addEmail = () => {
		const id = this.state.form3.nb_emails
		const nb = id + 1
		let emails = this.state.form3.emails
		let v_emails = this.state.form3.v_emails
		emails[id] = this.email_ph + ' ' + nb
		v_emails[id] = ""
		const form3 = {
			nb_emails: nb,
			emails: emails,
			v_emails: v_emails
		}
		this.setState({ form3: form3 })
	}

	focusEmails = (index, event) => {
		if (this.state.form3.v_emails[index].length === 0) {
			let emails = this.state.form3.emails
			emails[index] = ''
			const form3 = {
				nb_emails: this.state.form3.nb_emails,
				emails: emails,
				v_emails: this.state.form3.v_emails
			}
			this.setState({ form3: form3 })
		}
	}

	blurEmails = (index, event) => {
		if (this.state.form3.v_emails[index].length === 0) {
			const nb = index + 1
			let emails = this.state.form3.emails
			emails[index] = this.identifiant_ph + ' ' + nb
			const form3 = {
				nb_emails: this.state.form3.nb_emails,
				emails: emails,
				v_emails: this.state.form3.v_emails
			}
			this.setState({ form3: form3 })
		}
	}

	handleEmails = (index, event) => {
		this.btn_color(event, this.state.form1.v_identifiant)
		let emails = this.state.form3.emails
		let v_emails = this.state.form3.v_emails
		emails[index] = event.target.value.trim()
		v_emails[index] = event.target.value.trim()
		const form3 = {
			nb_emails: this.state.form3.nb_emails,
			emails: emails,
			v_emails: v_emails
		}
		this.setState({ form3: form3 })
	}

	focusEmail = (event) => {
		if (this.state.form4.v_email.length === 0) {
			let form4 = {
				email: '',
				v_email: this.state.form4.v_email
			}
			this.setState({ form4: form4 })
		}
	}

	blurEmail = (event) => {
		if (this.state.form4.v_email.length === 0) {
			let form4 = {
				email: this.identifiant_ph,
				v_email: this.state.form4.v_email
			}
			this.setState({ form4: form4 })
		}
	}

	handleEmail = (event) => {
		let form4 = {
			email: event.target.value.trim(),
			v_email: event.target.value.trim()
		}
		this.setState({ form4: form4 })
	}

	ph_color = (v_input) => {
		let color = this.default_ph_color
		if (v_input !== "")
			color = this.updated_ph_color
		return (color)
	}

	btn_color = (event, input) => {
		let style = this.state.current_btn_color
		if (event.target.value.length !== 0 && input.length !== 0)
			style = this.activated_btn_color
		else
			style = this.deactivated_btn_color
		this.setState({ current_btn_color : style })
	}

	/*
	_connectUser = (identifiant, password) => {
		const user = {
			identifiant: identifiant,
			password: password
		}
		this.props.postConnectUser(user)
		.then(user => {
			if (user !== false && user.stay === null)
			{
				let found = false
				let found_en = false
				for (let i = 0; i < this.props.langs.length; i++)
				{
					if (this.props.langs[i] === user.langage)
						found = true
					else if (this.props.langs[i] === "en")
						found_en = true
				}
				if (!found)
				{
					user.langage = (found_en) ? "en" : this.props.lang_default
					this.props.updateUserLang(user)
					.then(ret => {
						this._connectUser2(user)
					})
					.catch(err => {
						this.setErrorMsg("Une erreur s'est produite.")
						console.log(err)
					})
				}
				else
					this._connectUser2(user)
			}
			else if (user !== false)
			{
				let found = false
				let found_en = false
				for (let i = 0; i < this.props.langs.length; i++)
				{
					if (this.props.langs[i] === user.langage)
						found = true
					else if (this.props.langs[i] === "en")
						found_en = true
				}
				if (!found)
				{
					user.langage = (found_en) ? "en" : this.props.lang_default
					this.props.updateUserLang(user)
					.then(ret => {
						user.from = 'webapp'
						Cookies.set('session', user.session)
						localStorage.setItem("session", user.session)
						this.updateUser(user, user.session)
					})
					.catch(err => {
						this.setErrorMsg("Une erreur s'est produite.")
						console.log(err)
					})
				}
				else
				{
					user.from = 'webapp'
					Cookies.set('session', user.session)
					localStorage.setItem("session", user.session)
					this.updateUser(user, user.session)
				}
			}
			else
			{
				this.setErrorMsg("Identifiant ou mot de passe incorrect.")
				this.setState({ attempt_connexion: false })
			}
		})
		.catch(err => {
			this.setState({ attempt_connexion: false })
			this.setErrorMsg("Une erreur s'est produite")
			console.log(err)
		})
	}

	_connectUser2 = (user) => {
		user.from = 'webapp'
		fetch(connexion.connect.url + 'webapp/users/stays', {
			method: 'POST',
			body: JSON.stringify({
				client_number: user.client_number
			})
		})
		.then(res => res.json())
		.then(ret => {
			if (ret === false) // Pas de séjour
			{
				const stay = {
					stay_number: '?',
					location: '?',
					arrival: '?',
					departure: '?',
					arrival_time: null,
					departure_time: null
				}
				user.stay = stay
				fetch(connexion.connect.url + 'webapp/users/update-stay', {
					method: 'POST',
					body: JSON.stringify({
						id: user._id,
						stay: stay
					})
				})
				.then(res => res.json())
				.then(ret => {
					const nextStep = (this.showPrompt) ? 5 : 3
					this.clearFlash()
					this.setState({ step: nextStep, user: user })
				})
				.catch(err => {
					this.setErrorMsg("Une erreur s'est produite")
				})
			}
			else if (ret.length === 1) // Un séjour sur la fiche client
			{
				const stay = {
					stay_number: ret[0].stay_number,
					location: ret[0].location,
					arrival: ret[0].arrival,
					departure: ret[0].departure,
					arrival_time: ret[0].arrival_time,
					departure_time: ret[0].departure_time
				}
				user.stay = stay
				fetch(connexion.connect.url + 'webapp/users/update-stay', {
					method: 'POST',
					body: JSON.stringify({
						id: user._id,
						stay: stay
					})
				})
				.then(res => res.json())
				.then(ret => {
					const nextStep = (this.showPrompt) ? 5 : 3
					this.clearFlash()
					this.setState({ step: nextStep, user: user })
				})
				.catch(err => {
					this.setErrorMsg("Une erreur s'est produite")
				})
			}
			else // Plusieurs séjours sur la fiche client
			{
				this.clearFlash()
				this.setState({ step: 2, stay_list: ret, user: user })
			}
		})
		.catch(err => {
			this.setState({ attempt_connexion: false })
			this.setErrorMsg("Une erreur s'est produite")
		})
	}
	*/

	_connectDashboard = (identifiant, password) => {
		fetch(connexion.connect.url + 'dashboard/users/login', {
			method: 'POST',
			body: JSON.stringify({
				login: identifiant,
				password: password
			})
		})
		.then(res => res.json())
		.then(user => {
			if (user === false)
			{
				this.setErrorMsg("Identifiant ou mot de passse incorrect.")
				this.setState({ attempt_connexion: false })
			}
			else
			{
				let found = false
				for (let i = 0; i < this.props.langs.length; i++)
				{
					if (this.props.langs[i] === user.langage)
					{
						found = true
						break
					}
				}
				if (!found)
				{
					user.langage = this.props.lang_default
					this.props.updateUserDashboardLang(user)
					.then(ret => {
						this._connectUserDashboard2(user)
					})
					.catch(err => {
						this.setErrorMsg("Une erreur s'est produite.")
						console.log(err)
					})
				}
				else
					this._connectUserDashboard2(user)
			}
		})
		.catch(err => {
			this.setState({ attempt_connexion: false })
			this.setErrorMsg("Une erreur s'est produite")
			console.log(err)
		})
	}

	_connectUserDashboard2 = (user) => {
		const arrival = new Date()
		const arrival_time = Math.floor(arrival.getTime() / 1000)
		const departure = new Date(arrival.getTime() + 86400 * 1000)
		const departure_time = Math.floor(departure.getTime() / 1000)
		const update = {
			...user,
			from: 'dashboard',
			civility: null,
			birthday: null,
			client_number: 0,
			activate: true,
			langage: user.langage,
			stay: {
				stay_number: 0,
				arrival: tools.parseDate(arrival_time, false),
				arrival_time: arrival_time,
				departure: tools.parseDate(departure_time, false),
				departure_time: departure_time,
				location: "?"
			}
		}
		if (this.showPrompt)
			this.setState({ step: 5, user: update })
		else
		{
			Cookies.set('session', update.session)
			this.setState({ step: 6 })
			localStorage.setItem("session", update.session)
			this.updateUser(update, update.session)
		}
	}

	forgotPassword = () => {
		this.clearFlash()
		this.setState({ step: 4 })
	}

	handleSubmit = (event) => {
		event.preventDefault()
		this.clearFlash()
		this.setState({ attempt_connexion: true })
		const identifiant = this.state.form1.identifiant
		const password = this.state.form1.password
		if (identifiant.indexOf('@') !== -1)
		{
			// this._connectUser(identifiant, password)
			const user = {
				identifiant: identifiant,
				password: password
			}
			this.props.postConnectUser(user)
			.then(user => {
				this.setState({ attempt_connexion: false })
				if (user === false)
					this.setErrorMsg("Identifiant ou mot de passe incorrect.")
				else
				{
					user.from = 'webapp'
					if (user.stay.length > 1)
					{
						this.clearFlash()
						this.setState({ step: 2, user: user })
					}
					else if (this.showPrompt)
					{
						// const nextStep = (this.showPrompt) ? 5 : 3
						this.clearFlash()
						console.log("user1", user)
						this.setState({ step: 5, user: user })
					}
					else
					{
						Cookies.set('session', user.session)
						localStorage.setItem("session", user.session)
						this.updateUser(user, user.session)
						this.setState({ step: 6 })
					}
				}
			})
			.catch(err => console.log("can not connect user", err))
		}
		else
			this._connectDashboard(identifiant, password)
	}

	handleStaySubmit = (event) => {
		event.preventDefault()
		let stay_id = this.state.form2.stay
		if (stay_id === "")
			this.setErrorMsg("Veuillez sélectionner un emplacement")
		else
		{
			let stay = null
			stay_id = Number(stay_id)
			for (let i = 0; i < this.state.user.stay.length; i++)
			{
				if (this.state.user.stay[i].stay_number === stay_id)
				{
					stay = this.state.user.stay[i]
					break
				}
			}
			let user = this.state.user
			user.stay = stay
			this.props.postUpdateStay({
				id: user._id,
				stay: stay.stay_number
			})
			.then(ret => {
				if (this.showPrompt)
				{
					// const nextStep = (this.showPrompt) ? 5 : 3
					this.clearFlash()
					this.setState({ step: 5, user: user })
				}
				else
				{
					Cookies.set('session', user.session)
					localStorage.setItem("session", user.session)
					this.updateUser(user, user.session)
				}
			})
			.catch(err => {
				this.setErrorMsg("Une erreur s'est produite, veuillez réessayer plus tard")
				console.log("can not update stay", err)
			})
		}
	}

	handleShareSubmit = (event) => {
		event.preventDefault()
		const user = this.state.user
		let emails = []
		let j = 0
		for (let i = 0; i < this.state.form3.nb_emails; i++)
		{
			let email = this.state.form3.emails[i]
			if (/^[\w!#$%&'*+-/=?^_`{|}~]+(\.[\w!#$%&'*+-/=?^_`{|}~]+)*@((([-\w]+\.)+[a-zA-Z]{2,4})|(([0-9]{1,3}\.){3}[0-9]{1,3}))$/.test(email) === false)
				continue
			emails[j] = email
			j++
		}
		if (emails.length > 0)
		{
			console.log(emails, user.client_number)
			fetch(connexion.connect.url + 'webapp/users/share-email', {
				method: 'POST',
				body: JSON.stringify({
					client_number: user.client_number,
					emails: emails,
					user_id: user._id
				})
			})
			.then(res => res.json())
			.then(ret => {
				Cookies.set('session', user.session)
				localStorage.setItem("session", user.session)
				this.updateUser(user, user.session)
			})
			.catch(err => {
				this.setErrorMsg("Une erreur s'est produite")
				console.log(err)
			})
		}
		else
		{
			Cookies.set('session', user.session)
			localStorage.setItem("session", user.session)
			this.updateUser(user, user.session)
		}
	}

	submitForgot = (event) => {
		event.preventDefault()
		this.setState({ attempt_submit: true })
		const email = this.state.form4.v_email
		if (email.length === 0)
		{
			this.setErrorMsg("Veuillez indiquer votre adresse e-mail")
			this.setState({ attempt_submit: false })
		}
		else
		{
			fetch(connexion.connect.url + 'webapp/users/forgot-password', {
				method: 'POST',
				body: JSON.stringify({
					email: email
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret === false)
				{
					this.setErrorMsg("Une erreur s'est produite")
					this.setState({ attempt_submit: false })
				}
				else if (ret === 1)
				{
					this.setErrorMsg("L'adresse e-mail indiquée n'existe pas")
					this.setState({ attempt_submit: false })
				}
				else
				{
					this.setSuccessMsg("Un e-mail vient d'être envoyé à l'adresse indiquée")
					this.setState({ attempt_submit: false })
				}
			})
			.catch(err => {
				this.setErrorMsg("Une erreur s'est produite")
				this.setState({ attempt_submit: false })
				console.log(err)
			})
		}
	}

	handleInstallApp = (answer) => {
		const user = this.state.user
		if (answer === true)
		{
			this.props.installEvent.prompt()
			this.props.installEvent.userChoice
			.then(choice => {
				fetch(connexion.connect.url + 'webapp/config/downloads/increase')
				.then(res => res.json())
				.then(ret => {
					if (ret !== true)
						console.log("could not increase downloads nb")
					setTimeout(() => {
						Cookies.set('session', user.session)
						localStorage.setItem("session", user.session)
						this.updateUser(user, user.session)
						this.setState({ step: 6 })
						this.props.deleteInstallEvent()
					}, 1000)
				})
				.catch(err => {
					console.log("could not make request for increase downloads", err)
					setTimeout(() => {
						Cookies.set('session', user.session)
						localStorage.setItem("session", user.session)
						this.updateUser(user, user.session)
						this.setState({ step: 6 })
						this.props.deleteInstallEvent()
					}, 1000)
				})
			})
		}
		else
		{
			Cookies.set('session', user.session)
			localStorage.setItem("session", user.session)
			this.setState({ step: 6 })
			this.updateUser(user, user.session)
		}
	}

	handlePreviousStep4 = () => this.setState({ step: 1, error: null, success: null })

	render() {
		const btn4_color = (this.state.form4.v_email.length !== 0) ? this.activated_btn_color : this.deactivated_btn_color
		const topBtnStyle = {
			zIndex: "20",
			display: "block",
			top: "10px",
			position: "fixed",
			width: "100%",
			marginLeft : "10px"
		}
		const btnStyle = "font-semibold py-5 w-full rounded-full text-white shadow-xl text-xl my-10 waves-effect waves-light"
		if (this.state.step === 1) // Se connecter
		{
			const lang_assets = this.lang_assets['fr'].signin.step1
			let error = null
			if (this.state.error)
			{
				error = (
					<div className="rounded-md bg-red-50 p-4 my-3">
						<div className="flex">
							<div className="flex-shrink-0">
								<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
							</div>
							<div className="ml-3">
								<h3 className="text-sm font-medium text-red-800">{this.state.error}</h3>
							</div>
						</div>
					</div>
				)
			}
			let formJSX = (
				<form className="mb-13" onSubmit={this.handleSubmit}>
					<h3 className="text-lg text-gray-500">{lang_assets.email_title}</h3>
					<label>
						<div className="border-b border-gray-300 focus-within:border-indigo-600">
							<input
								className="block pl-0 w-full border-0 border-b border-transparent focus:border-indigo-600 text-gray-700 focus:ring-0 text-xl"
								type="text" 
								value={this.state.form1.identifiant} 
								onFocus={this.focusID} 
								onBlur={this.blurID} 
								onChange={this.handleID}
							/>
						</div>
					</label>
					<h3 className="text-lg text-gray-500 mt-5">{lang_assets.password_title}</h3>
					<div className="mb-3">
						<div className="mt-1 flex justify-between border-b border-gray-300 focus-within:border-indigo-600">
							<div className="focus-within:border-indigo-600">
								<input
									type={this.state.form1.t_password} 
									value={this.state.form1.password} 
									onFocus={this.focusPassword} 
									onBlur={this.blurPassword} 
									onChange={this.handlePassword}
									className="block pl-0 w-full border-0 border-b border-transparent focus:border-indigo-600 text-gray-700 focus:ring-0 text-xl"
								/>
							</div>
							<button
								type="button"
								value={this.state.form1.visible}
								onClick={this.handleVisible}
								className="-ml-px relative inline-flex space-x-2 px-4 py-2 text-sm font-medium text-gray-700 focus-within:border-indigo-600"
							>
								<EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
							</button>
						</div>
					</div>
					{error}
					<button className={btnStyle} type="submit" style={{ backgroundColor: this.state.current_btn_color }}>{lang_assets.button}</button>
				</form>
			)
			if (this.state.attempt_connexion === true)
			{
				formJSX = (
					<div className="wait">
						<Loader />
					</div>
				)
			}
			return (
				<div>
					<div style={{backgroundColor: "#" + this.props.theme.color1}} className="h-52 z-0 px-8 text-center">
						<h1 className="text-3xl text-white font-semibold m-0 pt-16">{lang_assets.title}</h1>
					</div>
					<Link to="/" style={topBtnStyle}>
						<button
							style={{ backgroundColor: '#' + this.props.theme.color1 }}
							className="inline-flex drop-shadow-lg items-center p-3 border border-transparent rounded-full shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
						>
							<ArrowLeftIcon className="h-6 w-6" aria-hidden="true" />
						</button>			
					</Link>
					<div className="rounded-t-3xl z-10" style={{backgroundColor: "#ffffff", marginTop: "-2rem"}}>
						<div className="px-10 pt-16 pb-10">
							<h2 className="text-3xl font-semibold text-gray-700 mb-10">{lang_assets.subtitle}</h2>
							<p className="success">{this.props.activate_msg}</p>
							<div>
								{formJSX}
							</div>
							<p className="switchPage" onClick={this.forgotPassword}><b style={{ color: '#' + this.theme.color1 }}>{lang_assets.forgot_password}</b></p>
							<div className="relative h-32">
								<p className="switchPage absolute inset-x-0 bottom-0 h-16"><Link className="text-gray-400 text-base" to="/inscription">{lang_assets.subscribe_option} <b style={{ color: '#' + this.theme.color1 }}>{lang_assets.subscribe_option_btn}</b></Link></p>
							</div>
						</div>
					</div>
				</div>
			)
		}
		else if (this.state.step === 2) // Sélectionner le séjour
		{
			// const lang_assets = this.lang_assets[this.state.user.langage].signin.step2
			const lang_assets = this.lang_assets['fr'].signin.step2
			let error = null
			if (this.state.error)
			{
				error = (
					<div className="rounded-md bg-red-50 p-4 my-3">
						<div className="flex">
							<div className="flex-shrink-0">
								<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
							</div>
							<div className="ml-3">
								<h3 className="text-sm font-medium text-red-800">{this.state.error}</h3>
							</div>
						</div>
					</div>
				)
			}
			return (
				<div>
					<div style={{backgroundColor: "#" + this.props.theme.color1}} className="h-52 z-0 px-8 text-center">
						<h1 className="text-3xl text-white font-semibold m-0 pt-16">{lang_assets.title}</h1>
					</div>
					<div className="rounded-t-3xl z-10" style={{backgroundColor: "#ffffff", marginTop: "-2rem"}}>
						<div className="px-10 pt-16 pb-10">
							<h2 className="text-3xl font-semibold text-gray-700 mb-10">{lang_assets.subtitle}</h2>
							<form onSubmit={this.handleStaySubmit}>
								<div className="space-y-5">
									{this.state.user.stay.map(stay => (
										<div key={stay._id} className="relative flex items-center">
											<div className="flex items-center h-5">
												<input
													id={stay._id}
													name="stay"
													type="radio"
													onChange={this.handleStay.bind(this, stay.stay_number)}
													className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
												/>
											</div>
											<div className="ml-3 text-lg">
												<label htmlFor={stay._id}>
													<p className="font-semibold text-gray-700">
														{stay.location_category}
													</p>
													<p className="text-gray-500">
														{stay.arrival} - {stay.departure}
													</p>
												</label>
											</div>
										</div>
									))}
								</div>
								{error}
								<button type="submit" className={btnStyle} style={{ backgroundColor: '#' + this.theme.color1 }}>{lang_assets.button}</button>
							</form>
						</div>
					</div>
				</div>
			)
		}
		else if (this.state.step === 3) // Partagez le lien de l'application
		{
			// const lang_assets = this.lang_assets[this.state.user.langage].signin.step3
			const lang_assets = this.lang_assets['fr'].signin.step3
			return (
				<div>
					<div style={{backgroundColor: "#" + this.props.theme.color1}} className="h-52 z-0 px-8 text-center">
						<h1 className="text-3xl text-white font-semibold m-0 pt-16">{lang_assets.title}<span role="img" aria-label="smiling face with hearts"> 🥰</span></h1>
					</div>
					<div className="rounded-t-3xl z-10" style={{backgroundColor: "#ffffff", marginTop: "-2rem"}}>
						<div className="px-10 pt-16 pb-10">
							<h2 className="text-3xl font-semibold text-gray-700 mb-10">{lang_assets.subtitle}</h2>
							<form onSubmit={this.handleShareSubmit}>
								<div>
									{this.state.form3.emails.map((val, index) => {
										const value = this.state.form3.emails[index]
										return (
											<label key={index}>
												<input style={{marginBottom : "1.5rem"}} type="text" value={value} onBlur={this.blurEmails.bind(this, index)} onFocus={this.focusEmails.bind(this, index)} onChange={this.handleEmails.bind(this, index)} />
											</label>
										)
									})}
									<div className="mt-4">
										<div className="w-full">
											<button
												style={{ backgroundColor: '#' + this.props.theme.color1 }}
												className="btn-floating btn-large waves-effect waves-light"
												onClick={this.addEmail}
											>
												<i className="material-icons">add</i>
											</button>
										</div>
									</div>
								</div>
								<button type="submit" className={btnStyle} style={{ backgroundColor: '#' + this.theme.color1 }}>{lang_assets.button}</button>
							</form>
						</div>
					</div>
				</div>

				// <div className="Signin">
				// 	<h1 className="collapse">{lang_assets.title}</h1>
				// 	<h3>{error}</h3>
				// 	<p className="center">{lang_assets.subtitle}</p>
				// 	<form className="login" onSubmit={this.handleShareSubmit}>
				// 		<div>
				// 			{this.state.form3.emails.map((val, index) => {
				// 				const value = this.state.form3.emails[index]
				// 				return (
				// 					<label className="row" key={index}>
				// 						<input type="text" value={value} onBlur={this.blurEmails.bind(this, index)} onFocus={this.focusEmails.bind(this, index)} onChange={this.handleEmails.bind(this, index)} />
				// 					</label>
				// 				)
				// 			})}
				// 			<p className="underline" onClick={this.addEmail}>{lang_assets.add}</p>
				// 		</div>
				// 		<div className="row send">
				// 			<button type="submit" style={{ backgroundColor: '#' + this.theme.color4 }}>{lang_assets.button}</button>
				// 		</div>
				// 	</form>
				// </div>
			)
		}
		else if (this.state.step === 4) // Mot de passe oublié
		{
			let error = null
			if (this.state.error)
			{
				error = (
					<div className="rounded-md bg-red-50 p-4 my-3">
						<div className="flex">
							<div className="flex-shrink-0">
								<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
							</div>
							<div className="ml-3">
								<h3 className="text-sm font-medium text-red-800">{this.state.error}</h3>
							</div>
						</div>
					</div>
				)
			}
			let success = null
			if (this.state.success)
			{
				success = (
					<div className="rounded-md bg-green-50 p-4 my-3">
						<div className="flex">
							<div className="flex-shrink-0">
								<CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
							</div>
							<div className="ml-3">
								<h3 className="text-sm font-medium text-green-800">{this.state.success}</h3>
							</div>
						</div>
					</div>
				)
			}
			let submit_btn = (
				<button 
					className="z-0 font-semibold py-5 w-full rounded-full text-white shadow-xl text-xl mt-10" 
					type="submit"
					style={{ backgroundColor: btn4_color }}
				>
					Envoyez-moi un e-mail
				</button>
			)
			if (this.state.attempt_submit === true)
			{
				submit_btn = (
					<div className="text-center">
						<Loader />
					</div>
				)
			}
			return (
				<div>
					<ButtonPrevious 
						theme={this.props.theme}
						handlePreviousBtn={this.handlePreviousStep4}
					/>
					<div style={{backgroundColor: "#" + this.props.theme.color1}} className="h-52 z-0 px-8 text-center">
						<h1 className="text-3xl text-white m-0 font-semibold pt-16">Problèmes de connexion ?</h1>
					</div>
					<div className="rounded-t-3xl z-10 px-8 pt-16" style={{backgroundColor: "#ffffff", marginTop: "-3rem"}}>
						<h1 className="text-3xl text-gray-700 font-semibold mb-10">Retrouvez votre compte</h1>
						<p className="mb-5 text-base">Veuillez entrer votre adresse e-mail pour rechercher votre compte. Nous vous enverrons un lien pour récupérer votre compte.</p>
						<form onSubmit={this.submitForgot}>
							<label>
								<div className="border-b border-gray-300 focus-within:border-indigo-600">
									<input
										className="block pl-0 w-full border-0 border-b border-transparent focus:border-indigo-600 text-gray-500 text-gray-700 focus:ring-0 text-xl"
										type="email" 
										value={this.state.form4.email} 
										onFocus={this.focusEmail} 
										onBlur={this.blurEmail} 
										onChange={this.handleEmail}
									/>
								</div>
							</label>
							{error}
							{success}
							{submit_btn}
						</form>
					</div>
				</div>
			)
		}
		else if (this.state.step === 5) // Installer la PWA
		{
			const lang_assets = this.lang_assets['fr'].signin.step5   
			// const lang_assets = this.lang_assets[this.state.user.langage].signin.step5
			return (
				<div>
					<div style={{backgroundColor: "#" + this.props.theme.color1}} className="h-52 z-0 px-8 text-center">
						{/* <p className="text-2xl text-white pt-14">Installer</p> */}
						<h1 className="text-3xl text-white font-semibold m-0 pt-16">{lang_assets.title}</h1>
					</div>
					<div className="rounded-t-3xl z-10" style={{backgroundColor: "#ffffff", marginTop: "-2rem"}}>
						<div className="px-10 pt-16 pb-10">
							<h2 className="text-3xl font-semibold text-gray-700 mb-10">{lang_assets.subtitle} <span role="img" aria-label="star-struck">🤩</span></h2>
						 	<h3 className="text-xl font-semibold" style={{ color: '#' + this.theme.color1 }}><span className="mr-3" role="img" aria-label="star-struck">🚀</span>{lang_assets.info}</h3>
							<button type="button" className={btnStyle} onClick={this.handleInstallApp.bind(this, true)} style={{ backgroundColor: '#' + this.theme.color1 }}>{lang_assets.button}</button>
							<button className="font-semibold py-5 w-full text-xl mb-10 text-center" style={{ color: '#' + this.theme.color1 }} onClick={this.handleInstallApp.bind(this, false)}>{lang_assets.later}</button>
						</div>
					</div>
				</div>

				// <div className="Signin">
				// 	<h1 style={{ color: '#' + this.theme.color1 }}>{lang_assets.title}</h1>
				// 	<h2 className="promote" style={{ color: '#' + this.theme.color3 }}>{lang_assets.subtitle}</h2>
				// 	<h3 className="info">{lang_assets.infos}</h3>
				// 	<button type="button" className="download" onClick={this.handleInstallApp.bind(this, true)} style={{ backgroundColor: '#' + this.theme.color2 }}>{lang_assets.button}</button>
				// 	<button className="nothanks" style={{ color: '#' + this.theme.color2 }} onClick={this.handleInstallApp.bind(this, false)}>{lang_assets.later}</button>
				// </div>
			)
		}
		else if (this.state.step === 6)
		{
			return (
				<div>
					<Redirect to="/" />
				</div>
			)
		}
	}
}

export default Signin
