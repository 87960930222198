import React, { Component } from 'react'
import Loader from '../UI/Loader'
import ModalMessage from '../UI/ModalMessage'
import ButtonPrevious from '../UI/ButtonPrevious'

class ActivationCodeHelp extends Component {
	constructor(props) {
		super(props)
		// this.lang_assets = {
		// 	fr: french_assets,
		// 	en: english_assets,
		// 	de: german_assets,
		// 	nl: dutch_assets,
		// 	it: italian_assets,
		// 	es: spanish_assets,
		// 	pt: portuguese_assets
		// }
		this.default_ph_color = "text-gray-400"
		this.updated_ph_color = "text-black"
		this.activated_btn_color = "#" + this.props.theme.color1
		this.deactivated_btn_color = "#A3A3A3"
		this.email_ph = "Email de la réservation"
		this.state = {
			form1: {
				email: this.email_ph,
				v_email: "",
			},
			attempt_submit: false,
			current_btn_color : this.deactivated_btn_color,
			modal_title: "Code d'activation envoyé !",
			modal_body: "Consultez votre boite de réception pour récupérer votre code !",
			modal_btn: "OK",
			error: null,
			success: null,
			show : false
		}
	}

	setErrorMsg = (msg) => {
		this.setState({ success: null, error: msg })
	}

	setSuccessMsg = (msg) => {
		this.setState({ success: msg, error: null })
	}

	clearFlash = () => {
		this.setState({ error: null, success: null })
	}

	_initErrors = () => {
		const errors = {
			global: null,
			email: null
		}
		return errors
	}

	focusEmail = (event) => {
		if (this.state.form1.v_email.length === 0) {
			let form1 = {
				...this.state.form1,
				email: '',
				v_email: this.state.form1.v_email
			}
			this.setState({ form1: form1 })
		}
	}

	blurEmail = (event) => {
		if (this.state.form1.v_email.length === 0) {
			let form1 = {
				...this.state.form1,
				email: this.email_ph,
				v_email: this.state.form1.v_email
			}
			this.setState({ form1: form1 })
		}
	}

	handleEmail = (event) => {
		this.btn_color(event)
		let form1 = {
			...this.state.form1,
			email: event.target.value.trim(),
			v_email: event.target.value.trim()
		}
		this.setState({ form1: form1 })
	}

	btn_color = (event) => {
		let style = this.state.current_btn_color
		if (event.target.value.length !== 0)
			style = this.activated_btn_color
		else
			style = this.deactivated_btn_color
		this.setState({ current_btn_color : style })
	}

	input_ph_color = () => {
		let color = this.default_ph_color
		if (this.state.form1.v_email !== "")
			color = this.updated_ph_color
		return (color)
	}

	_validateForm1 = () => {
		let error_obj = this._initErrors()
		let error = false
		const email = this.state.form1.v_email
		if (!email || !email.length)
		{
			error_obj.email = "Saisissez votre adresse e-mail"
			error = true
		}
		if (error === true)
		{
			error_obj.global = "Il y a une ou plusieurs erreur(s) dans ce formulaire"
			this.setState({ success: null, error: error_obj })
		}
		return (!error) ? true : false
	}

	handleSubmit = (event) => {
		event.preventDefault()
		this.setState({ attempt_submit: true })
		if (this._validateForm1())
		{
			this.props.postRequestActivationCode(this.state.form1.v_email)
			.then(ret => {
				if (ret === true)
					this.setState({ show: true, attempt_submit: false })
				else if (ret === null)
				{
					this.setState({ attempt_submit: false })
					this.setErrorMsg("Nous n'avons pas retrouvé de code d'activation lié à cette adresse e-mail")
				}
				else
				{
					this.setState({ attempt_submit: false })
					this.setErrorMsg("Une erreur s'est produite")
				}
			})
			.catch(err => {
				this.setState({ attempt_submit: false })
				this.setErrorMsg("Une erreur s'est produite")
				console.log(err)
			})
		}
	}

	hideModal = (e) => {
		e.preventDefault()
		this.setState({ show: false })
	}

	render() {
		let modal = null 
		if (this.state.show === true)
		{
			modal = (<ModalMessage 
				show={this.state.show}
				hideModal={this.hideModal} 
				theme={this.props.theme}
				title={this.state.modal_title}
				body_text={this.state.modal_body}
				btn_text={this.state.modal_btn}
				validateHelp={this.props.modalRedirection}
			/>)
		}
		let submit_btn = (
			<button 
				className="z-0 font-semibold py-5 w-full rounded-full text-white shadow-xl text-xl mt-10 mb-10"
				type="submit"
				style={{ backgroundColor: this.state.current_btn_color }}
			>
				Valider
			</button>
		)
		if (this.state.attempt_submit === true)
		{
			submit_btn = (
				<div className="text-center">
					<Loader />
				</div>
			)
		}
		return (
			<div className="z-0">
				<ButtonPrevious
					theme={this.props.theme}
					handlePreviousBtn={this.props.handlePreviousBtn}
				/>
				<div style={{backgroundColor: "#" + this.props.theme.color1}} className="h-52 z-0 px-8 text-center">
					<h1 className="text-3xl text-white m-0 font-semibold pt-16">Code appli</h1>
				</div>
				<div className="rounded-t-3xl z-10 px-8 pt-16" style={{backgroundColor: "#ffffff", marginTop: "-3rem"}}>
					<h1 className="text-3xl text-gray-700 font-semibold mb-5">Retrouver mon e-mail ou mon code d'activation</h1>
					<p className="mb-5 font-bold text-lg text-gray-700">Comment me connecter ?</p>
					<p className="mb-5 text-base text-gray-700">Pour vous connecter à l’application, vous pouvez utiliser soit l’adresse email utilisée lors de la réservation de votre séjour, soit le code d’activation que vous avez reçu par email après votre réservation.</p>
					
					<p className="mb-5 font-bold text-lg text-gray-700">Si vous avez oublié votre adresse email ou n'avez pas reçu le code d'activation <span role="img" aria-label="confused">😕</span></p>
					<p className="mb-5 text-base text-gray-700">Si vous ne vous souvenez plus de l’adresse email utilisée lors de la réservation, veuillez contacter directement l’établissement par email ou par téléphone.</p>
					<p className="mb-5 text-base text-gray-700">Fournissez-leur le nom et prénom de la personne ayant effectué la réservation pour obtenir de l'aide.</p>

					<p className="mb-5 font-bold text-lg text-gray-700">Je souhaiterai recevoir à nouveau le mail de bienvenue contenant mon code d'activation <span role="img" aria-label="mail">📩</span></p>
					<form onSubmit={this.handleSubmit}>
						<p className="text-base text-green-800">{this.state.success}</p>
						<p className="text-base text-red-400">{this.state.error}</p>
						<label>
							<div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
								<input
									className="block pl-0 w-full border-0 border-b border-transparent text-gray-500 focus:text-gray-700 focus:border-indigo-600 focus:ring-0 text-lg"
									type="email" 
									value={this.state.form1.email} 
									onFocus={this.focusEmail} 
									onBlur={this.blurEmail} 
									onChange={this.handleEmail}
								/>
							</div>
						</label>
						{submit_btn}
					</form>
					{modal}
				</div>
			</div>
		)
	}
}

export default ActivationCodeHelp

