import React, { Component } from 'react'
import Loader from '../UI/Loader'
import Cookies from 'js-cookie'
import connexion from '../../connexion'
import './Signin.css'
import { EyeIcon, XCircleIcon } from '@heroicons/react/solid'
import { CheckCircleIcon } from '@heroicons/react/outline'

class Forgot extends Component {
	constructor(props) {
		super(props)
		this.password_ph = 'Nouveau mot de passe'
		this.state = {
			validate: false,
			update: null,
			user: null,
			form1: {
				password: this.password_ph,
				v_password: "",
				t_password: "text",
				visible: false
			},
			error: null
		}
		this.code = props.match.params.code
	}

	componentDidMount()
	{
		this.props.postRetrieveUserByHash(this.code)
		.then(ret => {
			if (ret === false)
			{
				this.setErrorMsg("Le code de récupération est invalide")
				this.setState({ update: false })
			}
			else
				this.setState({ update: ret })
		})
		.catch(err => {
			console.log(err)
			this.setErrorMsg("Une erreur s'est produite")
		})
	}

	componentDidUpdate(prevProps, prevState)
	{
		if (prevState.user !== this.state.user && this.state.user !== null)
			this._connectUser()
	}

	setErrorMsg = (msg) => {
		this.setState({ error: msg })
	}

	focusPassword = (event) => {
		if (this.state.form1.v_password.length === 0) {
			const t_password = (this.state.form1.visible === false) ? "password" : "text"
			let form1 = {
				password: '',
				v_password: this.state.form1.v_password,
				t_password: t_password,
				visible: this.state.form1.visible
			}
			this.setState({ form1: form1 })
		}
	}

	blurPassword = (event) => {
		if (this.state.form1.v_password.length === 0) {
			let form1 = {
				password: this.password_ph,
				v_password: this.state.form1.v_password,
				t_password: "text",
				visible: this.state.form1.visible
			}
			this.setState({ form1: form1 })
		}
	}

	handlePassword = (event) => {
		let form1 = {
			password: event.target.value,
			v_password: event.target.value,
			t_password: this.state.form1.t_password,
			visible: this.state.form1.visible
		}
		this.setState({ form1: form1 })
	}

	handleVisible = (event) => {
		let visible = (this.state.form1.visible === true) ? false : true
		let t_password = (visible === false && this.state.form1.v_password.length > 0) ? "password" : "text"
		let form1 = {
			password: this.state.form1.password,
			v_password: this.state.form1.v_password,
			t_password: t_password,
			visible: visible
		}
		this.setState({ form1: form1 })
	}

	_connectUser = () => {
		const user = {
			identifiant: this.state.user.email,
			password: this.state.user.password
		}
		this.props.postConnectUser(user)
		.then(user => {
			let found = false
			let found_en = false
			for (let i = 0; i < this.props.langs.length; i++)
			{
				if (this.props.langs[i] === user.langage)
					found = true
				else if (this.props.langs[i] === "en")
					found_en = true
			}
			if (!found)
			{
				user.langage = (found_en) ? "en" : this.props.lang_default
				this.props.updateUserLang(user)
				.then(ret => {
					this._connectUser2(user)
				})
				.catch(err => {
					this.setErrorMsg("Une erreur s'est produite.")
					console.log(err)
				})
			}
			else
				this._connectUser2(user)
		})
		.catch(err => {
			this.setErrorMsg("Une erreur s'est produite")
			console.log(err)
		})
	}

	_connectUser2 = (user) => {
		user.from = 'webapp'
		fetch(connexion.connect.url + 'webapp/users/stays', {
			method: 'POST',
			body: JSON.stringify({
				client_number: user.client_number,
				user: user
			})
		})
		.then(res => res.json())
		.then(ret => {
			console.log("stay", ret, user)

			// Séjour par défaut : pas de séjour disponible pour cet utilisateur
			let stay = {
				stay_number: '?',
				location: '?',
				location_category: '?',
				arrival: '?',
				departure: '?',
				arrival_time: null,
				departure_time: null
			}
			if (ret !== false) // Un séjour sur la fiche client
			{
				stay = {
					stay_number: ret[0].stay_number,
					location: ret[0].location,
					location_category: ret[0].location_category,
					arrival: ret[0].arrival,
					departure: ret[0].departure,
					arrival_time: ret[0].arrival_time,
					departure_time: ret[0].departure_time
				}
			}
			user.stay = stay
			fetch(connexion.connect.url + 'webapp/users/update-stay', {
				method: 'POST',
				body: JSON.stringify({
					id: user._id,
					stay: stay
				})
			})
			.then(res => res.json())
			.then(ret => {
				Cookies.set('session', user.session)
				localStorage.setItem("session", user.session)
				this.props.updateUser(user, user.session)
			})
			.catch(err => {
				this.setErrorMsg("Une erreur s'est produite")
			})
		})
		.catch(err => {
			this.setErrorMsg("Une erreur s'est produite")
		})
	}

	submitPassword = (event) => {
		event.preventDefault()
		const password = this.state.form1.v_password
		if (password.length === 0)
			this.setErrorMsg("Veuillez créer un mot de passe")
		else if (password.length < 8)
			this.setErrorMsg("Le mot de passe est trop court (8 caractères min.)")
		else if (/[a-z]/.test(password) === false)
			this.setErrorMsg("Le mot de passe doit contenir au moins une lettre")
		else if (/[0-9]/.test(password) === false)
			this.setErrorMsg("Le mot de passe doit contenir au moins un chiffre")
		else
		{
			const user = {
				_id: this.state.update,
				password: password
			}
			this.props.postUpdatePassword(user)
			.then(user => {
				if (user === false)
					this.setErrorMsg("Une erreur s'est produite")
				else
				{
					user.password = password
					this.setState({ user: user })
				}
			})
			.catch(err => {
				console.log(err)
				this.setErrorMsg("Une erreur s'est produite")
			})
		}
	}

	render() {
		let error = null
		if (this.state.update === null || this.state.user !== null)
		{
			return (
				<div className="App">
					<div className="loading">
						<Loader />
					</div>
				</div>
			)
		}
		else if (this.state.update === false)
		{
			return (
				<div className="App">
					<div className="loading">
						<h1>Code de récupération invalide</h1>
					</div>
				</div>
			)
		}
		else
		{
			if (this.state.error)
			{
				error = (
					<div className="rounded-md bg-red-50 p-4 my-3">
						<div className="flex">
							<div className="flex-shrink-0">
								<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
							</div>
							<div className="ml-3">
								<h3 className="text-sm font-medium text-red-800">{this.state.error}</h3>
							</div>
						</div>
					</div>
				)
			}
			return (
				<div className="min-h-4/5-screen">
					<div className="flex justify-center	items-center z-0 w-full fixed top-0 h-36" style={{ backgroundColor: '#' + this.props.theme.color1 }}>
						<div className="text-white text-3xl font-semibold">
							<h1>Mot de passe oublié</h1>
						</div>
					</div>
					<div className="bg-white w-full pt-10 px-8 mt-32 min-h-9/10-screen absolute rounded-t-3xl pb-10">
						<h2 className="text-2xl text-gray-700 font-semibold mb-10">Créer un nouveau mot de passe</h2>
						<form className="login" onSubmit={this.submitPassword}>
							<label className="row">
								<div className="mt-1 flex justify-between border-b border-gray-300 focus-within:border-indigo-600">
									<input
										className="block pl-0 w-full border-0 border-b border-transparent focus:border-indigo-600 text-gray-500 text-gray-700 focus:ring-0 text-lg"
										type={this.state.form1.t_password}
										value={this.state.form1.password} 
										onFocus={this.focusPassword}
										onBlur={this.blurPassword} 
										onChange={this.handlePassword} 
									/>
									<button
										type="button"
										name="visible"
										className="-ml-px relative inline-flex space-x-2 px-4 py-2 text-sm font-medium text-gray-700 focus-within:border-indigo-600"
										value={this.state.form1.visible}
										onClick={this.handleVisible}
									>
										<EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
									</button>
								</div>
							</label>
							<div className="mt-5 text-sm">
								<p className="text-gray-700 font-semibold mb-1.5">Votre mot de passe doit contenir:</p>
								<div className="flex flex-row mb-1">
									<CheckCircleIcon className="h-4 w-4 text-gray-300"/>
									<p className="text-gray-500 pl-1">Au moins 8 caractères</p>
								</div>
								<div className="flex flex-row items-center">
									<CheckCircleIcon className="h-4 w-4 text-gray-300"/>
									<p className="text-gray-500 pl-1">Lettres et chiffres</p>
								</div>
							</div>
							{error}
							<button 
								className="z-0 font-semibold py-5 w-full rounded-full text-white shadow-xl text-xl mt-10" 
								type="submit"
								style={{ backgroundColor: '#' + this.props.theme.color1 }}
							>
								Enregistrer le mot de passe
							</button>
						</form>
					</div>
				</div>
			)
		}
	}
}

export default Forgot