import React, { Component } from 'react'
import M from 'materialize-css'
import ButtonPrevious from '../UI/ButtonPrevious'

class SubscribeStepNbPrimary extends Component {
	constructor(props) {
		super(props)
		this.default_ph_color = "text-gray-400"
		this.updated_ph_color = "text-black"
		this.activated_btn_color = "#" + this.props.theme.color1
		this.deactivated_btn_color = "#A3A3A3"
		this.state = {
			form1: {
				nb_primary: (this.props.user.nb_primary.length > 0) ? this.props.user.nb_primary : "",
			},
			current_btn_color: (this.props.user.nb_primary.length > 0) ? this.activated_btn_color : this.deactivated_btn_color,
			error: null
		}
	}
		
	componentDidUpdate() {
		const elems = document.querySelectorAll('select')
		M.FormSelect.init(elems)
	}

	setErrorMsg = (msg) => {
		this.setState({ error: msg })
	}

	handleNbPrimary = (event) => {
		let form1 = {
            nb_primary: event.target.value
		}
		let current_btn_color = this.deactivated_btn_color
		if (event.target.value.length > 0)
			current_btn_color = this.activated_btn_color
		this.setState({ form1: form1, current_btn_color: current_btn_color })
	}

	nb_ph_color = () => {
		let color = this.default_ph_color
		if (this.state.form1.nb_primary !== "")
			color = this.updated_ph_color
		return (color)
	}

	handleSubmit = (e) => {
		e.preventDefault()
		if (this.state.form1.nb_primary.length === 0)
            this.setErrorMsg("Veuillez renseigner le nombre de candidats")
		else if (this.state.form1.nb_primary > 6)
			this.setErrorMsg("Vous ne pouvez pas ajouter plus de 6 candidats")
		else if (this.state.form1.nb_primary < 1)
			this.setErrorMsg("Nombre de candidats incorrect")
        else
		{
			this.props.updateUser(this.state.form1)
			this.props.validateStepNbPrimary()
		}
	}

	render ()
	{
		return (
			<div>
				<ButtonPrevious
					theme={this.props.theme}
					handlePreviousBtn={this.props.handlePreviousBtn}
				/>
				<div style={{backgroundColor: "#" + this.props.theme.color1}} className="h-52 z-0 px-8 text-center">
					<p className="text-2xl text-white pt-14">Inscription</p>
					<h1 className="text-3xl text-white font-semibold m-0 pt-2">Étape {this.props.stepNb}</h1>
				</div>
				<div className="rounded-t-3xl z-10" style={{backgroundColor: "#ffffff", marginTop: "-2rem"}}>
					<div className="px-10 pt-16 pb-10">
						<h2 className="text-3xl font-semibold text-gray-700 mb-10">Combien de candidats accompagnez-vous ?</h2>
						<form className="mb-16" onSubmit={this.handleSubmit}>
							<p className="text-sm text-red-400 mt-0 mb-3">{this.state.error}</p>
							<div className="flex row space-x-4">
								<input type="number" className={this.nb_ph_color()} value={this.state.form1.nb_primary} onChange={this.handleNbPrimary} />
							</div>
							<button className="font-semibold py-5 w-full rounded-full text-white shadow-xl text-xl my-10 waves-effect waves-light" type="submit" style={{ backgroundColor: this.state.current_btn_color }}>Suivant</button>
						</form>
					</div>
				</div>
			</div>
		)
	}
}


export default SubscribeStepNbPrimary