import React, { Component } from 'react'
import ButtonPrevious from '../UI/ButtonPrevious'
import { XCircleIcon } from '@heroicons/react/solid'

class SubscribeStepName extends Component {
	constructor(props) {
		super(props)
		this.default_ph_color = "text-gray-400"
		this.updated_ph_color = "text-black"
		this.lastname_ph = (this.props.app_type === "campus") ? "ex : Potter" : "ex : Chirac"
		this.firstname_ph = "ex : Patrick"
		if (this.props.app_type === "campus")
			this.firstname_ph = "ex: Harry"
		else if (this.props.app_type === "syndic")
			this.firstname_ph = "ex : Jacques"
		this.activated_btn_color = "#" + this.props.theme.color1
		this.deactivated_btn_color = "#A3A3A3"
		this.state = {
			form1: {
				lastname: (this.props.user.lastname.length > 0) ? this.props.user.lastname : this.lastname_ph,
				v_lastname: (this.props.user.lastname.length > 0) ? this.props.user.lastname : "",
				firstname: (this.props.user.firstname.length > 0) ? this.props.user.firstname : this.firstname_ph,
				v_firstname: (this.props.user.firstname.length > 0) ? this.props.user.firstname : ""
			},
			current_btn_color : (this.is_btn_on() === false) ? this.deactivated_btn_color : this.activated_btn_color,
			success: null,
			error: this._initErrors()
		}
		this.length_max = 50
	}

	setErrorMsg = (key, msg) => {
		const errors = this._initErrors()
		errors[key] = msg
		this.setState({ success: null, error: errors })
	}

	setSuccessMsg = (msg) => {
		this.setState({ success: msg, error: this._initErrors() })
	}

	clearFlash = () => {
		this.setState({ error: null, success: this._initErrors() })
	}

	_initErrors = () => {
		const errors = {
			global: null,
			firstname: null,
			lastname: null,
		}
		return errors
	}

	btn_color = (event, input) => {
		let style = this.state.current_btn_color
		if (event.target.value.length !== 0 && input.length !== 0)
			style = this.activated_btn_color
		else
			style = this.deactivated_btn_color
		this.setState({ current_btn_color : style })
	}

	is_btn_on = () => {
		if (this.props.user.firstname.length > 0 && this.props.user.lastname.length > 0)
			return true
		return false
	}

	// Firstname - Prenom

	focusFirstname = (event) => {
		let error_obj = this.state.error
		error_obj.firstname = null
		this.setState({ error: error_obj }) //flush firstname error value when typing on firstname field
		if (this.state.form1.v_firstname.length === 0) {
			let form1 = {
				...this.state.form1,
				firstname: '',
				v_firstname: this.state.form1.v_firstname
			}
			this.setState({ form1: form1 })
		}
	}

	blurFirstname = (event) => {
		if (this.state.form1.v_firstname.length === 0) {
			let form1 = {
				...this.state.form1,
				firstname: this.firstname_ph
			}
			this.setState({ form1: form1 })
		}
	}
	
	handleFirstname = (event) => {
		this.btn_color(event, this.state.form1.v_lastname)
		let form1 = {
			...this.state.form1,
			firstname: event.target.value,
			v_firstname: event.target.value
		}
		this.setState({ form1: form1 })
	}
	
	ph_color = (input) => {
		let color = this.default_ph_color
		if (input !== "")
			color = this.updated_ph_color
		return (color)
	}

	// Lastname - Nom

	focusLastname = (event) => {
		let error_obj =  this.state.error
		error_obj.lastname = null
		this.setState({ error: error_obj }) //flush last name error value when typing on lastname field
		if (this.state.form1.v_lastname.length === 0) {
			let form1 = {
				...this.state.form1,
				lastname: '',
				v_lastname: this.state.form1.v_lastname
			}
			this.setState({ form1: form1 })
		}
	}

	blurLastname = (event) => {
		if (this.state.form1.v_lastname.length === 0) {
			let form1 = {
				...this.state.form1,
				lastname: this.lastname_ph,
				v_lastname: this.state.form1.v_lastname
			}
			this.setState({ form1: form1 })
		}
	}

	handleLastname = (event) => {
		this.btn_color(event, this.state.form1.v_firstname)
		let form1 = {
			...this.state.form1,
			lastname: event.target.value,
			v_lastname: event.target.value
		}
		this.setState({ form1: form1 })
	}

	_validateForm1 = () => {
		let error_obj = this._initErrors()
		let error = false
		const lastname = this.state.form1.v_lastname
		const firstname = this.state.form1.v_firstname
		if (!lastname || !lastname.length)
		{
			error_obj.lastname = 'Veuillez renseigner votre nom'
			error = true
		}
		else if (/[@./\\[\]#'"%*&!?;:{}0-9_]/.test(lastname))
		{
			error_obj.lastname = 'Ce nom n\'est pas valide, veuillez réessayer'
			error = true
		}
		else if (lastname.length > this.length_max)
		{
			error_obj.lastname = 'Votre nom dépasse le nombre de charactère maximum (' + this.length_max + ' caractères max.)'
			error = true
		}
		if (!firstname || !firstname.length)
		{
			error_obj.firstname = 'Veuillez renseigner votre prénom'
			error = true
		}
		else if (/[@./\\[\]#'"%*&!?;:{}0-9_]/.test(firstname))
		{
			error_obj.firstname = 'Ce prénom n\'est pas valide, veuillez réessayer'
			error = true
		}
		else if (!firstname || firstname.length < 2)
		{
			error_obj.firstname = 'Un peu court pour un prénom !'
			error = true
		}
		else if (firstname.length > this.length_max)
		{
			error_obj.firstname = 'Votre prénom dépasse le nombre de charactère maximum (' + this.length_max + ' caractères max.)'
			error = true
		}
		if (error === true)
		{
			error_obj.global = "Il y a une ou plusieurs erreur(s) dans ce formulaire"
			this.setState({ success: null, error: error_obj })
		}
		return (!error) ? true : false
	}

	handleSubmit = (e) => {
		e.preventDefault()
		if (this._validateForm1())
		{
			this.props.updateUser(this.state.form1)
			this.props.validateStepName()
		}
	}

	_printError = (msg) => {
		if (msg)
		{
			return (
				<div className="rounded-md bg-red-50 p-4 mt-3">
					<div className="flex">
						<div className="flex-shrink-0">
							<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
						</div>
						<div className="ml-3">
							<h3 className="text-sm font-medium text-red-800">{msg}</h3>
						</div>
					</div>
				</div>
			)
		}
		return null
	}

	render ()
	{
		let headerJSX = (
			<div style={{backgroundColor: "#" + this.props.theme.color1}} className="h-52 z-0 px-8 text-center">
				<p className="text-2xl text-white pt-14">Inscription</p>
				<h1 className="text-3xl text-white font-semibold m-0 pt-2">Étape {this.props.stepNb}</h1>
			</div>
		)
		if (this.props.stepNb === null)
		{
			headerJSX = (
				<div style={{backgroundColor: "#" + this.props.theme.color1}} className="h-52 z-0 px-8 text-center">
					<h1 className="text-3xl text-white font-semibold m-0 pt-14">Inscription</h1>
				</div>
			)
		}
		const errorLastName = this._printError(this.state.error.lastname)
		const errorFirstName = this._printError(this.state.error.firstname)
		return (
			<div>
				<ButtonPrevious
					theme={this.props.theme}
					handlePreviousBtn={this.props.handlePreviousBtn}
				/>
				{headerJSX}
				<div className="rounded-t-3xl z-10" style={{backgroundColor: "#ffffff", marginTop: "-2rem"}}>
					<div className="px-10 pt-16 pb-10">
						<div>
							<h2 className="text-3xl font-semibold text-gray-700 mb-10">Pour mieux vous connaître !</h2>
							<form className="mb-16" onSubmit={this.handleSubmit}>
								<h3 className="text-lg text-gray-500">Nom</h3>
								<label>
									{/* <input type="text" className={this.ph_color(this.state.form1.v_lastname)} value={this.state.form1.lastname} onFocus={this.focusLastname} onBlur={this.blurLastname} onChange={this.handleLastname} /> */}
									<div className="my-5 border-b border-gray-300 focus-within:border-indigo-600">
										<input
											type="text"
											className="block pl-0 w-full border-0 border-b border-transparent text-gray-500 focus:text-gray-700 focus:border-indigo-600 focus:ring-0 text-xl"
											value={this.state.form1.lastname}
											onChange={this.handleLastname}
											onBlur={this.blurLastname}
											onFocus={this.focusLastname}
										/>
									</div>
								</label>
								{errorLastName}
								<h3 className="text-lg text-gray-500">Prénom</h3>
								<label>
									{/* <input type="text" className={this.ph_color(this.state.form1.v_firstname)} value={this.state.form1.firstname} onFocus={this.focusFirstname} onBlur={this.blurFirstname} onChange={this.handleFirstname}/> */}
									<div className="my-5 border-b border-gray-300 focus-within:border-indigo-600">
										<input
											type="text"
											className="block pl-0 w-full border-0 border-b border-transparent text-gray-500 focus:text-gray-700 focus:border-indigo-600 focus:ring-0 text-xl"
											value={this.state.form1.firstname}
											onChange={this.handleFirstname}
											onBlur={this.blurFirstname}
											onFocus={this.focusFirstname}
										/>
									</div>
								</label>
								{errorFirstName}
								<button className="font-semibold py-5 w-full rounded-full text-white shadow-xl text-xl my-10 waves-effect waves-light" type="submit" style={{ backgroundColor: this.state.current_btn_color }}>Suivant</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default SubscribeStepName