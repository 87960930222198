import React, { Component } from 'react'
import { ArrowLeftIcon } from '@heroicons/react/outline'

class ButtonPrevious extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render ()
	{
		const Header = {
			zIndex: "30",
			display: "block",
			top: "10px",
			position: "fixed",
			width: "100%",
			marginLeft : "10px"
		}

		return (
			<div style={Header}>
				<button
					style={{ backgroundColor: '#' + this.props.theme.color1 }}
					className="inline-flex drop-shadow-lg items-center p-3 border border-transparent rounded-full shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
					onClick={this.props.handlePreviousBtn}
				>
					<ArrowLeftIcon className="h-6 w-6" aria-hidden="true" />
				</button>
			</div>
		)
	}
}

export default ButtonPrevious