import React, { Component } from 'react'
// import M from 'materialize-css'
import ButtonPrevious from '../UI/ButtonPrevious'
import { XCircleIcon } from '@heroicons/react/solid'

class SubscribeStepNbParticipants extends Component {
	constructor(props) {
		super(props)
		this.default_ph_color = "text-gray-400"
		this.updated_ph_color = "text-black"
		this.activated_btn_color = "#" + this.props.theme.color1
		this.deactivated_btn_color = "#A3A3A3"
		this.state = {
			form1: {
				nb_participants: (this.props.user.nb_participants.length > 0) ? this.props.user.nb_participants : "",
			},
			current_btn_color: (this.props.user.nb_participants.length > 0) ? this.activated_btn_color : this.deactivated_btn_color,
			error: null
		}
	}

	setErrorMsg = (msg) => {
		this.setState({ error: msg })
	}

	handleNbParticipants = (event) => {
		let form1 = {
            nb_participants: event.target.value
		}
		let current_btn_color = this.deactivated_btn_color
		if (event.target.value.length > 0)
			current_btn_color = this.activated_btn_color
		this.setState({ form1: form1, current_btn_color: current_btn_color })
	}

	_validateForm1 = (e) => {
		let error_obj = this._initErrors()
		let error = false
		let today = new Date()
		let currentyear = today.getFullYear()
		const day = this.state.form1.v_day
		const day_parsed = parseInt(day)
		const month = this.state.form1.v_month
		const month_parsed = parseInt(month)
		const year = this.state.form1.v_year
		const year_parsed = parseInt(year)
		if (!day && !month && !year)
		{
			error_obj.global = "Veuillez renseigner votre date de naissance !"
			error = true
		}
		else if (!day || (day_parsed <= 0 || day_parsed >= 32))
		{
			error_obj.day = "Oups, cette date n'est pas valide"
			error = true
		}
		else if (!month || (month_parsed <= 0 || month_parsed >= 13))
		{
			error_obj.month = "Oups, ce mois n'est pas valide"
			error = true
		}
		else if (!year || (year_parsed <= 1900 || year_parsed >= currentyear - 7))
		{
			error_obj.month = "Votre année de naissance est suspecte !"
			error = true
		}
		if (error === true)
		{
			this.setState({ success: null, error: error_obj })
		}
		return (!error) ? true : false
	}

	nb_ph_color = () => {
		let color = this.default_ph_color
		if (this.state.form1.nb_participants !== "")
			color = this.updated_ph_color
		return (color)
	}

	handleSubmit = (e) => {
		e.preventDefault()
		if (this.state.form1.nb_participants.length === 0)
            this.setErrorMsg("Veuillez renseigner le nombre de participants")
		else if (this.state.form1.nb_participants > 6)
			this.setErrorMsg("Vous ne pouvez pas ajouter plus de 6 accompagnants")
		else if (this.state.form1.nb_participants < 0)
			this.setErrorMsg("Nombre d'accompagnants incorrect")
        else
		{
			this.props.updateUser(this.state.form1)
			this.props.validateStepNbParticipants(this.state.form1.nb_participants)
		}
	}

	render ()
	{
		let error = <p></p>
		if (this.state.error)
		{
			error = (
				<div className="rounded-md bg-red-50 p-4 my-3">
					<div className="flex">
						<div className="flex-shrink-0">
							<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
						</div>
						<div className="ml-3">
							<h3 className="text-sm font-medium text-red-800">{this.state.error}</h3>
						</div>
					</div>
				</div>
			)
		}
		return (
			<div>
				<ButtonPrevious
					theme={this.props.theme}
					handlePreviousBtn={this.props.handlePreviousBtn}
				/>
				<div style={{backgroundColor: "#" + this.props.theme.color1}} className="h-52 z-0 px-8 text-center">
					<p className="text-2xl text-white pt-14">Inscription</p>
					<h1 className="text-3xl text-white font-semibold m-0 pt-2">Étape {this.props.stepNb}</h1>
				</div>
				<div className="rounded-t-3xl z-10" style={{backgroundColor: "#ffffff", marginTop: "-2rem"}}>
					<div className="px-10 pt-16 pb-10">
						<h2 className="text-3xl font-semibold text-gray-700 mb-10">Combien de personnes vous accompagnent ?</h2>
						<form className="mb-16" onSubmit={this.handleSubmit}>
							{error}
								{/* <input type="number" className={this.nb_ph_color()} value={this.state.form1.nb_participants} onChange={this.handleNbParticipants} /> */}
								<div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
									<input
										className="block w-full border-0 border-b border-transparent text-gray-500 focus:text-gray-700 focus:border-indigo-600 focus:ring-0 text-lg"
										type="number" 
										value={this.state.form1.nb_participants} 
										onChange={this.handleNbParticipants}
									/>
								</div>
							<button className="font-semibold py-5 w-full rounded-full text-white shadow-xl text-xl my-10 waves-effect waves-light" type="submit" style={{ backgroundColor: this.state.current_btn_color }}>Suivant</button>
						</form>
					</div>
				</div>
			</div>
		)
	}
}


export default SubscribeStepNbParticipants