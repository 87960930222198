import React, { Component } from 'react'
import tools from '../../tools'

class MessageList extends Component {
	constructor(props) {
		super(props)
			this.state = {
				subject: 'Demande d\'intervention',
				close: false,
				admin: 'Camping',
				conversations: [
					{
						user: {
							name: 'Véronique Sanson',
							email: '',
						},
						admin: null,
						content: 'Bonjour, il y a un souçis avec le gaz :(',
						created_time: 1647277524,
						read: false
					},
					{
						user: null,
						admin: true,
						content: 'Bonjour Madame, nous avons bien reçu votre message, quelqu\'un va passer aujourd\'hui !',
						created_time: 1647277524,
						read: false
					},
					{
						user: {
							name: 'Véronique Sanson',
							email: '',
						},
						admin: null,
						content: 'Super, c\'est noté ! Merci pour votre réactivité',
						created_time: 1647277524,
						read: false
					},
					{
						user: null,
						admin: true,
						content: 'A votre service !',
						created_time: 1647371997,
						read: false
					}
				],
				form1: {
					content: ''
				},
				form2: {
					content: ''
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.conversations.length !== prevState.conversations.length)
			this.scrollToBottom()
	}

	classNames = (...classes) => {
		return classes.filter(Boolean).join(' ')
	}
	
	handleResponse = (event) => {
		const form1 = {
			...this.state.form1,
			content: event.target.value
		}
		this.setState({ form1: form1 })
	}

	scrollToBottom = () => {
		const elem = document.getElementById("conversation")
		setTimeout(() => {
			elem.scrollIntoView(false)
		}, 10)
	}

	onSubmit = (event) => {
		event.preventDefault()
		const date = new Date()
		const res = this.state.form1.content.trim()
		if (res.length > 0)
		{
			const newMessage = {
				user: {
					name: 'Véronique Sanson',
					email: '',
				},
				admin: null,
				content: this.state.form1.content,
				created_time: date.getTime() / 1000,
				read: false
			}
			const conversations = tools.clone(this.state.conversations)
			conversations.push(newMessage)
			const form1 = {
				...this.state.form1,
				content: ''
			}
			this.setState({ conversations: conversations, form1: form1 })
		}
	}

	render() {
		const lang_assets = this.props.lang_assets.messages
		return (
			<div className="min-h-4/5-screen">
				<div className="flex flex-col w-full fixed">
					<div className="flex justify-center items-center z-0 w-full top-0 h-24" style={{ backgroundColor: '#' + this.props.theme.color1 }}>
						<div className="text-white text-3xl">
							<h1>Messagerie</h1>
						</div>
					</div>
					<div onClick={this.scrollToBottom} className="pl-5 py-2 bg-white border-b border-gray-200 text-sm flex item-center text-gray-700 w-full" style={{ filter: 'drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))'}}>
						<p>{lang_assets.subject} {this.state.subject}</p>
					</div>
				</div>

				<div className='p-5 text-sm pt-36' id='conversation'>
					{/* <ul className='flex flex-col min-h-9/10-screen pb-32' id='conversation'> */}
					<ul className='flex flex-col max-h-1/3 mb-32'>
						{this.state.conversations.map((elem, id) => {
							let user = ''
							let styleBoxPosition = 'mt-5 flex flex-row-reverse'
							let styleBorderBox = 'max-w-10/12 bg-gray-100 rounded-lg px-5 py-2'
							if (elem.admin)
							{
								user = this.state.admin
								styleBoxPosition = 'mt-5'
								styleBorderBox = 'max-w-10/12 border border-gray-200 rounded-lg px-5 py-2'
							}
							let timestamp = elem.created_time * 1000
							const hour = new Date(timestamp).getHours()
							let minutes = new Date(timestamp).getMinutes()
							if (minutes < 10)
							{
								minutes = minutes.toString()
								minutes = '0' + minutes
							}
							return (
								<li className={styleBoxPosition} key={id}>
									<p className='ml-1 text-sm text-gray-400 mb-1 font-light'>{user}</p>
									<div className={styleBorderBox}>
										<p>{elem.content}</p>                           
										<p className="text-sm text-gray-400 text-right">{hour}:{minutes}</p>
									</div>
								</li>
							)
						})}
					</ul>
				</div>

				{/* RESPONSE AREA */}
				<div className="flex items-start space-x-4 fixed w-full bottom-1/10-screen bg-white">
					<div className="min-w-0 flex-1 py-2 px-5">
						<form onSubmit={this.onSubmit} className="relative">
							<div className="border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
								<label htmlFor="comment" className="sr-only">
									Comment
								</label>
								<textarea
									rows={1}
									name="comment"
									id="comment"
									className="block w-full py-3 border-0 resize-none focus:ring-0 sm:text-sm"
									placeholder={lang_assets.textarea_placeholder}
									value={this.state.form1.content}
									onChange={this.handleResponse}
								/>

								{/* Spacer element to match the height of the toolbar */}
								<div className="py-2" aria-hidden="true">
								{/* Matches height of button in toolbar (1px border + 36px content height) */}
								<div className="py-px">
									<div className="h-9" />
									</div>
								</div>
							</div>

							<div className="absolute bottom-0 inset-x-0 pl-3 pr-2 py-2 flex justify-end">
								<div className="flex-shrink-0">
									<button
										type="submit"
										className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
									>
										{lang_assets.button}
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
				{/* RESPONSE AREA */}
			</div>
		)
	}
}

export default MessageList