import React, { Component } from 'react'
import M from 'materialize-css'
import ButtonPrevious from '../UI/ButtonPrevious'

class SubscribeStepCompany extends Component {
	constructor(props) {
		super(props)
		this.default_ph_color = "text-gray-400"
		this.updated_ph_color = "text-black"
		this.company_ph = "Camping les Flots Bleus"
		this.activated_btn_color = "#" + this.props.theme.color1
		this.deactivated_btn_color = "#A3A3A3"
		this.state = {
			form1: {
				company: (this.props.user.company.length > 0) ? this.props.user.company : this.company_ph,
				v_company: (this.props.user.company.length > 0) ? this.props.user.company : "",
			},
			current_btn_color : (this.is_btn_on() === false) ? this.deactivated_btn_color : this.activated_btn_color,
			success: null,
			error: this._initErrors()
		}
		this.length_max = 50
	}
		
	componentDidUpdate() {
		const elems = document.querySelectorAll('select')
		M.FormSelect.init(elems)
	}

	setErrorMsg = (key, msg) => {
		const errors = this._initErrors()
		errors[key] = msg
		this.setState({ success: null, error: errors })
	}

	setSuccessMsg = (msg) => {
		this.setState({ success: msg, error: this._initErrors() })
	}

	clearFlash = () => {
		this.setState({ error: null, success: this._initErrors() })
	}

	_initErrors = () => {
		const errors = {
			global: null,
            company: null
		}
		return errors
	}

	btn_color = (event, input) => {
		let style = this.state.current_btn_color
		if (event.target.value.length !== 0 && input.length !== 0)
			style = this.activated_btn_color
		else
			style = this.deactivated_btn_color
		this.setState({ current_btn_color : style })
	}

	is_btn_on = () => {
		if (this.props.user.company.length > 0)
			return true
		return false
	}

	focusCompany = (event) => {
		let error_obj = this.state.error
		error_obj.company = null
		this.setState({ error: error_obj }) // flush company error value when typing on company field
		if (this.state.form1.v_company.length === 0) {
			let form1 = {
				...this.state.form1,
				company: '',
				v_company: this.state.form1.v_company
			}
			this.setState({ form1: form1 })
		}
	}

	blurCompany = (event) => {
		if (this.state.form1.v_company.length === 0) {
			let form1 = {
				...this.state.form1,
				company: this.company_ph
			}
			this.setState({ form1: form1 })
		}
	}
	
	handleCompany = (event) => {
		this.btn_color(event, this.state.form1.v_company)
		let form1 = {
			...this.state.form1,
			company: event.target.value,
			v_company: event.target.value
		}
		this.setState({ form1: form1 })
	}
	
	ph_color = (input) => {
		let color = this.default_ph_color
		if (input !== "")
			color = this.updated_ph_color
		return (color)
	}

	_validateForm1 = () => {
		let error_obj = this._initErrors()
		let error = false
		const company = this.state.form1.v_company
		if (!company || !company.length)
		{
			error_obj.company = 'Veuillez renseigner le nom de votre établissement'
			error = true
		}
		else if (/[@./\\[\]#'"%&!?;:{}_]/.test(company))
		{
			error_obj.company = 'Ce nom n\'est pas valide, veuillez réessayer'
			error = true
		}
		else if (company.length > this.length_max)
		{
			error_obj.company = 'Votre nom dépasse le nombre de charactère maximum (' + this.length_max + ' caractères max.)'
			error = true
		}
		if (error === true)
		{
			error_obj.global = "Il y a une ou plusieurs erreur(s) dans ce formulaire"
			this.setState({ success: null, error: error_obj })
		}
		return (!error) ? true : false
	}

	handleSubmit = (e) => {
		e.preventDefault()
		if (this._validateForm1())
		{
			this.props.updateUser(this.state.form1)
			this.props.validateStepCompany()
		}
	}

	render ()
	{
		let headerJSX = (
			<div style={{backgroundColor: "#" + this.props.theme.color1}} className="h-52 z-0 px-8 text-center">
				<p className="text-2xl text-white pt-14">Inscription</p>
				<h1 className="text-3xl text-white font-semibold m-0 pt-2">Étape {this.props.stepNb}</h1>
			</div>
		)
		if (this.props.stepNb === null)
		{
			headerJSX = (
				<div style={{backgroundColor: "#" + this.props.theme.color1}} className="h-52 z-0 px-8 text-center">
					<h1 className="text-3xl text-white font-semibold m-0 pt-14">Inscription</h1>
				</div>
			)
		}
		return (
			<div>
				<ButtonPrevious
					theme={this.props.theme}
					handlePreviousBtn={this.props.handlePreviousBtn}
				/>
				{headerJSX}
				<div className="rounded-t-3xl z-10" style={{backgroundColor: "#ffffff", marginTop: "-2rem"}}>
					<div className="px-10 pt-16 pb-10">
						<div>
							<h2 className="text-3xl font-semibold text-gray-700 mb-10">Quel est le nom de votre établissement ? <span role="img" aria-label="camping">🏕️</span></h2>
							<form className="mb-16" onSubmit={this.handleSubmit}>
								<label>
									<div className="my-5 border-b border-gray-300 focus-within:border-indigo-600">
										<input
											type="text"
											className="block pl-0 w-full border-0 border-b border-transparent text-gray-500 focus:text-gray-700 focus:border-indigo-600 focus:ring-0 text-xl"
											value={this.state.form1.company}
											onChange={this.handleCompany}
											onBlur={this.blurCompany}
											onFocus={this.focusCompany}
										/>
									</div>
									{/* <input type="text" className={this.ph_color(this.state.form1.v_company)} value={this.state.form1.company} onFocus={this.focusCompany} onBlur={this.blurCompany} onChange={this.handleCompany} /> */}
								</label>
								<p className="text-sm text-red-400 mt-0 mb-3">{this.state.error.company}</p>
								<button className="font-semibold py-5 w-full rounded-full text-white shadow-xl text-xl my-10 waves-effect waves-light" type="submit" style={{ backgroundColor: this.state.current_btn_color }}>Suivant</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default SubscribeStepCompany