import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import Home from '../Home/Home'
import Categories from '../Rubrics/Categories'
import Notifications from '../Notifications/Notifications'
import MessageList from '../Notifications/MessageList'
import InterventionType from '../Notifications/InterventionType'
import NewMessage from '../Notifications/NewMessage'
import Conversation from '../Notifications/Conversation'
import Animations from '../Animations/Animations'
import AnimationDays from '../Animations/AnimationDays'
import YourStay from '../Membres/YourStay'
import FindStay from '../Membres/FindStay'
import Deposits from '../Membres/Deposits'
import Membres from '../Membres/Membres'
import Bookings from '../Membres/Bookings'
import Inventory from '../Membres/Inventory'
import Reviews from '../Reviews/Reviews'
import Pages from '../Rubrics/Pages'
import Services from '../Services/Services'

import ReviewsClient from '../Reviews/ReviewsClient'
import Forgot from '../Unlogged/Forgot'
import Signin from '../Unlogged/Signin'
import SubscribeCamping from '../Unlogged/SubscribeCamping'

class Main extends Component {
	render() {
		const lang_assets = this.props.lang_assets.paths
		const groups = this.props.groups
		let routes = [
			{
				path: lang_assets.account,
				render: (props) =>
					<Membres
						{...props}
						config_deposit={this.props.config_deposit}
						config_inventory={this.props.config_inventory}
						config_pms={this.props.config_pms}
						features={this.props.features}
						langs={this.props.langs}
						lang_assets={this.props.lang_assets}
						session={this.props.session}
						theme={this.props.theme}
						user={this.props.user}
						device={this.props.device}
						show_stay_dates={this.props.show_stay_dates}
						show_stay_location={this.props.show_stay_location}
						updateUser={this.props.updateUser}
						updateUserDashboardLang={this.props.updateUserDashboardLang}
						postUpdateUser={this.props.postUpdateUser}
						updateLogs={this.props.updateLogs}
						updateShell={this.props.updateShell}
						fetchConfigDeposit={this.props.fetchConfigDeposit}
						fetchConfigInventory={this.props.fetchConfigInventory}
						fetchConfigPms={this.props.fetchConfigPms}
						fetchDepositLink={this.props.fetchDepositLink}
						fetchInventory={this.props.fetchInventory}
						fetchNotifications={this.props.fetchNotifications}
					/>,
				exact: true
			},
			{
				path: "/membres/reservations",
				render: (props) =>
					<Bookings
						{...props}
						langs={this.props.langs}
						lang_assets={this.props.lang_assets}
						session={this.props.session}
						theme={this.props.theme}
						user={this.props.user}
						service_reservations={this.props.service_reservations}
						fetchServices={this.props.fetchServices}
						fetchServiceBenefits={this.props.fetchServiceBenefits}
						fetchServiceReservationsByUser={this.props.fetchServiceReservationsByUser}
						updateUser={this.props.updateUser}
						updateUserLang={this.props.updateUserLang}
						updateUserDashboardLang={this.props.updateUserDashboardLang}
						updateLogs={this.props.updateLogs}
						updateShell={this.props.updateShell}
						postDeleteServiceReservation={this.props.postDeleteServiceReservation}
						fetchNotifications={this.props.fetchNotifications}
					/>,
				exact: true
			},
			{
				path: "/membres/caution",
				render: (props) =>
					<Deposits
						{...props}
						theme={this.props.theme}
						user={this.props.user}
						config_deposit={this.props.config_deposit}
						fetchConfigDeposit={this.props.fetchConfigDeposit}
						fetchDepositLink={this.props.fetchDepositLink}
					/>,
				exact: false
			},
			{
				path: "/membres/retrouver-son-sejour",
				render: (props) =>
					<FindStay
						{...props}
						updateLogs={this.props.updateLogs}
						updateShell={this.props.updateShell}
						postResetClient={this.props.postResetClient}
						updateUser={this.props.updateUser}
						theme={this.props.theme}
						user={this.props.user}
					/>,
				exact: false
			},
			{
				path: "/membres/sejours",
				render: (props) =>
					<YourStay
						{...props}
						updateLogs={this.props.updateLogs}
						updateShell={this.props.updateShell}
						updateUser={this.props.updateUser}
						theme={this.props.theme}
						user={this.props.user}
						session={this.props.session}
					/>,
				exact: false
			},
			{
				path: "/inventaire",
				render: (props) =>
					<Inventory
						{...props}
						user={this.props.user}
						config_inventory={this.props.config_inventory}
						accommodations={this.props.accommodations}
						updateShell={this.props.updateShell}
						updateLogs={this.props.updateLogs}
						lang_assets={this.props.lang_assets}
						fetchAccommodations={this.props.fetchAccommodations}
						postInventory={this.props.postInventory}
						theme={this.props.theme}
						notifications={this.props.notifications}
						fetchConfigInventory={this.props.fetchConfigInventory}
						fetchInventory={this.props.fetchInventory}
						fetchNotifications={this.props.fetchNotifications}
						postInsertPicture={this.props.postInsertPicture}
						postClickNotification={this.props.postClickNotification}
					/>,
				exact: true
			},
			{
				path: lang_assets.notifications,
				render: (props) =>
					<Notifications
						{...props}
						user={this.props.user}
						updateShell={this.props.updateShell}
						updateLogs={this.props.updateLogs}
						lang_assets={this.props.lang_assets}
						theme={this.props.theme}
						notifications={this.props.notifications}
						handleAcceptNotifications={this.props.handleAcceptNotifications}
						fetchNotifications={this.props.fetchNotifications}
						postClickNotification={this.props.postClickNotification}
					/>,
				exact: true
			},
			{
				path: "/messages",
				render: (props) =>
					<MessageList
						{...props}
						user={this.props.user}
						conversations={this.props.conversations}
						updateShell={this.props.updateShell}
						updateLogs={this.props.updateLogs}
						lang_assets={this.props.lang_assets}
						theme={this.props.theme}
						notifications={this.props.notifications}
						fetchConversationsByUser={this.props.fetchConversationsByUser}
						fetchNotifications={this.props.fetchNotifications}
						postClickNotification={this.props.postClickNotification}
						features={this.props.features}
					/>,
				exact: true
			},
			{
				path: "/intervention",
				render: (props) =>
					<InterventionType
						{...props}
						user={this.props.user}
						updateShell={this.props.updateShell}
						updateLogs={this.props.updateLogs}
						lang_assets={this.props.lang_assets}
						postSubmitConversation={this.props.postSubmitConversation}
						theme={this.props.theme}
						features={this.props.features}
					/>,
				exact: true
			},
			{
				path: "/nouveau-message/:conversation",
				render: (props) =>
					<NewMessage
						{...props}
						conversations={this.props.conversations}
						messages={this.props.messages}
						user={this.props.user}
						updateShell={this.props.updateShell}
						updateLogs={this.props.updateLogs}
						lang_assets={this.props.lang_assets}
						fetchMessagesByConversation={this.props.fetchMessagesByConversation}
						fetchConversationByID={this.props.fetchConversationByID}
						postSubmitMessage={this.props.postSubmitMessage}
						theme={this.props.theme}
						features={this.props.features}
					/>,
				exact: true
			},
			{
				path: "/conversation",
				render: (props) =>
					<Conversation
						{...props}
						updateShell={this.props.updateShell}
						updateLogs={this.props.updateLogs}
						lang_assets={this.props.lang_assets}
						theme={this.props.theme}
						notifications={this.props.notifications}
						fetchNotifications={this.props.fetchNotifications}
						postClickNotification={this.props.postClickNotification}
						features={this.props.features}
					/>,
				exact: true
			},
			{
				path: lang_assets.reviews + '/:url/:id',
				render: (props) =>
					<Reviews
						{...props}
						features={this.props.features}
						reviews={this.props.reviews}
						theme={this.props.theme}
						lang_assets={this.props.lang_assets}
						user={this.props.user}
						fetchReviewByURL={this.props.fetchReviewByURL}
						fetchNotifications={this.props.fetchNotifications}
						postSubmitReview={this.props.postSubmitReview}
						updateLogs={this.props.updateLogs}
						updateShell={this.props.updateShell}
					/>,
				exact: false
			},
			{
				path: lang_assets.client_reviews + '/:url/:id',
				render: (props) =>
					<ReviewsClient
						{...props}
						features={this.props.features}
						reviews={this.props.reviews}
						lang_assets={this.props.lang_assets_global}
						theme={this.props.theme}
						fetchReviewByURL={this.props.fetchReviewByURL}
						postSubmitReview={this.props.postSubmitReview}
						updateLogs={this.props.updateLogs}
					/>,
				exact: true
			},
			{
				path: lang_assets.activities,
				render: (props) =>
					<Animations
						{...props}
						user={this.props.user}
						updateShell={this.props.updateShell}
						updateLogs={this.props.updateLogs}
						lang_assets={this.props.lang_assets}
						theme={this.props.theme}
						animationDates={this.props.animationDates}
						handleAcceptNotifications={this.props.handleAcceptNotifications}
						animationCategories={this.props.animationCategories}
						fetchAnimationsByDate={this.props.fetchAnimationsByDate}
						fetchAnimationCategories={this.props.fetchAnimationCategories}
						fetchNotifications={this.props.fetchNotifications}
					/>,
				exact: true
			},
			{
				path: lang_assets.activities + '/:date',
				render: (props) =>
					<AnimationDays
						{...props}
						updateLogs={this.props.updateLogs}
						updateShell={this.props.updateShell}
						features={this.props.features}
						lang_assets={this.props.lang_assets}
						theme={this.props.theme}
						user={this.props.user}
						animations={this.props.animations}
						animationDates={this.props.animationDates}
						animationCategories={this.props.animationCategories}
						fetchAnimationsByDate={this.props.fetchAnimationsByDate}
						fetchAnimationCategories={this.props.fetchAnimationCategories}
						fetchNotifications={this.props.fetchNotifications}
						fetchServiceBenefits={this.props.fetchServiceBenefits}
						fetchServiceReservationsByServiceList={this.props.fetchServiceReservationsByServiceList}
						fetchServiceWorkersByServiceList={this.props.fetchServiceWorkersByServiceList}
						postServiceReservation={this.props.postServiceReservation}
						postUpdateBirthday={this.props.postUpdateBirthday}
						postUpdatePhone={this.props.postUpdatePhone}
					/>,
				exact: true
			},
			{
				path: lang_assets.pages + '/:page',
				render: (props) =>
					<Pages
						{...props}
						updateShell={this.props.updateShell}
						updateLogs={this.props.updateLogs}
						categories={this.props.categories}
						groups={this.props.groups}
						icons={this.props.icons}
						lang_assets={this.props.lang_assets}
						lists={this.props.lists}
						pages={this.props.pages}
						servics={this.props.services}
						theme={this.props.theme}
						user={this.props.user}
						fetchIcons={this.props.fetchIcons}
						fetchNotifications={this.props.fetchNotifications}
						fetchPageByID={this.props.fetchPageByID}
						fetchPageByURL={this.props.fetchPageByURL}
						fetchPageCheckUpdate={this.props.fetchPageCheckUpdate}
						fetchServiceByID={this.props.fetchServiceByID}
					/>,
				exact: false
			},
			{
				path: lang_assets.services + '/:service',
				render: (props) =>
					<Services
						{...props}
						user={this.props.user}
						updateShell={this.props.updateShell}
						updateLogs={this.props.updateLogs}
						service_benefits={this.props.service_benefits}
						service_products={this.props.service_products}
						service_reservations={this.props.service_reservations}
						service_workers={this.props.service_workers}
						services={this.props.services}
						icons={this.props.icons}
						lang_assets={this.props.lang_assets}
						theme={this.props.theme}
						fetchIcons={this.props.fetchIcons}
						fetchNotifications={this.props.fetchNotifications}
						fetchServiceBenefitsByService={this.props.fetchServiceBenefitsByService}
						fetchServiceByURL={this.props.fetchServiceByURL}
						fetchServiceProductsByService={this.props.fetchServiceProductsByService}
						fetchServiceReservationsByService={this.props.fetchServiceReservationsByService}
						fetchServiceWorkersByService={this.props.fetchServiceWorkersByService}
						postServiceReservation={this.props.postServiceReservation}
						postServiceReservationCart={this.props.postServiceReservationCart}
						postUpdateBirthday={this.props.postUpdateBirthday}
						postUpdatePhone={this.props.postUpdatePhone}
					/>,
				exact: false
			},

			// inscription + connexion
			{
				path: '/inscription',
				render: (props) =>
					<SubscribeCamping
						{...props}
						activation_code={this.props.activation_code}
						activation_code_specials={this.props.activation_code_specials}
						app_name={this.props.app_name}
						app_type={this.props.app_type}
						application_ouverte={this.props.application_ouverte}
						user={this.props.user}
						config_pms={this.props.config_pms}
						langs={this.props.langs}
						lang_default={this.props.lang_default}
						updateUser={this.props.updateUser}
						updateUserLang={this.props.updateUserLang}
						logo={this.props.logo}
						theme={this.props.theme}
						deleteInstallEvent={this.props.deleteInstallEvent}
						installEvent={this.props.installEvent}
						handleInstallApp={this.props.handleInstallApp}
						fetchConfigPms={this.props.fetchConfigPms}
						postCheckUserEmail={this.props.postCheckUserEmail}
						postCheckUserKoban={this.props.postCheckUserKoban}
						postConnectUser={this.props.postConnectUser}
						postCheckActivationCode={this.props.postCheckActivationCode}
						postRequestActivationCode={this.props.postRequestActivationCode}
						postSubscribeUser={this.props.postSubscribeUser}
						postUpdateEmail={this.props.postUpdateEmail}
					/>,
				exact: true
			},
			{
				path: '/forgot-password/:code',
				render: (props) =>
					<Forgot
						{...props}
						theme={this.props.theme}
						langs={this.props.langs}
						lang_default={this.props.lang_default}
						postRetrieveUserByHash={this.props.postRetrieveUserByHash}
						postUpdatePassword={this.props.postUpdatePassword}
						postConnectUser={this.props.postConnectUser}
						updateUser={this.props.updateUser}
						updateUserLang={this.props.updateUserLang}
					/>,
				exact: true
			},
			{
				path: '/connexion/:identifiant?',
				render: (props) =>
					<Signin
						{...props}
						application_ouverte={this.props.application_ouverte}
						user={this.props.user}
						langs={this.props.langs}
						lang_default={this.props.lang_default}
						logo={this.props.logo}
						theme={this.props.theme}
						updateUser={this.props.updateUser}
						postConnectUser={this.props.postConnectUser}
						postUpdateStay={this.props.postUpdateStay}
						updateUserLang={this.props.updateUserLang}
						updateUserDashboardLang={this.props.updateUserDashboardLang}
						installEvent={this.props.installEvent}
						deleteInstallEvent={this.props.deleteInstallEvent}
					/>,
				exact: true
			},

			// page d'accueil
			{
				path: '/',
				render: (props) =>
					<Home
						{...props}
						updateShell={this.props.updateShell}
						updateLogs={this.props.updateLogs}
						appName={this.props.app_name}
						categories={this.props.categories}
						groups={this.props.groups}
						icons={this.props.icons}
						lang_assets={this.props.lang_assets}
						pages={this.props.pages}
						theme={this.props.theme}
						user={this.props.user}
						animations={this.props.animations}
						animationDates={this.props.animationDates}
						dismissInventory={this.props.dismissInventory}
						installEvent={this.props.installEvent}
						handleInstallApp={this.props.handleInstallApp}
						fetchIcons={this.props.fetchIcons}
						fetchModules={this.props.fetchModules}
						fetchCategories={this.props.fetchDeepCategories}
						fetchCategoryByID={this.props.fetchCategoryByID}
						fetchCategoriesByGroup={this.props.fetchCategoriesByGroup}
						fetchConfigInventory={this.props.fetchConfigInventory}
						fetchInventory={this.props.fetchInventory}
						fetchPageByID={this.props.fetchPageByID}
						fetchPageByCategory={this.props.fetchPageByCategory}
						fetchListByID={this.props.fetchListByID}
						fetchAnimations={this.props.fetchAnimations}
						fetchAnimationNext={this.props.fetchAnimationNext}
						fetchAnimationDates={this.props.fetchAnimationDates}
						fetchNotifications={this.props.fetchNotifications}
						updateScrollHome={this.props.updateScrollHome}
						scroll_home={this.props.scroll_home}
					/>,
				exact: false
			}
		]
		let key = 0
		let objects = []
		for (let i = 0; i < groups.length; i++)
		{
			let prefix = ""
			for (let j = 0; j < groups[i].translations.length; j++)
			{
				if (groups[i].translations[j].lang === this.props.user.lang)
				{
					prefix = groups[i].translations[j].prefix
					break
				}
			}
			const path1 = lang_assets.rubrics + '/' + prefix
			const path2 = lang_assets.rubrics + '/' + prefix + '/:position'
			objects[key] = (
				<Route
					key={key}
					exact
					path={path1}
					render={(props) =>
						<Categories
							{...props}
							updateShell={this.props.updateShell}
							updateLogs={this.props.updateLogs}
							icons={this.props.icons}
							group={groups[i]}
							categories={this.props.categories}
							pages={this.props.pages}
							lang_assets={this.props.lang_assets}
							lists={this.props.lists}
							fetchIcons={this.props.fetchIcons}
							fetchCategoriesByGroup={this.props.fetchCategoriesByGroup}
							fetchPageByCategory={this.props.fetchPageByCategory}
							fetchPagesByIDList={this.props.fetchPagesByIDList}
							fetchPageCheckUpdate={this.props.fetchPageCheckUpdate}
							fetchListByCategory={this.props.fetchListByCategory}
							fetchListCheckUpdate={this.props.fetchListCheckUpdate}
							fetchNotifications={this.props.fetchNotifications}
							theme={this.props.theme}
							user={this.props.user}
						/>}
				/>
			)
			key++
			objects[key] = (
				<Route
					key={key}
					exact
					path={path2}
					render={(props) =>
						<Categories
							{...props}
							updateShell={this.props.updateShell}
							updateLogs={this.props.updateLogs}
							categories={this.props.categories}
							group={groups[i]}
							icons={this.props.icons}
							lang_assets={this.props.lang_assets}
							lists={this.props.lists}
							pages={this.props.pages}
							services={this.props.services}
							theme={this.props.theme}
							fetchCategoriesByGroup={this.props.fetchCategoriesByGroup}
							fetchIcons={this.props.fetchIcons}
							fetchListByCategory={this.props.fetchListByCategory}
							fetchListCheckUpdate={this.props.fetchListCheckUpdate}
							fetchNotifications={this.props.fetchNotifications}
							fetchPageByCategory={this.props.fetchPageByCategory}
							fetchPagesByIDList={this.props.fetchPagesByIDList}
							fetchPageCheckUpdate={this.props.fetchPageCheckUpdate}
							fetchServiceByID={this.props.fetchServiceByID}
							user={this.props.user}
						/>}
				/>
			)
			key++
		}
		for (let i = 0; i < routes.length; i++)
		{
			const path = routes[i].path
			const exact = routes[i].exact
			const render = routes[i].render
			if (exact === true)
				objects[key] = (<Route key={key} exact path={path} render={render} />)
			else
				objects[key] = (<Route key={key} path={path} render={render} />)
			key++
		}
		return (
			<div>
				<Switch>
					{objects.map((route) => {
						return route
					})}
				</Switch>
			</div>
		)
	}
}

export default Main
