import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { XCircleIcon } from '@heroicons/react/solid'

class FindStay extends Component {
	constructor(props) {
		super(props)
		this.default_ph_color = "text-gray-400"
		this.updated_ph_color = "text-black"
		this.activated_btn_color = "#" + this.props.theme.color1
		this.deactivated_btn_color = "#A3A3A3"
		this.code_ph = "ex: CFUTRQ"
		this.state = {
			stays: this.props.user.stay,
			form1: {
				stay: ''
			},
			redirect: false,
			error: null,
			success: null
		}
	}

	componentDidMount() {
		this.props.updateLogs(this.props.history.location.pathname, 'Membres')
		this.props.updateShell('profile', true, this.props.history.location.pathname)
	}

	setErrorMsg = (msg) => {
		this.setState({ success: null, error: msg })
	}

	setSuccessMsg = (msg) => {
		this.setState({ success: msg, error: null })
	}

	clearFlash = () => {
		this.setState({ error: null, success: null })
	}

	handleStay = (stay) => {
		let form1 = {
			stay: stay
		}
		this.setState({ form1: form1 })
	}

	handleSubmit = (event) => {
		event.preventDefault()
		if (this.state.form1.stay === '')
		{
			this.setState({ error: "Veuillez sélectionner un séjour" })
		}
		else
		{
			let user = this.props.user
			user.stay_list = this.props.user.stay
			user.stay = this.state.form1.stay
			this.props.updateUser(user, this.props.session)
			this.setState({ redirect: true })
		}
	}

	render() {
		if (this.state.redirect === true)
		{
			return (
				<Redirect to="/membres" />
			)
		}
		else
		{
			let stays = (this.props.user.stay_list) ? this.props.user.stay_list : this.props.user.stay
			if (!Array.isArray(stays))
				stays = [stays]
			const btnStyle = "font-semibold py-5 w-full rounded-full text-white shadow-xl text-xl my-10 waves-effect waves-light"
			let error = null
			if (this.state.error)
			{
				error = (
					<div className="rounded-md bg-red-50 p-4 mt-3">
						<div className="flex">
							<div className="flex-shrink-0">
								<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
							</div>
							<div className="ml-3">
								<h3 className="text-sm font-medium text-red-800">{this.state.error}</h3>
							</div>
						</div>
					</div>
				)
			}
			return (
				<div>
					<div style={{backgroundColor: "#" + this.props.theme.color1}} className="h-52 z-0 px-8 text-center">
						<h1 className="text-3xl text-white font-semibold m-0 pt-16">Vos séjours</h1>
					</div>
					<div className="rounded-t-3xl z-10" style={{backgroundColor: "#ffffff", marginTop: "-2rem"}}>
						<div className="px-10 pt-16 pb-10">
							<h2 className="text-3xl font-semibold text-gray-700 mb-10">Choisissez votre séjour dans la liste</h2>
							<form onSubmit={this.handleSubmit}>
								<div className="space-y-5">
									{stays.map(stay => {
										return (
											<div key={stay._id} className="relative flex items-center">
												<div className="flex items-center h-5">
													<input
														id={stay._id}
														name="stay"
														type="radio"
														onChange={this.handleStay.bind(this, stay)}
														className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
													/>
												</div>
												<div className="ml-3 text-lg">
													<label htmlFor={stay._id}>
														<p className="font-semibold text-gray-700">
															{stay.location_category}
														</p>
														<p className="text-gray-500">
															{stay.arrival} - {stay.departure}
														</p>
													</label>
												</div>
											</div>
										)
									})}
								</div>
								{error}
								<button type="submit" className={btnStyle} style={{ backgroundColor: '#' + this.props.theme.color1 }}>Enregistrer</button>
							</form>
						</div>
					</div>
				</div>
			)
		}
		// else
		// {
		// 	return (
		// 		<Redirect to="/membres" />
		// 	)
		// }
	}
}

export default FindStay
