import React, { Component } from 'react'
import ButtonPrevious from '../UI/ButtonPrevious'
import { XCircleIcon } from '@heroicons/react/solid'

class SubscribeStepStreet extends Component {
	constructor(props) {
		super(props)
		this.default_ph_color = "text-gray-400"
		this.updated_ph_color = "text-black"
		this.street_ph = "55 rue du Faubourg Saint-Honoré"
		this.activated_btn_color = "#" + this.props.theme.color1
		this.deactivated_btn_color = "#A3A3A3"
		this.state = {
			form1: {
				street: (this.props.user.street.length > 0) ? this.props.user.street : this.street_ph,
				v_street: (this.props.user.street.length > 0) ? this.props.user.street : ""
			},
			current_btn_color : (this.is_btn_on() === false) ? this.deactivated_btn_color : this.activated_btn_color,
			success: null,
			error: this._initErrors()
		}
		this.length_max = 100
	}

	setErrorMsg = (key, msg) => {
		const errors = this._initErrors()
		errors[key] = msg
		this.setState({ success: null, error: errors })
	}

	setSuccessMsg = (msg) => {
		this.setState({ success: msg, error: this._initErrors() })
	}

	clearFlash = () => {
		this.setState({ error: null, success: this._initErrors() })
	}

	_initErrors = () => {
		const errors = {
			global: null,
			street: null
		}
		return errors
	}

	btn_color = (event, input) => {
		let style = this.state.current_btn_color
		const client_type = this.props.user.client_type
		if ((event.target.value.length !== 0 && input.length !== 0) || client_type === "visitor")
			style = this.activated_btn_color
		else
			style = this.deactivated_btn_color
		this.setState({ current_btn_color : style })
	}

	is_btn_on = () => {
		const client_type = this.props.user.client_type
		if (this.props.user.street.length > 0 || client_type === "visitor")
			return true
		return false
	}

	focusStreet = (event) => {
		let error_obj = this.state.error
		error_obj.street = null
		this.setState({ error: error_obj }) //flush street error value when typing on street field
		if (this.state.form1.v_street.length === 0) {
			let form1 = {
				...this.state.form1,
				street: '',
				v_street: this.state.form1.v_street
			}
			this.setState({ form1: form1 })
		}
	}

	blurStreet = (event) => {
		if (this.state.form1.v_street.length === 0) {
			let form1 = {
				...this.state.form1,
				street: this.street_ph
			}
			this.setState({ form1: form1 })
		}
	}
	
	handleStreet = (event) => {
		this.btn_color(event, this.state.form1.v_street)
		let form1 = {
			...this.state.form1,
			street: event.target.value,
			v_street: event.target.value
		}
		this.setState({ form1: form1 })
	}
	
	ph_color = (input) => {
		let color = this.default_ph_color
		if (input !== "")
			color = this.updated_ph_color
		return (color)
	}

	_validateForm1 = () => {
		let error_obj = this._initErrors()
		let error = false
		const street = this.state.form1.v_street
		const client_type = this.props.user.client_type
		if ((!street || !street.length) && (client_type === "owner" || client_type === "tenant"))
		{
			error_obj.street = 'Veuillez renseigner votre adresse'
			error = true
		}
		else if (street.length > this.length_max)
		{
			error_obj.street = 'Votre adresse dépasse le nombre de charactère maximum (' + this.length_max + ' caractères max.)'
			error = true
		}
		if (error === true)
		{
			error_obj.global = "Il y a une ou plusieurs erreur(s) dans ce formulaire"
			this.setState({ success: null, error: error_obj })
		}
		return (!error) ? true : false
	}

	handleSubmit = (e) => {
		e.preventDefault()
		if (this._validateForm1())
		{
			this.props.updateUser(this.state.form1)
			this.props.validateStepStreet()
		}
	}

	_printError = (msg) => {
		if (msg)
		{
			return (
				<div className="rounded-md bg-red-50 p-4 mt-3">
					<div className="flex">
						<div className="flex-shrink-0">
							<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
						</div>
						<div className="ml-3">
							<h3 className="text-sm font-medium text-red-800">{msg}</h3>
						</div>
					</div>
				</div>
			)
		}
		return null
	}

	render ()
	{
		let headerJSX = (
			<div style={{backgroundColor: "#" + this.props.theme.color1}} className="h-52 z-0 px-8 text-center">
				<p className="text-2xl text-white pt-14">Inscription</p>
				<h1 className="text-3xl text-white font-semibold m-0 pt-2">Étape {this.props.stepNb}</h1>
			</div>
		)
		if (this.props.stepNb === null)
		{
			headerJSX = (
				<div style={{backgroundColor: "#" + this.props.theme.color1}} className="h-52 z-0 px-8 text-center">
					<h1 className="text-3xl text-white font-semibold m-0 pt-14">Inscription</h1>
				</div>
			)
		}
		const errorStreet = this._printError(this.state.error.street)
		return (
			<div>
				<ButtonPrevious
					theme={this.props.theme}
					handlePreviousBtn={this.props.handlePreviousBtn}
				/>
				{headerJSX}
				<div className="rounded-t-3xl z-10" style={{backgroundColor: "#ffffff", marginTop: "-2rem"}}>
					<div className="px-10 pt-16 pb-10">
						<div>
							<h2 className="text-3xl font-semibold text-gray-700">Quelle est votre adresse sur place ?</h2>
							<form className="mb-16" onSubmit={this.handleSubmit}>
								<h3 className="text-lg text-gray-500">Pour vous rejoindre à l'apéro <span role="img" aria-label="smiling-face">😁</span><span role="img" aria-label="beers">🍻</span></h3>
								<label>
									<div className="my-5 border-b border-gray-300 focus-within:border-indigo-600">
										<input
											type="text"
											className="block pl-0 w-full border-0 border-b border-transparent text-gray-500 focus:text-gray-700 focus:border-indigo-600 focus:ring-0 text-xl"
											value={this.state.form1.street}
											onChange={this.handleStreet}
											onBlur={this.blurStreet}
											onFocus={this.focusStreet}
										/>
									</div>
								</label>
								{errorStreet}
								<button className="font-semibold py-5 w-full rounded-full text-white shadow-xl text-xl my-10 waves-effect waves-light" type="submit" style={{ backgroundColor: this.state.current_btn_color }}>Suivant</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default SubscribeStepStreet