import React, { Component } from 'react'
import Loader from '../UI/Loader'

class NewMessage extends Component {
	constructor(props) {
		super(props)
		this.state = {
			conversation: null,
			messages: [],
			form1: {
				content: ''
			}
		}
	}

	componentDidMount()
	{
		this.loadConversation()
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.messages !== this.props.messages)
			this.loadMessages(true)
	}

	loadMessages = (fromProps = false) => {
		if (fromProps === true)
			this.setState({ messages: this.props.messages })
		else if (this.state.conversation)
		{
			this.props.fetchMessagesByConversation(this.state.conversation._id)
			.then(messages => {
				this.setState({ messages: messages })
			})
			.catch(err => {
				console.log("could not fetch messages", err)
			})
		}
	}

	loadConversation = () => {
		this.props.fetchConversationByID(this.props.match.params.conversation)
		.then(conversation => {
			this.props.fetchMessagesByConversation(conversation._id)
			.then(messages => {
				this.setState({ conversation: conversation, messages: messages })
			})
			.catch(err => console.log(err))
		})
		.catch(err => console.log(err))
	}

	classNames = (...classes) => {
		return classes.filter(Boolean).join(' ')
	}

	handleResponse = (event) => {
		const form1 = {
			...this.state.form1,
			content: event.target.value
		}
		this.setState({ form1: form1 })
	}

	scrollToBottom = () => {
		const elem = document.getElementById("conversation")
		setTimeout(() => {
			elem.scrollIntoView(false)
		}, 10)
	}

	onSubmit = (event) => {
		event.preventDefault()
		const res = this.state.form1.content.trim()
		// const date = new Date()
		if (res.length > 0)
		{
			const message = {
				conversation: this.state.conversation._id,
				user: this.props.user._id,
				message: res
			}
			this.props.postSubmitMessage(message)
			.then(ret => {
				const form1 = {
					content: ''
				}
				this.setState({ form1: form1 })
			})
			.catch(err => console.log(err))
		}
	}

	render () {
		if (this.state.conversation === null)
		{
			return (
				<div className="loading">
					<Loader />
				</div>
			)
		}
		else
		{
			const lang_assets = this.props.lang_assets.messages
			return (
				<div className="min-h-4/5-screen">
					<div className="flex flex-col w-full fixed">
						<div className="flex justify-center items-center z-0 w-full top-0 h-24" style={{ backgroundColor: '#' + this.props.theme.color1 }}>
							<div className="text-white text-3xl">
								<h1>{lang_assets.new_message}</h1>
							</div>
						</div>
						<div onClick={this.scrollToBottom} className="pl-5 py-2 bg-white border-b border-gray-200 text-sm flex item-center text-gray-700 w-full" style={{ filter: 'drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))'}}>
							<p>{lang_assets.subject} {this.state.conversation.title}</p>
						</div>
					</div>
					<div className='p-5 text-sm pt-36' id='conversation'>
						<ul className='flex flex-col max-h-1/3 mb-32'>
							{this.state.messages.map((conversation, id) => {
								let user = ''
								let styleBoxPosition = 'mt-5 flex flex-row-reverse'
								let styleBorderBox = 'max-w-10/12 bg-gray-100 rounded-lg px-5 py-2'
								if (!conversation.user)
								{
									// user = this.state.admin
									styleBoxPosition = 'mt-5'
									styleBorderBox = 'max-w-10/12 border border-gray-200 rounded-lg px-5 py-2'
								}
								let timestamp = conversation.created_time * 1000
								const hour = new Date(timestamp).getHours()
								let minutes = new Date(timestamp).getMinutes()
								if (minutes < 10)
								{
									minutes = minutes.toString()
									minutes = '0' + minutes
								}
								return (
									<li className={styleBoxPosition} key={id}>
										<p className='ml-1 text-sm text-gray-400 mb-1 font-light'>{user}</p>
										<div className={styleBorderBox}>
											<p>{conversation.message}</p>                           
											<p className="text-sm text-gray-400 text-right">{hour}:{minutes}</p>
										</div>
									</li>
								)
							})}
						</ul>
					</div>
	
					<div className="flex items-start space-x-4 fixed w-full bottom-1/10-screen bg-white">
						<div className="min-w-0 flex-1 py-2 px-5">
							<form onSubmit={this.onSubmit} className="relative">
								<div className="border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
									<label htmlFor="comment" className="sr-only">
										Comment
									</label>
									<textarea
										rows={1}
										name="comment"
										id="comment"
										className="block w-full py-3 border-0 resize-none focus:ring-0 sm:text-sm"
										placeholder={lang_assets.textarea_placeholder}
										value={this.state.form1.content}
										onChange={this.handleResponse}
									/>
	
									{/* Spacer element to match the height of the toolbar */}
									<div className="py-2" aria-hidden="true">
									{/* Matches height of button in toolbar (1px border + 36px content height) */}
									<div className="py-px">
										<div className="h-9" />
										</div>
									</div>
								</div>
	
								<div className="absolute bottom-0 inset-x-0 pl-3 pr-2 py-2 flex justify-end">
									<div className="flex-shrink-0">
										<button
											type="submit"
											className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
										>
											{lang_assets.button}
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			)
		}
	}
}

export default NewMessage