import React, { Component } from 'react'
import ButtonPrevious from '../UI/ButtonPrevious'
import { XCircleIcon } from '@heroicons/react/solid'

class SubscribeStepParticipantType extends Component {
	constructor(props) {
		super(props)
		this.mother = "mother"
		this.father = "father"
		this.other = "other"
		this.empty = null
		this.button = (this.props.button) ? this.props.button : "Suivant"
		this.input_selected_style= {
			borderWidth: '2px',
			borderColor: '#' + this.props.theme.color1,
			backgroundColor: '#' + this.props.theme.color1,
			color : '#FFFFFF'
		}
		this.input_unselected_style = {
			borderWidth: '2px',
			borderStyle: 'solid',
			borderColor: '#' + this.props.theme.color1
		}
		this.state = {
			style: {
				current_mother_style: (this.props.user.participant_type !== "mother") ? this.input_unselected_style : this.input_selected_style,
				current_father_style: (this.props.user.participant_type !== "father") ? this.input_unselected_style : this.input_selected_style,
				current_other_style: (this.props.user.participant_type !== "other") ? this.input_unselected_style : this.input_selected_style
			},
			form1: {
                participant_type: (this.props.user.participant_type.length > 0) ? this.props.user.participant_type : null
			},
			error: null
		}
	}

	setErrorMsg = (msg) => {
		this.setState({ error: msg })
	}

	handleParticipantType = (type) => {
		let form1 = {
			user_type: null
		}
		let style = {
			current_mother_style: this.input_unselected_style,
			current_father_style: this.input_unselected_style,
			current_other_style: this.input_unselected_style
		}
		if (type === this.mother)
		{
			form1 = {
				participant_type: this.mother
			}
			style = {
				current_mother_style: this.input_selected_style,
				current_father_style: this.input_unselected_style,
				current_other_style: this.input_unselected_style
			}
		}
        else if (type === this.father)
		{
			form1 = {
				participant_type: this.father
			}
			style = {
				current_mother_style: this.input_unselected_style,
				current_father_style: this.input_selected_style,
				current_other_style: this.input_unselected_style
			}
		}
		else if (type === this.other)
		{
			form1 = {
				participant_type: this.other
			}
			style = {
				current_mother_style: this.input_unselected_style,
				current_father_style: this.input_unselected_style,
				current_other_style: this.input_selected_style
			}
		}
		this.setState({ style: style, form1: form1 })		
	}

	handleSubmit = (e) => {
		e.preventDefault()
		if (!this.state.form1.participant_type)
			this.setErrorMsg("Veuillez sélectionner le cas qui vous concerne")
		else
		{
			this.props.updateUser(this.state.form1)
			this.props.validateStepParticipantType(this.state.form1.participant_type)
		}
	}
	
	render ()
	{
		let headerJSX = (
			<div style={{backgroundColor: "#" + this.props.theme.color1}} className="h-52 z-0 px-8 text-center">
				<p className="text-2xl text-white pt-14">Inscription</p>
				<h1 className="text-3xl text-white font-semibold m-0 pt-2">Étape {this.props.stepNb}</h1>
			</div>
		)
		if (this.props.stepNb === null)
		{
			headerJSX = (
				<div style={{backgroundColor: "#" + this.props.theme.color1}} className="h-52 z-0 px-8 text-center">
					<h1 className="text-3xl text-white font-semibold m-0 pt-14">Inscription</h1>
				</div>
			)
		}
		let error = <p></p>
		if (this.state.error)
		{
			error = (
				<div className="rounded-md bg-red-50 p-4 mt-3">
					<div className="flex">
						<div className="flex-shrink-0">
							<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
						</div>
						<div className="ml-3">
							<h3 className="text-sm font-medium text-red-800">{this.state.error}</h3>
						</div>
					</div>
				</div>
			)
		}
		return (
			<div>
				<ButtonPrevious
					theme={this.props.theme}
					handlePreviousBtn={this.props.handlePreviousBtn}
				/>
				{headerJSX}
				<div className="rounded-t-3xl z-10" style={{backgroundColor: "#ffffff", marginTop: "-2rem"}}>
					<div className="px-10 pt-16 pb-10">
						<div>
							<h2 className="text-3xl font-semibold text-gray-700 mb-10">Vous accompagnez en tant que :</h2>
							{error}
							<form className="mb-16" onSubmit={this.handleSubmit}>
								<div>
									<div type="button" onClick={this.handleParticipantType.bind(this, this.father)} className="font-semibold py-5 w-full rounded-full text-xl mt-6 text-center waves-effect waves-light" style={ this.state.style.current_father_style }>Père</div>
									<div type="button" onClick={this.handleParticipantType.bind(this, this.mother)} className="font-semibold py-5 w-full rounded-full text-xl mt-6 text-center waves-effect waves-light" style={ this.state.style.current_mother_style }>Mère</div>
									<div type="button" onClick={this.handleParticipantType.bind(this, this.other)} className="font-semibold py-5 w-full rounded-full text-xl mt-6 mb-3 text-center waves-effect waves-light" style={ this.state.style.current_other_style }>Autre</div>
								</div>
								<button className="font-semibold py-5 w-full rounded-full text-white shadow-xl text-xl my-10 waves-effect waves-light" type="submit" style={{ backgroundColor: '#' + this.props.theme.color1 }}>{this.button}</button>
							</form>								
						</div>
					</div>
				</div>
			</div>
		)
	}
}
		
export default SubscribeStepParticipantType