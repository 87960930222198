import React, { Component } from 'react'
import Loader from '../UI/Loader'
import tools from '../../tools'
import ReactBodymovin from 'react-bodymovin'
import animation from '../../img/check-circle.json'

class Deposits extends Component
{
	constructor(props)
	{
		super(props)
		this.state = {
			config_deposit: null,
			deposit: null
		}
	}

	componentDidMount()
	{
		this.props.fetchDepositLink()
		.then(deposit => {
			console.log("deposit", deposit)
			this.setState({ deposit: deposit })
		})
		.catch(err => console.log("can not fetch config", err))
		this.loadConfig()
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps.config_deposit !== this.props.config_deposit)
			this.loadConfig(true)
	}

	loadConfig = (fromProps = false) => {
		if (fromProps === true)
			this.setState({ config_deposit: this.props.config_deposit })
		else
		{
			this.props.fetchConfigDeposit()
			.then(config_deposit => {
				this.setState({ config_deposit: config_deposit })
			})
			.catch(err => {
				console.log("could not fetch config_deposit", err)
			})
		}
	}

	render()
	{
		const deposit = this.state.deposit
		const stay = (this.props.user && this.props.user.stay) ? this.props.user.stay : null
		const time = tools.getTimestamp()
		let limit = (stay && stay.departure_time) ? stay.departure_time : 0
		limit += 10 * 24 * 60 * 60
		let contentJSX = null
		if (!deposit || !this.state.config_deposit)
		{
			contentJSX = (
				<div className="loading">
					<Loader />
				</div>
			)
		}
		else if (this.props.location.pathname === '/membres/caution/success')
		{
			const bodymovinOptions = {
				loop: false,
				autoplay: true,
				prerender: true,
				animationData: animation
			}
			contentJSX = (
				<div className="rounded-t-3xl z-10" style={{backgroundColor: "#ffffff", marginTop: "-2rem"}}>
					<div className="px-10 pt-16 pb-10">
						<div className="w-full flex justify-center">
							<div className="w-32">
								<ReactBodymovin options={bodymovinOptions} />
							</div>
						</div>
						<h2 className="text-center text-3xl font-bold tracking-tight sm:block sm:text-4xl mt-5" style={{ color: "#" + this.props.theme.color3 }}>
							Merci !
						</h2>
						<p className="text-center text-2xl mt-4 font-semibold tracking-tight text-gray-600 sm:block sm:text-4xl">
							Votre caution a bien été déposée !
						</p>
					</div>
					<div id="animation"></div>
				</div>
			)
		}
		else if (deposit.ref_swikly !== null && deposit.status !== "OK")
		{
			contentJSX = (
				<div className="flex flex-col">
					<div className="w-full flex justify-center mt-10">
						<div className="bg-red-100 w-24 h-24 rounded-3xl flex justify-center items-center">
							<span className="text-6xl" role="img" aria-label="sleepy-face">😵‍💫</span>
						</div>
					</div>
					<div className="px-10 mt-8 text-center">
						<h2 className="font-semibold text-xl text-gray-700">Oups, nous n'avons pas pu enregistrer votre caution <span role="img" aria-label="sad">😓</span></h2>
						<p className="mt-3 text-center text-sm text-gray-400">Un incident technique s'est produit, veuillez réessayer plus tard.</p>
					</div>
				</div>
			)
		}
		else if (deposit.ref_swikly !== null)
		{
			const time = tools.parseDate(deposit.created_time, false)
			contentJSX = (
				<div className="mx-4">
					<div className="w-full flex justify-center">
						<div className="bg-gray-100 w-24 h-24 rounded-3xl flex justify-center items-center">
							<span className="text-6xl" role="img" aria-label="check">✅</span>
						</div>
					</div>
					<h2 className="text-2xl font-semibold text-gray-700 mt-8 text-center">Votre caution a bien été enregistrée</h2>
					<div className="mt-5 rounded-lg bg-gray-100 px-6 py-6 space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-purple-500">
						<div className="divide-y divide-gray-300 divide-dashed">
							<div className="pb-3">
								<p className="text-base font-medium text-gray-800">{this.props.user.firstname} {this.props.user.lastname}</p>
							</div>
							<div className="py-3">
								<p className="text-sm text-gray-500">Séjour</p>
								<p className="text-sm font-medium text-gray-800">{stay.arrival} - {stay.departure}</p>
								<p className="text-sm text-gray-500 mt-4">Dépot de caution</p>
								<p className="text-sm font-medium text-gray-800">{time}</p>
							</div>
							<div className="pt-3">
								<div className="flex justify-between items-end">
									<p className="text-sm text-gray-500">Montant total</p>
									<p className="text-sm font-medium text-gray-800">{deposit.amount}€</p>
								</div>
								<div className="flex justify-between items-end">
									<p className="text-base font-medium text-gray-800">Réf. SWIKLY</p>
									<p className="text-xl font-semibold text-gray-800">{deposit.ref_swikly}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		}
		else if (!stay || !stay.departure_time || time > limit)
		{
			contentJSX = (
				<div className="mx-4">
					<div className="w-full flex justify-center">
						<div className="bg-gray-100 w-24 h-24 rounded-3xl flex justify-center items-center">
							<span className="text-6xl" role="img" aria-label="sleepy">😴</span>
						</div>
					</div>
					<h2 className="text-2xl font-semibold text-gray-700 mt-8 text-center">Vous ne pouvez plus déposer de caution.</h2>
					<div className="mt-5 rounded-lg bg-gray-100 px-6 py-6 space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-purple-500">
						<div className="divide-y divide-gray-300 divide-dashed">
							<div className="pb-3">
								<p className="text-base font-medium text-gray-800">{this.props.user.firstname} {this.props.user.lastname}</p>
							</div>
							<div className="py-3">
								<p className="text-sm text-gray-500">Séjour</p>
								<p className="text-sm font-medium text-gray-800">{stay.arrival} - {stay.departure}</p>
								<p className="text-sm text-gray-500 mt-4">Dépot de caution</p>
								<p className="text-sm font-medium text-gray-800">Non effectué</p>
							</div>
							<div className="pt-3">
								<div className="flex justify-between items-end mt-3">
									<p className="text-sm text-gray-500">Montant de la caution</p>
									<p className="text-sm font-medium text-gray-800">{deposit.amount}€</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		}
		else
		{
			contentJSX = (
				<div className="mx-4">
					<div className="w-full flex justify-center">
						<div className="bg-gray-100 w-24 h-24 rounded-3xl flex justify-center items-center">
							<span className="text-6xl" role="img" aria-label="lock">🔐</span>
						</div>
					</div>
					<h2 className="text-2xl font-semibold text-gray-700 mt-8">Comment ça marche ?</h2>
					<p className="text-base mt-4">Notre partenaire SWIKLY s'occupe de tout, il suffit de remplir le formulaire de dépot de caution sécurisé en cliquant sur le bouton "Déposer ma caution".</p>
					<div className="relative mt-5 rounded-lg bg-gray-100 px-6 py-6 flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-purple-500">
						<div className="focus:outline-none">
							<span className="absolute inset-0" aria-hidden="true" />
							<p className="text-base font-medium text-gray-800">{this.props.user.firstname} {this.props.user.lastname}</p>
							<p className="text-sm text-gray-500 truncate">Séjour du {stay.arrival} au {stay.departure}</p>
							<div className="flex justify-between mt-4 items-end">
								<p className="text-base font-medium text-gray-800">Montant total</p>
								<p className="text-xl font-semibold text-gray-800">{deposit.amount}€</p>
							</div>
						</div>
					</div>
					<a href={deposit.link}><button className="font-semibold py-5 w-full rounded-full text-white shadow-xl text-xl my-10 waves-effect waves-light" style={{backgroundColor: "#" + this.props.theme.color3}}>Déposer ma caution</button></a>
				</div>
			)
		}
		return (
			<div className="min-h-4/5-screen">
				<div className="flex justify-center	items-center z-0 w-full fixed top-0 h-36" style={{ backgroundColor: '#' + this.props.theme.color1 }}>
					<div className="text-white text-3xl">
						<h1>Caution</h1>
					</div>
				</div>
				<div className="bg-white w-full pt-10 px-5 mt-32 min-h-9/10-screen absolute rounded-t-3xl pb-10">
					{contentJSX}
				</div>
			</div>
		)
	}
}

export default Deposits