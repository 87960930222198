import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'

class InterventionType extends Component {
	constructor(props) {
		super(props)
		this.default_placeholder = 'Sélectionnez l\'objet de votre message'
		this.default_question = 'Question générale'
		this.default_intervention = 'Demande d\'intervention'
		this.state = {
			placeholder: this.default_placeholder,
			messageObj: [
				// this.default_placeholder,
				this.default_question, 
				this.default_intervention,
				// 'Objet perdu',
				// 'Animations'
			],
			subject: '',
			validate_step1: false
		}
	}

	componentDidMount() {
		this.props.updateLogs(this.props.history.location.pathname, 'Notifications')
		this.props.updateShell(null, true, this.props.history.location.pathname)
	}

	classNames = (...classes) => {
		return classes.filter(Boolean).join(' ')
	}

	handleSubject = (event) => {
		this.setState({ subject: event.target.value })
	}

	handleSelection = (elem) => {
		this.setState({ placeholder: elem })
	}

	validateStep = () => {
		let type = ''
		if (this.state.placeholder === this.default_intervention)
			type = "intervention"
		else if (this.state.placeholder === this.default_question)
			type = "message"
		const conversation = {
			user: this.props.user._id,
			title: this.state.subject,
			type: type
		}
		this.props.postSubmitConversation(conversation)
		.then(ret => {
			this.setState({ validate_step1: ret._id })
		})
		.catch(err => console.log(err))
	}

	render () {
		if (this.state.validate_step1 !== false)
		{
			const url= '/nouveau-message/' + this.state.validate_step1
			return (
				<Redirect to={url} />
			)
		}
		else
		{
			const lang_assets = this.props.lang_assets.messages
			let subject_form = null
			if (this.state.placeholder !== this.default_placeholder)
			{
				subject_form = (
					<div className="inline-block mt-4 w-11/12">
						<div className="w-full justify-center">
							<label htmlFor="comment" className="">
								Objet :
							</label>
							<div className="border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
								<input
									type="text"
									id="comment"
									className="block w-full py-3 border-0 resize-none focus:ring-0 sm:text-sm"
									value={this.state.subject}
									onChange={this.handleSubject}
								/>
							</div>
						</div>
					</div>
				)
			}
			return (
				<div className="h-screen bg-gray-100">
					<div className="flex flex-col w-full fixed">
						<div className="flex justify-center items-center z-0 w-full top-0 h-36" style={{ backgroundColor: '#' + this.props.theme.color1 }}>
							<div className="text-white text-3xl">
								<h1>{lang_assets.new_message}</h1>
							</div>
						</div>
						{/* <div className="w-full h-16 bg-white hover:bg-blue-50 pl-2.5 text-center flex items-center justify-center" style={{ filter: 'drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))' }}>
							<div className="">Nouveau message</div>
						</div> */}
						<div className="relative mt-10 w-full h-screen">
							<div className="w-full flex justify-center">
								<Menu as="div" className="relative inline-block w-11/12 text-left">
									<div>
										<Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
											{this.state.placeholder}
											<ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
										</Menu.Button>
									</div>
	
									<Transition
										as={Fragment}
										enter="transition ease-out duration-100"
										enterFrom="transform opacity-0 scale-95"
										enterTo="transform opacity-100 scale-100"
										leave="transition ease-in duration-75"
										leaveFrom="transform opacity-100 scale-100"
										leaveTo="transform opacity-0 scale-95"
									>
										<Menu.Items className="absolute mt-1 w-full max-h-60 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
											<div className="py-1">
												{this.state.messageObj.map((elem, id) => {
													return (
														<Menu.Item>
															{({ active }) => (
																<span
																	onClick={this.handleSelection.bind(this, elem)}
																	className={this.classNames(
																		active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
																		'block px-4 py-2 text-sm'
																	)}
																>
																	{elem}
																</span>
															)}
														</Menu.Item>
													)
												})}
											</div>
										</Menu.Items>
									</Transition>
								</Menu>
							</div>
							{subject_form}
							<div className="flex justify-center w-full absolute bottom-1/2">
								<button
									onClick={this.validateStep}
									// to="/nouveau-message"
									className="w-11/12 py-3 font-semibold rounded-full text-white text-center shadow-xl text-xl filter drop-shadow-lg" 
									style={{ backgroundColor: '#' + this.props.theme.color1 }}
									subject={this.state.subject}
								>
									{lang_assets.next_button}
								</button>
							</div>
						</div>
					</div>
				</div>
			)
		}
	}
}

export default InterventionType