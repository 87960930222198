import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import SubscribeStepActivationCode from './SubscribeStepActivationCode'
import ActivationCodeHelp from './ActivationCodeHelp'
import SubscribeStepCheckinCheckout from './SubscribeStepCheckinCheckout'
import SubscribeStepUserType from './SubscribeStepUserType'
import SubscribeStepParticipantType from './SubscribeStepParticipantType'
import SubscribeStepEmail from './SubscribeStepEmail'
import SubscribeStepName from './SubscribeStepName'
import SubscribeStepBirth from './SubscribeStepBirth'
import SubscribeStepCivility from './SubscribeStepCivility'
import SubscribeStepParticipantEmails from './SubscribeStepParticipantEmails'
import SubscribeStepPrimaryEmails from './SubscribeStepPrimaryEmails'
import SubscribeStepNbParticipants from './SubscribeStepNbParticipants'
import SubscribeStepNbPrimary from './SubscribeStepNbPrimary'
import SubscribeStepEnd from './SubscribeStepEnd'
import SubscribeStepPWA from './SubscribeStepPWA'
import SubscribeStepNotifications from './SubscribeStepNotifications'
import UpdateEmail from './UpdateEmail'
import Cookies from 'js-cookie'
import './Signin.css'

class SubscribeCampus extends Component {
	constructor(props) {
		super(props)
		this.first_steps = [
			'lobby',
			'stepActivationCode',
			'stepUserType',
			'stepName'
		]
		this.primary_steps = [
			'stepBirth',
			'stepCivility',
			'stepNbParticipants',
			'stepParticipantEmails',
			'stepEmail'
		]
		this.participant_steps = [
			'stepParticipantType',
			'stepCivility',
			'stepPrimaryEmails',
			'stepEmail'
		]
		this.state = {
			user: {
				email : '',
				password : '',
				client_number: '',
				civility: '',
				firstname: '',
				lastname: '',
				birthday: '',
				code : '',
				day: '',
				month: '',
				year: '',
				t_password: '',
				checkin: '',
				checkout: '',
				user_type: '',
				participant_type: '',
				nb_participants: '',
				nb_primary: '',
				participant_emails: [],
				primary_emails: []
			},
			no_client: false,
			error: null,
			// step: this.first_steps[1]
			step: this.first_steps[1]
		}
		this.showInstallPrompt = (this.props.installEvent) ? true : false
	}

	setErrorMsg = (msg) => {
		this.setState({ error: msg })
	}

	modalRedirection = () => {
		if (this.state.step === 'help')
			this.validateHelp()
		else if (this.state.step === 'updateEmail')
			this.validateUpdateEmail()
	}

	validateStepActivationCode = (data, client_number, special_code) => {
		const no_client = (special_code !== null) ? true : false
		const user_obj = {
			...this.state.user,
			client_number: client_number,
			checkin: (special_code && special_code.arrival_time) ? special_code.arrival_time : null,
			checkout: (special_code && special_code.departure_time) ? special_code.departure_time : null
		}
		if (data.v_code)
			user_obj.code = data.v_code.toUpperCase()
		this.setState({ step: 'stepUserType', no_client: no_client, user: user_obj })
	}
	
	validateHelp = () => {
		this.setState({ step : 'stepActivationCode'})
	}

	validateUpdateEmail = () => {
		this.setState({ step : "/" })
	}

	gotoHelp = () => {
		this.setState({ step: 'help' })
	}

	gotoMail = () => {
		this.setState({ step: 'updateEmail' })
	}
	
	validateStepActivationMail = (user) => {
		const success = "Un lien d'activation à été envoyé sur votre adresse " + user.email
		this.setState({ user: user, success: success, step: 'step2' })
	}

	// Franck : connecter l'utilisateur si le compte existe déjà 
	// Si existe déja, message d'erreur : Cette adresse mail existe déjà ! <link>Connectez-vous</link>
	
	validateStepEmail = (data) => {
		let birthday = null
		if (this.state.user.user_type === "primary")
			birthday = this.state.user.day + "/" + this.state.user.month + "/" + this.state.user.year
		let date = new Date(this.state.user.checkin * 1000)
		let day = date.getDate()
		if (day < 10)
			day = '0' + day
		let month = date.getMonth() + 1
		if (month < 10)
			month = '0' + month
		let year = date.getFullYear()
		const arrival = day + "/" + month + "/" + year
		date = new Date(this.state.user.checkout * 1000)
		day = date.getDate()
		if (day < 10)
			day = '0' + day
		month = date.getMonth() + 1
		if (month < 10)
			month = '0' + month
		year = date.getFullYear()
		const departure = day + "/" + month + "/" + year
		const user = {
			client_number: this.state.user.client_number,
			firstname: this.state.user.firstname,
			lastname: this.state.user.lastname,
			email: data.v_email,
			password: data.v_password,
			birthday: birthday,
			civility: this.state.user.civility,
			arrival: arrival,
			departure: departure,
			user_type: this.state.user.user_type
		}
		if (this.state.user.user_type === "primary")
		{
			user.nb_participants = this.state.user.nb_participants
			user.participant_emails = this.state.user.participant_emails
		}
		else if (this.state.user.user_type === "participant")
		{
			user.participant_type = this.state.user.participant_type
			user.nb_primary = this.state.user.nb_primary
			user.primary_emails = this.state.user.primary_emails
		}
		this.props.postSubscribeUser(user)
		.then(user => {
			if (!user)
			{
				this.setErrorMsg('Cette adresse e-mail existe déjà')
				// vérifier si le mdp est ok, puis connecter...
			}
			else
				this.setState({ step: "stepEnd" })
		})
		.catch(err => {
			this.setErrorMsg('Une erreur s\'est produite.')
			console.log(err)
		})
	}

	validateCheckin = () => {
		this.setState({ step: "stepName" })
	}

	validateStepName = () => {
		const nextStep = (this.state.user.user_type === "primary") ? "stepBirth" : "stepParticipantType"
		this.setState({ step: nextStep })
	}

	validateStepBirth = () => {
		this.setState({ step: "stepCivility" })
	}

	validateStepCivility = (data) => {
		const nextStep = (this.state.user.user_type === "primary") ? "stepNbParticipants" : "stepPrimaryEmails"
		this.setState({ step: nextStep })
	}

	validateStepUserType = () => {
		this.setState({ step: "stepName" })
	}

	validateStepParticipantType = (participant_type) => {
		let nextStep = "stepCivility"
		if (participant_type !== "other")
			nextStep = "stepPrimaryEmails"
		this.setState({ step: nextStep })
	}

	validateStepNbPrimary = () => {
		let nextStep = "stepPrimaryEmails"
		this.setState({ step: nextStep })
	}

	validateStepNbParticipants = (nb_participants) => {
		let nextStep = "stepParticipantEmails"
		if (Number(nb_participants) === 0)
			nextStep = "stepEmail"
		this.setState({ step: nextStep })
	}

	validateStepParticipantEmails = () => {
		this.setState({ step: "stepEmail" })
	}

	validateStepPrimaryEmails = () => {
		this.setState({ step: "stepEmail" })
	}

	validateStepEnd = () => {
		if (this.showInstallPrompt === true)
			this.setState({ step: "stepPWA" })
		else if ('serviceWorker' in navigator && 'PushManager' in window && Notification.permission === 'default' && this.props.text)
			this.setState({ step: "stepNotifications" })
		else
			this.connectUser()
	}

	validateStepPWA = () => {
		if ('serviceWorker' in navigator && 'PushManager' in window && Notification.permission === 'default' && this.props.text)
			this.setState({ step: "stepNotifications" })
		else
			this.connectUser()
	}

	validateStepNotifications = () => {
		this.connectUser()
	}

	connectUser = (bypass_user = null) => {
		if (bypass_user)
		{
			Cookies.set('session', bypass_user.session)
			localStorage.setItem("session", bypass_user.session)
			this.props.updateUser(bypass_user, bypass_user.session)
			this.setState({ step: "lobby", user: bypass_user })
		}
		else
		{
			const user = {
				identifiant: this.state.user.email,
				password: this.state.user.password
			}
			this.props.postConnectUser(user)
			.then(user => {
				Cookies.set('session', user.session)
				localStorage.setItem("session", user.session)
				this.props.updateUser(user, user.session)
				this.setState({ step: "lobby", user: user })
			})
			.catch(err => console.log("can not connect user", err))
		}
	}

	updateUser = (data) => {
		let user_obj = {
			...this.state.user
		}
		if (data.v_email && data.v_password)
		{
			user_obj.email = data.v_email
			user_obj.password = data.v_password
		}
		else if (data.v_email && this.state.step === 'updateEmail')
			user_obj.email = data.v_email
		else if (data.v_lastname && data.v_firstname)
		{
			user_obj.lastname =	data.v_lastname
			user_obj.firstname = data.v_firstname
		}
		else if (data.v_day && data.v_month && data.v_year)
		{
			user_obj.day = data.v_day
			user_obj.month = data.v_month
			user_obj.year = data.v_year
			user_obj.birthday = data.v_day + '/' + data.v_month + '/' + data.v_year
		}
		else if (data.v_checkin && data.v_checkout)
		{
			user_obj.checkin = data.v_checkin
			user_obj.checkout = data.v_checkout
			user_obj.long_checkin = data.d_checkin
			user_obj.long_checkout = data.d_checkout
		}
		else if (data.user_type)
		{
			user_obj.user_type = data.user_type
			if (data.user_type === "participant")
				user_obj.nb_primary = 1
		}
		else if (data.participant_type)
		{
			user_obj.participant_type = data.participant_type
			if (data.participant_type === "father")
				user_obj.civility = "male"
			if (data.participant_type === "mother")
				user_obj.civility = "female"
		}
		else if (data.civility)
			user_obj.civility = data.civility
		else if (data.nb_primary)
			user_obj.nb_primary = data.nb_primary
		else if (data.nb_participants)
			user_obj.nb_participants = data.nb_participants
		else if (data.participant_emails)
		{
			const participant_emails = []
			for (let i = 0; i < data.participant_emails.length; i++)
				participant_emails.push(data.participant_emails[i].v_email)
			user_obj.participant_emails = participant_emails
		}
		else if (data.primary_emails)
		{
			const primary_emails = []
			for (let i = 0; i < data.primary_emails.length; i++)
			{
				primary_emails.push({
					lastname: data.primary_emails[i].v_lastname,
					firstname: data.primary_emails[i].v_firstname,
					email: data.primary_emails[i].v_email
				})
			}
			console.log("adding primary emails", primary_emails)
			user_obj.primary_emails = primary_emails
		}
		this.setState({ user: user_obj })
	}

	handlePreviousBtn = () => {
		let valuePreviousStep = "lobby"
		if (this.state.step === 'help' || this.state.step === "stepUserType")
			valuePreviousStep = 'stepActivationCode'
		else if (this.state.step === "stepName")
			valuePreviousStep = "stepUserType"
		else if (this.state.step === 'updateEmail')
			valuePreviousStep = 'stepEnd'
		else if (this.state.user.user_type === "primary")
		{
			const actual_step = this.primary_steps.indexOf(this.state.step)
			if (this.state.step === "stepEmail" && Number(this.state.user.nb_participants) === 0)
				valuePreviousStep = "stepNbParticipants"
			else if (actual_step > 0)
				valuePreviousStep = this.primary_steps[actual_step - 1]
			else
				valuePreviousStep = "stepName"
		}
		else if (this.state.user.user_type === "participant")
		{
			const actual_step = this.participant_steps.indexOf(this.state.step)
			if (this.state.step === "stepPrimaryEmails" && this.state.user.participant_type !== "other")
				valuePreviousStep = "stepParticipantType"
			else if (actual_step > 0)
				valuePreviousStep = this.participant_steps[actual_step - 1]
			else
				valuePreviousStep = "stepName"
		}
		this.setState({ step: valuePreviousStep })
	}

	render() {
		let titleStep = null
		if (this.state.user.user_type === "primary" && this.state.step !== "stepName" && this.state.step !== "stepUserType")
		{
			const actual_step = this.primary_steps.indexOf(this.state.step) + 1
			const total_step = this.primary_steps.length
			titleStep = actual_step + "/" + total_step
		}
		else if (this.state.user.user_type === "participant" && this.state.step !== "stepName" && this.state.step !== "stepUserType")
		{
			const actual_step = this.participant_steps.indexOf(this.state.step) + 1
			const total_step = this.participant_steps.length
			titleStep = actual_step + "/" + total_step
		}
		if (this.state.step === 'stepActivationCode' && this.props.postCheckActivationCode)
		{
			return (
				<div>
					<SubscribeStepActivationCode
						theme={this.props.theme}
						activation_code_specials={this.props.activation_code_specials}
						logo={this.props.logo}
						postCheckActivationCode={this.props.postCheckActivationCode}
						validateStepActivationCode={this.validateStepActivationCode}
						gotoHelp={this.gotoHelp}
						updateUser={this.updateUser}
						handlePreviousBtn={this.handlePreviousBtn}
						user={this.state.user}
					/>
				</div>
			)
		}
		else if (this.state.step === 'help')
		{
			return (
				<ActivationCodeHelp 
					theme={this.props.theme}
					validateHelp={this.validateHelp}
					modalRedirection={this.modalRedirection}
					updateUser={this.updateUser}
					handlePreviousBtn={this.handlePreviousBtn}
					postRequestActivationCode={this.props.postRequestActivationCode}
					user={this.state.user}
				/>
			)
		}
		else if (this.state.step === 'stepUserType')
		{
			return (
				<SubscribeStepUserType
					theme={this.props.theme} 
					validateStepUserType={this.validateStepUserType}
					updateUser={this.updateUser}
					handlePreviousBtn={this.handlePreviousBtn}
					user={this.state.user}
					stepNb={titleStep}
				/>
			)
		}
		else if (this.state.step === 'stepParticipantType')
		{
			return (
				<SubscribeStepParticipantType
					theme={this.props.theme} 
					validateStepParticipantType={this.validateStepParticipantType}
					updateUser={this.updateUser}
					handlePreviousBtn={this.handlePreviousBtn}
					user={this.state.user}
					stepNb={titleStep}
				/>
			)
		}
		else if (this.state.step === 'stepEmail')
		{
			return (
				<SubscribeStepEmail
					theme={this.props.theme}
					error_msg={this.state.error}
					connectUser={this.connectUser}
					postConnectUser={this.props.postConnectUser}
					postCheckUserEmail={this.props.postCheckUserEmail}
					validateStepEmail={this.validateStepEmail}
					updateUser={this.updateUser}
					handlePreviousBtn={this.handlePreviousBtn}
					user={this.state.user}
					stepNb={titleStep}
				/>
			)
		}
		else if (this.state.step === 'stepCheckin')
		{
			return(
			<div>
				<SubscribeStepCheckinCheckout
					theme={this.props.theme}
					updateUser={this.updateUser}
					handlePreviousBtn={this.handlePreviousBtn}
					user={this.state.user}
					validateCheckin={this.validateCheckin}
					stepNb={titleStep}
				/>
			</div>)
		}
		else if (this.state.step === 'stepName')
		{
			return (
				<SubscribeStepName
					app_type={this.props.app_type}
					theme={this.props.theme}
					validateStepName={this.validateStepName}
					updateUser={this.updateUser}
					handlePreviousBtn={this.handlePreviousBtn}
					user={this.state.user}
					stepNb={titleStep}
				/>
			)
		}
		else if (this.state.step === 'stepBirth')
		{
			return (
				<SubscribeStepBirth
					theme={this.props.theme}
					validateStepBirth={this.validateStepBirth}
					updateUser={this.updateUser}
					handlePreviousBtn={this.handlePreviousBtn}
					mandatory={true}
					user={this.state.user}
					stepNb={titleStep}
				/>
			)
		}
		else if (this.state.step === 'stepCivility')
		{
			return (
				<SubscribeStepCivility
					theme={this.props.theme}
					validateStepCivility={this.validateStepCivility}
					updateUser={this.updateUser}
					handlePreviousBtn={this.handlePreviousBtn}
					mandatory={true}
					user={this.state.user}
					stepNb={titleStep}
				/>
			)
		}
		else if (this.state.step === 'stepNbPrimary')
		{
			return (
				<SubscribeStepNbPrimary
					theme={this.props.theme}
					validateStepNbPrimary={this.validateStepNbPrimary}
					updateUser={this.updateUser}
					handlePreviousBtn={this.handlePreviousBtn}
					user={this.state.user}
					stepNb={titleStep}
				/>
			)
		}
		else if (this.state.step === 'stepNbParticipants')
		{
			return (
				<SubscribeStepNbParticipants
					theme={this.props.theme}
					validateStepNbParticipants={this.validateStepNbParticipants}
					updateUser={this.updateUser}
					handlePreviousBtn={this.handlePreviousBtn}
					user={this.state.user}
					stepNb={titleStep}
				/>
			)
		}
		else if (this.state.step === 'stepPrimaryEmails')
		{
			return (
				<SubscribeStepPrimaryEmails
					theme={this.props.theme}
					validateStepPrimaryEmails={this.validateStepPrimaryEmails}
					updateUser={this.updateUser}
					handlePreviousBtn={this.handlePreviousBtn}
					user={this.state.user}
					stepNb={titleStep}
				/>
			)
		}
		else if (this.state.step === 'stepParticipantEmails')
		{
			return (
				<SubscribeStepParticipantEmails
					theme={this.props.theme}
					validateStepParticipantEmails={this.validateStepParticipantEmails}
					updateUser={this.updateUser}
					handlePreviousBtn={this.handlePreviousBtn}
					user={this.state.user}
					stepNb={titleStep}
				/>
			)
		}
		else if (this.state.step === 'stepEnd')
		{
			return (
				<SubscribeStepEnd
					theme={this.props.theme}
					error_msg={this.state.error}
					validateStepEnd={this.validateStepEnd}
					gotoMail={this.gotoMail}
					user={this.state.user}
				/>
			)
		}
		else if (this.state.step === 'stepPWA')
		{
			return (
				<SubscribeStepPWA
					theme={this.props.theme}
					validateStepPWA={this.validateStepPWA}
					installEvent={this.props.installEvent}
					handleInstallApp={this.props.handleInstallApp}
					deleteInstallEvent={this.props.deleteInstallEvent}
					user={this.state.user}
				/>
			)
		}
		else if (this.state.step === 'stepNotifications')
		{
			return (
				<SubscribeStepNotifications
					theme={this.props.theme}
					validateStepNotifications={this.validateStepNotifications}
					user={this.state.user}
				/>
			)
		}
		else if (this.state.step === 'updateEmail')
		{
			return(
			<div>
				<UpdateEmail
					theme={this.props.theme}
					modalRedirection={this.modalRedirection}
					updateUser={this.updateUser}
					handlePreviousBtn={this.handlePreviousBtn}
					user={this.state.user}
				/>
			</div>)
		}
		else
		{
			return (
				<div>
					<Redirect to="/" />
				</div>
			)
		}
	}
}

export default SubscribeCampus