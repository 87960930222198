import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { PlusSmIcon as PlusSmIconOutline } from '@heroicons/react/outline'
import Loader from '../UI/Loader'

class MessageList extends Component {
	constructor(props) {
		super(props)
		this.state = {
			currTab: 'Notifications',
			conversations: null,
			messages: [
				{
					id: 1,
					subject: 'Intervention technique',
					read: false,
					sender: 'Camping',
					href: '#',
					date: '7 MARS',
					datetime: '2021-01-27T16:35',
					preview:
					'Doloremque dolorem maiores assumenda dolorem facilis. Velit vel in a rerum natus facere. Enim rerum eaque qui facilis. Numquam laudantium sed id dolores omnis in. Eos reiciendis deserunt maiores et accusamus quod dolor.',
				},
				{
					id: 2,
					subject: 'Bouteille de gaz',
					read: false,
					sender: 'Camping',
					href: '#',
					date: '7 MARS',
					datetime: '2021-01-27T16:35',
					preview:
					'Doloremque dolorem maiores assumenda dolorem facilis. Velit vel in a rerum natus facere. Enim rerum eaque qui facilis. Numquam laudantium sed id dolores omnis in. Eos reiciendis deserunt maiores et accusamus quod dolor.',
				},
				{
					id: 3,
					subject: 'Ménage',
					read: true,
					sender: 'Camping',
					href: '#',
					date: '7 MARS',
					datetime: '2021-01-27T16:35',
					preview:
					'Doloremque dolorem maiores assumenda dolorem facilis. Velit vel in a rerum natus facere. Enim rerum eaque qui facilis. Numquam laudantium sed id dolores omnis in. Eos reiciendis deserunt maiores et accusamus quod dolor.',
				},
				{
					id: 4,
					subject: 'Eos sequi et aut ex impedit',
					read: true,
					sender: 'Camping',
					href: '#',
					date: '7 MARS',
					datetime: '2021-01-27T16:35',
					preview:
					'Doloremque dolorem maiores assumenda dolorem facilis. Velit vel in a rerum natus facere. Enim rerum eaque qui facilis. Numquam laudantium sed id dolores omnis in. Eos reiciendis deserunt maiores et accusamus quod dolor.',
				},
				{
					id: 5,
					subject: 'Quisquam veniam explicabo',
					read: false,
					sender: 'Camping',
					href: '#',
					date: '7 MARS',
					datetime: '2021-01-27T16:35',
					preview:
					'Doloremque dolorem maiores assumenda dolorem facilis. Velit vel in a rerum natus facere. Enim rerum eaque qui facilis. Numquam laudantium sed id dolores omnis in. Eos reiciendis deserunt maiores et accusamus quod dolor.',
				},
				{
					id: 6,
					subject: 'Est ratione molestiae modi maiores consequatur eligendi et excepturi magni',
					read: false,
					sender: 'Camping',
					href: '#',
					date: '7 MARS',
					datetime: '2021-01-27T16:35',
					preview:
					'Doloremque dolorem maiores assumenda dolorem facilis. Velit vel in a rerum natus facere. Enim rerum eaque qui facilis. Numquam laudantium sed id dolores omnis in. Eos reiciendis deserunt maiores et accusamus quod dolor.',
				},
				{
					id: 7,
					subject: 'Commodi deserunt aut veniam rem ipsam',
					read: true,
					sender: 'Camping',
					href: '#',
					date: '7 MARS',
					datetime: '2021-01-27T16:35',
					preview:
					'Doloremque dolorem maiores assumenda dolorem facilis. Velit vel in a rerum natus facere. Enim rerum eaque qui facilis. Numquam laudantium sed id dolores omnis in. Eos reiciendis deserunt maiores et accusamus quod dolor.',
				},
				{
					id: 8,
					subject: 'Illo illum aut debitis earum',
					read: false,
					sender: 'Camping',
					href: '#',
					date: '8 MARS',
					datetime: '2021-01-27T16:35',
					preview:
					'Doloremque dolorem maiores assumenda dolorem facilis. Velit vel in a rerum natus facere. Enim rerum eaque qui facilis. Numquam laudantium sed id dolores omnis in. Eos reiciendis deserunt maiores et accusamus quod dolor.',
				},
				{
					id: 9,
					subject: 'Qui dolore iste ut est cumque sed',
					read: true,
					sender: 'Camping',
					href: '#',
					date: '9 MARS',
					datetime: '2021-01-27T16:35',
					preview:
					'Doloremque dolorem maiores assumenda dolorem facilis. Velit vel in a rerum natus facere. Enim rerum eaque qui facilis. Numquam laudantium sed id dolores omnis in. Eos reiciendis deserunt maiores et accusamus quod dolor.',
				},
				{
					id: 10,
					subject: 'Aut sed aut illum delectus maiores laboriosam ex',
					read: true,
					sender: 'Camping',
					href: '#',
					date: '9 MARS',
					datetime: '2021-01-27T16:35',
					preview:
					'Doloremque dolorem maiores assumenda dolorem facilis. Velit vel in a rerum natus facere. Enim rerum eaque qui facilis. Numquam laudantium sed id dolores omnis in. Eos reiciendis deserunt maiores et accusamus quod dolor.',
				},
			]
		}
	}

	componentDidMount() {
		this.props.updateLogs(this.props.history.location.pathname, 'Notifications')
		this.props.updateShell(null, true, this.props.history.location.pathname)
		this.props.fetchConversationsByUser(this.props.user._id)
		.then(conversations => {
			console.log("fetch conversations", conversations)
			this.setState({ conversations: conversations })
		})
		.catch(err => console.log(err))
	}

	read = (id) => {
		const messages = {
			...this.state.messages
		}
		messages[id].read = true
		this.setState({ messages : messages })
	}

	handleCurrentTab = (tab) => {		
		console.log(tab)
	}

	render() {
		if (this.state.conversations === null)
		{
			return (
				<div className="loading">
					<Loader />
				</div>
			)
		}
		else
		{
			const lang_assets = this.props.lang_assets
			const buttonStyle = {
				position: 'fixed',
				top: '80vh',
				textAlign: 'right',
				width: '90%',
			}
			return (
				<div className="">
					<div className="flex flex-col w-full fixed z-10">
						<div className="flex justify-center items-center z-0 w-full top-0 h-36" style={{ backgroundColor: '#' + this.props.theme.color1 }}>
							<div className="text-white text-3xl">
								<h1>{lang_assets.messages.title}</h1>
							</div>
						</div>
						<div className="bg-white w-full h-16 flex" style={{ filter: 'drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))' }}>
							<Link 
								to={this.props.lang_assets.paths.notifications} 
								onClick={this.handleCurrentTab.bind(this, 'notifications')} 
								className="w-1/2 h-full pl-2.5 text-center border-r border-gray-300 flex items-center justify-center"
							>
								<div className="">{lang_assets.notifications.title}</div>
							</Link>
							<Link 
								to="/messages" 
								onClick={this.handleCurrentTab.bind(this, 'messages')} 
								className="w-1/2 h-full pr-2.5 text-center flex items-center justify-center"
							>
								<div>{lang_assets.messages.title}</div>
							</Link>
						</div>
					</div>
					<div className="bg-white w-full pt-52 min-h-9/10-screen z-0">
						<nav aria-label="Message list" className="min-h-0 flex-1 overflow-y-auto pt-2 bg-transparent">
							<ul className="border-b border-gray-200 divide-y divide-gray-200 ">
								{this.state.conversations.map((conversation, id) => {
									const preview = 'Doloremque dolorem maiores assumenda dolorem facilis. Velit vel in a rerum natus facere. Enim rerum eaque qui facilis. Numquam laudantium sed id dolores omnis in. Eos reiciendis deserunt maiores et accusamus quod dolor.'
									const date = '4 OCTOBRE'
									const sender = 'Franck DAVID'
									const datetime = '2021-01-27T16:35'
									let unreadNotif = (<p className="text-sm rounded-full w-5 h-5 px-1.5 bg-green-500 text-white">1</p>)
									if (conversation.read === true)
										unreadNotif = ''
									return (
										<Link to='/conversation'>
											<li
												key={id}
												className="relative bg-white w-full py-5 px-6 hover:bg-gray-100 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600"
												onClick={this.read.bind(this, id)}
											>
												<div className="flex justify-between space-x-3">
													<div className="min-w-0 flex-1">
														<div href={conversation.href} className="block focus:outline-none">
															<span className="absolute inset-0" aria-hidden="true" />
															<p className="text-sm font-medium text-gray-900 truncate">{sender}</p>
															<p className="text-sm text-gray-500 truncate">{conversation.title}</p>
														</div>
													</div>
													<time
														dateTime={datetime}
														className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500"
													>
														{date}
													</time>
												</div>
												<div className="mt-1 flex">
													<p className="line-clamp-2 text-sm text-gray-600 truncate">{preview}</p>
													{unreadNotif}
												</div>
											</li>
										</Link> 
									)
								})}
							</ul>
						</nav>
					</div>
					<div style={buttonStyle}>
						<Link
							to='/intervention'
							style={{ backgroundColor: '#' + this.props.theme.color1 }}
							className="inline-flex items-center p-3 border border-transparent rounded-full shadow-sm text-white filter drop-shadow-xl"
						>
							<PlusSmIconOutline className="h-6 w-6" aria-hidden="true" />
						</Link>
					</div>
				</div>
			)
		}
	}
}

export default MessageList