import React, { Component } from 'react'
import ButtonPrevious from '../UI/ButtonPrevious'
import { XCircleIcon } from '@heroicons/react/solid'

class SubscribeStepCivility extends Component {
	constructor(props) {
		super(props)
		this.button = (this.props.button) ? this.props.button : "Suivant"
		this.mandatory = (this.props.mandatory === true) ? true : false
		this.input_selected_style= {
			borderWidth: '2px',
			borderColor: '#' + this.props.theme.color1,
			backgroundColor: '#' + this.props.theme.color1,
			color : '#FFFFFF'
		}
		this.input_unselected_style = {
			borderWidth: '2px',
			borderStyle: 'solid',
			borderColor: '#' + this.props.theme.color1
		}
		this.state = {
			style: {
				current_female_style : (this.props.user.civility !== "female") ? this.input_unselected_style : this.input_selected_style,
				current_male_style : (this.props.user.civility !== "male") ? this.input_unselected_style : this.input_selected_style,
				current_other_style : (this.props.user.civility !== "other") ? this.input_unselected_style : this.input_selected_style
			},
			form1: {
				civility: (this.props.user.civility.length > 0) ? this.props.user.civility : null
			},
			error: null
		}
	}

	setErrorMsg = (msg) => {
		this.setState({ error: msg })
	}

	handleCivility = (civility) => {
		let form1 = {
			civility: null
		}
		let style = {
			current_female_style: this.input_unselected_style,
			current_male_style: this.input_unselected_style,
			current_other_style: this.input_unselected_style
		}
		if (civility === "female")
		{
			form1 = {
				civility: civility
			}
			style = {
				current_female_style: this.input_selected_style,
				current_male_style: this.input_unselected_style,
				current_other_style: this.input_unselected_style
			}
		}
		else if (civility === "male")
		{
			form1 = {
				civility: civility
			}
			style = {
				current_female_style: this.input_unselected_style,
				current_male_style: this.input_selected_style,
				current_other_style: this.input_unselected_style
			}
		}
		else if (civility === "other")
		{
			form1 = {
				civility: civility
			}
			style = {
				current_female_style: this.input_unselected_style,
				current_male_style: this.input_unselected_style,
				current_other_style: this.input_selected_style
			}
		}
		this.setState({ style: style, form1: form1 })	
	}

	handleSubmit = (e) => {
		e.preventDefault()
		if (this.mandatory === true && !this.state.form1.civility)
			this.setErrorMsg("Veuillez sélectionner votre civilité")
		else
		{
			this.props.updateUser(this.state.form1)
			this.props.validateStepCivility(this.state.form1)
		}
	}
	
	render ()
	{
		let otherJSX = (<div type="button" onClick={this.handleCivility.bind(this, "other")} className="font-semibold py-5 w-full rounded-full text-xl mb-10 text-center waves-effect waves-light" style={this.state.style.current_other_style}>Je préfère ne pas répondre </div>)
		if (this.mandatory === true)
			otherJSX = null
		let error = <p></p>
		if (this.state.error)
		{
			error = (
				<div className="rounded-md bg-red-50 p-4 mt-3">
					<div className="flex">
						<div className="flex-shrink-0">
							<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
						</div>
						<div className="ml-3">
							<h3 className="text-sm font-medium text-red-800">{this.state.error}</h3>
						</div>
					</div>
				</div>
			)
		}
		return (
			<div>
				<ButtonPrevious
					theme={this.props.theme}
					handlePreviousBtn={this.props.handlePreviousBtn}
				/>
				<div style={{backgroundColor: "#" + this.props.theme.color1}} className="h-52 z-0 px-8 text-center">
					<p className="text-2xl text-white pt-14">Inscription</p>
					<h1 className="text-3xl text-white font-semibold m-0 pt-2">Étape {this.props.stepNb}</h1>
				</div>
				<div className="rounded-t-3xl z-10" style={{backgroundColor: "#ffffff", marginTop: "-2rem"}}>
					<div className="px-10 pt-16 pb-10">
						<div>
							<h2 className="text-3xl font-semibold text-gray-700 mb-10">Vous êtes <span role="img" aria-label="crystal-ball">🔮</span></h2>
							{error}
							<form className="mb-16" onSubmit={this.handleSubmit}>
								<div>
									<div type="button" onClick={this.handleCivility.bind(this, "female")} className="font-semibold py-5 w-full rounded-full text-xl mt-10 text-center waves-effect waves-light" style={this.state.style.current_female_style}>Une femme</div>
									<div type="button" onClick={this.handleCivility.bind(this, "male")} className="font-semibold py-5 w-full rounded-full text-xl my-6 text-center waves-effect waves-light" style={this.state.style.current_male_style}>Un homme</div>
									{otherJSX}
								</div>
								<button className="font-semibold py-5 w-full rounded-full text-white shadow-xl text-xl my-10 waves-effect waves-light" type="submit" style={{ backgroundColor: '#' + this.props.theme.color1 }}>{this.button}</button>
							</form>								
						</div>
					</div>
				</div>
			</div>
		)
	}
}
		
export default SubscribeStepCivility