import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import tools from '../../tools'
import ButtonPrevious from '../UI/ButtonPrevious'
import { XCircleIcon } from '@heroicons/react/solid'

class SubscribeStepActivationCode extends Component {
	constructor(props) {
		super(props)
		this.default_ph_color = "text-gray-400"
		this.updated_ph_color = "text-black"
		this.activated_btn_color = "#" + this.props.theme.color1
		this.deactivated_btn_color = "#A3A3A3"
		this.code_ph = "ex: adresse@exemple.com"
		let code = this.code_ph
		let v_code = this.props.user.code
		let current_btn_color = this.deactivated_btn_color
		if (this.props.user.code && this.props.user.code.length > 0)
		{
			current_btn_color = this.activated_btn_color
			code = this.props.user.code
		}
		else if (this.props.code && this.props.code.length > 0 && this.props.code.length <= 6)
		{
			current_btn_color = this.activated_btn_color
			code = this.props.code.toUpperCase()
			v_code = this.props.code.toUpperCase()
		}
		this.state = {
			form1: {
				code: code,
				v_code: v_code
			},
			current_btn_color: current_btn_color,
			error: null,
			success: null
		}
	}

	setErrorMsg = (msg) => {
		this.setState({ success: null, error: msg })
	}

	setSuccessMsg = (msg) => {
		this.setState({ success: msg, error: null })
	}

	clearFlash = () => {
		this.setState({ error: null, success: null })
	}

	focusCode = (event) => {
		this.setState({ error : null })
		if (this.state.form1.v_code.length === 0) {
			let form1 = {
				code: '',
				v_code: this.state.form1.v_code
			}
			this.setState({ form1: form1 })
		}
	}

	blurCode = (event) => {
		if (this.state.form1.v_code.length === 0) {
			let form1 = {
				code: this.code_ph,
				v_code: this.state.form1.v_code
			}
			this.setState({ form1: form1 })
		}
	}

	btn_color = (event) => {
		let style = this.state.current_btn_color
		if (event.target.value.length === 6 || event.target.value.indexOf('@') !== -1)
			style = this.activated_btn_color
		else
			style = this.deactivated_btn_color
		this.setState({ current_btn_color : style })
	}

	handleCode = (event) => {
		this.btn_color(event)	
		let form1 = {
			code: event.target.value,
			v_code: event.target.value
		}
		this.setState({ form1: form1 })
	}

	code_ph_color = () => {
		let color = this.default_ph_color
		if (this.state.form1.v_code !== "")
			color = this.updated_ph_color
		return (color)
	}

	_validateForm1 = () => {
		let error = false
		let error_msg = null
		if (this.state.form1.v_code.length !== 6 && this.state.form1.v_code.indexOf('@') === -1)
		{
			if (this.state.form1.v_code.length === 0)
				error_msg = "Code ou email invalide, veuillez réessayer"
			else
				error_msg = "Ce code doit comprendre 6 caractères"
			error = true
			this.setState({ error: error_msg })
		}
		return (!error) ? true : false
	}

	handleSubmit = (event) => {
		event.preventDefault()
		if (this._validateForm1())
		{
			const time = tools.getTimestamp()
			let activation_specials_list = []
			const code = this.state.form1.v_code.toUpperCase()
			for (let i = 0; i < this.props.activation_code_specials.length; i++)
			{
				const activation_code = this.props.activation_code_specials[i]
				if ((activation_code.validity_start === null || time >= activation_code.validity_start) &&
					(activation_code.validity_end === null || time <= activation_code.validity_end))
					activation_specials_list.push(activation_code.code)
			}
			if (activation_specials_list.indexOf(code) !== -1)
			{
				let special_code = null
				for (let i = 0; i < this.props.activation_code_specials.length; i++)
				{
					if (this.props.activation_code_specials[i].code === code)
					{
						special_code = this.props.activation_code_specials[i]
						break
					}
				}
				this.props.validateStepActivationCode(this.state.form1, 0, special_code)
			}
			else
			{
				this.props.postCheckActivationCode(code)
				.then(client_number => {
					if (!client_number)
						this.setErrorMsg('Code ou email invalide, veuillez réessayer')
					else
						this.props.validateStepActivationCode(this.state.form1, client_number, null)
				})
				.catch(err => {
					this.setErrorMsg('Une erreur s\'est produite.')
					console.log(err)
				})
			}
		}
	}

	render() {
		let error = null
		if (this.state.error)
		{
			error = (
				<div className="rounded-md bg-red-50 p-4 mt-3">
					<div className="flex">
						<div className="flex-shrink-0">
							<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
						</div>
						<div className="ml-3">
							<h3 className="text-sm font-medium text-red-800">{this.state.error}</h3>
						</div>
					</div>
				</div>
			)
		}
		return (
			<div>
				<ButtonPrevious
					theme={this.props.theme}
					handlePreviousBtn={this.props.handlePreviousBtn}
				/>
				<div style={{backgroundColor: "#" + this.props.theme.color1}} className="h-52 z-0 px-8 text-center">
					<p className="text-2xl text-white pt-14 mt-0">Bienvenue</p>
					<h1 className="text-3xl text-white font-semibold m-0 pt-2">Créez votre compte</h1>
				</div>
				<div className="rounded-t-3xl z-10" style={{backgroundColor: "#ffffff", marginTop: "-2rem"}}>
					<div className="px-10 pt-16 pb-10">
						<div>
							<h2 className="text-2xl text-gray-400 mb-10"><span className="text-gray-700 font-semibold">Entrez l'adresse e-mail utilisée pour la réservation</span> ou votre code d'activation</h2>
							<div className="w-full flex justify-center">
						</div>
							<form className="mb-16" onSubmit={this.handleSubmit}>
								<label>
									<div>
										<div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
											<input
												className="block pl-0 w-full border-0 border-b border-transparent text-gray-500 focus:text-gray-700 focus:border-indigo-600 focus:ring-0 text-xl"
												type="text"
												value={this.state.form1.code}
												onChange={this.handleCode}
												onBlur={this.blurCode}
												onFocus={this.focusCode}
											/>
										</div>
									</div>
								</label>
								{error}
								<button 
									className="items-center px-6 py-5 border border-transparent text-xl font-medium rounded-full w-full shadow-sm text-white my-10" 
									type="submit" 
									style={{ backgroundColor: this.state.current_btn_color }}
								>
									Suivant
								</button>
								<p className="text-gray-400 text-base">Je ne sais plus quel e-mail a été utilisé lors de la réservation.</p>
								<button className="text-base font-semibold" style={{ color: '#' + this.props.theme.color1 }} type="button" onClick={this.props.gotoHelp}>Obtenir de l'aide</button>
							</form>
							<p className="mb-5">
								<Link to="/connexion" className="font-semibold text-gray-400">Déjà inscrit ?
									<b className="font-semibold ml-2" style={{ color: '#' + this.props.theme.color1 }}>Connectez-vous</b>
								</Link>
							</p>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default SubscribeStepActivationCode
