import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { ArrowLeftIcon } from '@heroicons/react/outline'
import './Header.css'

class Header extends Component {
	constructor(props) {
		super(props)
		this.state = {
			previousPage: null,
			showPrevious: false
		}
	}

	componentDidMount() {
		if (this.props.previousPage)
			this.setState({ previousPage: this.props.previousPage })
		if (this.props.showPrevious)
			this.setState({ showPrevious: this.props.showPrevious })
	}

	componentDidUpdate(prevProps) {
		if (prevProps.previousPage !== this.props.previousPage)
			this.setState({ previousPage: this.props.previousPage })
		if (prevProps.showPrevious !== this.props.showPrevious)
			this.setState({ showPrevious: this.props.showPrevious })
	}

	render() {
		/*let menuJSX = (
			<button
				style={{ backgroundColor: '#' + this.props.theme.color1 }}
				className="btn-floating btn-large waves-effect waves-light"
				onClick={this.props.toggleBurger}
			>
				<i className="material-icons">dehaze</i>
			</button>
		)
		let profil_icon = null
		for (let i = 0; i < this.props.icons.length; i++)
		{
			if ((this.props.icons[i].name === "user-1" && this.props.user.civility === "femme") ||
				(this.props.icons[i].name === "user-2" && this.props.user.civility === "homme") ||
				(this.props.icons[i].name === "users" && this.props.user.civility !== "homme" && this.props.user.civility !== "femme"))
			{
				for (let j = 0; j < this.props.icons[i].lineal.length; j++)
				{
					if (this.props.icons[i].lineal[j].theme === this.props.theme._id)
					{
						profil_icon = this.props.icons[i].lineal[j].icon
						break
					}
				}
				break
			}
		}
		let menu_k = -1
		let burgerJSX = (
			<Swipeable onSwipingLeft={this.props.swipeLeft}>
				<div className="Menu" id="burger-menu">
					<ul>
						<li className="Menu-title"><Link to="/" onClick={this.props.closeBurger}><img src={this.props.logo} alt="Logo" /></Link></li>
						{this.props.menu.map(({ title, type, rubric, icon }) => {
							menu_k++
							let pic = null
							for (let i = 0; i < this.props.icons.length; i++)
							{
								if (this.props.icons[i]._id === icon)
								{
									for (let j = 0; j < this.props.icons[i].lineal.length; j++)
									{
										if (this.props.icons[i].lineal[j].theme === this.props.theme._id)
										{
											pic = this.props.icons[i].lineal[j].icon
											break
										}
									}
									break
								}
							}
							let link = null
							if (type === "rubric")
							{
								for (let i = 0; i < this.props.groups.length; i++)
								{
									if (this.props.groups[i]._id === rubric)
									{
										link = '/' + this.props.groups[i].prefix
										break
									}
								}
							}
							else
								link = "/animations"
							return (
								<li key={menu_k} className="elem"><img className="icon" src={pic} alt="Icône menu" /><Link to={link} onClick={this.props.closeBurger}>{title}</Link></li>
							)
						})}
						<li className="elem">
							<img className="icon" src={profil_icon} alt="Icône menu" /><Link to="/membres" onClick={this.props.closeBurger}><strong>{this.props.user.firstname} {this.props.user.lastname}</strong></Link>
						</li>
					</ul>
				</div>
			</Swipeable>
		)*/
		if (this.state.previousPage !== null && this.state.showPrevious === true)
		{
			let previous = (this.state.previousPage[0] !== undefined) ? this.state.previousPage[0] : '/'
			return (
				// <div className="Header">
				<div className="z-30 w-full fixed block">
					<Link
						to={previous}
						style={{ backgroundColor: '#' + this.props.theme.color1 }}
						className="inline-flex mt-3 ml-2 z-30 items-center p-3 border border-transparent shadow-previousbtn rounded-full text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
						onClick={this.props.shiftPrevious}
					>
						<ArrowLeftIcon className="h-6 w-6" aria-hidden="true" />
					</Link>
				</div>
			)
		}
		else
			return (<div></div>)
		/*else
		{
			return (
				<div className="Header">
					{menuJSX}
					{burgerJSX}
				</div>
			)
		}*/
	}
}

export default Header