import React, { Component } from 'react'
import Loader from '../UI/Loader'
import M from 'materialize-css'

class Bookings extends Component {
	constructor(props) {
		super(props)
		this.state = {
			form1: {
				lang: this.props.user.langage
			},
			error: null
		}
	}

	componentDidMount() {
		this.props.updateLogs(this.props.history.location.pathname, 'Membres')
		this.props.updateShell('profile', true, this.props.history.location.pathname)
		this.props.fetchNotifications()
		this.loadReservations()
	}

	componentDidUpdate() {
		const elems = document.querySelectorAll('select')
		M.FormSelect.init(elems)
	}

	loadReservations = (fromProps = false) => {
		this.props.fetchServices()
		.then(services => {
			this.props.fetchServiceBenefits()
			.then(service_benefits => {
				this.props.fetchServiceReservationsByUser(this.props.user._id)
				.then(reservations => {
					for (let i = 0; i < reservations.length; i++)
					{
						for (let j = 0; j < service_benefits.length; j++)
						{
							const service_benefit = service_benefits[j]
							if (reservations[i].service_benefit === service_benefit._id)
							{
								reservations[i].service_benefit = service_benefit
								break
							}
						}
						if (!reservations[i].service_benefit) // reservation product
						{
							for (let j = 0; j < services.length; j++)
							{
								const service = services[j]
								if (reservations[i].service === service._id)
								{
									reservations[i].service = service
									break
								}
							}
						}
					}
					reservations.sort((a, b) => {
						return a.start - b.start
					})
					this.setState({ reservations: reservations })
				})
				.catch(err => console.log(err))
			})
			.catch(err => console.log(err))
		})
		.catch(err => console.log(err))
	}

	cancelReservation = (service_reservation) => {
		let found = false
		const reservations = this.state.reservations
		for (let i = 0; i < reservations.length; i++)
		{
			if (reservations[i]._id === service_reservation)
			{
				found = i
				break
			}
		}
		reservations.splice(found, 1)
		this.setState({ reservations: reservations })
		this.props.postDeleteServiceReservation(service_reservation)
		.then(ret => {
			if (ret === true)
			{
				// todo...
			}
		})
		.catch(err => console.log(err))
	}

	render() {
		const reservations = this.state.reservations
		if (!reservations)
		{
			return (
				<div>
					<div>
						<div style={{backgroundColor: "#" + this.props.theme.color1}} className="h-52 z-0 px-8 text-center">
							<h1 className="text-3xl text-white font-semibold m-0 pt-16">Mes réservations</h1>
						</div>
					</div>
					<div className="rounded-t-3xl z-10" style={{backgroundColor: "#ffffff", marginTop: "-2rem"}}>
						<div className="px-10 pt-16 pb-10">
							<div className="loading" style={{ marginTop: "30vh" }}>
								<Loader />
							</div>
						</div>
					</div>
				</div>
			)
		}
		else
		{
			let reservation_k = -1
			const month_list = [
				'janvier',
				'février',
				'mars',
				'avril',
				'mai',
				'juin',
				'juillet',
				'août',
				'septembre',
				'octobre',
				'novembre',
				'décembre'
			]
			let products_key = -1
			return (
				<div>
					<div style={{backgroundColor: "#" + this.props.theme.color1}} className="h-52 z-0 px-8 text-center">
						<h1 className="text-3xl text-white font-semibold m-0 pt-16">Mes réservations</h1>
					</div>
					<div className="rounded-t-3xl z-10" style={{backgroundColor: "#ffffff", marginTop: "-2rem"}}>
						<div className="px-10 pt-16 pb-10">
							<ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
								{reservations.map(({ _id, service, service_benefit, start, self, participants, cart }) => {
									reservation_k++
									let date = new Date(start * 1000)
									let day = date.getDate()
									if (day < 10)
										day = '0' + day
									let month = month_list[date.getMonth()]
									let year = date.getFullYear()
									let hour = date.getHours()
									if (hour < 10)
										hour = '0' + hour
									let minute = date.getMinutes()
									if (minute < 10)
										minute = '0' + minute
									const timetxt = day + " " + month + " " + year + ", " + hour + "h" + minute
									const benefit = (service_benefit !== null) ? true : false
									const translations = (service_benefit !== null) ? service_benefit.translations : service.translations
									let subtitle = ""
									let location = ""
									if (translations)
									{
										for (let i = 0; i < translations.length; i++)
										{
											if (translations[i].lang === this.props.user.langage)
											{
												subtitle = translations[i].subtitle
												location = translations[i].location
											}
										}
									}
									let participants_txt = null
									let participants_ul = null
									let cart_txt = null
									if (benefit === true)
									{
										let participant_names = []
										if (self === true)
											participant_names.push("Vous")
										if (participants)
											for (let i = 0; i < participants.length; i++)
												participant_names.push(participants[i].firstname + " " + participants[i].lastname)
											if (participant_names.length === 1)
												participants_txt = " " + participant_names[0]
											else
											{
												participants_ul = (
													<p className="mt-1 text-gray-500 text-sm truncate">
														<b>Participants :</b>
														<ul className="mt-1 text-gray-500 text-sm list-disc">
															{participant_names.map(name => {
																return (<li>{name}</li>)
															})}
														</ul>
													</p>
												)
											}
									}
									else // product
									{
										if (cart)
										{
											let price = 0
											for (let i = 0; i < cart.length; i++)
												price += Number(cart[i].price) * Number(cart[i].quantity)
											let total_txt = String(price)
											if (price > 0)
											{
												let price_tab = total_txt.split('.')
												if (price_tab.length > 1)
												{
													if (price_tab[1].length === 1)
														price_tab[1] += '0'
													else if (price_tab[1].length > 2)
														price_tab[1] = price_tab[1].substring(0, 2)
													total_txt = price_tab[0] + ',' + price_tab[1]
												}
											}
											cart_txt = (
												<div className="mt-1 text-gray-500 text-sm truncate">
													<b>Produits :</b>
													{cart.map(article => {
														products_key++
														let name = ""
														for (let i = 0; i < article.translations.length; i++)
														{
															if (article.translations[i].lang === this.props.user.langage)
															{
																name = article.translations[i].subtitle
																break
															}
														}
														let price = Number(article.price)
														let quantity = Number(article.quantity)
														let price_txt = String(price)
														if (price > 0)
														{
															let price_tab = price_txt.split('.')
															if (price_tab.length > 1)
															{
																if (price_tab[1].length === 1)
																	price_tab[1] += '0'
																else if (price_tab[1].length > 2)
																	price_tab[1] = price_tab[1].substring(0, 2)
																price_txt = price_tab[0] + ',' + price_tab[1]
															}
														}
														return (
															<div key={products_key} className="flex justify-between mt-5">
																<div className="text-gray-500">{name}</div>
																<div className="text-gray-500 font-semibold">{quantity}x {price_txt}€</div>
															</div>
														)
													})}
													<p><b>Total :</b> {total_txt}€</p>
												</div>
											)
										}
									}
									return (
										<li key={reservation_k} className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
											<div className="w-full flex items-center justify-between p-6 space-x-6">
												<div className="flex-1 truncate">
													<p className="mt-1 text-gray-500 text-sm truncate">{timetxt}</p>
													<div className="flex items-center space-x-3">
														<h3 className="text-gray-900 text-sm font-medium truncate my-4">{subtitle}</h3>
													</div>
													{participants_txt}
													{participants_ul}
													{cart_txt}
													<p className="mt-1 text-gray-500 text-sm truncate"><b>Lieu : </b>{location}</p>
												</div>
											</div>
											<div>
												<div className="-mt-px flex divide-x divide-gray-200">
													<div className="-ml-px w-0 flex-1 flex">
														<span onClick={this.cancelReservation.bind(this, _id)} className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
															<svg className="w-5 h-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
																<path d="M10.185,1.417c-4.741,0-8.583,3.842-8.583,8.583c0,4.74,3.842,8.582,8.583,8.582S18.768,14.74,18.768,10C18.768,5.259,14.926,1.417,10.185,1.417 M10.185,17.68c-4.235,0-7.679-3.445-7.679-7.68c0-4.235,3.444-7.679,7.679-7.679S17.864,5.765,17.864,10C17.864,14.234,14.42,17.68,10.185,17.68 M10.824,10l2.842-2.844c0.178-0.176,0.178-0.46,0-0.637c-0.177-0.178-0.461-0.178-0.637,0l-2.844,2.841L7.341,6.52c-0.176-0.178-0.46-0.178-0.637,0c-0.178,0.176-0.178,0.461,0,0.637L9.546,10l-2.841,2.844c-0.178,0.176-0.178,0.461,0,0.637c0.178,0.178,0.459,0.178,0.637,0l2.844-2.841l2.844,2.841c0.178,0.178,0.459,0.178,0.637,0c0.178-0.176,0.178-0.461,0-0.637L10.824,10z"></path>
															</svg>
															<span className="ml-3 text-red-400">Annuler</span>
														</span>
													</div>
												</div>
											</div>
										</li>
									)
								})}
							</ul>
						</div> 
					</div>
				</div>
			)
		}
	}
}

export default Bookings