import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import connexion from '../../connexion'

class Tracker extends Component {
	constructor(props) {
		super(props)
		this.token = props.match.params.token
		this.code = props.match.params.code
	}

	componentDidMount() {
		let tracker = null
		if (this.token === "764d53ffbf8d0c38f0a40759af6892a8f468a3d4")
			tracker = "flyer"
		else if (this.token === "be31f19556ec70d0518fe0e7dec1fc7c309d8c5c")
			tracker = "kakemono"
		else if (this.token === "6581f6543148cc24252eb7b3fecbcdcec8952ea1")
			tracker = "sticker"
		if (tracker !== null)
		{
			fetch(connexion.connect.url + 'webapp/config/redirect', {
				method: 'POST',
				body: JSON.stringify({
					tracker: tracker
				})
			})
			.then(ret => {
			})
			.catch(err => console.log(err))
		}
		else if (this.code !== null)
			this.props.updateActivationCode(this.code)
	}

	render() {
		return (
			<Redirect to="/" />
		)
	}
}

export default Tracker