import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Loader from '../UI/Loader'
import SubscribeStepActivationCode from './SubscribeStepActivationCode'
import ActivationCodeHelp from './ActivationCodeHelp'
import SubscribeStepCheckinCheckout from './SubscribeStepCheckinCheckout'
import SubscribeStepEmail from './SubscribeStepEmail'
import SubscribeStepName from './SubscribeStepName'
import SubscribeStepBirth from './SubscribeStepBirth'
import SubscribeStepCivility from './SubscribeStepCivility'
import SubscribeStepEnd from './SubscribeStepEnd'
import SubscribeStepPWA from './SubscribeStepPWA'
import SubscribeStepNotifications from './SubscribeStepNotifications'
import UpdateEmail from './UpdateEmail'
import Cookies from 'js-cookie'
import tools from '../../tools'
import './Signin.css'
import M from 'materialize-css'

class SubscribeCamping extends Component {
	constructor(props) {
		super(props)
		this.steps = [
			'lobby',
			'stepActivationCode',
			'stepEmail',
			'stepCheckin',
			'stepName',
			'stepBirth',
			'stepCivility',
			'stepEnd',
			'stepPWA',
			'stepNotifications',
			'updateEmail'
		]
		this.state = {
			user: {
				email : '',
				password : '',
				client_number: '',
				civility: '',
				firstname: '',
				lastname: '',
				birthday: '',
				code : '',
				day: '',
				month: '',
				year: '',
				t_password: '',
				checkin: '',
				long_checkin: '',
				checkout: '',
				long_checkout: ''
			},
			is_ready: false,
			is_client: false,
			success: null,
			error: null,
			step: this.steps[1]
		}
	}

	componentDidMount() {
		const code = (this.props.activation_code) ? this.props.activation_code.toUpperCase().trim() : null
		if (this.props.user && this.props.user.invite !== true)
			this.setState({ step: "lobby" })
		if (code && code.length > 0)
		{
			const time = tools.getTimestamp()
			let activation_specials_list = []
			for (let i = 0; i < this.props.activation_code_specials.length; i++)
			{
				const activation_code = this.props.activation_code_specials[i]
				if ((activation_code.validity_start === null || time >= activation_code.validity_start) &&
					(activation_code.validity_end === null || time <= activation_code.validity_end))
					activation_specials_list.push(activation_code.code)
			}
			if (activation_specials_list.indexOf(code) !== -1)
			{
				let special_code = null
				for (let i = 0; i < this.props.activation_code_specials.length; i++)
				{
					if (this.props.activation_code_specials[i].code === code)
					{
						special_code = this.props.activation_code_specials[i]
						break
					}
				}
				this.props.postCheckActivationCode(code)
				.then(client_number => {
					if (!client_number)
					{
						this.validateStepActivationCode({
							v_code: code
						}, client_number, special_code)
						this.setState({ is_ready: true })
					}
				})
				.catch(err => {
					this.setState({ is_ready: true })
					// this.setErrorMsg('Une erreur s\'est produite.')
					console.log(err)
				})
			}
			else
				this.setState({ is_ready: true })
		}
		else
			this.setState({ is_ready: true })
	}

	componentDidUpdate() {
		const elems = document.querySelectorAll('select')
		M.FormSelect.init(elems)
	}

	setErrorMsg = (msg) => {
		this.setState({ error: msg })
	}

	modalRedirection = () => {
		if (this.state.step === 'help')
			this.validateHelp()
		else if (this.state.step === 'updateEmail')
			this.validateUpdateEmail()
	}

	validateStepActivationCode = (data, client_number, special_code) => {
		let is_client = true
		if (special_code && (special_code.arrival_time === null || special_code.departure_time === null))
			is_client = false
		const user_obj = {
			...this.state.user,
			client_number: client_number,
			checkin: (special_code && special_code.arrival_time) ? tools.parseDate(special_code.arrival_time, false) : null,
			checkout: (special_code && special_code.departure_time) ? tools.parseDate(special_code.departure_time, false) : null
		}
		if (data.v_code)
			user_obj.code = data.v_code.toUpperCase()
		this.setState({ step: 'stepEmail', is_client: is_client, user: user_obj })
	}
	
	validateHelp = () => {
		this.setState({ step : 'stepActivationCode'})
	}

	validateUpdateEmail = () => {
		this.setState({ step : "/" })
	}

	gotoHelp = () => {
		this.setState({ step: 'help' })
	}

	gotoMail = () => {
		this.setState({ step: 'updateEmail' })
	}
	
	validateStepActivationMail = (user) => {
		const success = "Un lien d'activation à été envoyé sur votre adresse " + user.email
		this.setState({ user: user, success: success, step: 'step2' })
	}
	
	validateStepEmail = () => {
		let step = "stepName"
		if (this.state.is_client === false)
			step = "stepCheckin"
		this.setState({ step: step })
	}

	validateCheckin = () => {
		this.setState({ step: "stepName" })
	}

	validateStepName = () => {
		this.setState({ step: "stepBirth" })
	}

	validateStepBirth = () => {
		this.setState({ step: "stepCivility" })
	}

	validateStepCivility = (data) => {
		const user = {
			client_number: this.state.user.client_number,
			firstname: this.state.user.firstname,
			lastname: this.state.user.lastname,
			email: this.state.user.email,
			password: this.state.user.password,
			birthday: (this.state.user.birthday) ? this.state.user.birthday : null,
			civility: (data.civility) ? data.civility : null,
			arrival: this.state.user.checkin,
			departure: this.state.user.checkout,
			user_type: this.state.user.user_type
		}
		this.props.postSubscribeUser(user)
		.then(user => {
			if (!user)
			{
				this.setErrorMsg('Cette adresse e-mail existe déjà')
				// vérifier si le mdp est ok, puis connecter...
			}
			else
				this.setState({ step: "stepEnd" })
		})
		.catch(err => {
			this.setErrorMsg('Une erreur s\'est produite.')
			console.log(err)
		})
	}

	validateStepEnd = () => {
		if (this.showInstallPrompt === true)
			this.setState({ step: "stepPWA" })
		else if ('serviceWorker' in navigator && 'PushManager' in window && Notification.permission === 'default' && this.props.text)
			this.setState({ step: "stepNotifications" })
		else
			this.connectUser()
	}

	validateStepPWA = () => {
		if ('serviceWorker' in navigator && 'PushManager' in window && Notification.permission === 'default' && this.props.text)
			this.setState({ step: "stepNotifications" })
		else
			this.connectUser()
	}

	validateStepNotifications = () => {
		this.connectUser()
	}

	connectUser = (bypass_user = null) => {
		if (bypass_user)
		{
			Cookies.set('session', bypass_user.session)
			localStorage.setItem("session", bypass_user.session)
			this.props.updateUser(bypass_user, bypass_user.session)
			this.setState({ step: "lobby", user: bypass_user })
		}
		else
		{
			const user = {
				identifiant: this.state.user.email,
				password: this.state.user.password
			}
			this.props.postConnectUser(user)
			.then(user => {
				Cookies.set('session', user.session)
				localStorage.setItem("session", user.session)
				this.props.updateUser(user, user.session)
				this.setState({ step: "lobby", user: user })
			})
			.catch(err => console.log("can not connect user", err))
		}
	}

	updateUser = (data) => {
		let user_obj = {
			...this.state.user
		}
		if (data.v_email && data.v_password)
		{
			user_obj.email = data.v_email
			user_obj.password = data.v_password
			user_obj.t_password = data.t_password

			// Cas particulier KOBAN
			if (data.v_firstname && data.v_lastname)
			{
				user_obj.lastname =	data.v_lastname
				user_obj.firstname = data.v_firstname
			}
			if (data.v_birthday)
			{
				user_obj.day = data.v_birthday.substr(0, 2)
				user_obj.month = data.v_birthday.substr(3, 2)
				user_obj.year = data.v_birthday.substr(6, 4)
				user_obj.birthday = data.v_birthday
			}
		}
		else if (data.v_email && this.state.step === 'updateEmail')
			user_obj.email = data.v_email
		else if (data.v_lastname && data.v_firstname)
		{
			user_obj.lastname =	data.v_lastname
			user_obj.firstname = data.v_firstname
		}
		else if (data.civility)
			user_obj.civility =	data.civility
		else if (data.v_day && data.v_month && data.v_year)
		{
			user_obj.day = data.v_day
			user_obj.month = data.v_month
			user_obj.year = data.v_year
			user_obj.birthday = data.v_day + '/' + data.v_month + '/' + data.v_year
		}
		else if (data.v_checkin && data.v_checkout)
		{
			user_obj.checkin = data.v_checkin
			user_obj.checkout = data.v_checkout
			user_obj.long_checkin = data.d_checkin
			user_obj.long_checkout = data.d_checkout
		}
		// console.log("user_obj", user_obj)
		this.setState({ user : user_obj })
	}

	handlePreviousBtn = () => {
		const currIndex = this.steps.indexOf(this.state.step)
		let valuePreviousStep = this.steps[currIndex - 1]
		if (this.state.step === "stepEmail" && this.props.activation_code && this.props.activation_code.length > 0)
			valuePreviousStep = "lobby"
		else if (this.state.is_client === true && this.state.step === "stepName")
			valuePreviousStep = 'stepEmail'
		else if (this.state.step === 'help')
			valuePreviousStep = 'stepActivationCode'
		else if (this.state.step === 'updateEmail')
			valuePreviousStep = 'stepEnd'
		this.setState({ step: valuePreviousStep })
	}

	render() {
		const totalSteps = (this.state.is_client === true) ? 4 : 5
		let actualStep = this.steps.indexOf(this.state.step) - 1
		if (actualStep > 2 && this.state.is_client === true)
			actualStep--
		const titleStep = actualStep + "/" + totalSteps
		if (this.state.is_ready === false)
		{
			return (
				<div className="App">
					<div className="loading">
						<Loader />
					</div>
				</div>
			)
		}
		if (this.state.step === 'stepActivationCode' && this.props.postCheckActivationCode)
		{
			return (
				<div>
					<SubscribeStepActivationCode
						theme={this.props.theme}
						// code={this.props.match.params.code}
						activation_code_specials={this.props.activation_code_specials}
						logo={this.props.logo}
						postCheckActivationCode={this.props.postCheckActivationCode}
						validateStepActivationCode={this.validateStepActivationCode}
						gotoHelp={this.gotoHelp}
						updateUser={this.updateUser}
						handlePreviousBtn={this.handlePreviousBtn}
						user={this.state.user}
					/>
				</div>
			)
		}
		else if (this.state.step === 'help')
		{
			return (
				<ActivationCodeHelp 
					theme={this.props.theme}
					validateHelp={this.validateHelp}
					modalRedirection={this.modalRedirection}
					updateUser={this.updateUser}
					handlePreviousBtn={this.handlePreviousBtn}
					postRequestActivationCode={this.props.postRequestActivationCode}
					user={this.state.user}
				/>
			)
		}
		else if (this.state.step === 'stepEmail')
		{
			return (
				<SubscribeStepEmail 
					theme={this.props.theme}
					// Cas particulier MES Travels
					config_pms={this.props.config_pms}
					fetchConfigPms={this.props.fetchConfigPms}
					connectUser={this.connectUser}
					postConnectUser={this.props.postConnectUser}
					postCheckUserKoban={this.props.postCheckUserKoban}
					postCheckUserEmail={this.props.postCheckUserEmail}
					validateStepEmail={this.validateStepEmail}
					updateUser={this.updateUser}
					handlePreviousBtn={this.handlePreviousBtn}
					user={this.state.user}
					stepNb={titleStep}
				/>
			)
		}
		else if (this.state.step === 'stepCheckin')
		{
			return (
				<div>
					<SubscribeStepCheckinCheckout
						theme={this.props.theme}
						updateUser={this.updateUser}
						handlePreviousBtn={this.handlePreviousBtn}
						user={this.state.user}
						validateCheckin={this.validateCheckin}
						stepNb={titleStep}
					/>
				</div>
			)
		}
		else if (this.state.step === 'stepName')
		{
			return (
				<SubscribeStepName
					theme={this.props.theme}
					app_type={this.props.app_type}
					validateStepName={this.validateStepName}
					updateUser={this.updateUser}
					handlePreviousBtn={this.handlePreviousBtn}
					user={this.state.user}
					stepNb={titleStep}
				/>
			)
		}
		else if (this.state.step === 'stepBirth')
		{
			return (
				<SubscribeStepBirth 
					theme={this.props.theme}
					validateStepBirth={this.validateStepBirth}
					updateUser={this.updateUser}
					handlePreviousBtn={this.handlePreviousBtn}
					user={this.state.user}
					stepNb={titleStep}
				/>
			)
		}
		else if (this.state.step === 'stepCivility')
		{
			return (
				<SubscribeStepCivility
					theme={this.props.theme}
					validateStepCivility={this.validateStepCivility}
					updateUser={this.updateUser}
					handlePreviousBtn={this.handlePreviousBtn}
					postSubscribeUser={this.props.postSubscribeUser}
					user={this.state.user}
					stepNb={titleStep}
				/>
			)
		}
		else if (this.state.step === 'stepEnd')
		{
			return (
				<SubscribeStepEnd 
					theme={this.props.theme}
					error_msg={this.state.error}
					validateStepEnd={this.validateStepEnd}
					gotoMail={this.gotoMail}
					user={this.state.user}
				/>
			)
		}
		else if (this.state.step === 'stepPWA')
		{
			return (
				<SubscribeStepPWA
					theme={this.props.theme}
					validateStepPWA={this.validateStepPWA}
					installEvent={this.props.installEvent}
					handleInstallApp={this.props.handleInstallApp}
					deleteInstallEvent={this.props.deleteInstallEvent}
					user={this.state.user}
				/>
			)
		}
		else if (this.state.step === 'stepNotifications')
		{
			return (
				<SubscribeStepNotifications
					theme={this.props.theme}
					validateStepNotifications={this.validateStepNotifications}
					user={this.state.user}
				/>
			)
		}
		else if (this.state.step === 'updateEmail')
		{
			return(
			<div>
				<UpdateEmail
					theme={this.props.theme}
					modalRedirection={this.modalRedirection}
					updateUser={this.updateUser}
					handlePreviousBtn={this.handlePreviousBtn}
					user={this.state.user}
				/>
			</div>)
		}
		else
		{
			return (
				<div>
					<Redirect to="/" />
				</div>
			)
		}
	}
}

export default SubscribeCamping