import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Booking from '../Services/Booking'
import Loader from '../UI/Loader'
import tools from '../../tools'
import './Animations.css'
import { ClockIcon } from '@heroicons/react/solid'

class AnimationDays extends Component {
	constructor(props) {
		super(props)
		this.state = {
			date: null,
			animations: null,
			dates: null,
			categories: null,
			book_service: null,
			service_workers: null,
			service_reservations: null
		}
	}

	componentDidMount() {
		this.loadAnimations()
		this.loadAnimationCategories()
		this.props.updateShell(null, true, this.props.history.location.pathname)
		this.setState({ date: this.props.match.params.date })
		const date = Number(this.props.match.params.date)
		const dateObj = new Date(date * 1000)
		const year = dateObj.getFullYear()
		let month = dateObj.getMonth() + 1
		if (month < 10)
			month = '0' + month
		let day = dateObj.getDate()
		if (day < 10)
			day = '0' + day
		const infos = {
			timestamp: date,
			date: day + '/' + month + '/' + year
		}
		if (this.props.features === "premium")
			this.loadServiceBenefits()
		this.props.updateLogs(this.props.history.location.pathname, 'AnimationDays', infos)
		this.props.fetchNotifications()
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.match.params.date !== this.props.match.params.date)
		{
			this.loadAnimations()
			const date = Number(this.props.match.params.date)
			const dateObj = new Date(date * 1000)
			const year = dateObj.getFullYear()
			let month = dateObj.getMonth() + 1
			if (month < 10)
				month = '0' + month
			let day = dateObj.getDate()
			if (day < 10)
				day = '0' + day
			const infos = {
				timestamp: date,
				date: day + '/' + month + '/' + year
			}
			this.props.updateLogs(this.props.history.location.pathname, 'AnimationDays', infos)
			this.setState({ date: date, animations: null, dates: null })
		}
		if (prevProps.animationCategories !== this.props.animationCategories)
			this.loadAnimationCategories()
		if (prevState.categories !== this.state.categories || prevProps.match.params.date !== this.props.match.params.date)
			this.loadAnimations()
		if (this.props.features === "premium" && prevState.service_benefits !== this.state.service_benefits)
			this.loadAnimations()
	}

	loadAnimations = () => {
		const categories = this.state.categories
		if (categories)
		{
			this.props.fetchAnimationsByDate(this.props.match.params.date)
			.then(ret => {
				let service_list = []
				let animations = ret.animations
				for (let i = 0; i < animations.length; i++)
				{
					for (let j = 0; j < categories.length; j++)
					{
						if (animations[i].category === categories[j]._id)
						{
							animations[i].category = categories[j]
							break
						}
					}
					if (this.props.features === "premium" && animations[i].service_benefit)
					{
						for (let j = 0; j < this.state.service_benefits.length; j++)
						{
							if (animations[i].service_benefit === this.state.service_benefits[j]._id)
							{
								if (service_list.indexOf(this.state.service_benefits[j].service) === -1)
									service_list.push(this.state.service_benefits[j].service)
								animations[i].service_benefit = this.state.service_benefits[j]
								break
							}
						}
					}
				}
				if (this.props.features === "premium" && service_list.length > 0)
				{
					this.props.fetchServiceWorkersByServiceList(service_list)
					.then(service_workers => {
						console.log("service_workers", service_workers)
						this.props.fetchServiceReservationsByServiceList(service_list)
						.then(service_reservations => {
							this._checkSlotsAvailable(ret.dates, animations, service_workers, service_reservations)
						})
						.catch(err => {
							console.log("can not fetch service_reservations", err)
						})
					})
					.catch(err => {
						console.log("can not fetch service_workers", err)
					})
				}
				else
				{
					for (let i = 0; i < ret.dates.length; i++)
					{
						if (typeof ret.dates[i].animation !== "string")
							continue
						for (let j = 0; j < animations.length; j++)
						{
							if (String(ret.dates[i].animation) === String(animations[j]._id))
							{
								ret.dates[i].animation = animations[j]
								break
							}
						}
					}
					this.setState({ dates: ret.dates, animations: animations })
				}
			})
			.catch(err => {
				console.log("can not fetch animations by date", err)
			})
		}
	}

	loadAnimationCategories = () => {
		if (this.props.animationCategories)
			this.setState({ categories: this.props.animationCategories })
		else
			this.props.fetchAnimationCategories()
	}

	loadServiceBenefits = () => {
		this.props.fetchServiceBenefits()
		.then(service_benefits => {
			this.setState({ service_benefits: service_benefits })
		})
		.catch(err => {
			console.log("could not fetch service_benefits", err)
		})
	}

	getFiveDays = () => {
		const timestamp = parseInt(this.props.match.params.date)
		const times = [
			timestamp - 172800,
			timestamp - 86400,
			timestamp,
			timestamp + 86400,
			timestamp + 172800
		]
		const dates = [
			new Date(times[0] * 1000),
			new Date(times[1] * 1000),
			new Date(times[2] * 1000),
			new Date(times[3] * 1000),
			new Date(times[4] * 1000)
		]
		let days = []
		for (let i = 0; i < 5; i++)
		{
			const month = dates[i].getMonth()
			days[i] = {
				dayNb: dates[i].getDate(),
				dayTxt: this.props.lang_assets.time.weekdays[dates[i].getDay()],
				monthNb: month + 1,
				monthTxt: this.props.lang_assets.time.months_short[month],
				time: times[i]
			}
			if (days[i].dayNb < 10)
				days[i].dayNb = '0' + days[i].dayNb
			if (days[i].monthNb < 10)
				days[i].monthNb = '0' + days[i].monthNb
		}
		return days
	}

	// copied from Services/Services.jsx
	_selectSlot = (service_benefit, start, end) => {
		const workers = []
		for (let i = 0; i < this.state.service_workers.length; i++)
			for (let j = 0; j < this.state.service_workers[i].services.length; j++)
				if (this.state.service_workers[i].services[j] === service_benefit)
					workers.push(this.state.service_workers[i])
		const reservations = tools.clone(this.state.service_reservations)
		const count_workers = {}
		for (let i = 0; i < workers.length; i++)
			count_workers[workers[i]._id] = 0
		let i = 0
		while (i < reservations.length)
		{
			if (reservations[i].start <= start && reservations[i].end >= end)
				count_workers[reservations[i].service_worker]++
			else
			{
				reservations.splice(i, 1)
				continue
			}
			i++
		}
		let min = null
		for (const [key, value] of Object.entries(count_workers))
		{
			if (min === null || min > value)
				min = value
			if (!key) // obligatoire pour masquer le warning "unused variable `key`"
				console.log(key)
		}
		for (const [key, value] of Object.entries(count_workers))
		{
			if (value !== min)
			{
				i = 0
				while (i < workers.length)
				{
					if (workers[i]._id === key)
					{
						workers.splice(i, 1)
						break
					}
					i++
				}
			}
		}
		const rand = Math.floor(Math.random() * workers.length)
		const worker = workers[rand]
		return (worker._id)
	}

	_checkSlotsAvailable = (dates, animations, service_workers, service_reservations) => {
		for (let i = 0; i < dates.length; i++)
		{
			const date = new Date(dates[i].start * 1000)
			const year = date.getFullYear()
			const weekday = date.getDay()
			const monthday = date.getDate()
			const month = date.getMonth()
			for (let j = 0; j < animations.length; j++)
			{
				const animation = animations[j]
				if (dates[i].animation === animation._id)
				{
					dates[i].animation = tools.clone(animation)
					if (animation.service_benefit && animation.service_benefit.simultaneous !== null)
					{
						// sélectionner les prestataires du service
						let workers = []
						for (let k = 0; k < service_workers.length; k++)
						{
							for (let l = 0; l < service_workers[k].services.length; l++)
							{
								if (service_workers[k].services[l] === animation.service_benefit._id)
								{
									workers.push(service_workers[k])
									break
								}
							}
						}

						// filtrer les prestataires disponibles
						let k = 0
						while (k < workers.length)
						{
							let remove = false
							let worker_available = false
							for (let l = 0; l < workers[k].working_dates.length; l++)
							{
								const working_dates = workers[k].working_dates[l]
								const start_working = Math.floor(new Date(year, working_dates.start_month - 1, working_dates.start_day).getTime() / 1000)
								const end_working = Math.floor(new Date(year, working_dates.end_month - 1, working_dates.end_day + 1).getTime() / 1000)
								if (start_working <= dates[i].start && end_working >= dates[i].start)
								{
									worker_available = true
									break
								}
							}
							if (worker_available === true)
							{
								worker_available = false
								for (let l = 0; l < workers[k].working_hours.length; l++)
								{
									const working_hours = workers[k].working_hours[l]
									const days = [
										working_hours.sunday,
										working_hours.monday,
										working_hours.tuesday,
										working_hours.wednesday,
										working_hours.thursday,
										working_hours.friday,
										working_hours.saturday
									]
									const start_working = Math.floor(new Date(year, month, monthday, working_hours.start_hour, working_hours.start_minute).getTime() / 1000)
									const end_working = Math.floor(new Date(year, month, monthday, working_hours.end_hour, working_hours.end_minute).getTime() / 1000)
									if (days[weekday] === true && start_working <= dates[i].start && end_working >= dates[i].start &&
										start_working <= dates[i].end && end_working >= dates[i].end)
									{
										worker_available = true
										break
									}
								}
								if (!worker_available)
									remove = true
							}
							else
								remove = true
							if (remove)
							{
								workers.splice(k, 1)
								continue
							}
							k++
						}
						dates[i].animation.nb_slots = animation.service_benefit.simultaneous * workers.length

						// sélectionner les réservations du service
						let reservations = []
						for (let k = 0; k < service_reservations.length; k++)
						{
							const service_reservation = service_reservations[k]
							if (service_reservation.start >= dates[i].start && service_reservation.end <= dates[i].end)
							{
								for (let l = 0; l < workers.length; l++)
								{
									if (service_reservations[k].service_worker === workers[l]._id)
									{
										reservations.push(service_reservations[k])
										break
									}
								}
							}
						}
						dates[i].reservations = reservations
					}
					break
				}
			}
		}
		this.setState({ dates: dates, animations: animations, service_workers: service_workers, service_reservations: service_reservations })
	}

	bookService = (service_benefit, start, end) => {
		const reservation = {
			answers: null,
			service: service_benefit.service,
			service_benefit: service_benefit._id,
			service_worker: this._selectSlot(service_benefit._id, start, end),
			start: start,
			end: end,
			user: this.props.user._id
		}
		const book_service = {
			service_benefit: service_benefit,
			reservation: reservation
		}
		this.setState({ book_service: book_service })
	}

	render() {
		let animationsJSX = (
			<div className="animations loading">
				<Loader />
			</div>
		)
		if (this.state.book_service)
		{
			return (
				<Booking
					pathname={this.props.history.location.pathname}
					user={this.props.user}
					updateShell={this.props.updateShell}
					updateLogs={this.props.updateLogs}
					reservation={this.state.book_service.reservation}
					service_benefit={this.state.book_service.service_benefit}
					theme={this.props.theme}
					lang_assets={this.props.lang_assets}
					postServiceReservation={this.props.postServiceReservation}
					postUpdateBirthday={this.props.postUpdateBirthday}
					postUpdatePhone={this.props.postUpdatePhone}
				/>
			)
		}
		else if (this.state.dates)
		{
			let dates = tools.clone(this.state.dates)
			for (let i = 0; i < dates.length; i++)
			{
				const time = new Date(dates[i].start * 1000)
				let hours = time.getHours()
				if (hours < 10)
					hours = '0' + hours
				let minutes = time.getMinutes()
				if (minutes < 10)
					minutes = '0' + minutes
				let duration = dates[i].end - dates[i].start
				let durationTxt = null
				if (duration < 60 * 60)
					durationTxt = Math.round(duration / 60) + "min"
				else
				{
					let sub = Math.floor(duration / 3600) * 3600
					let mins = duration - sub
					let m = Math.floor(mins / 60)
					if (m < 10)
						m = '0' + m
					durationTxt = Math.floor(duration / 3600) + "h" + m
				}
				dates[i].hours = hours
				dates[i].minutes = minutes
				dates[i].durationTxt = durationTxt
			}
			animationsJSX = (
				<div className="animations">
					{dates && dates.map(({ _id, animation, reservations, hours, minutes, durationTxt, start, end }) => {
						let background = ''
						let border = ''
						if (animation.category && animation.category._id)
						{
							background = "#" + animation.category.color
							border = '2px solid #' + animation.category.color
						}
						let name = ""
						let content = ""
						if (animation.translations)
						{
							for (let i = 0; i < animation.translations.length; i++)
							{
								if (animation.translations[i].lang === this.props.user.lang)
								{
									name = animation.translations[i].name
									content = animation.translations[i].content
									break
								}
							}
						}
						else
							console.log("no content", animation)
						let nb_slots = null
						let service = null
						if (this.props.features === "premium" && animation.service_benefit)
						{
							const style = { color: '#' + this.props.theme.color1 }
							if (animation.service_benefit.simultaneous === null)
							{
								nb_slots = (<p className="slots"><b>{this.props.lang_assets.activities.nb_slots} :</b> {this.props.lang_assets.activities.unlimited}</p>)
								service = (<p className="book" onClick={this.bookService.bind(this, animation.service_benefit, start, end )} style={style}>{this.props.lang_assets.activities.subscribe}</p>)
							}
							else
							{
								let nb_reservations = 0
								if (reservations)
								{
									for (let i = 0; i < reservations.length; i++)
									{
										if (reservations[i].self === true)
											nb_reservations++
										nb_reservations += (reservations[i].participants) ? reservations[i].participants.length : 0
									}
								}
								nb_slots = (<p className="slots"><b>{this.props.lang_assets.activities.nb_slots} :</b> {nb_reservations}/{animation.nb_slots}</p>)
								if (nb_reservations < animation.nb_slots)
									service = (<p className="book" onClick={this.bookService.bind(this, animation.service_benefit, start, end )} style={style}>{this.props.lang_assets.activities.subscribe}</p>)
								else
									service = (<p className="full">{this.props.lang_assets.activities.full}</p>)
							}
						}
						return (
							<div className="elem" key={_id} style={{ borderLeft: border }}>
								<div className="dot" style={{ backgroundColor: background }}></div>
								<div className="details">
									{/* <p className="title">{hours}h{minutes} {name} - {durationTxt} </p> */}
									<div className="flex justify-between items-start mb-0.5">
										<p className="text-base font-semibold">{name}</p>
										<span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800">
											{hours}h{minutes}
										</span>
									</div>
									<div className="flex items-start items-center space-x-2 mb-2">
										<ClockIcon className="h-4 w-4 text-gray-300" aria-hidden="true" />
										<p className="text-sm text-gray-500">{durationTxt}</p>
									</div>
									<div className="mb-1" dangerouslySetInnerHTML={{ __html: content }}></div>
									{nb_slots}
									{service}
								</div>
							</div>
						)
					})}
					<p className="indicators">
						{this.state.categories && this.state.categories.map(({ _id, translations, color }) => {
							let name = ""
							for (let i = 0; i < translations.length; i++)
							{
								if (translations[i].lang === this.props.user.lang)
								{
									name = translations[i].name
									break
								}
							}
							return (<span key={_id}><span style={{ backgroundColor: '#' + color }} className="pastille"></span> {name}<br /></span>)
						})}
					</p>
				</div>
			)
		}
		const days = this.getFiveDays()
		let day_k = -1
		return (
			<div className="AnimationDay">
				{/* <div className="header" style={{ backgroundColor: '#' + this.props.theme.color1 }}> */}
				<div className="header" style={{ backgroundColor: '#' + this.props.theme.color1 }}>
					<div className="pt-12 pb-5">
						<h1 className="text-3xl text-center">{this.props.lang_assets.activities.title}</h1>
					</div>
					<div className="days">
						{days.map(({ dayNb, monthTxt, time }) => {
							day_k++
							let classname = "day"
							if (day_k === 0)
								classname += ' first'
							else if (day_k === 2)
								classname += ' select'
							if (day_k !== 2)
							{
								let url = this.props.lang_assets.paths.activities + "/" + time
								return (
									<Link to={url} key={day_k}>
										<div className={classname}>
											<p>
												<strong>{dayNb}</strong><br />
												{monthTxt}
											</p>
										</div>
									</Link>
								)
							}
							else
							{
								return (
									<div className={classname} key={day_k}>
										<p>
											<strong>{dayNb}</strong><br />
											{monthTxt}
										</p>
									</div>
								)
							}
						})}
					</div>
					<div style={{ clear: 'both' }}></div>
				</div>
				{animationsJSX}
			</div>
		)
	}
}

export default AnimationDays