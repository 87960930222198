import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import SubscribeStepCompany from './SubscribeStepCompany'
import SubscribeStepPhone from './SubscribeStepPhone'
import SubscribeStepEmail from './SubscribeStepEmail'
import SubscribeStepName from './SubscribeStepName'
import SubscribeStepEnd from './SubscribeStepEnd'
import SubscribeStepPWA from './SubscribeStepPWA'
import SubscribeStepNotifications from './SubscribeStepNotifications'
import UpdateEmail from './UpdateEmail'
import Cookies from 'js-cookie'
import './Signin.css'
import M from 'materialize-css'

class SubscribeDemo extends Component {
	constructor(props) {
		super(props)
		this.steps = [
			'lobby',
			'stepEmail',
			'stepName',
			'stepCompany',
			'stepPhone',
			'stepEnd',
			'updateEmail'
		]
		this.state = {
			user: {
				email : '',
				company: '',
				phone: '',
				password : '',
				civility: '',
				firstname: '',
				lastname: '',
				t_password: ''
			},
			error: null,
			success: null,
			step: this.steps[1]
		}
		this.showInstallPrompt = (this.props.installEvent) ? true : false
	}

	componentDidUpdate() {
		const elems = document.querySelectorAll('select')
		M.FormSelect.init(elems)
	}

	setErrorMsg = (msg) => {
		this.setState({ error: msg })
	}

	modalRedirection = () => {
		if (this.state.step === 'help')
			this.validateHelp()
		else if (this.state.step === 'updateEmail')
			this.validateUpdateEmail()
	}

	validateUpdateEmail = () => {
		this.setState({ step : "/" })
	}

	gotoHelp = () => {
		this.setState({ step: 'help' })
	}

	gotoMail = () => {
		this.setState({ step: 'updateEmail' })
	}
	
	validateStepActivationMail = (user) => {
		const success = "Un lien d'activation à été envoyé sur votre adresse " + user.email
		this.setState({ user: user, success: success, step: 'step2' })
	}

	validateStepEmail = () => {
		this.setState({ step: "stepName" })
	}

	validateStepCompany = () => {
		this.setState({ step: "stepPhone" })
	}

	validateStepPhone = (data) => {
		const year = new Date().getFullYear()
		const user = {
			client_number: 0,
			firstname: this.state.user.firstname,
			lastname: this.state.user.lastname,
			email: this.state.user.email,
			password: this.state.user.password,
			company: this.state.user.company,
			phone: data.v_phone,
			birthday: null,
			civility: null,
			arrival: "01/01/" + year,
			departure: "31/12/" + year
		}
		this.props.postSubscribeUser(user)
		.then(user => {
			if (!user)
			{
				this.setErrorMsg('Cette adresse e-mail existe déjà')
				console.log("email adress already exists")
				// vérifier si le mdp est ok, puis connecter...
			}
			else
			{
				this.setState({ step: "stepEnd", real_user: user })
			}
		})
		.catch(err => {
			this.setErrorMsg('Une erreur s\'est produite.')
			console.log(err)
		})
	}

	validateStepName = () => {
		this.setState({ step: "stepCompany" })
	}

	validateStepEnd = () => {
		if (this.showInstallPrompt === true)
			this.setState({ step: "stepPWA" })
		else if ('serviceWorker' in navigator && 'PushManager' in window && Notification.permission === 'default' && this.props.text)
			this.setState({ step: "stepNotifications" })
		else
			this.connectUser()
	}

	validateStepPWA = () => {
		if ('serviceWorker' in navigator && 'PushManager' in window && Notification.permission === 'default' && this.props.text)
			this.setState({ step: "stepNotifications" })
		else
			this.connectUser()
	}

	validateStepNotifications = () => {
		this.connectUser()
	}

	connectUser = (bypass_user = null) => {
		if (bypass_user)
		{
			Cookies.set('session', bypass_user.session)
			localStorage.setItem("session", bypass_user.session)
			this.props.updateUser(bypass_user, bypass_user.session)
			this.setState({ step: "lobby", user: bypass_user })
		}
		else
		{
			const user = {
				identifiant: this.state.user.email,
				password: this.state.user.password
			}
			this.props.postConnectUser(user)
			.then(user => {
				Cookies.set('session', user.session)
				localStorage.setItem("session", user.session)
				this.props.updateUser(user, user.session)
				this.setState({ step: "lobby", user: user })
			})
			.catch(err => console.log("can not connect user", err))
		}
	}

	updateUser = (data) => {
		let user_obj = {
			...this.state.user
		}
		if (data.v_email && data.v_password)
		{
			user_obj.email = data.v_email
			user_obj.password = data.v_password
			user_obj.t_password = data.t_password		
		}
		else if (data.v_email && this.state.step === 'updateEmail')
			user_obj.email = data.v_email
		else if (data.v_lastname && data.v_firstname)
		{
			user_obj.lastname = data.v_lastname
			user_obj.firstname = data.v_firstname
		}
		else if (data.v_phone)
			user_obj.phone = data.v_phone
		else if (data.v_company)
			user_obj.company = data.v_company
		this.setState({ user : user_obj })
	}

	handlePreviousBtn = () => {
		const currIndex = this.steps.indexOf(this.state.step)
		let valuePreviousStep = this.steps[currIndex - 1]
		if (this.state.step === 'help')
			valuePreviousStep = 'stepActivationCode'
		else if (this.state.step === 'updateEmail')
			valuePreviousStep = 'stepEnd'
		this.setState({ step: valuePreviousStep })
	}

	render() {
		const totalSteps = 4
		let actualStep = this.steps.indexOf(this.state.step)
		if (actualStep > 2 && this.state.no_client === false)
			actualStep--
		const titleStep = actualStep + "/" + totalSteps
		if (this.state.step === 'stepEmail')
		{
			return (
				<SubscribeStepEmail 
					theme={this.props.theme}
					connectUser={this.connectUser}
					postConnectUser={this.props.postConnectUser}
					postCheckUserEmail={this.props.postCheckUserEmail}
					validateStepEmail={this.validateStepEmail}
					updateUser={this.updateUser}
					handlePreviousBtn={this.handlePreviousBtn}
					user={this.state.user}
					stepNb={titleStep}
				/>
			)
		}
		else if (this.state.step === 'stepCompany')
		{
			return (
				<SubscribeStepCompany
					theme={this.props.theme}
					validateStepCompany={this.validateStepCompany}
					updateUser={this.updateUser}
					handlePreviousBtn={this.handlePreviousBtn}
					user={this.state.user}
					stepNb={titleStep}
				/>
			)
		}
		else if (this.state.step === 'stepPhone')
		{
			return (
				<SubscribeStepPhone
					theme={this.props.theme}
					error_msg={this.state.error}
					validateStepPhone={this.validateStepPhone}
					updateUser={this.updateUser}
					handlePreviousBtn={this.handlePreviousBtn}
					user={this.state.user}
					stepNb={titleStep}
				/>
			)
		}
		else if (this.state.step === 'stepName')
		{
			return (
				<SubscribeStepName
					theme={this.props.theme}
					app_type={this.props.app_type}
					validateStepName={this.validateStepName}
					updateUser={this.updateUser}
					handlePreviousBtn={this.handlePreviousBtn}
					user={this.state.user}
					stepNb={titleStep}
				/>
			)
		}
		else if (this.state.step === 'stepEnd')
		{
			return (
				<SubscribeStepEnd
					theme={this.props.theme}
					error_msg={this.state.error}
					validateStepEnd={this.validateStepEnd}
					gotoMail={this.gotoMail}
					user={this.state.user}
				/>
			)
		}
		else if (this.state.step === 'stepPWA')
		{
			return (
				<SubscribeStepPWA
					theme={this.props.theme}
					validateStepPWA={this.validateStepPWA}
					installEvent={this.props.installEvent}
					handleInstallApp={this.props.handleInstallApp}
					deleteInstallEvent={this.props.deleteInstallEvent}
					user={this.state.user}
				/>
			)
		}
		else if (this.state.step === 'stepNotifications')
		{
			return (
				<SubscribeStepNotifications
					theme={this.props.theme}
					validateStepNotifications={this.validateStepNotifications}
					user={this.state.user}
				/>
			)
		}
		else if (this.state.step === 'updateEmail')
		{
			return(
			<div>
				<UpdateEmail
					theme={this.props.theme}
					modalRedirection={this.modalRedirection}
					updateUser={this.updateUser}
					handlePreviousBtn={this.handlePreviousBtn}
					user={this.state.user}
				/>
			</div>)
		}
		else
		{
			return (
				<div>
					<Redirect to="/" />
				</div>
			)
		}
	}
}

export default SubscribeDemo