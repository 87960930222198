import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Lobby extends Component {
	render() {
		let welcome = null
		for (let i = 0; i < this.props.welcome_txt.length; i++)
		{
			if (this.props.welcome_txt[i].lang === this.props.lang_default)
			{
				welcome = this.props.welcome_txt[i].welcome
				break
			}
		}
		const style = {
			backgroundImage: "url('" + this.props.background_img + "')"
		}
		return (
		<div className="h-screen bg-cover bg-center" style={style}>
			<div className="h-screen bg-black bg-opacity-60">
				<div className="absolute w-screen top-1/3"> 
					<div className="flex flex-col gap-y-5 mx-8">
						<div className="mb-14 space-y-4">
							<h1 className="text-5xl text-center text-white">Bienvenue</h1>
							<h1 className="text-3xl text-center text-white">{welcome}</h1>
						</div>
					<Link to='/inscription' className="waves-effect waves-light text-center font-semibold py-5 px-4 rounded-full text-white shadow-xl text-xl" style={{backgroundColor: '#' + this.props.theme.color1}}>Créer son compte</Link>
					<Link to='/connexion' className="waves-effect waves-light border border-white text-center font-semibold py-5 px-4 rounded-full text-white shadow-xl text-xl">Se connecter</Link>
					</div>
				</div>
			</div>
		</div>
		)
	}
}

export default Lobby