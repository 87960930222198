import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import picture from '../../img/default.jpg'
import Loader from '../UI/Loader'
import '../Home/Home.css'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'


class Pages extends Component
{
	constructor(props)
	{
		super(props)
		this.state = {
			page: null,
			service: null,
			mounted: false,
			revealContent: false,
			heights : []
		}
	}

	componentDidMount()
	{
		this.props.updateLogs(this.props.history.location.pathname, 'Pages')
		this.loadPage()
		this.props.updateShell(null, true, this.props.history.location.pathname)
		if (!this.props.icons)
			this.props.fetchIcons()
		this.props.fetchNotifications()
	}

	componentDidUpdate(prevProps, prevState)
	{
		if (prevProps.pages !== this.props.pages)
			this.loadPage()
		if (prevState.page !== this.state.page)
		{
			const page = this.state.page
			let category = null
			if (this.props.categories && this.props.lists)
			{
				for (let i = 0; i < this.props.categories.length; i++)
				{
					if (this.props.categories[i].type === "list")
					{
						let list = null
						for (let j = 0; j < this.props.lists.length; j++)
						{
							if (this.props.lists[j].category === this.props.categories[i]._id)
							{
								list = this.props.lists[j]
								break
							}
						}
						if (list)
						{
							const elements = list.elements
							for (let j = 0; j < elements.length; j++)
							{
								if (elements[j].page === page._id)
								{
									category = this.props.categories[i]
									break
								}
							}
						}
					}
				}
			}
			let infos = {
				page: page._id,
				from_category: null,
				from_group: null
			}
			if (category)
			{
				let group = null
				infos = {
					...infos,
					from_category: category._id
				}
				if (this.props.groups)
				{
					for (let i = 0; i < this.props.groups.length; i++)
					{
						if (this.props.groups[i]._id === category.group)
						{
							group = this.props.groups[i]
							break
						}
					}
				}
				if (group)
				{
					infos = {
						...infos,
						from_group: group._id
					}
				}
			}
			this.props.updateLogs(this.props.history.location.pathname, 'Pages', infos)
			setTimeout(() => {
				this.revealContent(0)
			})
		}
	}

	_loadPageService = (page) => {
		if (page.service && page.service.service)
		{
			this.props.fetchServiceByID(page.service.service)
			.then(service => {
				this.setState({ page: page, service: service, mounted: true })
			})
		}
		else
			this.setState({ page: page, mounted: true })
	}

	loadPage = () => {
		const pages = this.props.pages
		const length = this.props.lang_assets.paths.pages.length + 1
		const url = this.props.history.location.pathname.substr(length)
		let page = null
		if (pages)
		{
			for (let i = 0; i < pages.length; i++)
			{
				let page_url = ""
				for (let j = 0; j < pages[i].translations.length; j++)
				{
					if (pages[i].translations[j].lang === this.props.user.lang)
					{
						page_url = pages[i].translations[j].url
						break
					}
				}
				if (page_url === url)
				{
					page = pages[i]
					break
				}
			}
			if (page)
			{
				page.timestamp = (page.edited_time) ? page.edited_time : page.created_time
				this.props.fetchPageCheckUpdate(page)
				.then(fetch_page => {
					if (fetch_page !== 1 && fetch_page !== false)
						this._loadPageService(fetch_page)
					else if (fetch_page === 1 && !page.cover)
					{
						this.props.fetchPageByURL(url)
						.then(page => {
							this._loadPageService(page)
						})
						.catch(err => console.log(err))
					}
					else
						this._loadPageService(page)
				})
				.catch(err => {
					console.log("can not check update page", err)
				})
			}
			else
			{
				this.props.fetchPageByURL(url)
				.then(page => {
					this._loadPageService(page)
				})
				.catch(err => console.log(err))
			}
		}
		else
		{
			this.props.fetchPageByURL(url)
			.then(page => {
				this._loadPageService(page)
			})
			.catch(err => console.log(err))
		}
	}

	revealContent = (id) => {
		const div = document.getElementById(id)
		let heights = this.state.heights
		if (div != null)
		{
			let content = div.getElementsByClassName('reveal-on-tap pt-3 pb-5')[0]
			if (heights[id] === undefined)
			{
				const clone = content.cloneNode(true)
				clone.style.cssText = "position: fixed; top -9999px; opacity: 0"
				document.body.appendChild(clone)
				let height = clone.offsetHeight
				height += 20
				clone.parentNode.removeChild(clone)
				height *= 2
				heights[id] = height + "px"
			}
			if (content.style.maxHeight === heights[id]) //section fermée
			{
				content.style.transition = "max-height 0.4s ease, padding-bottom 0.4s ease"
				content.style.paddingBottom = 0
				content.style.maxHeight = 0
				heights = heights.filter((_, index) => index !== id) //Pour vider le tableau des sections fermées
			}
			else
			{
				content.style.transition = "max-height 0.8s ease, padding-bottom 0.8s ease"
				content.style.paddingBottom = "20px"
				content.style.maxHeight = heights[id]
			}
			this.setState({ heights : heights })
		}
	}

	printSection = (title, html, icon, phone, email, website, localisation, facebook, instagram, key) => {
		let jsx = null
		let pictoJSX = null
		let titleJSX = null
		let prev = null
		const icons = this.props.icons
		const theme = this.props.theme
		for (let i = 0; i < icons.length; i++)
		{
			if (icons[i]._id === icon)
			{
				for (let j = 0; j < icons[i].fill.length; j++)
				{
					if (icons[i].fill[j].theme === theme._id)
					{
						prev = icons[i].fill[j].icon
						break
					}
				}
				break
			}
		}
		let phoneJSX = null
		let emailJSX = null
		let websiteJSX = null
		let localisationJSX = null
		let facebookJSX = null
		let instagramJSX = null
		const chevronDown = <ChevronDownIcon className="h-6 w-6 flex-shrink-0"/>
		const chevronUp = <ChevronUpIcon className="h-6 w-6 flex-shrink-0"/>
		if (phone)
		{
			const phone_number = "tel:" + phone
			phoneJSX = (
				<a href={phone_number} onClick={this.revealContent.bind(this, key)}>
					<span className="inline-flex items-center rounded-full bg-indigo-100 px-3 py-0.5 text-sm font-medium text-indigo-800">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="text-indigo-800 w-6 h-6">
							<path fillRule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z" clipRule="evenodd" />
						</svg>
						Téléphone
					</span>
				</a>
			)
		}
		if (email)
		{
			const email_txt = "mailto:" + email
			emailJSX = (
				<a href={email_txt} onClick={this.revealContent.bind(this, key)}>
					<span className="inline-flex items-center rounded-full bg-indigo-100 px-3 py-0.5 text-sm font-medium text-indigo-800">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="text-indigo-800 w-6 h-6">
							<path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
							<path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
						</svg>
						E-mail
					</span>
				</a>
			)
		}
		if (website)
		{
			websiteJSX = (
				<a href={website} onClick={this.revealContent.bind(this, key)}>
					<span className="inline-flex items-center rounded-full bg-indigo-100 px-3 py-0.5 text-sm font-medium text-indigo-800">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="text-indigo-800 w-6 h-6">
							<path d="M21.721 12.752a9.711 9.711 0 00-.945-5.003 12.754 12.754 0 01-4.339 2.708 18.991 18.991 0 01-.214 4.772 17.165 17.165 0 005.498-2.477zM14.634 15.55a17.324 17.324 0 00.332-4.647c-.952.227-1.945.347-2.966.347-1.021 0-2.014-.12-2.966-.347a17.515 17.515 0 00.332 4.647 17.385 17.385 0 005.268 0zM9.772 17.119a18.963 18.963 0 004.456 0A17.182 17.182 0 0112 21.724a17.18 17.18 0 01-2.228-4.605zM7.777 15.23a18.87 18.87 0 01-.214-4.774 12.753 12.753 0 01-4.34-2.708 9.711 9.711 0 00-.944 5.004 17.165 17.165 0 005.498 2.477zM21.356 14.752a9.765 9.765 0 01-7.478 6.817 18.64 18.64 0 001.988-4.718 18.627 18.627 0 005.49-2.098zM2.644 14.752c1.682.971 3.53 1.688 5.49 2.099a18.64 18.64 0 001.988 4.718 9.765 9.765 0 01-7.478-6.816zM13.878 2.43a9.755 9.755 0 016.116 3.986 11.267 11.267 0 01-3.746 2.504 18.63 18.63 0 00-2.37-6.49zM12 2.276a17.152 17.152 0 012.805 7.121c-.897.23-1.837.353-2.805.353-.968 0-1.908-.122-2.805-.353A17.151 17.151 0 0112 2.276zM10.122 2.43a18.629 18.629 0 00-2.37 6.49 11.266 11.266 0 01-3.746-2.504 9.754 9.754 0 016.116-3.985z" />
						</svg>
						Site web
					</span>
				</a>
			)
		}
		if (localisation)
		{
			localisationJSX = (
				<a href={localisation} onClick={this.revealContent.bind(this, key)}>
					<span className="inline-flex items-center rounded-full bg-indigo-100 px-3 py-0.5 text-sm font-medium text-indigo-800">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="text-indigo-800 w-6 h-6">
							<path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM6.262 6.072a8.25 8.25 0 1010.562-.766 4.5 4.5 0 01-1.318 1.357L14.25 7.5l.165.33a.809.809 0 01-1.086 1.085l-.604-.302a1.125 1.125 0 00-1.298.21l-.132.131c-.439.44-.439 1.152 0 1.591l.296.296c.256.257.622.374.98.314l1.17-.195c.323-.054.654.036.905.245l1.33 1.108c.32.267.46.694.358 1.1a8.7 8.7 0 01-2.288 4.04l-.723.724a1.125 1.125 0 01-1.298.21l-.153-.076a1.125 1.125 0 01-.622-1.006v-1.089c0-.298-.119-.585-.33-.796l-1.347-1.347a1.125 1.125 0 01-.21-1.298L9.75 12l-1.64-1.64a6 6 0 01-1.676-3.257l-.172-1.03z" clipRule="evenodd" />
						</svg>
						Plan
					</span>
				</a>
			)
		}
		if (facebook)
		{
			facebookJSX = (
				<a href={facebook} onClick={this.revealContent.bind(this, key)}>
					<span className="inline-flex items-center rounded-full bg-indigo-100 px-3 py-0.5 text-sm font-medium text-indigo-800">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
							<path fillRule="evenodd" d="M5.337 21.718a6.707 6.707 0 01-.533-.074.75.75 0 01-.44-1.223 3.73 3.73 0 00.814-1.686c.023-.115-.022-.317-.254-.543C3.274 16.587 2.25 14.41 2.25 12c0-5.03 4.428-9 9.75-9s9.75 3.97 9.75 9c0 5.03-4.428 9-9.75 9-.833 0-1.643-.097-2.417-.279a6.721 6.721 0 01-4.246.997z" clipRule="evenodd" />
						</svg>
						Facebook
					</span>
				</a>
			)
		}
		if (instagram)
		{
			instagramJSX = (
				<a href={instagram} onClick={this.revealContent.bind(this, key)}>
					<span className="inline-flex items-center rounded-full bg-indigo-100 px-3 py-0.5 text-sm font-medium text-indigo-800">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="text-indigo-800 w-6 h-6">
							<path d="M12 9a3.75 3.75 0 100 7.5A3.75 3.75 0 0012 9z" />
							<path fillRule="evenodd" d="M9.344 3.071a49.52 49.52 0 015.312 0c.967.052 1.83.585 2.332 1.39l.821 1.317c.24.383.645.643 1.11.71.386.054.77.113 1.152.177 1.432.239 2.429 1.493 2.429 2.909V18a3 3 0 01-3 3h-15a3 3 0 01-3-3V9.574c0-1.416.997-2.67 2.429-2.909.382-.064.766-.123 1.151-.178a1.56 1.56 0 001.11-.71l.822-1.315a2.942 2.942 0 012.332-1.39zM6.75 12.75a5.25 5.25 0 1110.5 0 5.25 5.25 0 01-10.5 0zm12-1.5a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
						</svg>
						Instagram
					</span>
				</a>
			)
		}
		if (title !== null)
		{
			const alt = "picto " + title
			pictoJSX = (prev !== null) ? <img src={prev} alt={alt} className="w-6 float-left mr-3" /> : null
			titleJSX = 
				<div className="flex justify-between space-x-2 h-12">
					<p className={`font-semibold flex-grow ${this.state.heights[key] ? '' : 'truncate'}`}>{title}</p>
					{this.state.heights[key] ? chevronDown : chevronUp}
				</div>
			jsx = (
				<div className="page bg-white rounded-xl pt-7 px-6 m-5 min-h-20 text-base filter drop-shadow-sm" id={key} key={key} onClick={this.revealContent.bind(this, key)}>
					{pictoJSX} {titleJSX}
					{phoneJSX}{emailJSX}{websiteJSX}{localisationJSX}{facebookJSX}{instagramJSX}
					<div className="reveal-on-tap pt-3 pb-5" dangerouslySetInnerHTML={{ __html: html }}></div>
				</div>
			)
		}
		else
		{
			jsx = (
				<div className="page bg-white rounded-xl pt-7 px-6 m-5 min-h-20 text-base filter drop-shadow-sm" id={key} key={key} onClick={this.revealContent.bind(this, key)}>
					<div className="reveal-on-tap pt-3 pb-5" dangerouslySetInnerHTML={{ __html: html }}></div>
				</div>
			)
		}
		return (jsx)
	}

	render() {
		const page = this.state.page
		if (page && this.props.icons && this.state.mounted)
		{
			let image = this.state.page.cover
			if (image === null)
				image = picture
			let pictureJSX = null
			if (image)
			{
				pictureJSX = (
					// <div className="photoWrap">
					<div className="fixed overflow-y-hidden top-0 max-h-30">
						<img src={image} alt="banner" />
					</div>
				)
			}
			const sections = this.state.page.sections
			let section_k = -1
			let service = null
			if (page.service)
			{
				let title = ""
				for (let j = 0; j < page.service.translations.length; j++)
				{
					if (page.service.translations[j].lang === this.props.user.lang)
					{
						title = page.service.translations[j].title
						break
					}
				}
				const styles = { backgroundColor: '#' + this.props.theme.color1, borderColor: '#' + this.props.theme.color1 }
				if (this.state.service)
				{
					let url = ""
					for (let i = 0; i < this.state.service.translations.length; i++)
					{
						if (this.state.service.translations[i].lang === this.props.user.lang)
						{
							url = this.state.service.translations[i].url
							break
						}
					}
					url = this.props.lang_assets.paths.services + "/" + url
					service = (<Link to={url}><button type="button" style={styles}>{title}</button></Link>)
				}
				else if (page.service.link)
					service = (<a href={page.service.link}><button type="button" style={styles}>{title}</button></a>)
			}
			return (
				<div>
					{pictureJSX}
					{/* <div className="min-h-80 w-full pt-10 p-0 top-40 absolute bg-white rounded-3xl"> */}
					<div className="contentWrapper bg-gray-100">
						{
							sections.map(({ translations, icon, phone, email, website, localisation, facebook, instagram }) => {
								let title = ""
								let html = ""
								for (let j = 0; j < translations.length; j++)
								{
									if (translations[j].lang === this.props.user.lang)
									{
										title = translations[j].title
										html = translations[j].html
										break
									}
								}
								section_k++
								let ret = this.printSection(title, html, icon, phone, email, website, localisation, facebook, instagram, section_k)
								return (ret)
							})
						}
						{service}
					</div>
				</div>
			)
		}
		else
		{
			return (
				<div className="loading">
					<Loader />
				</div>
			)
		}
	}
}

export default Pages;